/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description:
 */
import { LocalOperator } from '@/utils/business.js'
import { isSameDay } from '@/utils/date.js'

function useLoginLogic () {
  /** 今日是否登入过 */
  function isLoginedToday () {
    const { keys, get } = LocalOperator
    const loginedTime = get(keys.todayLoginedTimestamp)
    if (!loginedTime) return false
    return isSameDay(+new Date(), loginedTime)
  }
  return { isLoginedToday }
}

export { useLoginLogic }
