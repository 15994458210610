/*
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 各活动共用的事件
 */
import { useEncryptActData } from '@/views/Activity'
import { getVuexInstance, handleTrack } from '@/utils'

const { getAesEncryptLoginData } = useEncryptActData()

export class CommonActions {
  // 发送埋点
  sendTrack = (...arg) => handleTrack(...arg);

  // 子应用获取登入参数
  getLoginParams () {
    const _store = getVuexInstance()
    const { trace_id: traceId } = _store?.state?.tool || {}
    return {
      traceId,
      ...getAesEncryptLoginData()
    }
  }
}
