<!--
 * @Date: 2021-12-15 15:02:48
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-07-05 17:19:38
 * @FilePath: /dc-pay-front/src/components/FormCard.vue
-->
<template>
  <div :class="['main-card', { 'card-open': isOpen }]">
    <div class="main-card__head" @click="onclickHead">
      <div class="left">
        <slot name="logo"></slot>
        <div class="main-title">{{ title }}</div>
      </div>
      <div class="right">
        <slot name="headRight"></slot>

        <van-icon
          v-show="!hiddenArrowIcon"
          class="right-icon"
          :name="isOpen ? 'arrow-up' : 'arrow-down'"
        />
      </div>
    </div>
    <slot v-if="isOpen"></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    // 控制隐藏折叠箭头
    hiddenArrowIcon () {
      return this.disabled || this.isOpen
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否展开
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onclickHead () {
      this.$emit('clickHead')
    }
  }
}
</script>
<style scoped lang="less">
.card-open {
  padding-bottom: var(--dp-sp-7xl);
}
.main-card {
  background: var(--dp-bg-secondary);
  border-radius: var(--dp-b-rd-md);
  .main-card__head {
    padding: var(--dp-sp-4xl);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
    }
    .main-title {
      vertical-align: middle;
      font-size: var(--dp-text-xl);
      font-weight: bold;
      color: var(--dp-text-secondary);
      margin-left: var(--dp-sp-xl);
    }
  }
}
.right-icon {
  font-size: var(--dp-text-3xl);
  color: var(--dp-text-tertiary);
}
</style>
