/*
 * @Date: 2022-06-23 10:35:30
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-05-14 15:25:07
 * @FilePath: /dc-pay-front/src/utils/business/cart.js
 */
import { shoppingCartConfig } from '@/utils/globalData.js'
import { $t } from '@/i18n'
import { Toast } from 'vant'
import { getVueVm, handleTrack } from '@/utils/index.js'
import {
  filterPropByProducts,
  productTransformData
} from '@/utils/business/product.js'

/**
 * @description: 加入购物车通用埋点
 * @param {array} products 商品列表
 * @param {string: 'group' 组合 | 'favorite'收藏夹 | 'single'单商品} touch_type 触发类型
 * @param {object} otherData 额外埋点入参
 */
export function sendTrackAddCartCommon ({
  products,
  touch_type,
  otherData = {}
}) {
  handleTrack('common_add_shopping_cart', {
    touch_type,
    cp_product_list: productTransformData(products),
    ...otherData
  })
}
// 增加单个商品到购物车
export async function validateBeforeUpdateCartCount ({
  product,
  targetCount,
  vm
}) {
  const { state, dispatch } = vm.$store
  const { maxLength } = shoppingCartConfig
  const { count, id: product_id } = product
  const isAdd = count < targetCount
  const cartLength = state.cart.cartLength

  if (isAdd && cartLength >= maxLength) {
    tipCartOverbalance()
    return
  }

  const product_cart = [{ product_id, count: targetCount }]

  dispatch('tool/showLoading')
  try {
    await dispatch('cart/updateCartCount', { product_cart })
  } catch (error) {
    dispatch('tool/closeLoading')
    return
  }
  dispatch('tool/closeLoading')
  product.count = targetCount
  if (isAdd) {
    const cloneProduct = Object.assign({}, product)
    cloneProduct.count = 1
    const products = [cloneProduct]
    sendTrackAddCartCommon({ products, touch_type: 'single' })
  }
}

/**
 * @description: 批量增加商品到购物车
 * @use1 多个商品加入购物车 validateBeforeUpdateBatchCartCount({products, count})
 * @use2 收藏夹组加入购物车 validateBeforeUpdateBatchCartCount({favorite_group_id, mode, count})
 * @param favorite_group_id 表示加入收藏夹组（不传products）
 * @param products 表示加入多个商品
 * @param {number} count 购物车增量(加为正数，减为负数)
 * @param {number} mode: use1传 0；use2传 1或-1（ 0 直接更新商品数量；1 组合加入购物车；-1 组合减少购物车）
 * @return {promise: boolean} true 表示加入成功, 其他则意味着失败
 */
export async function validateBeforeUpdateBatchCartCount ({
  products,
  mode = 0,
  count,
  favorite_group_id
}) {
  const vm = getVueVm()
  if (!vm) return

  const { $store } = vm
  const { maxLength } = shoppingCartConfig
  // 请求加入购物车接口入参
  const finalParams = { mode }
  let mergeParams = null
  if (favorite_group_id) {
    // 收藏夹整组加入购物车
    mergeParams = {
      favorite_group_id
    }
  } else {
    // 多个商品加入购物车
    const product_cart = filterPropByProducts(products)

    mergeParams = {
      product_cart
    }
  }
  // -1 为组合商品减
  const isAdd = count > 0
  const cartLength = $store.state.cart.cartLength
  if (isAdd && cartLength + count > maxLength) {
    Toast($t('fav.maxCartLengthTip', { maxLength }))
    return
  }

  Object.assign(finalParams, mergeParams)
  $store.dispatch('tool/showLoading')
  try {
    await $store.dispatch('cart/updateCartCount', finalParams)
  } catch (error) {
    $store.dispatch('tool/closeLoading')
    return
  }
  $store.dispatch('tool/closeLoading')

  return true
}

// 提示购物车超过最大加购数量
function tipCartOverbalance () {
  const vm = getVueVm()
  if (!vm) return

  vm.$store.dispatch('tool/showDialog', {
    // 购物车已满
    title: $t('cart.cartFull'),
    // 清理后即可加购
    desc: $t('cart.pleaseClean')
  })
}

/**
 * @description: 根据组互斥关系设置购买项禁用状态
 * @param {arr} mutex_products 已加入购物车的组 [{ mutex_id: 组id, product_id, existMutex: 是否存在互斥 }]
 * @param {arr} products 购买项列表
 */
// export function setDisabledByMutex (mutex_products, products) {
//   if (!mutex_products?.length) return

//   products.forEach((item) => {
//     const { id, mutex_id } = item
//     if (!mutex_id) return

//     const mutexItem = mutex_products.find(
//       (child) => child.mutex_id === mutex_id
//     )

//     if (mutexItem && mutexItem.product_id !== id) {
//       item.existMutex = mutexItem.existMutex
//     }
//   })
// }
