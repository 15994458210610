import { ENV_DATA, gameConfigMap } from './globalData'
import { userAgent, getVuexInstance, getUUID } from './'
// 由于触发该文件比 main 中导入全局 _localStorage 早，所以该文件的local 需引入使用
import currLocalStorage from '@/utils/localStorage'
import currSessionStorage from '@/utils/sessionStorage'
import { encryption, encryptByMd5 } from '@/utils/cipher.js'
import { apiInstance } from '@/api'
import { sortBy } from 'lodash-es'
import { jsonStringifyInOrder } from '@/utils/objArrTool'
import { useLanguageList } from '@/hooks/useLanguageList'
import { getVersionByParseUrl, getVersionDetailInfo } from './versionHelper'

/**
 * @description: 获取当前游戏配置某个key的值
 * @param {string} key gameConfigMap的key
 * @return {any}
 */
function getCurrGameConfigProperty (key) {
  const { gameCode } = getVersionByParseUrl()
  const value = gameConfigMap[gameCode]?.[key] || gameConfigMap.default[key]
  return value
}

// 返回当前游戏配置
const getCurrGameConfig = () => {
  const { gameCode } = getVersionByParseUrl()
  return gameConfigMap[gameCode]
}

// 是否是指定游戏
const isMatchGame = (code) => {
  const { gameCode } = getVersionByParseUrl()
  return code === gameCode
}

// 是否是指定版本
const isMatchVersion = (version) => {
  const { versionName } = getVersionByParseUrl()
  return versionName === version
}

// 是否启用优惠券功能
const ifOpenCouponVersion = () => {
  const { if_close_login_auth } = getVuexData()

  // 当无法使用邮件服务 if_close_login_auth 统一设置为 true，届时密码功能及依赖密码业务统一关闭
  return !if_close_login_auth
}

// （根据域名）判断前端服务器所在地区
function getServerRegionInfo () {
  let isChina, isHongkong, isEurope
  const { isPro } = ENV_DATA
  if (isPro) {
    const { host } = window.location
    isChina = host.includes('pay-cn')
    isHongkong = host.includes('pay-hk')
    isEurope = host.includes('pay')
  }
  return { isChina, isHongkong, isEurope, isPro }
}

/**
 * 判断正式环境下，当前域名和游戏版本域名是否匹配
 * 非正式环境默认都匹配（返回 true）
 * @return {boolean} true 为匹配
 */
const matchingProductHostAndGame = () => {
  const { isPro } = ENV_DATA
  const { serverRegion } = getVersionDetailInfo()
  const { host } = serverRegion

  return !isPro || location.host.includes(host)
}

/**
 * 通过该类获取、设置本地语种
 */
class LanguageOperator {
  static localKey = 'language';

  static findLangItem (lang) {
    const { languageList: languages } = useLanguageList()
    return languages.find(({ key }) => key === lang)
  }

  // 获取缓存语言标识，不存在返回第一个语言
  static getLangOnLocal () {
    const { languageList: languages } = useLanguageList()

    let lang = currLocalStorage.get(this.localKey)
    if (lang) {
      const targetItem = LanguageOperator.findLangItem(lang)
      // 当前语言不存在,默认第一种语言
      if (!targetItem) {
        lang = languages[0].key
        this.setLangOnLocal(lang)
      }
    } else {
      lang = languages[0].key
      this.setLangOnLocal(lang)
    }

    return lang
  }

  static setLangOnLocal (lang) {
    currLocalStorage.set(this.localKey, lang)
  }

  // 获取缓存语言标识
  static getLocalLangNoDefault () {
    return currLocalStorage.get(this.localKey)
  }

  static async getDefaultLanguage () {
    const { lang } = await Apis.getDefaultLanguage()
    return lang
  }

  static isChinese () {
    return LanguageOperator.getLangOnLocal() === 'zh-cn'
  }

  // 切换语言
  static switchLanguage (targetLang) {
    const currLang = LanguageOperator.getLangOnLocal()
    if (!!targetLang && targetLang !== currLang) {
      const languages = useLanguageList().languageList.map((item) => item.key)
      if (languages.includes(targetLang)) {
        LanguageOperator.setLangOnLocal(targetLang)
        window.location.reload()
      }
    }
  }
}

/**
 * 通过该类获取、设置用户表单缓存
 * 存在问题：在浏览器页签A登入角色1，然后在页签B登入角色2，刷新页签A可发现 userForm 为角色2信息
 * 解决方案：多存一份数据到 session ，并优先返回 session 中数据，避免混用 session存储时需区分游戏。
 */
class CacheUserForm {
  static localKey = 'userForm';
  static getLocalUserForm () {
    const sessionResult = currSessionStorage.get(this.localKey) || null
    const result =
      JSON.parse(sessionResult) || currLocalStorage.get(this.localKey) || {}
    // 线上bug导致逻辑大区存为undefined，若不值为空，导致用户无法选择区服
    result.logical_region_id =
      result.logical_region_id === 'undefined' ? '' : result.logical_region_id

    // 返回默认大区 logical_region_id
    if (!result.logical_region_id) {
      const { region } = getVersionDetailInfo()
      result.logical_region_id = region[0]
    }
    result.pwd = result.pwd ? encryption.decryptByAES(result.pwd) : ''

    return result
  }

  /**
   * @description: 设置缓存
   * @param {obj} userForm: {
   *  user_id,
   *  server_id,
   *  logical_region_id,
   *  pwd,
   *  steam_id
   * }
   */
  static setLocalUserForm (userForm) {
    const data = Object.assign(this.getLocalUserForm() || {}, userForm)
    data.pwd = data.pwd ? encryption.encryptByAES(data.pwd) : ''

    currSessionStorage.set(this.localKey, JSON.stringify(data))
    currLocalStorage.set(this.localKey, data)
  }

  static clearUserForm () {
    currSessionStorage.set(this.localKey, '')
    currLocalStorage.remove(this.localKey)
  }
}

/** 缓存 openId */
class CacheOpenId {
  static sessionKey = 'openId';
  static getOpenId () {
    return sessionStorage.getItem(this.sessionKey) || ''
  }

  /**
   * @description: 设置 openId
   * @param openId
   */
  static setOpenId (openId) {
    sessionStorage.setItem(this.sessionKey, openId)
  }
}

/** 缓存 创建顶大入参  */
class CacheOrderParams {
  static sessionKey = 'createOrderParams';
  static getCreateOrderParams () {
    const data = sessionStorage.getItem(this.sessionKey)
    if (data) {
      return data ? JSON.parse(data) : null
    }
  }

  /**
   * @description: 设置 createOrderParams
   * @param {str} createOrderParams
   */
  static setCreateOrderParams (createOrderParams) {
    sessionStorage.setItem(this.sessionKey, JSON.stringify(createOrderParams))
  }
}

/** 过滤支付方式列表 */
function filterPayMethods (payList) {
  const { isSafari } = userAgent()

  // 【Apple Pay】仅支持在safari浏览器展示
  if (!isSafari) {
    payList = payList.filter((child) => child.name !== 'Apple Pay')
  }
  return payList
}

function getVuexData () {
  const _store = getVuexInstance()
  const { user_id, if_close_login_auth } = _store?.state.user?.userInfo || {}
  return { user_id, if_close_login_auth }
}

/**
 * @description: 统一通过此类操作localStorage缓存
 * @警告 需登入（不同用户数据隔离）
 */
class LocalOperator {
  static keys = {
    highlightFavEntry: 'highlightFavEntry',
    todayLoginedTimestamp: 'todayLoginedTimestamp',
    showCouponTipTimestamp: 'showCouponTipTimestamp'
  };

  static get (key) {
    const { user_id } = getVuexData()
    if (!user_id) return null

    const localData = currLocalStorage.get(user_id) || {}
    return localData[key]
  }

  static set (key, val) {
    const { user_id } = getVuexData()
    if (!user_id) return

    const localData = currLocalStorage.get(user_id) || {}
    localData[key] = val
    return currLocalStorage.set(user_id, localData)
  }
}

/**
 * @description: 统一通过此类操作sessionStorage缓存
 * @警告 需登入（不同用户数据隔离）
 */
class SessionOperator {
  static keys = {
    from_recommended_cp_product_list: 'from_recommended_cp_product_list',
    from_favorites_cp_product_list: 'from_favorites_cp_product_list',
    apiInfoList: 'apiInfoList'
  };

  static get (key) {
    const { user_id } = getVuexData()
    if (!user_id) return null

    const sessionData = currSessionStorage.get(user_id) || {}
    return sessionData[key]
  }

  static set (key, val) {
    const { user_id } = getVuexData()
    if (!user_id) return

    const sessionData = currSessionStorage.get(user_id) || {}
    sessionData[key] = val
    return currSessionStorage.set(user_id, sessionData)
  }
}

// 获取缓存在session中的vuex数据
function getSessionVuexData (key = 'vuex') {
  const { versionName } = getVersionByParseUrl()
  let value = sessionStorage.getItem(key)
  if (key === 'vuex' && value) {
    // catch原因：版本更细后，避免对未加密数据进行解密异常
    try {
      value = encryption.decryptByAES(value)
    } catch (error) {}
  }
  value = JSON.parse(value)
  // 以游戏版本分块获取
  return value?.[versionName]
}
// 清空 session 中 vuex 数据
function clearSessionVuexData () {
  sessionStorage.removeItem('vuex')
}

// 渲染全屏遮罩
function useRenderOverlay () {
  const elId = 'common-full-screen-overlay'
  const elStyle = {
    'background-color': '#fff',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    left: '0',
    top: '0',
    'z-index': 1
  }

  function renderOverlay () {
    const targetEl = document.getElementsByTagName('body')?.[0]
    const el = document.createElement('div')
    el.setAttribute('id', elId)
    el.setAttribute('style', genStyleString(elStyle))
    targetEl && targetEl.appendChild(el)
  }

  function destroyOverlay () {
    const targetEl = document.getElementById(elId)
    if (targetEl) {
      targetEl.parentElement.removeChild(targetEl)
    }
  }

  // 将style对象转换为html中标签style属性可用值
  function genStyleString (styleObj = {}) {
    if (!styleObj) return ''
    const handler = (result, key) => {
      return result + `${key}: ${styleObj[key]}; `
    }
    return Object.keys(styleObj).reduce(handler, '')
  }

  return { renderOverlay, destroyOverlay }
}

// 是否是测试订单
function is_debug_order () {
  return !ENV_DATA.isPro
}

/**
 * @description: api接口参数加密
 * @return {*}
 */
function useEncryptApiParam () {
  // 秘钥
  const privateKey = 'dcWebPayment.ad6b5e8d'

  // 判断api是否需要加密
  function isEncryptionApi (path) {
    return apiInstance.encryptionApiPath.includes(path)
  }

  /**
   * @description: 签名参数
   * @param {object} params
   * @return {string} a=1&b=2
   */
  function signatureParam (params) {
    // 生成键值对数组 && 排序
    const paramPairs = sortBy(Object.entries(params), (item) => item[0])

    return paramPairs.reduce((result, [key, value], index) => {
      const sign = index === 0 ? '' : '&'
      if (value === null || value === undefined) {
        value = ''
      } else if (typeof value === 'object') {
        value = jsonStringifyInOrder(value)
      }

      result += `${sign}${key}=${value}`
      return result
    }, '')
  }

  // 返回加密相关信息
  function genEncryptionInfo (params) {
    const uuid = getUUID()
    const timestamp = +new Date()
    const signParams = Object.assign({ uuid, timestamp }, params)
    const signValue = signatureParam(signParams) + `&${privateKey}`
    const sign = encryptByMd5(signValue)

    return {
      'X-Webpay-RequestId': uuid,
      'X-Webpay-Timestamp': timestamp,
      'X-Webpay-Sign': sign
    }
  }

  return { isEncryptionApi, genEncryptionInfo }
}

export {
  clearSessionVuexData,
  getSessionVuexData,
  getVersionByParseUrl,
  getCurrGameConfigProperty,
  isMatchGame,
  ifOpenCouponVersion,
  getServerRegionInfo,
  getVersionDetailInfo,
  matchingProductHostAndGame,
  LanguageOperator,
  CacheUserForm,
  CacheOpenId,
  CacheOrderParams,
  filterPayMethods,
  LocalOperator,
  SessionOperator,
  getCurrGameConfig,
  useRenderOverlay,
  is_debug_order,
  useEncryptApiParam
}
