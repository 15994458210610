<!--
 * @Date: 2022-08-27 11:32:06
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @FilePath: /dc-pay-front/src/views/Home/components/RecommendGroup.vue
-->
<template>
  <div style="height: 100%">
    <ul
      class="flex-between flex-wrap group-list"
      :class="['common-scroll-container', { 'common-hide-scroll': hideScroll }]"
    >
      <li
        class="group-item"
        v-for="item in groups"
        :key="item.id"
        @click="clickGroupItem(item)"
      >
        <div
          class="item-info"
          :style="{ backgroundImage: `url(${item.image_url})` }"
        >
          <p class="item-info-name">{{ item.name }}</p>
        </div>
        <Button type="info" class="btn-buy" @click.stop="clickBuy(item)">
          {{ $t("cart.buyRightnow") }}
        </Button>
      </li>
    </ul>

    <ProductGroupDetailPopup
      ref="detailPopRef"
      :groupInfo="detailPopInfo.groupInfo"
      :products="currGroup.products"
      @addCart="addCartByDetailPop"
      @clickBuy="clickBuyByDetailPop"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductGroupDetailPopup from '@/components/ProductGroupDetailPopup.vue'
import { validateHideScroll } from '@/utils/index.js'
import {
  validateBeforeUpdateBatchCartCount,
  sendTrackAddCartCommon
} from '@/utils/business/cart.js'
import {
  sendFavAndRecommandTrack,
  OperatorRecord
} from '@/utils/business/product.js'
import Button from '@/components/ui/Button.vue'

export default {
  components: { ProductGroupDetailPopup, Button },
  data () {
    return {
      currId: ''
    }
  },
  computed: {
    detailPopInfo () {
      const { name, id, total_amount_free_estimate } = this.currGroup
      const groupInfo = { name, id, total_amount_free_estimate }
      return { groupInfo }
    },
    currGroup () {
      return this.getCurrGroup(this.currId)
    }
  },
  props: {
    // 组合列表
    groups: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('cart', ['submitCartByProducts', 'setCartAmountToProducts']),
    ...mapActions('payment', ['goPaymentPage']),
    ...mapActions('tool', ['showLoading', 'closeLoading']),

    getCurrGroup (id) {
      return this.groups.find((item) => item.id === id) || {}
    },
    genRecommendEventData (groupId) {
      const { name, id } = this.getCurrGroup(groupId)
      return { id, name }
    },
    clickBuyByDetailPop ({ products }) {
      const moreEventData = this.genRecommendEventData(this.currId)

      sendFavAndRecommandTrack({
        eventName: 'buy_by_recommend_detail',
        products,
        moreEventData
      })
      OperatorRecord.setRecord({ products, type: 'recommend' })
    },
    async updateCart () {
      const keyName = 'cartNum'
      const products = JSON.parse(JSON.stringify(this.currGroup.products))
      // 为 products 增加 keyName 属性，表示购物车数量
      this.setCartAmountToProducts({ products, keyName })
      // 计算购物车增量，设置count为购物车目标数量
      const count = products.reduce((increment, item) => {
        // item.count 商品数量
        let { cartNum = 0, count } = item
        increment += count
        count += cartNum

        // 设置加购目标数量
        item.count += cartNum
        return increment
      }, 0)

      return await validateBeforeUpdateBatchCartCount({
        products,
        count
      })
    },
    // 详情弹窗 - 加入购物车按钮回调
    async addCartByDetailPop () {
      const { id: recommend_group_id, name: recommend_group_name } =
        this.genRecommendEventData(this.currId)
      const { products } = this.currGroup
      const otherData = {
        recommend_group_id,
        recommend_group_name
      }
      sendTrackAddCartCommon({ products, touch_type: 'group', otherData })
      // sendFavAndRecommandTrack({
      //   eventName: 'add_cart_by_recommend',
      //   products,
      //   moreEventData
      // })

      const updateState = await this.updateCart()
      if (updateState === true) {
        OperatorRecord.setRecord({ products, type: 'recommend' })
        this.$router.push('/cart')
      }
    },
    clickGroupItem ({ id, name, products = [] }) {
      this.currId = id
      this.$refs.detailPopRef.showPopup()
      const moreEventData = { id, name }
      sendFavAndRecommandTrack({
        eventName: 'view_recommend_group_detail',
        products,
        moreEventData
      })
    },
    async clickBuy ({ id, products = [] }) {
      const moreEventData = this.genRecommendEventData(id)

      this.showLoading()
      sendFavAndRecommandTrack({
        eventName: 'buy_by_recommend',
        products,
        moreEventData
      })
      await this.submitCartByProducts({ products }).finally(this.closeLoading)
      OperatorRecord.setRecord({ products, type: 'recommend' })
      this.goPaymentPage()
    }
  },
  created () {
    this.hideScroll = validateHideScroll()
  }
}
</script>
<style scoped lang="less">
.group-list {
  padding: 0 12px;
  align-content: flex-start;

  .group-item {
    width: 288px;
    margin-bottom: 12px;
    overflow: hidden;
    border-radius: var(--dp-b-rd-md);

    .item-info {
      width: 100%;
      height: 220px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: var(--dp-bg-quaternary);
      .item-info-name {
        width: 100%;
        height: 120px;
        background: linear-gradient(0, #0C0D1C 0%, rgba(0,0,0,0) 100%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 12px 3px;
        font-size: 26px;
        text-align: center;
        line-height: 1.32;
        font-weight: bold;
        // 毛玻璃效果
        // background-color: rgba(255, 255, 255, 0);
        // backdrop-filter: blur(10px);
      }
    }

    .btn-buy {
      width: 100%;
      height: 64px;
      min-height: 64px;
      border-radius: 0;
    }
  }
}
</style>
