<!--
 * @Date: 2021-12-14 11:04:57
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @FilePath: /dc-pay-front/src/views/Home/SelectLanguage.vue
-->
<template>
  <div class="custom-select">
    <div @click="onclickLabel" class="custom-select__cont">
      <span class="custom-select__label">{{ langName }}</span>
      <van-icon name="arrow-down" class="custom-select__icon" />
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        value-key="value"
        show-toolbar
        :columns="languages"
        :default-index="defaultIndex"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { LanguageOperator } from '@/utils/business.js'
import { useLanguageList } from '@/hooks/useLanguageList'

export default {
  data () {
    return {
      defaultIndex: 0, // 默认索引
      langName: '',
      showPicker: false,
      languages: []
    }
  },
  methods: {
    onclickLabel () {
      this.showPicker = true
    },
    onConfirm (item) {
      this.langName = item.value
      LanguageOperator.setLangOnLocal(item.key)
      this.showPicker = false
      this.$emit('confirm', item.key)
    },
    // 设置默认值
    setDefaultValue () {
      const langKey = LanguageOperator.getLangOnLocal()
      const targetItemIndex = this.languages.findIndex(
        (item) => item.key === langKey
      )
      const targetItem = this.languages[targetItemIndex]
      if (targetItem) {
        this.langName = targetItem.value
        this.defaultIndex = targetItemIndex
      }
    }
  },
  created () {
    this.languages = useLanguageList().languageList
  },
  mounted () {
    this.setDefaultValue()
  }
}
</script>
<style scoped lang="less"></style>
