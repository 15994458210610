/*
 * @Date: 2022-11
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-06-03 16:40:13
 * @Description:
 */

import { computed, reactive } from 'vue'
import { $t } from '@/i18n'

export const data = reactive({
  // 选中的券id
  coupon_id: '',
  // 控制弹窗显示
  showPopup: false,
  // 抵扣金额
  deduction: {
    amount: null,
    currency: ''
  },
  couponInfo: {
    coupons: [],
    loadingList: false
  }
})

/** 操作 */
const { validateBuyMoreTipPop, validateCanUseTipPop } = useBuyMoreTip()

// 是否存在可用券
export const existUseableCoupon = computed(
  () => !!data.couponInfo.coupons.length
)

// 已选中券 item
export const selectedCouponItem = computed(() => {
  const { coupon_id, couponInfo } = data
  const { coupons } = couponInfo
  return coupons.find((item) => item.role_coupon_id === coupon_id)
})

// 页面展示的抵扣价格(无负号)
export const viewAmount = computed(() => {
  const { amount, currency } = data.deduction
  return typeof amount === 'number' ? `${amount} ${currency}` : ''
})

// 页面展示的抵扣价格(有负号)
export const viewMinusAmount = computed(() => {
  const amount = viewAmount.value
  return amount ? `- ${amount}` : ''
})

// 选中券后逻辑
export const selectedCouponLogic = () => {
  const { currency = '', total_reduce_amount_display: amount } =
    selectedCouponItem.value || {}

  data.deduction = {
    amount,
    currency
  }
  data.showPopup = false
  validateBuyMoreTipPop(selectedCouponItem.value)
}

export class UseCouponBarTool {
  static clickBar = () => {
    if (!existUseableCoupon) return
    data.showPopup = true
  };
}

export default class UseCouponTool {
  // 触发时机：初始化优惠券 || 切换国家
  static async init ({ country, product_cart }) {
    await UseCouponTool.getCoupons({ country, product_cart })

    // 关闭默认选中（TB:ZBBQ-2191）
    // if (existUseableCoupon) {
    //   data.coupon_id = data.couponInfo.coupons[0]?.role_coupon_id || ''
    // }
    if (selectedCouponItem.value) {
      selectedCouponLogic()
    } else {
      validateCanUseTipPop()
    }
  }

  static clearCoupon () {
    data.coupon_id = ''
    data.deduction = {
      amount: null,
      currency: ''
    }
  }

  static async getCoupons ({ country, product_cart }) {
    data.couponInfo.loadingList = true
    const { coupons, currency } = await Apis.getUseableCoupons({
      country,
      product_cart
    }).finally(() => (data.couponInfo.loadingList = false))
    // 注入属性
    const finalCoupons = coupons.map((item) => {
      const { coupon, total_reduce_amount_display } = item
      coupon.currency = currency
      coupon.total_reduce_amount_display = total_reduce_amount_display
      return coupon
    })
    data.couponInfo.coupons = finalCoupons
  }
}

// 数据不能放useBuyMoreTip内，因为调用了两次
const tipData = reactive({
  // 显示气泡（抵扣金额不足50%，添加更多商品...）
  visiblePop: false,
  popMsg: ''
})
// 提示用户购买更多气泡逻辑
export function useBuyMoreTip () {
  // 折扣比例（低于该比例才显示气泡）
  const SALE_RATIO = 0.5
  // 气泡展示时间(秒)
  const SHOW_DURATION = 5

  // 是否折扣券折扣金额较低
  function isLessSale (coupon) {
    const { total_reduce_amount_display, coupon_type, discount_decrement } =
      coupon || {}
    const { maximum_deduction_amount, is_not_limit_deduction_amount } =
      discount_decrement || {}

    // 非折扣券 || 不存在最大优惠价格 || 不限制价格
    if (
      coupon_type !== '1' ||
      !maximum_deduction_amount ||
      !!is_not_limit_deduction_amount
    ) {
      return false
    }
    // 当前优惠金额 < 最大优惠金额的 SALE_RATIO 倍？
    return total_reduce_amount_display < maximum_deduction_amount * SALE_RATIO
  }

  function showPopover (visible = true, msg = '') {
    tipData.visiblePop = visible
    tipData.popMsg = msg
  }

  let timer = null
  // showPopover 自动关闭版
  function showPopoverAutoOff (...args) {
    if (timer) clearTimeout(timer)

    showPopover(...args)
    timer = setTimeout(() => showPopover(false, ''), SHOW_DURATION * 1000)
  }
  // 购买更多享更多优惠提示文案
  const getBuyMoreTipText = () => {
    if (!selectedCouponItem.value) return ''

    const percent = `${SALE_RATIO * 100}%`
    const { currency = '', discount_decrement } = selectedCouponItem.value
    const { maximum_deduction_amount } = discount_decrement || {}

    if (!maximum_deduction_amount) return ''

    return $t('coupon.enjoyMoreDiscountsTip', {
      money: `${maximum_deduction_amount} ${currency}`,
      proportion: percent
    })
  }

  // 提示可优惠更多
  function validateBuyMoreTipPop (coupon) {
    if (isLessSale(coupon)) {
      showPopoverAutoOff(true, getBuyMoreTipText())
    }
  }

  // 提示使用优惠券
  function validateCanUseTipPop () {
    if (existUseableCoupon.value) {
      showPopoverAutoOff(true, $t('coupon.existUseabledCouponTip'))
    }
  }

  return {
    tipData,
    validateBuyMoreTipPop,
    validateCanUseTipPop
  }
}
