<!-- 通用二维码弹窗 -->
<script lang="jsx">
import { usePopup, useUrlQRcode, useBase64QRcode } from '../hooks/useQRCode'

export default {
  props: {
  // 二维码资源类型 'url' | 'base64'
    resourceType: {
      type: String,
      default: 'url'
    }
  },
  setup (props, { expose }) {
    const { show, showPopup } = usePopup()
    const { render: renderUrlQRCode, updateQRcode: updateUrlQRcode } = useUrlQRcode()
    const { render: renderBase64QRCode, updateQRcode: updateBase64QRcode } = useBase64QRcode()
    const renderQRCode = props.resourceType === 'url' ? renderUrlQRCode : renderBase64QRCode
    const updateQRcode = props.resourceType === 'url' ? updateUrlQRcode : updateBase64QRcode

    expose({ showPopup, updateQRcode })

    return { show, renderQRCode }
  },
  render () {
    return <div>
        <van-popup vModel={this.show} lazy-render={false} round closeable>
          {this.renderQRCode()}
        </van-popup>
      </div>
  }
}

</script>
<style scoped lang="less">
/deep/ .van-popup__close-icon--top-right {
  top: 0.1rem;
  right: 0.1rem;
}
</style>
