/*
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-05
 * @Description: vant 二次封装
 */

import { Toast } from 'vant'

/**
 * @description: 支持await的toast
 * @param {string | object} msgOrOption 提示文案 或 toast 的配置
 * @return {Promise}
 */
export function ToastSync (msgOrOption) {
  const options =
    typeof msgOrOption === 'string' ? { message: msgOrOption } : msgOrOption
  return new Promise((resolve) => {
    Toast({
      ...options,
      onClose: () => resolve()
    })
  })
}
