/*
 * @Date: 2023-07
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description: checkoutPay 的 hook
 */
import { loadScript } from '@/utils'
import { currEnvConfig } from '@/utils/globalData.js'
import { getVersionByParseUrl } from '@/utils/business.js'

const { gameCode } = getVersionByParseUrl()
const {
  'checkout-public-key': checkoutPublicKeyMap,
  'checkout-risk-sdk-url': checkoutRiskSdkUrl
} = currEnvConfig

export const publicKey = checkoutPublicKeyMap[gameCode]

/**
 * @description: 集成风控工具，协助checkout采集设备信息
 * 文档：https://www.checkout.com/docs/business-operations/prevent-fraud/integrate-with-risk-js
 */
export function useIntegrationRiskJs () {
  // risk.js 实例
  let checkoutRiskInstance = null

  async function checkout_initRiskJs () {
    return new Promise((resolve, reject) => {
      loadScript(
        checkoutRiskSdkUrl,
        () => resolve(window.Risk.init(publicKey)),
        (err) => reject(err)
      )
    })
  }

  async function checkout_getDeviceSessionId () {
    if (!checkoutRiskInstance) {
      checkoutRiskInstance = await checkout_initRiskJs()
    }
    return await checkoutRiskInstance.publishRiskData()
  }

  return { checkout_getDeviceSessionId }
}
