<!--
 * @Date: 2021-12-16 16:30:43
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-12-17 14:18:00
 * @FilePath: /dc-pay-front/src/views/Home/PayMethodItem.vue
-->
<template>
  <li :class="mainElClass" @click="onclickItem">
    <!-- 优先展示支付logo图 -->
    <van-image
      v-show="!showPayNameText"
      :src="payItemInfo.image_url"
      class="pay-item__img"
      @error="loadImgErr"
      @load="loadImgSuc"
    />
    <!-- 支付logo图展示失败 兜底，展示支付方式名称 text -->
    <div class="pay-name" v-show="showPayNameText">
      <van-image
        class="pay-name__img"
        :src="require('@/assets/pay-form/pay_def_logo.png')"
      />
      <span class="pay-name__text">{{ payItemInfo.name }}</span>
    </div>

    <!-- 热门标记icon -->
    <div class="pay-item__tag flex-vcenter flex-end">
      <van-image
        v-if="payItemInfo.icon_url"
        :src="payItemInfo.icon_url"
        class="pay-item__tag__sale pay-item__tag__item"
      />
      <van-icon
        v-if="get(payItemInfo, 'discount_detail_info.length')"
        class="pay-item__tag_desc pay-item__tag__item"
        name="question-o"
        @click.stop="$emit('clickDescIcon')"
      />
    </div>

    <div class="paymethod-item__selected-logo">
      <van-icon :name="IconChecked" class="paymethod-item__selected-logo__icon" />
    </div>

    <div v-if="hasMethodDiscount" class="method-discount">
      {{ payItemInfo.discount_info }}
    </div>
  </li>
</template>

<script>
import IconChecked from '@/assets/icon/icon_checked.svg'
import { get } from 'lodash-es'

const LOAD_STATUS = {
  loading: Symbol('loading'),
  suc: Symbol('suc'),
  err: Symbol('err')
}
export default {
  components: {},
  data () {
    return {
      IconChecked,
      // 展示支付名称（仅支付logo无法加载时）
      showPayNameText: false
    }
  },
  props: {
    // default 默认尺寸，big 大尺寸
    size: {
      type: String,
      default: 'default'
    },
    selected: {
      type: Boolean,
      default: false
    },
    payItemInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mainElClass () {
      const { selected, size, hasMethodDiscount } = this
      return [
        'pay-item',
        { selected, 'pay-item__big': size === 'big' },
        // 当一行支付方式（2个）仅有一个有支付方式折扣时，无支付折扣的logo需上下居中 避免被拉伸
        { 'flex-vcenter': !hasMethodDiscount }
      ]
    },
    hasMethodDiscount () {
      return !!this.payItemInfo.discount_info
    }
  },
  methods: {
    get,
    // imgs_load_err_info [{image_url: 图片地址, id: 子渠道id }]
    loadImgErr (e) {
      const { image_url, id } = this.payItemInfo
      const data = {
        image_url,
        id
      }

      this.imgLoadStatus = LOAD_STATUS.err
      this.showPayNameText = true
      this.$emit('loadErr', data)
    },
    // imgs_load_suc_info [{image_url: 图片地址, load_time: 加载时长(s), id: 子渠道id }]
    loadImgSuc () {
      const { image_url, id } = this.payItemInfo
      const endLoadingTimestamp = +new Date()
      const load_time = endLoadingTimestamp - this.startLoadingTimestamp
      const data = {
        image_url,
        id,
        load_time
      }

      this.imgLoadStatus = LOAD_STATUS.suc
      this.showPayNameText = false
      this.$emit('loadSuc', data)
    },
    onclickItem () {
      this.$emit('clickPayItem', this.payItemInfo)
    }
  },
  watch: {
    'payItemInfo.image_url': {
      immediate: true,
      handler (url) {
        this.imgLoadStatus = LOAD_STATUS.loading
        this.startLoadingTimestamp = +new Date()

        // 一定时间未加载成功，则展示支付名称text
        setTimeout(() => {
          if (this.imgLoadStatus === LOAD_STATUS.loading) {
            this.showPayNameText = true
          }
        }, 1000)
      }
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
.pay-item {
  width: 332px;
  background-color: #fff;
  border-radius: var(--dp-b-rd-md);
  overflow: hidden;
  margin-top: var(--dp-sp-lg);
  position: relative;
  &.selected {
    // border-color: #3aa6f9;
    // border-radius: 10px;
    .paymethod-item__selected-logo {
      display: block;
    }
  }
  &.pay-item__big {
    width: 100%;
    .pay-item__img {
      height: 178px;
    }
  }
  .pay-item__img {
    height: 97px;
    width: 100%;
  }
  .pay-item__tag {
    position: absolute;
    top: 4px;
    right: 0;
    z-index: 2;
    .pay-item__tag__item{
      margin-left: 6px;
      margin-right: 6px;
    }
    .pay-item__tag__sale{
      width: 43px;
      height: 25px;
    }
    .pay-item__tag_desc{
      font-size: 26px;
      font-weight: bold;
      color: var(--dp-bg-icon);
    }
  }
  .pay-name {
    width: 100%;
    text-align: center;
    font-size: var(--dp-text-md);
    color: #000000;
    font-weight: 700;
    line-height: 97px;
    .pay-name__text {
      vertical-align: super;
    }
    .pay-name__img {
      width: 36px;
      margin-right: 15px;
    }
  }
  .method-discount{
    background-color: #F23841;
    text-align: center;
    font-size: .2rem;
    font-weight: bold;
    line-height: .24rem;
    height: .24rem;
    color: #fff;
  }
}

.paymethod-item__selected-logo {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(29, 45, 99, 0.5);
  .paymethod-item__selected-logo__icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}
</style>
