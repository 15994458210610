<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-05
 * @Description: 兑换优惠券
-->

<template>
  <div class="main" :class="['border-' + borderPosition]">
    <FieldBtn
      v-model="redeemCode"
      :placeholder="$t('coupon.convertPlaceholder')"
      :btnText="$t('coupon.convertBtn')"
      :disabledBtn="redeemCode.length < 6"
      clearable
      @clickBtn="clickConvert"
    />
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import FieldBtn from '@/components/FieldBtn.vue'
import { Toast } from 'vant'
import { $t } from '@/i18n'
import { handleTrack } from '@/utils/index.js'

defineProps({
  borderPosition: {
    type: String,
    default: 'bottom'
  }
})

let _this = null
const emit = defineEmits(['convertSuccess'])
const redeemCode = ref('')

onMounted(() => {
  const { proxy } = getCurrentInstance()
  _this = proxy
})

const clickConvert = async () => {
  if (!redeemCode.value) return Toast($t('coupon.convertPlaceholder'))
  _this.$store.dispatch('tool/showLoading')
  const { coupon_id = '', count } = await Apis.convertCoupon({
    redeem_code: redeemCode.value
  }).finally(() => _this.$store.dispatch('tool/closeLoading'))
  Toast($t('coupon.convertSucTip'))
  emit('convertSuccess')
  setTimeout(() => {
    _this.$store.dispatch('ifShowPwdTip', { touch_type: 'useRedeemCode' })
  }, 500)
  handleTrack('convert_coupon', { coupon_id, count })
}
</script>

<style lang="less" scoped>
.main {
  background-color: var(--dp-bg-secondary);
  &.border-top {
    border-top: 1px solid #363B5E;
  }
  &.border-bottom {
    border-bottom: 1px solid var(--dp-divider-primary);
  }
}
</style>
