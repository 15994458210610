/*
 * @Date: 2022-06-13 11:49:33
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @FilePath: /dc-pay-front/src/api/new-version.js
 */

import http from '@/utils/request'
import Order from './order'
import ActTurntable from './act-turntable'
import Coupon from './coupon'

class ApiGenerater {
  config = {};
  // 需加密接口地址
  encryptionApiPath = [
    Order.createOrder,
    ActTurntable.doLottery,
    Coupon.obtainCoupon,
    Coupon.convertCoupon
  ];

  // 存储所有apis函数
  apis = {
    // 蚂蚁金服 - 需支持传入地址
    postPayToMayi: (url, data) => http.post(url, data)
  };

  constructor () {
    this.mergeConfig()
    this.genApis()
  }

  mergeConfig () {
    // 要剔除的文件
    const omitFileName = ['./index.js']
    const files = require.context('.', false, /\.js$/)
    const filesKeys = files.keys()

    filesKeys.forEach((item) => {
      if (!omitFileName.includes(item)) {
        Object.assign(this.config, files(item).default)
      }
    })
  }

  genApis () {
    const { config } = this
    // 配置转换为接口
    for (const key in config) {
      const url = config[key]
      this.apis[key] = (data) => http.post(url, data)
    }
  }
}

export const apiInstance = new ApiGenerater()

export default apiInstance.apis
