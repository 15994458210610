<!--
 * @Date: 2021-12-19 10:56:24
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-07-19 16:42:34
 * @FilePath: /dc-pay-front/src/views/Home/ProductItem/PropList.vue
 * @describe: 道具列表（购买项的道具 或 额外赠送的道具）
-->

<template>
  <div>
    <p class="title">{{ title }}</p>
    <ul class="ul" :style="{ maxWidth: maxPropListWidth }">
      <!-- index 做 key，返回数据无唯一标识 -->
      <li
        class="flex-center li"
        v-for="(item, index) in viewList"
        :key="index"
        :style="propItemStyle"
        @click.stop="clickPropItem(item)"
      >
        <van-image :src="item.image_url" class="li-img" />

        <span class="li-num" :style="numStyle">{{
          item.count | logogramNum
        }}</span>
      </li>
      <div
        class="custom-icon"
        v-show="showMoreOperator"
        @click.stop="foldSwitch"
      >
        <i :class="['van-icon', foldBtnInfo.iconName]"></i>
      </div>
    </ul>

    <!-- 道具详情弹窗 -->
    <PropDetailPopup ref="propPopupRef" :propItem="currItem"></PropDetailPopup>
  </div>
</template>

<script>
import PropDetailPopup from '@/views/Home/popup/PropDetailPopup.vue'
import { logogramNum } from '@/core/filters/index.js'

// 需要折叠的最小数量
const SHOW_FOLD_NUM = 7
export default {
  components: { PropDetailPopup },
  data () {
    return {
      isFold: true, // 是否处于折叠状态
      currItem: {} // 当前点击的道具
    }
  },
  computed: {
    foldBtnInfo () {
      const MAP = {
        open: {
          iconName: 'van-icon-arrow-down'
        },
        fold: {
          iconName: 'van-icon-arrow-up'
        }
      }
      const key = this.isFold ? 'open' : 'fold'
      return MAP[key]
    },
    viewList () {
      if (this.showMoreOperator && this.isFold) {
        return this.data.slice(0, SHOW_FOLD_NUM - 1)
      }
      return this.data
    },
    showMoreOperator () {
      return this.data.length >= SHOW_FOLD_NUM
    }
  },
  props: {
    title: {
      type: String,
      default: 'title'
    },
    data: {
      type: Array,
      default: () => []
    },
    // 道具 item 背景色
    propItemStyle: {
      type: Object,
      default: () => {}
    },
    numStyle: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    // 道具列表的最大宽度
    getPropListWidth () {
      // 一行展示个数
      const oneLineNum = SHOW_FOLD_NUM - 1
      const onePropWidth = 0.7
      return oneLineNum * onePropWidth + 'rem'
    },
    foldSwitch () {
      this.isFold = !this.isFold
    },
    clickPropItem (item) {
      this.currItem = item
      const { item_name, item_source, item_usage } = item

      if (item_name || item_source || item_usage) {
        this.$nextTick(() => {
          this.$refs.propPopupRef.showPopup()
        })
      }
    }
  },
  filters: {
    logogramNum
  },
  mounted () {},
  created () {
    this.maxPropListWidth = this.getPropListWidth()
  }
}
</script>
<style scoped lang="less">
.title {
  color: var(--dp-text-tertiary);
  font-size: var(--dp-text-sm);
  line-height: 1.32;
}
ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--dp-sp-md);
  position: relative;
  .li {
    border: 1px solid var(--dp-prop-primary);
    background-color: var(--dp-prop-bg-primary);
    overflow: hidden;
    border-radius: 5px;
    margin: 0 14px 14px 0;
    position: relative;
    width: 56px;
    height: 56px;
    .li-img {
      width: 95%;
      height: 95%;
      box-sizing: content-box;
      display: block;
    }
    .li-num {
      position: absolute;
      padding: 2px 4px 0 4px;
      right: 0px;
      bottom: 0px;
      font-size: 16px;
      font-weight: 400;
      background-color: #003B73;
      border-radius: 16px;
      opacity: 0.75;
    }
  }
}
.custom-icon {
  position: absolute;
  left: 440px;
  top: 24px;
  color: #636BAA;
  font-size: 32px;
}
</style>
