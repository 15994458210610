/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 开屏弹窗逻辑
 */
import { ref, watch } from 'vue';
import { ActRedirect, useAct } from '@/views/Activity';
import { useTurntableAct } from '@/views/Activity/TurntableAct.js';
import { PopupDisplay } from '@/views/Activity/common.js';

// 开屏图片地址
var actImgUrl = ref('');
// 是否显示弹窗
var showDialog = ref(false);
// 弹窗类型
var dialogType = ref('');
// 是否是大转盘活动
var isActTurntable = ref(false);
// 活动弹窗配置
var popupInfo = {};
var _useTurntableAct = useTurntableAct(),
  wakeUpTurntableAct = _useTurntableAct.wakeUpTurntableAct;
var _useActGuidePopup = useActGuidePopup(),
  showPopup = _useActGuidePopup.showPopup;
var _handleGuidePopupStat = handleGuidePopupState(),
  setImgUrl = _handleGuidePopupStat.setImgUrl;
var _useAct = useAct(),
  popup = _useAct.popup;

// 控制弹窗
function useActGuidePopup() {
  function showPopup() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      isTurntable: false
    };
    var isTurntable = opts.isTurntable;
    isActTurntable.value = isTurntable;
    showDialog.value = true;
  }
  function closePopup() {
    showDialog.value = false;
  }
  return {
    actImgUrl: actImgUrl,
    showDialog: showDialog,
    isActTurntable: isActTurntable,
    closePopup: closePopup,
    showPopup: showPopup
  };
}

// 修改状态
function handleGuidePopupState() {
  function getImgUrl() {
    return actImgUrl.value;
  }
  function setImgUrl(url) {
    actImgUrl.value = url;
  }
  return {
    setImgUrl: setImgUrl,
    getImgUrl: getImgUrl
  };
}
function handleClickPopup() {
  if (isActTurntable.value) {
    wakeUpTurntableAct();
  } else {
    var _popupInfo = popupInfo,
      login_type = _popupInfo.login_type,
      activity_url = _popupInfo.activity_url;
    var redirectIns = new ActRedirect(activity_url, login_type);
    redirectIns.redirect();
  }
}

// 开屏弹窗相关的逻辑
function useOpenScreenActTabs() {
  var acts = ref([]);
  var activeIndex = ref(0);
  var activeAct = computed(function () {
    return acts.value.length ? acts.value[activeIndex.value] : {};
  });
  function addListenerVisiblePopup() {
    watch(popup, function (popupList) {
      if (popupList && popupList.length) {
        var firstAct = popupList[0];
        var activity_id = firstAct.activity_id;
        var ins = new PopupDisplay({
          lastVisiblePopupLocalKey: activity_id
        });
        if (!ins.isShowedToday()) {
          acts.value = popupList;
          popupInfo = firstAct;
          showPopup();
          ins.setLastViewPopupTime(+new Date());
        }
      }
    });
  }
  function updateActiveIndex(index) {
    activeIndex.value = index;
    popupInfo = activeAct.value;
  }
  return {
    acts: acts,
    activeIndex: activeIndex,
    activeAct: activeAct,
    updateActiveIndex: updateActiveIndex,
    addListenerVisiblePopup: addListenerVisiblePopup
  };
}
export { handleGuidePopupState, useActGuidePopup, handleClickPopup, useOpenScreenActTabs };