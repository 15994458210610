<!--
 * @Date: 2022-11
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-12-13 10:07:35
 * @Description: 优惠券使用弹窗
-->

<template>
  <van-popup
    class="use-popup"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="showPopup"
    round
    position="bottom"
    :close-on-click-overlay="false"
    @click.stop=""
    @open="onOpenPopup"
  >
    <CommonPanel
      convertBorderPosition="bottom"
      :couponInfo="couponInfo"
      :openSelect="true"
      v-on="{ convertSuccess: $listeners.convertSuccess }"
    >
      <template v-slot:footer>
        <UseTip class="tip-position" />
        <!-- 该节点用于撑起底部，避免列表被遮挡 -->
        <div style="padding-top: 1.48rem; flex: 1"></div>
        <FooterOperatorBar
          :footerBarType="FOOTER_BAR.btn"
          :text="$t('common.confirm')"
          @submit="clickConfirm"
        />
      </template>
    </CommonPanel>
  </van-popup>
</template>

<script setup>
import CommonPanel from '@/views/Coupon/components/CommonPanel.vue'
import UseTip from '@/views/Coupon/components/UseTip.vue'
import FooterOperatorBar, {
  FOOTER_BAR
} from '@/components/FooterOperatorBar.vue'
import {
  onMounted,
  getCurrentInstance,
  toRefs,
  defineEmits
} from 'vue'
import { data, selectedCouponLogic } from './use.js'

let _this = null
let oldCouponId = ''
const emit = defineEmits(['change'])

onMounted(() => {
  const { proxy } = getCurrentInstance()
  _this = proxy
})

const clickConfirm = () => {
  selectedCouponLogic()
  if (oldCouponId !== data.coupon_id) emit('change')
}
const onOpenPopup = () => {
  oldCouponId = data.coupon_id
}

const { showPopup, couponInfo } = toRefs(data)
</script>

<style lang="less" scoped>
.use-popup {
  height: 80%;
  background-color: var(--dp-bg-primary);
}
.tip-position {
  position: fixed;
  left: 50%;
  bottom: 110px;
  transform: translateX(-50%);
}
</style>
