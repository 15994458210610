/*
 * @Date: 2022-06-16 14:37:08
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-11 11:23:57
 * @FilePath: /dc-pay-front/src/utils/business/product.js
 */
import { $t } from '@/i18n'
import { handleTrack } from '@/utils/index.js'
import { SessionOperator } from '@/utils/business.js'

// 是否为限购商品
function isLimitNum (product) {
  const { limit } = product
  return !!limit && limit > 0
}

// 是否为互斥商品
function isMutex (product) {
  const { limit, mutex_id } = product
  return !!mutex_id && !!limit && limit > 0
}

// 是否为限时商品
function isLimitTime (product) {
  const { expired_time } = product
  return expired_time && expired_time > 0
}

// 是否为禁用商品
function isDisabled (product) {
  const { disabled } = product
  return disabled || false
}

// 是否为超时商品
function isTimeoutProduct (product) {
  const { expired_time_millisecond, state } = product
  // 下架（state = 1） || 限时
  return state === 1 || (isLimitTime(product) && expired_time_millisecond <= 0)
}

// 剩余可购买数量
function purchasableNum (product) {
  const { limit, purchased } = product
  return limit - purchased
}

// 当前购买数量为最大或已超量
function isOverbalance (product) {
  const { count } = product
  return isLimitNum(product) && count >= purchasableNum(product)
}

/**
 * @description: 校验商品是否可下单
 * @param {arr} products 商品列表
 * @return {obj} { timeoutList: 超时商品列表, excessList: 超量商品列表, payableList: 可下单列表 }
 */
function validateUnpayableProduct (products) {
  return products.reduce(
    (result, product) => {
      const { purchased, limit, count } = product
      let payable = true // 标记是否可购买
      const purchasableNum = limit - purchased // 可购买数量（仅限购需要）

      if (isTimeoutProduct(product)) {
        payable = false
        result.timeoutList.push(product)
      }
      // 限量
      if (isLimitNum(product) && purchasableNum < count) {
        payable = false
        result.excessList.push(product)
        // 将数量置为可购数量，添加到可购买队列
        if (purchasableNum > 0) {
          const copyProduct = { ...product }
          copyProduct.count = purchasableNum
          result.payableList.push(copyProduct)
        }
      }
      if (payable) {
        result.payableList.push(product)
      }
      return result
    },
    { timeoutList: [], excessList: [], payableList: [] }
  )
}

/**
 * @description: 判断是否禁用购买项
 * @param {object} product 单个购买项数据
 * @return {boolean} 是否禁用
 */
function validateDisabledProduct (product) {
  const { purchased, limit } = product

  if (isLimitNum(product) && purchased >= limit) return true
  if (isTimeoutProduct(product)) return true
  // if (existMutex) return true
  return false
}

/**
 * @description: 根据disabled属性值，将数组拆分为两个数组
 * @param {array} list 目标数组
 * @return {object} { useabledList, disabledList }
 */
function splitDisabledArr (list) {
  return list.reduce(
    (result, item) => {
      const isDisabled = validateDisabledProduct(item)
      if (isDisabled) {
        result.disabledList.push(item)
      } else {
        result.useabledList.push(item)
      }
      return result
    },
    { useabledList: [], disabledList: [] }
  )
}

function parseFloatNum (num, decimalPlace = 2) {
  if (typeof num === 'number') {
    return parseFloat(num.toFixed(decimalPlace))
  }
  return num
}

/**
 * @description: 统计多个商品的金额、币种、积分、商品总数
 * @param {array} productList
 * @returns { amount, currency, point, count }
 */
function countAmountInfo (productList) {
  // 统计金额
  let { amount, point, currency, count } = productList.reduce(
    (result, item, index) => {
      const { count = 0, currency = '', amount_display = 0, amount = 0 } = item
      if (index === 0) {
        result.currency = currency
      }
      result.amount += amount_display * count
      result.point += amount * count
      result.count += count
      return result
    },
    { amount: 0, point: 0, currency: '', count: 0 }
  )
  amount = parseFloatNum(amount, 2)
  // 后端反馈积分无需管小数位数和四舍五入(估传4，扩大小数范围)
  point = parseFloatNum(point, 4)

  return { amount, point, currency, count }
}

// filter { count, product_id } to new arr
function filterPropByProducts (products) {
  return products.map((item) => {
    const { id: product_id, count } = item
    return { product_id, count }
  })
}

// product countdown
function addCountdownForProducts (products) {
  const intervalTime = 1000
  // 过滤出需倒计时列表
  const limitTimeProducts = products.filter(
    (item) => item?.expired_time_millisecond > 0
  )
  setInterval(() => {
    limitTimeProducts.forEach((item) => {
      if (item.expired_time_millisecond > 0) {
        item.expired_time_millisecond -= intervalTime
      }
    })
  }, intervalTime)
}

function findMenuItem (label_id, menus) {
  const index = menus.findIndex((item) => item.label_id === label_id)
  return { item: menus[index], index }
}

/**
 * @param products 购买项列表
 * @param returnFormat 返回值类型 string、json
 * @return {string} JSON.stringify({
    cp_product_id, cp_point, sp_amount, sp_currency_code, count
 * })
 */
function productTransformData (products, config = { returnFormat: 'string' }) {
  const { returnFormat } = config
  const list = products.map((item) => {
    const {
      id: cp_product_id,
      amount: cp_point,
      amount_display: sp_amount,
      currency: sp_currency_code,
      count,
      purchased,
      limit
    } = item
    return {
      cp_product_id,
      cp_point,
      sp_amount,
      sp_currency_code,
      count,
      purchased,
      limit
    }
  })
  switch (returnFormat) {
    case 'json':
      return list
    case 'string':
      return JSON.stringify(list)
  }
}

// 推荐礼包和收藏夹相关埋点封装
function sendFavAndRecommandTrack ({
  eventName,
  products = [],
  moreEventData = {}
}) {
  const cp_product_list = productTransformData(products)
  handleTrack(eventName, { cp_product_list, ...moreEventData })
}

/**
 * @description: 采集 收藏夹、推荐礼包 操作记录(采集的数据在创建订单时，发送埋点使用)
 */
class OperatorRecord {
  /**
   * @param {string} type 类型 favorite、recommend
   */
  static getKey (type) {
    const { from_recommended_cp_product_list, from_favorites_cp_product_list } =
      SessionOperator.keys
    const key =
      type === 'favorite'
        ? from_favorites_cp_product_list
        : from_recommended_cp_product_list

    return key
  }

  /**
   * @description: 获取 收藏夹、推荐礼包操作记录
   * @param {string} type 类型 favorite、recommend
   */
  static getRecord ({ type = 'favorite' }) {
    const key = this.getKey(type)
    return SessionOperator.get(key) || []
  }

  /**
   * @description: 添加 收藏夹、推荐礼包 操作记录
   * @param {*} products 商品
   * @param {string} type 类型 favorite、recommend
   */
  static setRecord ({ products = [], type = 'favorite' }) {
    const key = this.getKey(type)
    const originList = this.getRecord({ type })
    const transformProducts = productTransformData(products, {
      returnFormat: 'json'
    })
    originList.push(...transformProducts)
    SessionOperator.set(key, originList)
  }

  // 获取所有操作记录
  static getAllRecord () {
    const favoriteRecord = JSON.stringify(this.getRecord({ type: 'favorite' }))
    const recommendRecord = JSON.stringify(
      this.getRecord({ type: 'recommend' })
    )

    return {
      from_favorites_cp_product_list: favoriteRecord,
      from_recommended_cp_product_list: recommendRecord
    }
  }

  static clearAllRecord () {
    this.setRecord({ products: [], type: 'favorite' })
    this.setRecord({ products: [], type: 'recommend' })
  }
}

// 购买项配置
const PRODUCT_CONFIG = (function () {
  // 静态菜单
  const staticMenuMap = {
    all: {
      label_id: 'all',
      label_name: $t('cart.all'),
      isStatic: true
    },
    // 推荐礼包调整为后端返回，此处仅用做判断
    recommend: {
      // 推荐礼包
      label_id: 'recommend'
      // label_name: $t('fav.recommendGroup'),
    },
    // 热门
    hot: {
      label_id: 'product_hot'
    }
  }
  const staticMenuList = [staticMenuMap.all]

  return { staticMenuMap, staticMenuList }
})()

function useHandleMenu () {
  const OTHER_SUB_TYPE = {
    sub_type_id: '__other__',
    sub_type_name: $t('common.other')
  }

  function addOtherSubType (types) {
    for (const item of types) {
      item.sub_type_list.push(OTHER_SUB_TYPE)
    }
  }

  // 返回无子分类的商品
  function filterNoSubTypeIdProducts (products = []) {
    return products.filter(({ sub_type_id }) => !sub_type_id)
  }

  // 判断商品与子分类是否匹配
  function validateMatchType (product, sub_type_id) {
    const { sub_type_id: id } = product
    return id && id === sub_type_id
  }

  // 返回 存在子分类 && 未与菜单子分类匹配的商品
  function filterUnmatchedSubTypeProducts (products, subTypes) {
    const ids = subTypes.map(({ sub_type_id }) => sub_type_id)
    return products.filter(
      ({ sub_type_id }) => sub_type_id && !ids.includes(sub_type_id)
    )
  }

  // 返回模板展示商品数据格式
  function transformProductDataByType (products, subTypes) {
    // 根据子分类将list拆分为多个列表 [{title, list, sub_type_id}]
    return subTypes.map(({ sub_type_name, sub_type_id }) => {
      const matchedProducts = products.filter((product) =>
        validateMatchType(product, sub_type_id)
      )
      return { title: sub_type_name, list: matchedProducts, sub_type_id }
    })
  }

  /**
   * 填充商品到【其他】子分类
   * @param {arr} products 要填充的商品
   * @param {*} subTypes 子类列表
   */
  function fillOtherSubType (products, subTypes) {
    const otherSubType = subTypes.find(
      ({ sub_type_id }) => sub_type_id === OTHER_SUB_TYPE.sub_type_id
    )
    if (otherSubType) {
      otherSubType.list = products
    }
  }

  // 处理商品与分类关系的逻辑
  function transformTypeProduct (products, subTypes) {
    const typesProducts = transformProductDataByType(products, subTypes)
    const unmatchedProducts = filterUnmatchedSubTypeProducts(
      products,
      subTypes
    )
    const noSubTypeProducts = filterNoSubTypeIdProducts(products)
    fillOtherSubType(
      [...unmatchedProducts, ...noSubTypeProducts],
      typesProducts
    )
    return typesProducts.filter(({ list }) => !!list?.length)
  }

  return {
    OTHER_SUB_TYPE,
    addOtherSubType,
    transformTypeProduct
  }
}

export {
  isTimeoutProduct,
  parseFloatNum,
  validateDisabledProduct,
  splitDisabledArr,
  countAmountInfo,
  filterPropByProducts,
  validateUnpayableProduct,
  isLimitNum,
  isMutex,
  isLimitTime,
  isOverbalance,
  isDisabled,
  addCountdownForProducts,
  findMenuItem,
  productTransformData,
  sendFavAndRecommandTrack,
  OperatorRecord,
  PRODUCT_CONFIG,
  useHandleMenu,
  purchasableNum
}
