/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-05
 * @Description:
 */

export default {
  // 获取商品列表
  getBounghts: '/user/GetProductInfo',
  // 获取商品分类
  getClassify: '/productType/GetProductType',
  // 获取商品标签
  getLabels: '/productLabel/GetProductLabel',
  // 获取指定商品更多信息（如：指定国家的金额）
  getGoodsMoreInfo: '/cart/GetProductById',
  // 获取互斥商品
  // getMutexProducts: '/cart/GetCartMutex',
  // 获取推荐礼包
  getRecommendProducts: '/user/GetRecommendedProductGroup'
}
