<!--
 * @Date: 2021-12-15 11:09:17
 * @LastEditors: wfj
 * @LastEditTime: 2023-01
 * @FilePath: /dc-pay-front/src/views/Home/StepBar.vue
-->
<template>
  <div class="main">
    <!-- 存在邮箱 -->
    <div class="main-tip" v-if="existServiceEmail">
      <p>{{ $t("form.supportTextHasEmail") }}</p>
      <p class="main-tip-email">
        <IconService />
        <span class="email-text">{{
          $t("form.emailLabel") + serviceEmail
        }}</span>
      </p>
    </div>
    <!-- 不存在邮箱 -->
    <p class="main-tip" v-else>{{ textForNonEmail() }}</p>
  </div>
</template>

<script>
import {
  getVersionDetailInfo,
  getVersionByParseUrl
} from '@/utils/business.js'
import IconService from '@/components/ui/icon/IconService.vue'

export default {
  data () {
    return {
      serviceEmail: '' // 客服邮箱
    }
  },
  components: { IconService },
  computed: {
    // 是否存在客服邮箱
    existServiceEmail () {
      const { messages, locale } = this.$i18n

      // 有配置邮箱的文案
      const { supportTextHasEmail } = messages[locale].form

      if (supportTextHasEmail && this.serviceEmail) {
        return true
      }
      return false
    }
  },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  methods: {
    textForNonEmail () {
      const { versionName } = getVersionByParseUrl()
      return versionName === 'dq-tw'
        ? this.$t('form.supportTextDq')
        : this.$t('form.supportText')
    }
  },
  created () {
    const { serviceEmail } = getVersionDetailInfo()
    this.serviceEmail = serviceEmail
  }
}
</script>
<style scoped lang="less">
.main {
  margin-top: var(--dp-sp-sm);
  .main-step {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .main-tip {
    color: var(--dp-text-tertiary);
  }
  .main-tip-email {
    margin-top: var(--dp-sp-md);
    color: var(--dp-secondary);
    font-weight: 700;
  }
}
.email-text {
  font-weight: 700;
  color: var(--dp-text-secondary);
  margin-left: var(--dp-sp-xs);
}
</style>
