/*
 * @Date: 2023-07
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description: 对象、数组工具方法
 */
import { sortBy, isPlainObject, forIn } from 'lodash-es'

// 有序遍历对象
export function forInOrder (obj, callback) {
  const pairs = sortBy(Object.entries(obj), (item) => item[0])
  pairs.some(([key, val]) => {
    return callback(key, val) === false
  })
}

/**
 * @description: 返回所有key(包含数组、嵌套对象的key)，并升序排序
 * @param {array|object} objOrArr
 * @return {array} string[]
 */
export function getAllKeyAndInOrder (objOrArr) {
  if (!objOrArr) return []
  let keys = []
  if (Array.isArray(objOrArr)) {
    for (const item of objOrArr) {
      if (typeof item === 'object') {
        keys = keys.concat(getAllKeyAndInOrder(item))
      }
    }
  } else if (isPlainObject(objOrArr)) {
    forIn(objOrArr, (val, key) => {
      keys.push(key)
      if (typeof val === 'object') {
        keys = keys.concat(getAllKeyAndInOrder(val))
      }
    })
  }

  // 去重
  keys = Array.from(new Set(keys))

  // 排序
  return sortBy(keys)
}

/**
 * @description: 序列化对象（按key升序排序）
 * @param {array|object} objOrArr
 * @return {string}
 */
export function jsonStringifyInOrder (objOrArr) {
  return JSON.stringify(objOrArr, getAllKeyAndInOrder(objOrArr))
}
