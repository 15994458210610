/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-05
 * @Description:
 */

import { reactive } from 'vue'
import { handleTrack } from '@/utils/index.js'

export const presenterData = reactive({
  showDialog: false,
  coupons: []
})

export default class Presenter {
  // _this: vue instance
  async init (_this) {
    const { coupons, currency } = await this.obtainCoupon(_this)
    // 注入属性
    const coupon_ids = coupons.map((item) => {
      item.currency = currency
      return item.coupon_id
    })
    presenterData.coupons = coupons
    if (this.isShowDailog()) {
      this.showDialog()
      handleTrack('obtain_coupon', {
        coupon: JSON.stringify(mergeIds(coupon_ids))
      })
    }
  }

  // _this: vue instance
  obtainCoupon (_this) {
    const { country_en_us: country } =
      _this.$store.state.payment.defaultCountryInfo
    return Apis.obtainCoupon({ country })
  }

  showDialog (isShow = true) {
    presenterData.showDialog = isShow
  }

  isShowDailog () {
    return !!presenterData.coupons?.length
  }
}

/**
 * @description: 统计相同id数量
 * @param {arr} ids ['11','22']
 * @return {arr} [{count,coupon_id}]
 */
function mergeIds (ids) {
  const data = ids.reduce((obj, id) => {
    obj[id] = obj[id] ? obj[id] + 1 : 1
    return obj
  }, {})
  const countIdsData = []
  for (const id in data) {
    const count = data[id]
    countIdsData.push({
      count,
      coupon_id: id
    })
  }
  return countIdsData
}
