import _objectSpread from "/builds/dc_platform/dc_website/website/dc-pay-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import Comp from './Dialog.vue';
import Vue from 'vue';
export function Dialog(options) {
  return new Promise(function (resolve, reject) {
    var dom = document.createElement('div');
    var inner = document.createElement('div');
    dom.appendChild(inner);
    document.body.appendChild(dom);
    var show = ref(true);
    function onUpdateValue(value) {
      show.value = value;
    }
    function destory() {
      instance.$destroy();
      document.body.removeChild(dom);
    }
    var instance = new Vue({
      render: function render(h) {
        return h(Comp, {
          props: _objectSpread(_objectSpread({}, options), {}, {
            value: show.value
          }),
          on: {
            'update:value': onUpdateValue,
            closed: destory,
            confirm: resolve,
            cancel: reject
          }
        });
      }
    }).$mount(inner);
  });
}
export default Comp;