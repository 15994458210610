<!--
 * @Date: 2022-06-06 14:16:17
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-08-31 11:38:51
 * @FilePath: /dc-pay-front/src/views/Home/SideBar.vue
-->
<template>
  <van-sidebar v-model="activeIndex" v-on="$listeners">
    <van-sidebar-item v-for="item in list" :key="item.label_id">
      <template slot="title">
        <div class="flex-end badge">
          <img v-if="item.image_url" :src="item.image_url" class="item-logo" />
        </div>
        <p style="text-align: center;word-break: break-word;">
          {{ item.label_name }}
        </p>
        <div class="spacer" v-if="item.image_url"></div>
      </template>
    </van-sidebar-item>
  </van-sidebar>
</template>

<script>
import { Sidebar, SidebarItem } from 'vant'
import { mapGetters, mapState } from 'vuex'
export default {
  components: { [Sidebar.name]: Sidebar, [SidebarItem.name]: SidebarItem },
  data () {
    return {
      activeIndex: this.$store.state.product.activeKey
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('product', ['activeKey']),
    ...mapGetters('product', ['mergeList'])
  },
  methods: {
    setActivitySide (index) {
      this.activeIndex = index
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
.item-logo {
  width: 56px;
  height: 40px;
}
.spacer {
  height: 40px;
}
/deep/ &.van-sidebar {
  .van-sidebar-item {
    color: var(--dp-text-secondary);
    font-weight: 700;
    font-size: var(--dp-text-sm);
    background-color: transparent;
    line-height: 1.32;
    min-height: 120px;
    padding: var(--dp-sp-md) var(--dp-sp-xs);
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/ .van-sidebar-item__text {
      width: 100%;
    }
  }
  .van-sidebar-item--select {
    background-color: var(--dp-primary);
    &::before {
      width: 0px;
      height: 100%;
      background-color: var(--dp-primary);
    }
  }
}
</style>
