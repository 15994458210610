/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */
import { ref, watch } from 'vue'
import { getManualAppInstance } from '@/qiankun/actBusiness/manualLoadMicroApp.js'
import { setGlobalState } from '@/qiankun/globalState/'
import { useTurntableAct } from '@/views/Activity/TurntableAct.js'
import { useCouponTip } from '@/views/Home/index.js'

const { getTurntableApp } = getManualAppInstance()
const { handleVisibleCouponTip } = useCouponTip()
const { canLottery } = useTurntableAct()
const showDialog = ref(false)

watch(showDialog, (openActPopup) => {
  setGlobalState({
    openActPopup
  })

  if (!openActPopup) {
    // 关闭活动时，校验优惠券icon高亮（活动时可能会获得优惠券）
    handleVisibleCouponTip()
  }
})

function usePopup () {
  // 判断显示活动弹窗
  async function checkVisibleActDialog ({ haveLoginToday }) {
    const turntableApp = getTurntableApp()
    // 唤起大转盘，canLottery中会控制活动icon显示状态，必须触发
    if (await canLottery()) {
      !haveLoginToday && turntableApp.manualStartApp()
    }
  }

  function openPopup (isOpen = true) {
    showDialog.value = isOpen
  }

  return { showDialog, openPopup, checkVisibleActDialog }
}

export { usePopup }
