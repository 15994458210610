/*
 * @Date: 2022-05-16 11:04:50
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-05-18 10:54:45
 * @FilePath: /dc-pay-front/src/core/filters/index.js
 */
/**
 * @description: 将num转换为M为单位的string
 * @param {number} num 目标数字
 * @return {number or string} '1M'
 */
export function logogramNum (num) {
  if (typeof num !== 'number') return num

  const DIGIT_MAP = {
    six: 100000,
    seven: 1000000,
    eight: 10000000
  }
  const unit = 'M'

  // 大于等于六位数需转换
  if (num >= DIGIT_MAP.six) {
    let result = num / DIGIT_MAP.seven
    switch (true) {
      case num >= DIGIT_MAP.eight:
        result = parseInt(result)
        break
      case num >= DIGIT_MAP.six:
        result = Math.floor(result * 100) / 100
        break
    }
    return `${result}${unit}`
  }

  return num
}
