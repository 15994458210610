<!--
 * @Date: 2022-06-16 10:36:10
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-06-16 10:46:41
 * @FilePath: /dc-pay-front/src/components/WhiteCard.vue
-->
<template>
  <div class="card-cont">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
  created () {}
}
</script>
<style scoped lang="less">
.card-cont {
  background-color: var(--dp-bg-secondary);
  margin: var(--dp-sp-lg) 0;
  border-radius: var(--dp-b-rd-md);
}
</style>
