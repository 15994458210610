<!--
 * @Date: 2021-12-15 10:10:36
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-14 16:19:18
 * @FilePath: /dc-pay-front/src/views/Home/components/HeadBar.vue
-->
<template>
  <div class="main">
    <van-image :src="gameLogo" class="head-logo" :style="gameLogoStyle()" />
    <van-image
      v-if="checkVisibleTitle()"
      :src="getTitleImgPath()"
      class="title-logo"
    />
    <div class="flex-box right">
      <SelectLanguage @confirm="onconfirmSelectLang" />
      <LogoutBtn class="ml-btn" v-if="isThirdLogin" />
    </div>
  </div>
</template>

<script>
import SelectLanguage from '@/views/Home/components/SelectLanguage.vue'
import LogoutBtn from './ThirdLogoutBtn.vue'
import {
  getVersionDetailInfo,
  getVersionByParseUrl,
  isMatchGame,
  LanguageOperator
} from '@/utils/business.js'
import { mapGetters } from 'vuex'

const { gameCode } = getVersionByParseUrl()
/**
 * @description: 返回存在标题图片的语言标识
 * @return {arr} ['zh-cn', 'en-us']
 */
function getTitleLangMap () {
  const data = require.context('@/assets/home-title/gos', false, /\.png/)
  const imgPathList = data.keys()
  return imgPathList.map((item) => item.match(/\.\/(\S*)\.png/)?.[1])
}
// 不同游戏logo差异样式
function gameLogoStyle () {
  const styleMap = {
    vk: {
      width: `${3.6 * 0.8}rem`,
      height: `${0.82 * 0.8}rem`
    }
  }
  return styleMap[gameCode] || {}
}
export default {
  components: { SelectLanguage, LogoutBtn },
  data () {
    return {
      gameLogo: getVersionDetailInfo().gameLogo
    }
  },
  computed: {
    ...mapGetters('user', ['isThirdLogin'])
  },
  methods: {
    gameLogoStyle,
    // 返回title图片地址
    getTitleImgPath () {
      const langMap = getTitleLangMap()
      const currLang = LanguageOperator.getLangOnLocal()
      const titleLang = langMap.includes(currLang) ? currLang : 'en-us'
      return require(`@/assets/home-title/gos/${titleLang}.png`)
    },
    checkVisibleTitle () {
      return isMatchGame('gos')
    },
    onconfirmSelectLang () {
      window.location.reload()
    }
  },
  created () {},
  setup () {}
}
</script>
<style scoped lang="less">
.ml-btn {
  margin-left: 15px;
}
.main {
  height: 88px;
  background: var(--dp-bg-secondary);
  color: var(--dp-text-tertiary);
  display: flex;
  justify-content: space-between;
  // padding: 10px 23px 10px 10px;
  padding-right: 34px;
  align-items: center;
  .head-logo {
    width: 202px;
    height: 82px;
    display: block;
  }
  .title-logo {
    width: 268px;
    height: 44px;
  }
}

</style>
