import { ref } from 'vue'
import QRCode from 'qrcodejs2'

export function usePopup () {
  const show = ref(false)
  const showPopup = (isShow = true) => (show.value = isShow)

  return { show, showPopup }
}

export function useUrlQRcode () {
  let qrCodeIns = null
  const qrCodeRef = ref(null)
  const render = () => <div ref={qrCodeRef} style="padding: .5rem;"></div>
  /**
   * @description: 更新 url qrcode
   * @param {string} url 地址
   */
  const updateQRcode = (url) => {
    if (qrCodeIns) {
      // 存在实例
      qrCodeIns.clear()
      qrCodeIns.makeCode(url)
    } else {
      // 不存在实例
      qrCodeIns = new QRCode(qrCodeRef.value, url)
    }
  }

  return { render, updateQRcode }
}

export function useBase64QRcode () {
  const base64 = ref('')
  const render = () => <img src={base64.value} style="padding: .5rem;"></img>
  /**
   * @description: 更新 qrcode
   * @param {string} url 地址
   */
  const updateQRcode = (base64Resource) => {
    base64.value = base64Resource
  }

  return { render, updateQRcode }
}
