/*
 * @Date: 2022-12
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */
import {
  registerMicroApps,
  addGlobalUncaughtErrorHandler,
  start
} from 'qiankun'
import { infoLog } from './utils'
import { getSortApps } from './apps.js'
import { handleTrack } from '@/utils'
import { MicroAppTrack } from '@/qiankun/actBusiness/track.js'

// 存储不同子应用埋点实例
const trackInsMap = {}
const { usableRegisterMicroApps } = getSortApps()

// 注册路由微应用
export const startRegisterQiankun = () => {
  registerMicroApps(usableRegisterMicroApps, {
    // 微应用加载前
    beforeLoad: (app) => {
      infoLog(app, 'beforeLoad')
      const { name } = app
      trackInsMap[name] = new MicroAppTrack({ microAppName: name })
      trackInsMap[name].startTrack()
      trackInsMap[name].loadTrack()

      return Promise.resolve()
    },
    beforeMount: (app) => {
      infoLog(app, 'beforeLoad')
      const { name } = app
      if (trackInsMap[name]) {
        trackInsMap[name].bootstrapTrack()
      }

      return Promise.resolve()
    },
    // 微应用挂载后
    afterMount: (app) => {
      infoLog(app, 'afterMount', 'success')
      const { name } = app
      if (trackInsMap[name]) {
        trackInsMap[name].mountTrack()
      }
      return Promise.resolve()
    },
    beforeUnmount: (app) => {
      infoLog(app, 'beforeUnmount')
      const { name } = app
      // 清空埋点实例（因为仅需首次加载时触发埋点）
      trackInsMap[name] = null
    }
  })

  start()
}

// 检测qiankun全局异常
export function listenerQiankunErr () {
  const errCallback = (event) => {
    const { reason = {}, type = '' } = event
    const { stack, message } = reason

    handleTrack('global_err', {
      stack,
      message,
      type,
      trigger: 'dcPayFront.qiankun.addGlobalUncaughtErrorHandler'
    })
  }
  addGlobalUncaughtErrorHandler(errCallback)
}
