import { ENV_DATA } from '@/utils/globalData.js'
import { loadScript } from '@/utils'
import { mapActions } from 'vuex'
import { LanguageOperator, is_debug_order } from '@/utils/business.js'
import {
  publicKey,
  useIntegrationRiskJs
} from '@/views/Payment/hooks/CheckoutPay.js'

let errorStack = []
const localization =
  LanguageOperator.findLangItem(LanguageOperator.getLangOnLocal())
    ?.checkoutKey || 'EN-GB'
const SDK_URL = 'https://cdn.checkout.com/js/framesv2.min.js'
const writeCardItem = {
  fingerprint: ''
}

const { checkout_getDeviceSessionId } = useIntegrationRiskJs()

function renderErrorMsg (errorMessage) {
  var errorMessageElement = document.querySelector('.checkout-error-message')
  if (errorMessageElement) {
    errorMessageElement.textContent = errorMessage
  }
}

export default {
  data () {
    return {
      // 命名空间，避免mixin后冲突
      checkoutNamespace: {
        // 卡的校验码（由用户手动输入）
        cvv: '',
        // 选中卡的id(存在意味着勾选卡下单)
        selectSourceId: '',
        // 是否支持卡类型（与sdk的acceptedPaymentMethods匹配才支持）
        isPaymentMethodAccepted: false,
        // 记住卡 checkbox
        rememberCard: false,
        // 记住卡列表
        rememberCards: [writeCardItem]
      },
      // 显示支付弹窗
      showCheckoutPopup: false,
      initSDKPayId: '',
      checkoutBtnState: {
        disabled: true,
        loading: false
      }
    }
  },
  computed: {
    existRememberCard () {
      return this.checkoutNamespace.rememberCards?.length > 1
    }
  },
  methods: {
    ...mapActions('tool', ['showLoading', 'closeLoading']),

    async checkout_deleteCard () {
      const { id: sub_pay_type_id, pay_center_id: pay_type_id } =
        this.currPayMethodItem
      const { selectSourceId } = this.checkoutNamespace
      if (!selectSourceId) return

      // from /src/views/Payment/index.vue
      const { id: country = '' } = this.currCountryItem

      this.showDialog({
        title: this.$t('checkoutPayType.deleteCardTip'),
        desc: '',
        showCancelButton: true,
        tipConfirmCallbak: async () => {
          await Apis.deleteStoreCard({
            card_id: selectSourceId,
            pay_type_id,
            sub_pay_type_id,
            is_test: is_debug_order(),
            country
          })
          this.getCheckoutCards()
        }
      })
    },
    showCheckoutPaymentPopup (isShow = true) {
      this.showCheckoutPopup = isShow
    },
    onCloseCheckoutPop () {
      this.resetCheckoutCardForm()
    },
    resetCheckoutCardForm () {
      this.checkoutNamespace.selectSourceId = ''
      this.checkoutNamespace.cvv = ''
      this.checkoutNamespace.rememberCard = false
      this.checkoutNamespace.rememberCards = [writeCardItem]
      renderErrorMsg('')
    },
    onClickItem (item) {
      this.checkoutNamespace.selectSourceId = item.fingerprint
    },
    getErrorMessage (element) {
      var errors = {
        'card-number': this.$t('checkoutPayType.errCardNumTip'),
        'expiry-date': this.$t('checkoutPayType.errExpiryDateTip'),
        cvv: this.$t('checkoutPayType.errCVVCodeTip')
      }
      return errors[element]
    },
    isActCard (fingerprint) {
      return (
        this.checkoutNamespace.selectSourceId === fingerprint &&
        this.existRememberCard
      )
    },
    submitCheckoutCard () {
      const { selectSourceId } = this.checkoutNamespace
      this.checkoutBtnState.loading = true

      if (selectSourceId) {
        this.submitByHistoryCard(selectSourceId)
      } else {
        Frames.submitCard()
      }
    },
    async submitByHistoryCard (fingerprint) {
      const { cvv: source_cvv } = this.checkoutNamespace
      let risk_session_id
      try {
        risk_session_id = await checkout_getDeviceSessionId()
      } catch (error) {}

      const order_info = await this.handleSubmit({
        risk_session_id,
        source_cvv,
        source_type: 'id',
        fingerprint
      }).finally(() => (this.checkoutBtnState.loading = false))

      this.checkoutPaySucLogic(order_info)
    },
    destroyEvent () {
      const { Events } = window.Frames || {}
      if (!Events) return
      const eventList = [
        Events.CARD_TOKENIZED,
        Events.CARD_TOKENIZATION_FAILED,
        Events.FRAME_VALIDATION_CHANGED,
        Events.CARD_VALIDATION_CHANGED,
        Events.PAYMENT_METHOD_CHANGED,
        Events.READY
      ]
      eventList.forEach((event) => Frames.removeAllEventHandlers(event))
    },
    /**
     * @link [自定义sdk样式](https://www.checkout.com/docs/payments/accept-payments/accept-a-payment-on-your-website/get-started#Customize_Frames)
     */
    async initCheckoutSDK () {
      const { id: pay_type_id, checkout_card_scheme } = this.currPayMethodItem
      if (this.visibleStoreCards) {
        this.getCheckoutCards()
      }
      this.destroyEvent()
      // 加载过重置即可
      if (this.initSDKPayId === pay_type_id) {
        Frames.init()
        this.showCheckoutPopup = true
        return
      }

      this.showLoading()
      loadScript(SDK_URL, () => {
        this.initSDKPayId = pay_type_id
        Frames.init({
          frameSelector: '#checkout-container',
          acceptedPaymentMethods: [checkout_card_scheme],
          publicKey,
          localization,
          debug: !ENV_DATA.isPro,
          style: {
            base: {
              // 使用大写单位，避免被postcss转换，导致样式有问题
              fontSize: '14PX',
              background: '#2d3045',
              color: '#fff'
            },
            invalid: {
              color: '#F23841'
            },
            placeholder: {
              base: {
                color: '#7881AB'
              }
            }
          },
          ready: () => {
            // 触发risk.js初始化预加载
            checkout_getDeviceSessionId()
            this.closeLoading()
            this.showCheckoutPopup = true
            this.submitTrack('use_third_pay_suc', '', '', '')
          },
          paymentMethodChanged: ({ isPaymentMethodAccepted }) => {
            this.checkoutNamespace.isPaymentMethodAccepted =
              isPaymentMethodAccepted
          },
          // 卡号change
          cardValidationChanged: (event) => {
            this.checkoutBtnState.disabled =
              !Frames.isCardValid() ||
              !this.checkoutNamespace.isPaymentMethodAccepted
          },
          // 用户输入信息有误
          frameValidationChanged: (event) => {
            const hasError = !event.isValid && !event.isEmpty

            if (event.isEmpty) {
              // 未输入卡号，取消卡类型不匹配提示
              this.checkoutNamespace.isPaymentMethodAccepted = true
            }
            if (hasError) {
              errorStack.push(event.element)
            } else {
              errorStack = errorStack.filter(function (element) {
                return element !== event.element
              })
            }
            // 增加支付卡类型不匹配提示
            if (
              !this.checkoutNamespace.isPaymentMethodAccepted &&
              !errorStack.length
            ) {
              errorStack.push('card-number')
            }

            const errorMessage = errorStack.length
              ? this.getErrorMessage(errorStack[errorStack.length - 1])
              : ''
            renderErrorMsg(errorMessage)
          },
          // 获取token失败
          cardTokenizationFailed: () => {
            this.checkoutBtnState.loading = false
            this.$toast('get token failed')
            Frames.enableSubmitForm()
            // 埋点文档：https://www.kdocs.cn/l/c3NTfya1J
            this.submitTrack('use_third_pay_err', '', 307)
          },
          // 获取token成功
          cardTokenized: async (event) => {
            const { token: source_token } = event
            let risk_session_id
            try {
              risk_session_id = await checkout_getDeviceSessionId()
            } catch (error) {}

            const order_info = await this.handleSubmit({
              source_type: 'token',
              source_token,
              risk_session_id
            })
              .catch((err) => {
                Frames.enableSubmitForm()
                return Promise.reject(err)
              })
              .finally(() => (this.checkoutBtnState.loading = false))
            this.checkoutPaySucLogic(order_info)
          }
        })
      })
    },
    validateNeedSavecheckoutCard () {
      const { selectSourceId, rememberCard } = this.checkoutNamespace
      return !selectSourceId && rememberCard
    },
    // 支付成功后处理
    async checkoutPaySucLogic (order_info) {
      const { attachment, pay_id } = order_info
      const { order_id } = attachment
      const { existPwd } = this.userAuth
      const sucTipDuration = 2000
      const { selectSourceId, rememberCard } = this.checkoutNamespace
      // 卡录入支付 && 勾选了记住卡号
      const needSaveCard = !selectSourceId && rememberCard

      this.showCheckoutPopup = false
      this.submitTrack('pay_suc', pay_id, 0, order_id)
      needSaveCard && (await this.saveCheckoutCard(order_info))
      this.$toast({
        message: this.$t('form.paySuccessTip'),
        duration: sucTipDuration,
        onClose: async () => {
          let setPwdTipDuration = 0
          if (needSaveCard && !existPwd) {
            setPwdTipDuration = 4000
            await this.nonexistPwdSendEmail({ setPwdTipDuration })
          }
          this.asyncGoHome(setPwdTipDuration)
        }
      })
    },
    // 获取已保存卡列表
    async getCheckoutCards () {
      const { id: pay_type_id } = this.currPayMethodItem
      const strCards = await Apis.getStoreCard({
        pay_type_id
      })
      this.checkoutNamespace.rememberCards = strCards
        .map(({ source }) => JSON.parse(source))
        .concat([writeCardItem])
      this.$nextTick(() => {
        this.checkoutNamespace.selectSourceId = this.existRememberCard
          ? this.checkoutNamespace.rememberCards[0].fingerprint
          : ''
      })
    },
    async saveCheckoutCard (order_info) {
      const { attachment } = order_info
      let { order_id, source } = attachment
      const { id: pay_type_id } = this.currPayMethodItem

      source = JSON.stringify(source)
      await Apis.storeCard({ pay_type_id, order_id, source })
    },
    selectCardFormIsValid () {
      const { cvv } = this.checkoutNamespace
      return cvv && cvv.length >= 3
    },
    clearCheckoutSelectCardForm () {
      this.checkoutNamespace.cvv = ''
      this.checkoutNamespace.rememberCard = false
    }
  },
  watch: {
    'checkoutNamespace.cvv' (cvvVal) {
      this.checkoutBtnState.disabled = !this.selectCardFormIsValid()
    },
    'checkoutNamespace.selectSourceId' (id) {
      if (id) {
        this.checkoutBtnState.disabled = !this.selectCardFormIsValid()
      } else {
        this.checkoutBtnState.disabled = !Frames.isCardValid()
      }
      this.clearCheckoutSelectCardForm()
    }
  }
}
