/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description:
 */
const infoLog = (app, hook, type = 'primary') => {
  const color = {
    primary: '#1890ff',
    success: '#52c41a',
    warning: '#ffbc35'
  }[type]
  console.info(
    `%c [main-front] ${hook} %c ${app.name} %c`,
    'background:#001529 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    `background:${color} ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff`,
    'background:transparent'
  )
}

function existChildrenElement (containerId) {
  if (!containerId) return

  const el = document.getElementById(containerId)
  return !!el?.childNodes?.length
}

export { infoLog, existChildrenElement }
