/**
 * Steam 小额交易
 * @link https://partner.steamgames.com/doc/features/microtransactions/implementation#7
 *
 * 具体步骤：
 * 1. 获取用户的 SteamID
 * 2. 调用创建订单的接口，并将 SteamID 和需要购买的商品传递给后端
 *    后端会和 Steam 服务交互，创建 Steam 订单，并返回一个支付地址
 * 3. 将当前页面跳转至后端返回的交易链接，并附上交易成功的回调地址 location.href
 * 4. 用户返回 Payment 页面
 */
import { getCurrentInstance, onMounted } from 'vue'
import { useRouter } from '@/router/index'
import { Toast } from 'vant'
import Storage from '@/utils/sessionStorage'
import Apis from '@/api/index'
import { useRetryFnWhenMatchErrorCodes } from '@/utils/index.js'
import { $t } from '@/i18n'
import { useSaveLocalThirdInfo } from '@/views/User/ThirdPartyLogin'
import { useStoreTool } from '@/utils/business/store.js'

export const useSteamPay = () => {
  const router = useRouter()
  const instance = getCurrentInstance()
  const { getLocalThirdId } = useSaveLocalThirdInfo()
  const { showLoading } = useStoreTool()

  const CountrySessionKey = 'steam_pay_country'
  const SteamOrderInfo = 'steam_order_info'

  function cleanURLQuery () {
    const products = router.currentRoute.query.products
    const query = { products }
    router.replace({ query })
  }

  function getSteamRedirectURL (sourceURL) {
    const url = new URL(sourceURL)
    const { origin, pathname, hash } = url
    // hash: #/payment?order_id=xxx&products=xxx
    const [hashPath, hashQuery] = hash.split('?')
    let query = ''
    const parsedQuery = new URLSearchParams(hashQuery)

    if (parsedQuery.has('products')) {
      query = `?products=${encodeURIComponent(parsedQuery.get('products'))}`
    }
    return `${origin}${pathname}${hashPath}${query}`
  }

  // 处理创建订单流程中，steamPay相关的逻辑
  const handleSubmitBySteam = async (data, country) => {
    Storage.set(CountrySessionKey, country.id)
    const steamID = getLocalThirdId()
    if (steamID) {
      await doCreateOrder(data, steamID)
    } else {
      throw new Error('创建订单时未找到SteamID')
    }
  }

  // 创建订单
  const doCreateOrder = async (orderParams, steamID) => {
    const toast = Toast.loading()
    // 这里需要取到订单数据，然后拼上 steamID，并调用创建订单的接口
    try {
      const createOrderParams = orderParams
      const channelInfo = createOrderParams.channel_info
        ? JSON.parse(createOrderParams.channel_info)
        : {}
      channelInfo.steam_id = steamID
      createOrderParams.channel_info = JSON.stringify(channelInfo)
      await instance.proxy.useCreateOrderApi(createOrderParams)
    } finally {
      toast.clear()
    }
  }

  /**
   * 处理 Steam 支付链接回跳的逻辑
   * 当执行到这里时，当前订单可能支付完成，或者用户取消支付也会走到这里
   *
   * 需要用当前的 order_id 和 country 查询当前订单的状态
   *      如果接口没有错误，说明订单支付成功
   *      如果接口报错，说明订单没有支付
   */
  const handlePostSteamPay = async (order_id) => {
    // 去 session 缓存中取 country_id
    const country = Storage.get(CountrySessionKey)
    if (!country) return
    // 清空缓存
    Storage.set(CountrySessionKey, undefined)

    function getOrderStatus (order_id, country) {
      return Apis.informPayCenter({ order_id, country })
    }

    const order_info = Storage.get(SteamOrderInfo)
    if (!order_info) return

    const { pay_id } = order_info
    const toast = Toast.loading()

    try {
      await useRetryFnWhenMatchErrorCodes(
        () => getOrderStatus(order_id, country),
        2,
        1000
      )
      const { $toast, submitTrack, asyncGoHome } = instance.proxy
      $toast({
        message: $t('form.paySuccessTip'),
        duration: 2000,
        onClose: () => {
          // 立即跳转首页
          asyncGoHome && asyncGoHome(0)
        }
      })

      // 埋点
      submitTrack('pay_suc', pay_id, 0, order_id)
    } finally {
      // 清理 url 上的 order_id
      cleanURLQuery()
      Storage.set(SteamOrderInfo, undefined)
      toast.clear()
    }
  }

  const showSteamPayPage = (order_info) => {
    Storage.set(SteamOrderInfo, order_info)
    const { pay_url, pay_id, attachment } = order_info
    const { order_id } = attachment
    const { submitTrack } = instance.proxy
    if (pay_url) {
      // loading - 避免跳转慢多次点击提交，无需close
      showLoading()
      // 延时避免埋点未发送
      setTimeout(() => {
        window.location.href = pay_url
      }, 500)

      submitTrack('use_third_pay_suc', pay_id, 0, order_id)
    } else {
      submitTrack('use_third_pay_err', pay_id, 305, order_id)
    }
  }

  onMounted(async () => {
    const order_id = router.currentRoute.query.order_id
    if (order_id) {
      handlePostSteamPay(order_id)
    }
  })

  return {
    showSteamPayPage,
    getSteamRedirectURL,
    handleSubmitBySteam
  }
}
