/*
 * @Date: 2023-03
 * @LastEditors: wfj
 * @LastEditTime: 2023-03
 * @Description:
 */
/** 以下为游戏方提供语言标识 */

// 自定义语言码  标准语言码    备注
// id    standard_code  NULL
// c    c      NULL
// string    string      NULL
// zh    zh-cn      简体中文（Chinese (Simplified)）
// zht    zh-tw      繁体中文（Chinese (Traditional)）
// en    en      英语（English）
// idn    id      印度尼西亚（Indonesian）
// pt    pt      葡萄牙语（Portuguese）
// es    es      西班牙语（Spanish）
// de    de      德语（German）
// fr    fr      法语（French）
// it    it      意大利语（Italian）
// tr    tr      土耳其语（Turkish）
// ru    ru      俄语（Russian）

import { LanguageData } from '@/utils/globalData.js'

const getLangKey = LanguageData.getLangKey.bind(LanguageData)

export default {
  zh: getLangKey('zh-cn'),
  zht: getLangKey('zh-tw'),
  en: getLangKey('en-us'),
  idn: getLangKey('id-id'),
  pt: getLangKey('pt-pt'),
  es: '',
  de: getLangKey('de-de'),
  fr: getLangKey('fr-fr'),
  it: getLangKey('it-it'),
  tr: getLangKey('tr-tr'),
  ru: getLangKey('ru-ru')
}
