<!-- 苹果支付 -->
<template>
  <div>
    <!-- airwallex 支付弹窗 -->
    <van-popup
      v-model="showPopup"
      v-bind="{ ...commonPopupProps() }"
      class="airwallex-pay-popup"
      @closed="onClosedAirwallex"
    >
      <div class="container">
        <!-- 3ds风控节点 -->
        <div ref="form3dsRef" :id="sdkState.CONTAINER_FOR_3DS"></div>

        <!-- 支付节点(显示风控时，支付不显示) -->
        <div v-show="!isTouch3dsValidate">
          <div :id="sdkState.AIRWALLEX_ID"></div>
          <div class="manage-btn-position">
            <LinkBtn v-if="visibleManageBtn" @click="onClickManage">{{
              $t("checkoutPayType.cardManage")
            }}</LinkBtn>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 卡管理弹窗 -->
    <AirwallexManagePopup ref="cardManageRef" @closed="onClosedManagePopup" />
  </div>
</template>

<script setup>
// TODO: 上线后，在渠道后台（分为测试、正式环境）配置正式环境的域名
import { useSDK, usePopup, useGetPayMethod } from './AirwallexPayPopup'
import { Toast } from 'vant'
import { ref, inject, onUnmounted, defineExpose, onMounted } from 'vue'
import { useStoreTool } from '@/utils/business/store.js'
import { commonPopupProps } from '@/views/Payment'
import AirwallexManagePopup from './AirwallexManagePopup.vue'
import { $t } from '@/i18n'
import LinkBtn from '@/components/LinkBtn.vue'

// 暴露函数供外部打开弹窗
defineExpose({ showAirwallexPayPage })

// 卡管理组件ref
const cardManageRef = ref()
// 3ds dom
const form3dsRef = ref()
// 是否触发3ds风控校验
const isTouch3dsValidate = ref(false)
// 是否显示卡管理按钮
const visibleManageBtn = ref(false)
// 订单信息
let airwallex_order_info = {}

const { showLoading, closeLoading } = useStoreTool()
const getData = inject('getData', () => ({}))
const { sdkState, loadSDK, createEl, destroyEl, registerEvent, removeEvent } =
  useSDK()
const { showPopup, openPopup, closePopup } = usePopup()
const { validatePayMethod } = useGetPayMethod()
onMounted(() => {
  // 当观察到变动时执行的回调函数
  const callback = function (mutationsList) {
    for (const { type, target } of mutationsList) {
      if (type === 'childList') {
        isTouch3dsValidate.value = !!target.firstChild
      }
    }
  }
  // 创建一个观察器实例并传入回调函数
  const observer = new MutationObserver(callback)
  // 以上述配置开始观察目标节点
  observer.observe(form3dsRef.value, { childList: true })
})

/**
 * @description: 显示支付弹窗
 * @param {*} order_info
 * @param {*} cards 已保存卡列表
 */
async function showAirwallexPayPage (order_info, cards = []) {
  airwallex_order_info = order_info
  const { currPayMethodItem } = getData()
  const options = returnSDKParams(order_info)
  const { isCardPay } = validatePayMethod(currPayMethodItem.id)
  showLoading()
  visibleManageBtn.value = cards.length && isCardPay
  isTouch3dsValidate.value = false
  if (sdkState.loadedSDK) {
    createEl(options)
  } else {
    await loadSDK()
    createEl(options)
    registerEvent({ onReady, onSuccess, onError })
  }
  // onReady 回调 中会 closeLoading && 打开弹窗
}

// 返回初始化 sdk 所需参数
function returnSDKParams (order_info) {
  const { attachment } = order_info
  const { currPayMethodItem } = getData()
  const {
    payment_intent_id: intent_id,
    client_secret,
    currency,
    customer_id
  } = attachment
  const options = {
    intent_id,
    client_secret,
    currency,
    customer_id, // 控制开启绑卡功能
    sub_pay_id: currPayMethodItem.id
  }
  const { visibleStoreCards } = getData()

  if (!visibleStoreCards) {
    // 关闭绑卡功能
    delete options.customer_id
  }
  return options
}
// STEP #6: Add an event listener to handle events when the element is mounted
function onReady (event) {
  const { order_id, pay_id } = getHandleOrderInfo()
  closeLoading()
  openPopup()
  submitTrack('use_third_pay_suc', pay_id, 0, order_id)
}
// STEP #8: Add an event listener to handle events when the payment has failed.
// 错误响应结构：https://www.airwallex.com/docs/api?v=2019-09-09#/Errors
function onError (event) {
  const { message, code } = event.detail.error
  closeLoading()
  Toast(message)
  // 错误码太多不做维护，传-1 即可
  submitTrack('use_third_pay_err', pay_id, -1, order_id, {
    status_code_info: code
  })
}
// STEP #7: Add an event listener to handle events when the payment is successful.
function onSuccess (event) {
  const { order_id, pay_id } = getHandleOrderInfo()
  const { asyncGoHome } = getData()
  closePopup()
  Toast({
    message: $t('form.paySuccessTip'),
    duration: 2000,
    onClose: () => asyncGoHome(0)
  })
  submitTrack('pay_suc', pay_id, 0, order_id)
}

function getHandleOrderInfo () {
  const { attachment, pay_id } = airwallex_order_info
  const { order_id } = attachment
  return { pay_id, order_id }
}

// 发送埋点
function submitTrack (...args) {
  const { submitTrack: track } = getData()
  track(...args)
}

// 点击卡号管理
function onClickManage () {
  cardManageRef.value.mountPopup()
}

// 管理弹窗关闭回调
function onClosedManagePopup ({ isRemoved, cards }) {
  if (!isRemoved) return
  const { currPayMethodItem } = getData()
  const { isCardPay } = validatePayMethod(currPayMethodItem.id)
  const options = returnSDKParams(airwallex_order_info)
  visibleManageBtn.value = cards.length && isCardPay
  showLoading()
  destroyEl()
  createEl(options)
  closeLoading()
}

// 关闭弹窗回调
function onClosedAirwallex () {
  destroyEl()
  form3dsRef.value.innerHTML = ''
}

onUnmounted(() => {
  if (sdkState.loadedSDK) {
    removeEvent({ onReady, onSuccess, onError })
  }
})
</script>

<style scoped lang="less">
.pr-40 {
  padding-right: 40px;
}
.airwallex-pay-popup {
  width: 650px;
  max-height: 70%;
  // 调整关闭按钮位置，避免挡住支付页内容
  /deep/ .van-popup__close-icon--top-right {
    top: 0.1rem;
    right: 0.1rem;
  }

  .container {
    padding: 60px 40px 80px;
    position: relative;
  }
}
.manage-btn-position {
  position: absolute;
  bottom: 20px;
  right: 40px;
}
</style>
