/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
 */

import User from '@/utils/business/user.js'

class ErrorHandle {
  handleMap () {
    return {
      401: () => {
        User.authExpire()
      }
    }
  }

  handleError (code) {
    const fnMap = this.handleMap()
    const targetFn = fnMap[code]

    targetFn && targetFn()
  }
}

export default new ErrorHandle()
