<!--
 * @Date: 2021-12-19 10:12:04
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-11 14:49:44
 * @FilePath: /dc-pay-front/src/views/Home/ProductItem/ProductItem.vue
-->
<template>
  <li
    :class="[
      'pro-item',
      {
        selected: productItemInfo.selected,
        'single-choice': isSingleChoice,
        disabled: disabled,
        'is-selected-next-item': isSingleChoice && isNextItemOnSelectedItem,
        'no-prop': !hasContentProp && !hasBenefitsProp && !hasExtraShareProp,
        'split-border': showBorder,
      },
    ]"
    @click="onclickItem"
  >
    <van-checkbox
      :disabled="disabled"
      class="mul-choice"
      v-model="productItemInfo.selected"
      v-if="isMultipleChoice"
    ></van-checkbox>

    <div class="product-img">
      <div class="product-img-bg flex-center">
        <van-image
          :src="productItemInfo.image_url"
          round
          class="pro-item__logo"
        />
      </div>
    </div>

    <div class="pro-item__cont">
      <div class="cont">
        <!-- 标题和价格栏 -->
        <div class="cont-title-row">
          <h3 class="cont-title">{{ productItemInfo.name }}</h3>
          <div class="cont-price-container">
            <p
              :class="[
                'common-price',
                {
                  'normal-font-color': visibleImputedPrice || visibleCouponPrice,
                },
              ]"
            >
              {{
                visibleImputedPrice || visibleCouponPrice
                  ? productItemInfo.currency + " " + productItemInfo.amount_display
                  : productItemInfo.amount_display + " " + productItemInfo.currency
              }}
            </p>
            <!-- 限购数量，暂时关闭（2022/6/7，客户投诉自己的购买次数会被他人看到） -->
            <!-- <div
              v-if="isLimitNum"
              :style="{
                background: `linear-gradient(to right, #ffe7ec 0%, #ffe7ec ${limitProgress}%, white ${limitProgress +
                  1}%, white 100%)`
              }"
              class="cont-right__limit"
            >
              {{ $t("form.productLimitText", { limit: productItemInfo.limit }) }}
            </div> -->
            <!-- 原价 -->
            <p
              class="cont-right__original-price"
              v-if="productItemInfo.amount_origin"
            >
              {{ productItemInfo.currency }}{{ productItemInfo.amount_origin }}
            </p>

            <!-- 预估到手价格 -->
            <DiscountPrice
              v-if="visibleImputedPrice"
              :label="$t('product.futurePrices')"
              :value="imputedPrice"
            />

            <!-- 到手价格 -->
            <DiscountPrice
              v-else-if="visibleCouponPrice"
              :label="$t('product.futurePrices')"
              :value="afterCouponPrice"
            />
          </div>
        </div>
        <!-- 限时限时和步进器 -->
        <div class="cont-step-row">
          <!-- 限时 -->
          <CountDownTimer
            v-if="isLimitTime"
            :timestamp="productItemInfo.expired_time_millisecond"
          ></CountDownTimer>
          <div class="spacer" v-else></div>

          <!-- 步进器 -->
          <Stepper
            v-bind="stepperProps"
            v-model="productItemInfo.count"
            v-if="isShowStepperAfterPrice"
            @change="changeAmount"
            @focus="stepperFocus"
          ></Stepper>

          <!-- 纯文案展示数量 -->
          <p class="product-num" v-if="showNumText">
            x{{ productItemInfo.count }}
          </p>
          <!-- 限购 -->
          <!-- <div
              v-if="isLimitNum"
              :style="{
                background: `linear-gradient(to right, #ffe7ec 0%, #ffe7ec ${limitProgress}%, white ${limitProgress +
                  1}%, white 100%)`
              }"
              class="cont-right__limit"
            >
              {{
                $t("form.productLimitText", { limit: productItemInfo.limit })
              }}
            </div> -->
        </div>
        <!-- 使用 flex-between 令左侧的倒计时和右侧的添加按钮保持在同一行 -->
        <!-- <div class="flex-vbox flex-between cont-left">
          <p class="cont-title">{{ productItemInfo.name }}</p>
          <div class="cont-limit">
          </div>
        </div> -->
      </div>
      <div class="cont-prop" v-if="showPropList">
        <!-- 包含内容、额外赠送 的道具列表 -->
        <PropList
          v-for="(item, key) in propData"
          :key="key"
          class="prop-li"
          v-bind="item"
        ></PropList>
      </div>

      <!-- 购物车购买项操作栏 -->
      <div class="foot-operator" @click.stop="" v-if="isShowFootOperator">
        <!-- 按钮宽高无需 做转换适配，所以此处用style -->
        <div
          class="circle-btn btn-item"
          @click="clickDelete"
          style="width: .44rem; height: .44rem"
        >
          <van-icon :name="IconDelete" size=".44rem" />
        </div>
        <Stepper
          v-bind="stepperProps"
          v-model="productItemInfo.count"
          @change="changeAmount"
          @focus="stepperFocus"
        ></Stepper>
      </div>
    </div>
    <div class="selected-logo">
      <van-icon :name="IconChecked" class="selected-logo__icon" />
    </div>
    <AmountInputPopup ref="amountInput" v-if="enableStepperInput" :min="1" :max="maxAddCartNum" @confirm="changeAmount" />
  </li>
</template>

<script>
import Stepper from '@/components/Stepper.vue'
import PropList from '@/views/Home/ProductItem/PropList.vue'
import CountDownTimer from '@/components/CountDownTimer.vue'
import DiscountPrice from '@/components/DiscountPrice.vue'
import { Checkbox } from 'vant'
import {
  validateDisabledProduct,
  isLimitNum,
  isOverbalance,
  isLimitTime,
  parseFloatNum,
  isMutex,
  purchasableNum
} from '@/utils/business/product.js'
import { validateBeforeUpdateCartCount } from '@/utils/business/cart.js'
import { mapState, mapGetters } from 'vuex'
import IconDelete from '@/assets/icon/icon_delete.svg'
import IconChecked from '@/assets/icon/icon_checked.svg'
import AmountInputPopup from './AmountInputPopup.vue'

export default {
  components: {
    PropList,
    CountDownTimer,
    Stepper,
    [Checkbox.name]: Checkbox,
    DiscountPrice,
    AmountInputPopup
  },
  data () {
    return {
      IconDelete,
      IconChecked
      // amount: 0
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (isDisabled) {
        // 设置购买项列表禁用状态
        this.productItemInfo.disabled = isDisabled
      }
    }
  },
  computed: {
    ...mapState('cart', ['cartLength']),
    ...mapGetters('cart', ['canAddCartNum', 'productMap']),
    ...mapGetters('product', ['mutexGroup']),

    // 是否显示预估价
    visibleImputedPrice () {
      const { showImputedPrice, imputedPrice, productItemInfo } = this
      return showImputedPrice && !!imputedPrice && !productItemInfo.disabled
    },
    // 是否显示券后价
    visibleCouponPrice () {
      const { showCouponPrice, afterCouponPrice, productItemInfo } = this
      const { disabled } = productItemInfo
      return showCouponPrice && !!afterCouponPrice && !disabled
    },
    // 券后价格
    afterCouponPrice () {
      const { reduce_amount_display, amount_display, currency } =
        this.productItemInfo
      if (!reduce_amount_display) return ''

      const price = parseFloatNum(amount_display - reduce_amount_display)
      return `${price} ${currency}`
    },
    // 预估价格
    imputedPrice () {
      const { amount_free_estimate, amount_display, currency } =
        this.productItemInfo
      if (!amount_free_estimate) return ''

      const price = parseFloatNum(amount_display - amount_free_estimate)
      return `${price} ${currency}`
    },
    // 传递给步进器的属性
    stepperProps () {
      const { mutex_id } = this.productItemInfo
      const { isMaximal = false } = this.mutexGroup[mutex_id] || {}
      return {
        ...this.stepperPropsHelp,
        'disable-plus': isOverbalance(this.productItemInfo) || isMaximal
      }
    },
    // 在购物车的最大数量（包含已加购数量）
    maxAddCartNum ({ canAddCartNum }) {
      let num = 0
      const { mutex_id, id } = this.productItemInfo
      const currProductCartNum = this.productMap[id]?.count || 0 // 当前商品加购数量
      const maxNum = currProductCartNum + canAddCartNum // 数量上限

      if (this.isMutex) {
        const { purchasable, cartCount } = this.mutexGroup[mutex_id] || {} // 互斥组可购买数量
        num = purchasable - (cartCount - currProductCartNum)
      } else if (this.isLimitNum) {
        num = purchasableNum(this.productItemInfo)
      } else {
        // +已加购数量
        num = maxNum
      }

      return num > maxNum ? maxNum : num
    },
    // 是否显示底部操作栏
    isShowFootOperator () {
      return this.operatorPosition === 'foot' && !this.disabled
    },
    // 是否在价格后显示步进器
    isShowStepperAfterPrice () {
      return this.operatorPosition === 'afterPrice' && !this.disabled
    },
    // 不存在选中效果
    isNoneChoice () {
      return !this.selectedStyle || this.selectedStyle === 'none'
    },
    isMultipleChoice () {
      return this.selectedStyle === 'multipleChoice'
    },
    isSingleChoice () {
      return this.selectedStyle === 'singleChoice'
    },
    // 限购血槽进度
    limitProgress () {
      if (this.isLimitNum) {
        const { limit, purchased } = this.productItemInfo
        return parseInt((purchased / limit) * 100)
      }
      return 0
    },
    // 超过：限购数量、限时时间 则禁用
    disabled () {
      // return false
      return validateDisabledProduct(this.productItemInfo)
    },
    // 是否是限购商品
    isLimitNum () {
      return isLimitNum(this.productItemInfo)
    },
    // 是否是限时商品
    isLimitTime () {
      return isLimitTime(this.productItemInfo)
    },
    // 是否是互斥商品
    isMutex () {
      return isMutex(this.productItemInfo)
    },
    // 渲染道具数据
    propData () {
      const result = {}

      // 增加判断，避免渲染空对象
      if (this.hasContentProp) {
        result.cont = {
          title: this.$t('form.includeContText'),
          data: this.productItemInfo.content,
          propItemStyle: {}
        }
      }
      if (this.hasBenefitsProp) {
        result.benefits = {
          title: this.$t('form.giveText'),
          data: this.benefits,
          propItemStyle: {
            backgroundColor: '#1B3333',
            borderColor: '#41A687'
          },
          numStyle: {
            color: '#17b784',
            'background-color': '#a2f4d9'
          }
        }
      }
      if (this.hasExtraShareProp) {
        result.extraShareProp = {
          title: this.$t('form.extraSharePropText'),
          data: this.extraShareProp,
          propItemStyle: {}
        }
      }
      return result
    },
    // 是否存在内容道具
    hasContentProp () {
      const { content } = this.productItemInfo
      return content && !!content.length
    },
    // 是否存在额外道具
    hasBenefitsProp () {
      const { benefits } = this
      return benefits && !!benefits.length
    },
    benefits () {
      return this.productItemInfo?.extra_benefit?.benefits || []
    },
    // 额外的分享道具
    extraShareProp () {
      return this.productItemInfo?.extra_share_content?.share_content || []
    },
    // 是否存在额外的分享道具
    hasExtraShareProp () {
      return this.extraShareProp && !!this.extraShareProp.length
    },
    enableStepperInput ({ stepperPropsHelp }) {
      if (stepperPropsHelp['disable-input'] === false || stepperPropsHelp.disableInput === false) {
        return true
      }
      return false
    }
  },
  props: {
    // 是否显示数量文案
    showNumText: {
      type: Boolean,
      default: false
    },
    // 是否显示分割线
    showBorder: {
      type: Boolean,
      default: true
    },
    // 是否显示道具列表
    showPropList: {
      type: Boolean,
      default: true
    },
    // 是否显示到手价（多个商品对应一张券，一般在组合商品、购物车时为true）
    showCouponPrice: {
      type: Boolean,
      default: false
    },
    // 是否显示预估到手价（单个商品对应一张券，一般在首页商品列表时为true）
    showImputedPrice: {
      type: Boolean,
      default: false
    },
    // 步进器的位置：foot底部；afterPrice价格后面；none不显示；
    operatorPosition: {
      type: String,
      default: 'afterPrice'
    },
    // 选择类型：singleChoice单选；multipleChoice多选；none无选中效果；
    selectedStyle: {
      type: String,
      default: 'multipleChoice'
    },
    // 当前项是否是已选中项的下一项
    isNextItemOnSelectedItem: {
      type: Boolean,
      default: false
    },
    // 购买项信息
    productItemInfo: {
      type: Object,
      default: () => ({})
    },
    stepperPropsHelp: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    stepperFocus () {
      const { count } = this.productItemInfo
      this.$refs.amountInput.openPopup({ count })
      this.$emit('stepperFocus')
    },
    clickDelete () {
      this.setAmount(0)
    },
    async changeAmount (targetCount) {
      const product = this.productItemInfo
      const { count } = product
      await validateBeforeUpdateCartCount({ product, targetCount, vm: this })
      this.$emit('changeAmount', {
        count,
        product,
        targetCount
      })
    },
    async setAmount (count) {
      const { id: product_id } = this.productItemInfo
      const product_cart = [{ product_id, count }]

      this.$store.dispatch('tool/showLoading')
      await this.$store.dispatch('cart/updateCartCount', { product_cart })
      this.$store.dispatch('tool/closeLoading')
    },
    onclickItem () {
      if (this.isNoneChoice || this.disabled) return
      this.$emit('clickProductItem', this.productItemInfo)
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
@import url("~@/styles/common.less");

.pro-item {
  display: flex;
  // padding: 25px 20px;
  // background-color: pink;
  position: relative;
  overflow: hidden;
  &.split-border {
    border: 1px solid transparent;
    border-top-color: var(--dp-divider-primary);
  }
  &:first-child {
    border-top-color: transparent;
  }
  &.selected {
    &.single-choice {
      border-color: var(--dp-primary);
      border-radius: 10px;
      .selected-logo {
        display: block;
      }
    }
  }
  &.single-choice {
    padding-right: var(--dp-sp-2xs);
  }
  &.disabled {
    .product-img, .pro-item__cont {
      opacity: 0.4;
      cursor: not-allowed;
    }
    // pointer-events: none;
  }
  &.is-selected-next-item {
    border-top-color: transparent;
  }
  &.no-prop {
    // .pro-item__logo {
    //   width: 96px;
    //   height: 96px;
    // }
    .pro-item__cont {
      margin-top: 10px;
    }
  }

  .pro-item__logo {
    width: 95%;
    height: 95%;
  }
  .pro-item__cont {
    flex: 1;
    margin-left: 17px;
    .cont-title {
      font-size: var(--dp-text-md);
      font-weight: 700;
      line-height: 1.32;
    }
  }
}
.cont-title-row {
  display: flex;
  justify-content: space-between;
  .cont-title {
    flex: 1;
    font-size: var(--dp-text-md);
    line-height: 1.32;
  }
  .cont-price-container {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .cont-right__original-price {
    font-size: var(--dp-text-lg);
    font-weight: 700;
    text-decoration: line-through;
  }
}
.cont-step-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--dp-sp-xs) 0;
}
.cont {
  .cont-left {
    flex: 1;
    .cont-limit {
      display: flex;
      align-items: center;
      margin-top: 18px;
    }
  }
}
.cont-right__limit {
  font-size: 16px;
  font-weight: 400;
  color: @productPivotalColor;
  line-height: 25px;
  padding: 0px 14px;
  border: 1px solid @productPivotalColor;
  border-radius: 12px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-left: 13px;
}
.cont-prop {
  overflow: hidden;
  .prop-li {
    margin-top: var(--dp-sp-sm);
  }
  .prop-li:nth-child(1) {
    margin-top: 0;
  }

}
.mul-choice {
  align-self: flex-start;
  margin: 22px 30px 0 0;
  /deep/.van-checkbox__icon {
    font-size: var(--dp-text-xl);
  }
}
.foot-operator {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .btn-item {
    margin-left: var(--dp-sp-lg);
  }
}
.normal-font-color {
  color: var(--dp-text-primary);
}
</style>
