/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description:
 */

export default {
  getBanners: '/banner/GetADInfo',
  getServers: '/user/GetServerInfo',
  // getDefaultCountry: '/user/GetCountryInfo',
  getDefaultCountry: '/user/GetDefaultCountry',
  // 获取微信配置信息
  getWechatInfo: '/user/GetWechatInfo',
  // 判断用户是否设置密码
  checkExistPwd: '/user/GetRoleInfo',
  // 登入
  userLogin: '/user/Login',
  // 三方登入
  thirdLogin: '/user/ThirdLogin',
  // 刷新token
  refreshToken: '/user/RefreshToken',
  // 重置密码
  resetUserPwd: '/user/ResetUserPwd',
  // 发送重置密码邮件
  sendResetMail: '/user/SendResetMail',
  // 记录已显示过【提醒设置密码弹窗】
  notPwdSetRemind: '/user/NotPwdSetRemind'
}
