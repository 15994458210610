<!--
 * @Date: 2023-01
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 活动开屏弹窗、大转盘活动的开屏弹窗
-->

<template>
  <popup class="guide-popup" v-model="showDialog" getContainer="body">
    <div class="content" v-if="isActTurntable">
      <van-image class="img" :src="actImgUrl" @click="onclickActGuide" />
      <van-button
        class="btn"
        round
        type="info"
        size="small"
        @click="onclickActGuide"
      >
        {{ $t("activity.joinBtnText") }}
      </van-button>
    </div>

    <open-screen-act-tabs
      v-else
      :acts="acts"
      :active-act="activeAct"
      :active-index="activeIndex"
      @changeIndex="updateActiveIndex"
      @join="onclickActGuide"
    />
  </popup>
</template>

<script setup>
import { onMounted, defineProps, defineEmits } from 'vue'
import Popup from '@/components/Popup.vue'
import {
  useActGuidePopup,
  handleClickPopup,
  useOpenScreenActTabs
} from '@/views/Activity/popup/GuidePopup.js'
import { useTurntableAct } from '@/views/Activity/TurntableAct.js'
import OpenScreenActTabs from './OpenScreenActTabs.vue'

/** 数据 */
const props = defineProps({
  isUrlLogin: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['clickJoinAct'])

/** 操作 */
const { visibleTurntableActGuidePopup } = useTurntableAct()
const { actImgUrl, showDialog, isActTurntable, closePopup } = useActGuidePopup()
const { acts, activeIndex, activeAct, updateActiveIndex, addListenerVisiblePopup } = useOpenScreenActTabs()

onMounted(async () => {
  addListenerVisiblePopup()
  // 是否显示大转盘开屏弹窗
  visibleTurntableActGuidePopup(props.isUrlLogin)
})

async function onclickActGuide () {
  closePopup()
  handleClickPopup()
  emits('clickJoinAct')
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .content {
    width: 750px;
    margin: 0 auto;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
  .btn {
    background-color: #e83c35;
    border-color: #e83c35;
    margin-top: 15px;
  }
}
.img {
  width: 667px;
  height: 675px;
}

.guide-popup {
  /deep/ .van-popup__close-icon {
    bottom: -0.12rem;
  }
}
</style>
