/*
 * @Date: 2023-03
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-04-24 14:28:37
 * @Description: views/Payment/index.vue 的相关逻辑
 */
import { mapState, mapMutations, mapActions } from 'vuex'
import { Base64 } from '@/utils/index.js'

export default {
  computed: {
    ...mapState('payment', ['lastOrderInfo']),

    /**
     * 返回url 上 products 参数的解析结果
     * query.products 解析后数据格式： [{product_id, count}]
     */
    urlProducts () {
      // 数据格式：[{}]
      let { products } = this.$route.query
      if (!products) return null

      products = Base64.decode(products)
      return products
    },
    // url 上是否有传递 products
    existUrlProducts () {
      return !!this.urlProducts?.length
    },
    urlPresentInfo () {
      // 数据格式见：https://xmdc.yuque.com/staff-nt39y2/ulet29/yllel5#BxKuU
      const { presentInfo } = this.$route.query
      if (!presentInfo) return

      return Base64.decode(presentInfo)
    }
  },
  methods: {
    ...mapMutations('payment', ['UPDATE_PAYMENT_STATE']),

    ...mapActions('product', ['getProductByIds', 'validateProductAndTip']),

    // 读取待支付订单商品
    async loadUnpaidProducts (country) {
      const { cart } = this.lastOrderInfo.order_info
      const products = cart.map((item) => ({
        count: item.count,
        product_id: item.product_id
      }))
      await this.updatePayProducts(products, country)
    },
    // 读取地址透传商品
    async loadUrlProducts (country) {
      await this.updatePayProducts(this.urlProducts, country)
    },
    // 读取购物车订单商品
    async loadOrderProducts (country) {
      // 创建订单流程
      return this.$store.dispatch('payment/getOrderInfo', country)
    },
    // 更新要结算的商品
    async updatePayProducts (products, country) {
      const extend = { channelType: this.query.channelType }
      const list = await this.getProductByIds({
        product_cart: products,
        country,
        extend
      })
      this.UPDATE_PAYMENT_STATE({ key: 'productList', value: list })
    }
  }
}
