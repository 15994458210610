<!--
 * @Date: 2021-12-16 14:13:45
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-06-17 16:43:41
 * @FilePath: /dc-pay-front/src/views/Payment/popup/UnpaidPopup.vue
-->
<template>
  <van-popup v-model="show" class="unpaid-popup" round>
    <div class="unpaid-popup-main">
      <!-- 您有一笔待支付的订单，是否去支付？ -->
      <p class="unpaid-popup__title">{{ $t("popup.goOnPayTip") }}</p>
      <ul class="ul">
        <li class="li" v-for="(item, key) in orderInfoForView" :key="key">
          <p class="li-label">{{ item.label }}:</p>
          <p class="li-value" :style="item.valueStyle || {}">
            {{ item.value }}
          </p>
        </li>
      </ul>
    </div>

    <div class="btn-group">
      <Button
        class="btn-group__item"
        type="cancel"
        @click="onclickCancel"
        :loading="loadingBtn.cancel"
      >
        <!-- 关闭订单 -->
        {{ $t("cart.closeOrder") }}
      </Button>
      <Button type="info" class="btn-group__item" @click="onclickConfirm">
        <!-- 确认支付 -->
        {{ $t("cart.toPay") }}
      </Button>
    </div>
  </van-popup>
</template>

<script>
import { mapActions } from 'vuex'
import Button from '@/components/ui/Button.vue'

export default {
  components: { Button },
  data () {
    return {
      loadingBtn: {
        cancel: false
      },
      orderInfoForView: {
        // productName: { label: this.$t('common.productLabel'), value: '' },
        price: {
          label: this.$t('common.priceLabel'),
          value: '',
          valueStyle: {
            color: '#FFAD14',
            fontSize: '0.36rem'
          }
        }
      },
      show: false
    }
  },
  props: {
    /**
     * 订单信息数据
     * {
     *  productName, price
     * }
     */
    orderInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions('payment', ['cancelLastOrder']),
    showPopup (isShow = true) {
      this.show = isShow
    },
    async onclickCancel () {
      this.loadingBtn.cancel = true
      await this.cancelLastOrder()
      this.loadingBtn.cancel = false
      this.showPopup(false)

      this.$emit('cancel')
    },
    onclickConfirm () {
      this.$emit('confirm')
    }
  },
  watch: {
    orderInfo: {
      deep: true,
      handler (obj) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const item = obj[key]
            this.orderInfoForView[key].value = item
          }
        }
      }
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
.unpaid-popup {
  width: 626px;
  min-height: 386px;
  background-color: var(--dp-bg-secondary);
  padding: var(--dp-sp-5xl);
  .unpaid-popup-main {
    min-height: 242px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    font-size: var(--dp-text-2xl);
    line-height: 1.32;
    color: var(--dp-text-primary);
    text-align: center;
  }
}
.ul {
  margin: 25px 0;
  .li {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid var(--dp-divider-primary);
    &:last-child {
      border-bottom: 0;
    }
    .li-label {
      line-height: 75px;
      margin-left: 32px;
    }
    .li-value {
      color: #646566;
      line-height: 75px;
      line-height: 1.4;
      padding: 16px;
    }
  }
}
.btn-group {
  display: flex;
  .btn-group__item {
    flex: 1;
    margin-left: var(--dp-sp-2xl);
    &:first {
      margin-left: 0;
    }
  }
}
</style>
