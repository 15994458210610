/*
 * @Date: 2023-03
 * @LastEditors: wfj
 * @LastEditTime: 2023-03
 * @Description: 多语言逻辑
 */
import { getQueryString } from '@/utils/index.js'
import { LanguageOperator, getVersionByParseUrl } from '@/utils/business.js'
import herobumpLangMap from '../langMap/herobump'

export function useSetLanguage () {
  // 游戏客户端语言标识转化
  function gameClientLanguageTransformWebsite (gameClientLang) {
    if (!gameClientLang) return ''
    const { gameCode } = getVersionByParseUrl()
    // 不同游戏转换策略
    const TRANSFORM_FN_MAP = {
      herobump: () => herobumpLangMap[gameClientLang]
    }
    return TRANSFORM_FN_MAP[gameCode]?.() || gameClientLang
  }

  // 校验当前访问地址上 lang 参数是否正确
  function validateUrlLang () {
    let urlLang = getQueryString('lang')
    let isRightLang = false

    urlLang = gameClientLanguageTransformWebsite(urlLang)

    if (urlLang) {
      const targetLangItem = LanguageOperator.findLangItem(urlLang)
      isRightLang = !!targetLangItem
    }
    return { urlLang, isRightLang }
  }

  return { validateUrlLang }
}
