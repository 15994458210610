<!--
 * @Date: 2021-12-15 11:01:41
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-03-29 10:58:57
 * @FilePath: /dc-pay-front/src/components/NumberItem.vue
-->
<template>
  <div class="main" :style="customStyle" @click="$emit('clickCB')">{{ num }}</div>
</template>

<script>
export default {
  components: {},
  computed: {
    customStyle () {
      const diameterToRem = `${this.diameter / 100}rem`
      return {
        width: diameterToRem,
        height: diameterToRem,
        lineHeight: diameterToRem,
        fontSize: `${this.fontSize / 100}rem`
      }
    }
  },
  data () {
    return {}
  },
  props: {
    // 直径(单位：px)
    diameter: {
      type: Number,
      default: 40
    },
    fontSize: {
      type: Number,
      default: 22
    },
    num: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {},
  created () {}
}
</script>
<style scoped lang="less">
@diameter: 40px;
.main {
  display: inline-block;
  color: var(--dp-text-secondary);
  font-size: 22px;
  width: @diameter;
  height: @diameter;
  line-height: @diameter;
  background-color: var(--dp-bg-icon);
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
}
</style>
