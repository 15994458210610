<!--
 * @Date: 2022-05-28 15:01:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-03-24 09:45:08
 * @FilePath: /dc-pay-front/src/views/Payment/index.vue
-->
<template>
  <div style="overflow: hidden; padding: 0 0.13rem">
    <WhiteCard style="padding: 0.24rem 0.32rem">
      <ul class="ul">
        <li class="li" v-for="(item, key) in orderInfoForView" :key="key">
          <p>{{ item.label }}:</p>
          <p class="li-value">
            {{ item.value }}
          </p>
        </li>
      </ul>
    </WhiteCard>

    <WhiteCard
      style="padding: 0.32rem 0.24rem 0 0.24rem"
      v-if="productList.length"
    >
      <ul>
        <ProductItem
          v-for="productItem in productList"
          :key="productItem.id"
          :productItemInfo="productItem"
          selectedStyle="none"
          operatorPosition="none"
          :showPropList="false"
          :showBorder="false"
          showNumText
        ></ProductItem>
      </ul>

      <!-- 优惠券抵扣信息栏 -->
      <UseCouponBar
        v-show="isStartCouponUsing"
        @convertSuccess="refreshCoupons"
        @change="changeCoupon"
      />

      <van-divider style="margin: 0 auto" />
      <div style="padding: .28rem 0;">
        <p class="total">
          <span>{{ countPriceLabel }}</span>
          <span>{{ totalPrice }}</span>
        </p>

        <p class="method-discount" v-if="countMethodDiscount">
          <span>{{ $t('payMethod.estimatedDiscount', countMethodDiscount) }}</span>
        </p>
      </div>
    </WhiteCard>

    <FormCard
      class="payment-form-card"
      :title="cardConfig.title"
      :name="cardConfig.name"
      :isOpen="cardConfig.isOpen"
      :disabled="cardConfig.disabled"
    >
      <!-- card 的头部右侧显示块 -->
      <template v-slot:headRight>
        <!-- 国家下拉框 -->
        <!-- @click.stop 阻止冒泡，触发头部点击事件 -->
        <div v-if="!loadingBtn.loadPayMethods" @click.stop="">
          <van-field
            readonly
            clickable
            :value="country_name"
            :placeholder="$t('form.selectContryTip')"
            right-icon="arrow-down"
            style="width: 2.2rem; height: 0.6rem; line-height: 0.6rem"
            @click="showCountryPicker = true"
          />
          <van-popup
            v-model="showCountryPicker"
            class="payment-contry-picker"
            round
            position="bottom"
          >
            <van-field
              class=""
              clearable
              input-align="center"
              v-model="filterCountryVal"
              :placeholder="`🔍  ${$t('common.searchHint')}`"
              @input="filterCountry"
            />
            <van-picker
              show-toolbar
              :columns="filteredCountryList"
              :default-index="countryPickerDefaultIndex"
              :confirm-button-text="$t('common.confirm')"
              :cancel-button-text="$t('common.cancel')"
              value-key="name"
              @cancel="showCountryPicker = false"
              @confirm="onCountryConfirm(arguments[0])"
            />
          </van-popup>
        </div>
      </template>

      <!-- 支付方式表单 -->
      <template>
        <div class="pay-method-container" v-if="pay_types && pay_types.length">
          <ul class="pay-method-container__ul">
            <PayMethodItem
              v-for="childItem in pay_types"
              :key="childItem.id"
              :payItemInfo="childItem"
              :selected="loginForm.pay_type_id === childItem.id"
              :size="
                bigSizePayTypeIds.includes(String(childItem.id))
                  ? 'big'
                  : 'default'
              "
              @clickPayItem="onclickPayItem"
              @loadErr="loadPayImgCallback('err', ...arguments)"
              @loadSuc="loadPayImgCallback('suc', ...arguments)"
              @clickDescIcon="showDiscountDescPopup(childItem)"
            ></PayMethodItem>
          </ul>
        </div>

        <van-loading
          style="text-align: center"
          type="spinner"
          v-else-if="loadingBtn.loadPayMethods"
        />

        <p v-else class="empty-tip">
          {{ $t("form.emptyPayMethodTip") }}
        </p>
      </template>
    </FormCard>

    <div class="bottom-operate fixed-bottom">
      <Button
        class="card-btn"
        type="info"
        ref="submitBtnRef"
        :disabled="!canPay"
        @click="clickSubmit"
      >
        {{ $t("common.submit") }}
      </Button>
    </div>

    <!-- adyen 支付弹窗 -->
    <van-popup
      v-model="showAdyenView"
      class="adyen-pay-popup"
      v-bind="{ ...commonPopupProps() }"
      @open="onopenAdyenPop"
      @closed="onClosedAdyenPop"
    >
      <!-- 挂载 ayden 支付页的节点 -->
      <div
        v-show="adyenStatus === AdyenPaymentStatus.Pending"
        :class="['payment', { 'point-none-event': lockAdyenForm }]"
        ref="dropin-container"
      ></div>
      <!-- 支付成功的弹窗 -->
      <div
        v-if="adyenStatus === AdyenPaymentStatus.Success"
        class="adyen-result-popup"
      >
        <img
          class="adyen-result_icon"
          src="@/assets/payment/result/success.gif"
        />
        <p class="adyen-result_text">{{ $t("form.paySuccessTip") }}</p>
      </div>
      <!-- 支付失败的弹窗 -->
      <div
        v-if="adyenStatus === AdyenPaymentStatus.Fail"
        class="adyen-result-popup"
      >
        <img
          class="adyen-result_icon"
          src="@/assets/payment/result/error.gif"
        />
        <p class="adyen-result_text">{{ adyenErrorMessage }}</p>
      </div>
    </van-popup>

    <!-- paypal 支付弹窗 -->
    <van-popup
      v-model="showPaypalView"
      v-bind="{ ...commonPopupProps() }"
      class="paypal-pay-popup"
      @closed="onclosedPaypalPop"
    >
      <div :id="renderEleId"></div>
    </van-popup>

    <!-- checkout 支付弹窗 -->
    <van-popup
      v-model="showCheckoutPopup"
      v-bind="{ ...commonPopupProps() }"
      class="checkout-pay-popup"
      @close="onCloseCheckoutPop"
    >
      <div class="checkout">
        <ul>
          <li
            v-for="item in checkoutNamespace.rememberCards"
            :key="item.fingerprint"
            :class="[
              'card-item',
              {
                'item-selected': isActCard(item.fingerprint),
              },
            ]"
            @click="onClickItem(item)"
          >
            <!-- 已保存的卡 -->
            <template v-if="item.fingerprint">
              <div class="flex-between flex-vcenter card-item-container">
                <div class="flex-vcenter">
                  <van-radio
                    checked-color="#0F67FF"
                    class="card-radio"
                    :name="item.fingerprint"
                    v-model="checkoutNamespace.selectSourceId"
                  ></van-radio>
                  <van-image
                    class="card-logo"
                    :src="currPayMethodItem.image_url"
                  />
                  <span class="card-desc">···· {{ item.last4 }}</span>
                </div>
                <van-field
                  v-if="isActCard(item.fingerprint)"
                  class="card-cvv"
                  placeholder="CVV"
                  type="digit"
                  v-model="checkoutNamespace.cvv"
                  :maxlength="4"
                />
                <Button
                  v-if="item.fingerprint === checkoutNamespace.selectSourceId"
                  type="danger"
                  size="mini"
                  style="width: auto"
                  @click="checkout_deleteCard"
                >{{ $t("fav.del") }}</Button>
              </div>
            </template>

            <!-- 手动输入卡号 -->
            <template v-else>
              <div
                :class="[
                  'card-item-container',
                  'write-card-item',
                  { 'inexistence-remember-card': !existRememberCard },
                ]"
              >
                <!-- 选项区域 -->
                <div class="flex-vcenter">
                  <van-radio
                    v-show="existRememberCard"
                    checked-color="#0F67FF"
                    class="card-radio write-card-radio"
                    name=""
                    v-model="checkoutNamespace.selectSourceId"
                  ></van-radio>
                  <van-image
                    class="card-logo"
                    :src="currPayMethodItem.image_url"
                  />
                  <p class="card-name">{{ currPayMethodItem.name }}</p>
                </div>

                <!-- 卡录入区域 -->
                <div
                  class="write-card"
                  v-show="!checkoutNamespace.selectSourceId"
                >
                  <!-- 在该节点下渲染frame -->
                  <div id="checkout-container" class="card-frame"></div>
                  <p class="checkout-error-message"></p>

                  <template v-if="enableRememberCard">
                    <van-checkbox
                      class="checkbox-remember"
                      checked-color="#4369E6"
                      shape="square"
                      v-model="checkoutNamespace.rememberCard"
                    >
                      {{ $t("checkoutPayType.saveForNextUse") }}
                    </van-checkbox>

                    <p class="set-pwd-tip" v-if="!userAuth.existPwd">
                      {{ $t("checkoutPayType.rememberCardSetPasswordTip") }}
                    </p>
                  </template>
                </div>
              </div>
            </template>
          </li>
        </ul>

        <Button
          type="primary"
          size="small"
          class="pay-btn"
          :disabled="checkoutBtnState.disabled"
          :loading="checkoutBtnState.loading"
          @click="submitCheckoutCard"
        >{{ $t("common.confirmPay") }}（{{ totalPrice }}）</Button>
      </div>
    </van-popup>

    <WechatQRcodePopup ref="qrcodePopupRef"></WechatQRcodePopup>

    <!-- 待支付订单弹窗 -->
    <UnpaidPopup
      ref="unpaidPopupRef"
      :orderInfo="unpaidOrderInfoForPopup"
      @cancel="onclickUnpaidCancel"
      @confirm="onclickUnpaidConfirm"
    ></UnpaidPopup>

    <PwdPopup ref="pwdPopupRef" :before-close="beforeClosePwdPop"></PwdPopup>
    <AirwallexPayPopup ref="airwallexRef" />
    <QRcodeCommonPopup ref="dukPayRef" resourceType="base64" />
    <DiscountDescPopup ref="descPopupRef" />
  </div>
</template>

<script>
import FormCard from '@/components/FormCard.vue'
import PayMethodItem from '@/views/Payment/components/PayMethodItem.vue'
import AirwallexPayPopup from '@/views/Payment/components/AirwallexPay/AirwallexPayPopup.vue'
import MixinWechatPay from '@/views/Payment/mixins/MixinWechatPay.vue'
import MixinMayiPay from '@/views/Payment/mixins/MixinMayiPay.vue'
// import MixinAdyenPay from '@/views/Payment/mixins/MixinAdyenPay.vue'
import MixinXsollaPay from '@/views/Payment/mixins/MixinXsollaPay.vue'
import MixinMyCardPay from '@/views/Payment/mixins/MixinMyCardPay.js'
import MixinPaypalPay, {
  renderEleId
} from '@/views/Payment/mixins/MixinPaypalPay.vue'
import MixinPaymentwallPay from '@/views/Payment/mixins/MixinPaymentwallPay.vue'
import MixinPassword from '@/views/Payment/mixins/MixinPassword.js'
import MixinIndex from '@/views/Payment/mixins/MixinIndex.js'
import MixinCheckoutPay from '@/views/Payment/mixins/MixinCheckoutPay.js'
import MixinDukPay from '@/views/Payment/mixins/MixinDukPay.js'

import WechatQRcodePopup from '@/views/Payment/popup/WechatQRcodePopup.vue'
import UnpaidPopup from '@/views/Payment/popup/UnpaidPopup.vue'
import QRcodeCommonPopup from '@/views/Payment/popup/QRcodeCommonPopup.vue'
import DiscountDescPopup from '@/views/Payment/popup/DiscountDescPopup.vue'
import WhiteCard from '@/components/WhiteCard.vue'
import ProductItem from '@/views/Home/ProductItem/ProductItem.vue'
import UseCouponBar from '@/views/Coupon/components/UseCouponBar.vue'
import PwdPopup from '@/views/Home/popup/PwdPopup.vue'
import {
  LanguageOperator,
  ifOpenCouponVersion,
  CacheUserForm,
  filterPayMethods,
  is_debug_order,
  getCurrGameConfigProperty
} from '@/utils/business.js'
import { useOnlyPaymentPageMode } from '@/hooks/useShoppingMode'

import { Divider, Radio, Checkbox, Toast } from 'vant'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { handleTrack, userAgent, encodePwd, triggerClick } from '@/utils'
import { urlConfig, payIdMap, THIRD_STATUS_MAP } from '@/utils/globalData.js'
import {
  filterPropByProducts,
  addCountdownForProducts,
  productTransformData,
  OperatorRecord,
  parseFloatNum
} from '@/utils/business/product.js'
import { PAYMENT_PAGE_MAP } from '@/store/modules/payment.js'
import {
  isUnusablePayId,
  sendJumpTrack,
  useActPayment,
  commonPopupProps,
  useListenerPopState
} from '.'
import UseCouponTool, {
  data as useCoupnData,
  selectedCouponItem
} from '@/views/Coupon/popup/use.js'
import { ToastSync } from '@/utils/vantEncapsulation.js'
import { useGetPayMethod } from '@/views/Payment/components/AirwallexPay/AirwallexPayPopup.js'
import { useApis } from './hooks/useApis'
import { useSteamPay } from '@/views/Payment/components/SteamPay/useSteamPay.js'
import Button from '@/components/ui/Button.vue'
import { useAdyenPayment, AdyenPaymentStatus } from './hooks/useAdyenPayment'

export default {
  provide () {
    return {
      getData: () => ({
        visibleStoreCards: this.visibleStoreCards,
        currPayMethodItem: this.currPayMethodItem,
        currCountryItem: this.currCountryItem,
        submitTrack: this.submitTrack,
        asyncGoHome: this.asyncGoHome,
        airwallexSaveCard: this.airwallexSaveCard
      })
    }
  },
  mixins: [
    MixinWechatPay,
    MixinMayiPay,
    // MixinAdyenPay,
    MixinXsollaPay,
    MixinPaypalPay,
    MixinPaymentwallPay,
    MixinPassword,
    MixinIndex,
    MixinCheckoutPay,
    MixinMyCardPay,
    MixinDukPay
  ],
  components: {
    Button,
    PwdPopup,
    UseCouponBar,
    ProductItem,
    WhiteCard,
    FormCard,
    PayMethodItem,
    WechatQRcodePopup,
    UnpaidPopup,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    [Divider.name]: Divider,
    AirwallexPayPopup,
    QRcodeCommonPopup,
    DiscountDescPopup
  },
  data () {
    return {
      lockAdyenForm: false, // 是否遮挡adyen表单
      showUnPaidPopupCause: '', // 哪种业务触发的显示未支付订单弹窗（confirmUser: 确定用户；createOrder: 创建订单）
      countryPickerDefaultIndex: 0, // 国家下拉框 默认选中项索引
      filterCountryVal: '', // 过滤小区的值
      filteredCountryList: [], // 国家下拉数据（过滤后）
      pay_type_infos: [], // 国家下拉数据（所有）
      showCountryPicker: false,
      country_name: '', // 选中的国家名称
      loadingBtn: {
        loadPayMethods: false // 正在加载支付列表
      },
      cardConfig: {
        id: 'payMethod',
        disabled: true,
        isOpen: true,
        title: this.$t('form.payMethodFormTitle')
      },
      pay_types: [] // 支付类型项
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'loginForm', 'app_key']),
    ...mapState('payment', [
      'server_name',
      'defaultCountryInfoForPayment',
      'paymentPageType',
      'lastOrderInfo',
      'productList'
    ]),
    ...mapGetters('user', ['userAuth']),
    ...mapGetters('payment', [
      'unpaidOrderInfoForPopup',
      'amountTotalInfo',
      'isUnpaidPage',
      'isCreatePaymentPage'
    ]),
    countMethodDiscount () {
      const { discount_amount = 0, discount_amount_currency = '' } = this.currPayMethodItem
      if (discount_amount <= 0) return null
      return { amount: discount_amount, currency: discount_amount_currency }
    },
    canPay () {
      return (
        this.productList.length > 0 &&
        this.pay_types &&
        this.pay_types.length > 0
      )
    },
    isStartCouponUsing () {
      // 活动订单不能用券
      return !this.isActPayment && !!this.showCouponBar && getCurrGameConfigProperty('couponDisable') !== true
    },
    countPriceLabel () {
      return selectedCouponItem.value
        ? this.$t('coupon.countByCoupon')
        : this.$t('cart.count')
    },
    currCountryItem () {
      return this.pay_type_infos.find(
        (item) => item.name === this.country_name
      )
    },
    /**
     * @return {object} {
     *  deduction:抵扣金额,
     *  amount_original: 原价,
     *  amount: 需付款金额,
     *  currency: 币种
     * }
     */
    priceInfo () {
      const { amount: amount_original, currency } = this.amountTotalInfo
      const deduction = useCoupnData.deduction.amount
      let amount = amount_original

      if (typeof deduction === 'number') {
        amount = parseFloatNum(amount_original - deduction, 2)
        // 负数重置为0
        amount = amount < 0 ? 0 : amount
      }
      return { currency, deduction, amount, amount_original }
    },
    totalPrice () {
      const { amount, currency } = this.priceInfo
      return amount + ' ' + currency
    },
    productTrackData () {
      return productTransformData(this.productList)
    },
    orderInfoForView () {
      const { server_name, loginForm, userInfo } = this
      return {
        serverName: { label: this.$t('form.serverLabel'), value: server_name },
        userId: { label: 'ID', value: loginForm.user_id },
        roleName: { label: this.$t('common.roleName'), value: userInfo.name }
      }
    },
    // 选中的支付方式信息
    currPayMethodItem () {
      const { pay_type_id } = this.loginForm
      const index = this.findPayMethodIndex(pay_type_id)
      return index >= 0 ? this.pay_types[index] : {}
    },
    currPayMethodIndex () {
      const { pay_type_id } = this.loginForm
      if (!pay_type_id) return -1
      return this.findPayMethodIndex(pay_type_id)
    },
    // 是否启用记卡 checkbox
    enableRememberCard () {
      return !this.isActPayment
    },
    // 是否回显已记卡列表
    visibleStoreCards () {
      return this.enableRememberCard && !!this.userAuth.existPwd
    }
  },
  methods: {
    ...mapMutations('user', ['UPDATE_LOGIN_FORM']),
    ...mapMutations('payment', ['UPDATE_PAYMENT_STATE']),

    ...mapActions('payment', ['getDefaultCountry', 'cancelLastOrder']),
    ...mapActions('product', ['validateProductAndTip']),
    ...mapActions('tool', ['showLoading', 'closeLoading', 'closeAllLoading']),
    ...mapActions('user', ['cacheCheckExistPwd', 'isCompletePwd']),

    showDiscountDescPopup (payItem) {
      this.$refs.descPopupRef.openPopup(payItem)
    },
    changeCoupon () {
      this.getPayMethods(this.currCountryItem.id)
    },
    // 过滤国家数据
    filterCountry (val) {
      if (val) {
        val = val.toLowerCase()
        this.filteredCountryList = this.pay_type_infos.filter(({ name }) =>
          name.toLowerCase().includes(val)
        )
      } else {
        this.filteredCountryList = this.pay_type_infos
      }
    },
    // 将当前选中的支付方式替换为指定支付（原支付会被删除）
    replacePayMethod (pay_child_id, newPayMethodConf) {
      const targetIndex = this.findPayMethodIndex(pay_child_id)

      return new Promise((resolve) => {
        if (targetIndex < 0) {
          this.pay_types.push(newPayMethodConf)
        } else {
          this.pay_types.splice(targetIndex, 1, newPayMethodConf)
        }
        this.$nextTick(() => resolve())
      })
    },
    findPayMethodIndex (pay_child_id) {
      return this.pay_types.findIndex(({ id }) => id === pay_child_id)
    },
    // 异步跳转首页
    asyncGoHome (intervalTime = 4000) {
      this.timer = setTimeout(this.afterPaySucStep, intervalTime)
      this.$once(
        'hook:beforeDestroy',
        () => this.timer && clearTimeout(this.timer)
      )
    },
    afterPaySucStep () {
      this.$store.dispatch('tool/goHome', { isReplace: true })
    },
    genCouponTrackData () {
      const {
        currency: sp_currency_amount,
        amount: sp_amount,
        amount_original: sp_amount_original
      } = this.priceInfo

      const coupon_id = useCoupnData.coupon_id
      return { sp_currency_amount, sp_amount, sp_amount_original, coupon_id }
    },
    async initUseCoupon (country) {
      if (!country) {
        country = this.currCountryItem.id
      }
      const product_cart = filterPropByProducts(this.productList)
      await UseCouponTool.init({ product_cart, country })
    },
    refreshCoupons () {
      const { id: country } = this.currCountryItem
      const product_cart = filterPropByProducts(this.productList)
      UseCouponTool.getCoupons({ product_cart, country })
    },
    onopenAdyenPop () {
      this.lockAdyenForm = false
    },
    // 重置支付方式表单
    resetData () {
      this.UPDATE_LOGIN_FORM({ pay_type_id: '' })
      this.UPDATE_PAYMENT_STATE({
        key: 'productList',
        value: []
      })
    },
    onclickUnpaidConfirm () {
      // 更新页面为待支付订单页
      this.UPDATE_PAYMENT_STATE({
        key: 'paymentPageType',
        value: PAYMENT_PAGE_MAP.uppaid
      })
      window.location.reload()
    },
    // 支付待支付订单逻辑（取消待支付订单 && 创建新订单）
    async confirmToPayLastOrder () {
      this.UPDATE_PAYMENT_STATE({
        key: 'paymentPageType',
        value: PAYMENT_PAGE_MAP.create
      })
      await this.cancelLastOrder({ showTip: false })
      this.submitPayment()
    },
    // 取消最后一笔订单
    async onclickUnpaidCancel () {},
    /**
     * @description: 创建订单埋点
     * @param {str} event_name 埋点事件名称
     * @param {str} pay_type_id 支付方式id（大的支付方式，比如 coda ，而非子渠道）
     * @param {num} web_pay_code 创建订单状态码（对应后端接口错误码）
     * @param {str} order_id 订单id 仅成功时存在
     * @param {moreData} 更多数据，合并到event_data
     */
    submitTrack (
      event_name,
      pay_type_id,
      web_pay_code = 0,
      order_id = '',
      moreData = {}
    ) {
      const { user_id, name: role_name } = this.userInfo
      const { point: cp_point } = this.amountTotalInfo
      const { pay_type_id: sub_pay_type_id, user_id: show_id } = this.loginForm
      const cp_product_list = this.productTrackData
      const status_code_info = THIRD_STATUS_MAP[web_pay_code] || ''

      const event_data = Object.assign(
        {
          cp_order_id: order_id, // 订单ID string
          status_code: web_pay_code, // 网页支付状态码 int
          status_code_info, // 状态码信息
          user_id,
          show_id,
          role_name,
          country_area_code: this.country_name,
          pay_type_id, // 支付方式 int
          sub_pay_type_id, // 子支付方式 int
          cp_product_list, // 购买项
          cp_point // 价格 float
        },
        this.genCouponTrackData(),
        moreData
      )

      handleTrack(event_name, event_data)
    },
    // 创建订单接口
    handleSubmit (channel_info = {}) {
      const data = this.genCreateOrderParams(channel_info)
      return this.useCreateOrderApi(data)
    },
    // 生成创建订单参数
    genCreateOrderParams (channel_info = {}) {
      const { role_id, user_id, retail_id } = this.userInfo
      const { pay_center_id } = this.currPayMethodItem
      const { server_id, pay_type_id, pwd } = this.loginForm
      const { homeUrl } = urlConfig.redirect
      const redirect_url =
        this.getRedirectUrlIfPaymentPageMode() ||
        urlConfig.redirect[pay_center_id] ||
        homeUrl
      const product_cart = this.productList.map((item) => {
        const { id: product_id, count, name: product_name } = item
        return { product_id, count, product_name }
      })
      const role_coupon_id = selectedCouponItem.value
        ? useCoupnData.coupon_id
        : ''
      const pay_password = pwd ? encodePwd(pwd) : ''
      // undefined 是为了不存在值时，不传递该key到后端
      const activity_order_id = this.activity_order_id || undefined
      // 具体属性功能见：https://xmdc.yuque.com/staff-nt39y2/ulet29/yllel5#PRshr
      const extend = { channelType: this.query.channelType, presentInfo: this.urlPresentInfo, appKey: this.app_key }

      channel_info = Object.assign(
        // channel_info 默认配置
        {
          redirect_url
        },
        channel_info
      )

      return {
        product_cart,
        role_id,
        retail_id,
        // 是否测试订单
        debug_order: is_debug_order(),
        phone_browser: this.isMobile,
        // 支付透传数据
        event_payload: JSON.stringify(this.genEtraData()),
        // 不同支付方式 额外参数
        channel_info: JSON.stringify(channel_info),
        // 后端扩展字段
        extend: JSON.stringify(extend),
        user_id,
        server_id,
        pay_type_id,
        pay_password,
        role_coupon_id,
        activity_order_id
      }
    },
    genCreateOrderTrackData () {
      return OperatorRecord.getAllRecord()
    },
    // 调用创建订单 api
    useCreateOrderApi (createOrderParams) {
      return Apis.createOrder(createOrderParams)
        .then((res) => {
          let { pay_id, attachment } = res.order_info
          let order_id = ''

          // attachment 为string类型的json
          attachment = attachment ? JSON.parse(attachment) : {}
          res.order_info.attachment = attachment
          order_id = attachment.order_id || ''

          const trackData = this.genCreateOrderTrackData()
          this.submitTrack('create_order_suc', pay_id, 0, order_id, trackData)

          this.showPayPage(res.order_info)
          return res.order_info
        })
        .catch((err) => {
          const { code, data, info: status_code_info } = err || {}
          const { product_info, order_info } = data || {}
          const { pay_id } = order_info || {}

          if (code === 216) {
            // 216 表示存在购买项限时、限购、下架
            this.tipLimitProduct(product_info || [])
          }
          if (code === 230) {
            // 230 表示密码错误
            this.$refs.pwdPopupRef.showPopup()
          }
          if (code === 405) {
            // 405 触发渠道风控
            this.riskControlHandle(createOrderParams, err)
          }
          if (code === 248) {
            // 优惠券已被使用
            this.initUseCoupon()
          }
          this.submitTrack('create_order_err', pay_id, code, '', {
            status_code_info
          })
          return Promise.reject(err)
        })
    },
    /**
     * 创建订单触发风控处理
     * @param {obj} reqParam 创建订单参数
     * @param {obj} resErr 接口错误响应
     * err中存在switch_pay时，自动唤起该支付渠道
     * err中无switch_pay 提示错误即可
     */
    async riskControlHandle (reqParam, resErr) {
      const { pay_type_id } = reqParam
      const { data } = resErr
      const { switch_pay, order_info } = data || {}
      const { pay_id } = order_info || {}

      this.closePreCreateOrderPaySDK(pay_id)
      if (switch_pay.id) {
        await this.replacePayMethod(pay_type_id, switch_pay)
        this.UPDATE_LOGIN_FORM({ pay_type_id: switch_pay.id })
        // 配置 forbidClick ，避免用户选其他支付方式
        await ToastSync({
          forbidClick: true,
          message: this.$t('error.riskControlSwitchPayTip')
        })
        this.$nextTick(() => {
          triggerClick(this.$refs.submitBtnRef)
        })
      } else {
        ToastSync(this.$t('error.riskControlUseOtherPayTip'))
        await this.getPayMethods(this.currCountryItem.id)
      }
    },
    // 关闭在创建订单前就唤起的sdk（checkout）
    closePreCreateOrderPaySDK (pay_center_id) {
      const PRE_PAY_MAP = {
        [payIdMap.checkout]: () => this.showCheckoutPaymentPopup(false)
      }
      const handleFn = PRE_PAY_MAP[pay_center_id]
      handleFn && handleFn()
    },
    async tipLimitProduct (product_info) {
      await this.validateProductAndTip({
        list: product_info,
        tipConfirmCallbak: this.disposeLimitProduct
      })
    },
    // 显示支付页
    showPayPage (order_info) {
      let { pay_id } = order_info

      pay_id = String(pay_id)

      // 不同支付策略
      const pageFnMap = {
        // 默认
        default: () => this.showPayPageByUrl(order_info),
        // coda
        [payIdMap.coda]: () => this.showPayPageByUrl(order_info),
        // adyen sit 环境
        ayden_test: () => this.showAdyenPayPage(order_info),
        // adyen
        [payIdMap.adyen]: () => this.showAdyenPayPage(order_info),
        // 支付宝
        [payIdMap.ali]: () => this.showPayPageByUrl(order_info),
        // 微信
        [payIdMap.wx]: () => this.toWeChatPay(order_info),
        // 蚂蚁金服
        [payIdMap.mayi]: () => this.showMayiPayPage(order_info),
        // xsolla
        [payIdMap.xsolla]: () => this.showXsollaPayPage(order_info),
        // paypal
        [payIdMap.paypal]: () => this.showPaypalPayPage(order_info),
        // paymentwall
        [payIdMap.paymentwall]: () => this.showPaymentwallPayPage(order_info),
        // payermax
        [payIdMap.payermax]: () => this.showPayPageByUrl(order_info),
        // steam
        [payIdMap.steam]: () => this.showSteamPayPage(order_info),
        // pingpong
        // [PingPong 对接文档](https://acquirer-api-docs-v4.pingpongx.com/pages/77eeac/)
        [payIdMap.pingpong]: () => this.showPayPageByUrl(order_info),
        // checkout
        [payIdMap.checkout]: () => {},
        // mycard
        [payIdMap.mycard]: () => this.showMyCardPayPage(order_info),
        [payIdMap.airwallex]: () =>
          this.$refs.airwallexRef?.showAirwallexPayPage(
            order_info,
            this.airwallexSaveCard
          ),
        [payIdMap.dukpay]: () => this.showDukPayPage(order_info)
      }
      const pageFn = pageFnMap[pay_id] || pageFnMap.default
      pageFn()
    },
    /**
     * @description: 直接根据 order_info.pay_url 跳链接
     * @param {str} order_info 订单信息
     */
    showPayPageByUrl (order_info) {
      const { pay_url, pay_id, attachment } = order_info
      const { order_id } = attachment
      if (pay_url) {
        // loading - 避免跳转慢多次点击提交，无需 close
        this.showLoading()
        // 延时避免埋点未发送
        setTimeout(() => {
          window.location.href = pay_url
        }, 500)

        this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)
      } else {
        this.submitTrack('use_third_pay_err', pay_id, 305, order_id)
      }
    },
    /**
     * @description: 返回透传数据，用于创建订单时透传 => 支付中心 => 大数据
     * @return {obj} 透传数据
     */
    genEtraData () {
      const {
        role_id,
        user_id,
        name: role_name,
        role_lv,
        vip_lv
      } = this.userInfo
      const { server_id, user_id: show_id } = this.loginForm
      const { logical_region_id } = CacheUserForm.getLocalUserForm() || {}
      // 埋点数据
      const trackData = this.vizier.lib.info.properties() || {}
      trackData.web_device_id = this.vizier.get_device_id()

      // 将对象所有key值转换为 int
      const toNumMap = { server_id, logical_region_id, role_lv, vip_lv }
      for (const key in toNumMap) {
        if (Object.hasOwnProperty.call(toNumMap, key)) {
          const item = toNumMap[key]
          toNumMap[key] = +item || item
        }
      }
      return {
        user_id,
        server_id: toNumMap.server_id,
        role_id,
        logical_region_id: toNumMap.logical_region_id,
        show_id,
        role_name,
        role_lv: toNumMap.role_lv,
        vip_lv: toNumMap.vip_lv,
        app_key: this.app_key,
        ...trackData
      }
    },
    // 点击提交按钮
    async clickSubmit () {
      // 校验表单
      const isComplete = await this.$store.dispatch('user/isCompleteForm', [
        'pay_type_id'
      ])
      if (!isComplete) return
      this.submitPayment()
    },
    /**
     * @description: 创建订单校验
     * @param {''默认 | 'clickConfirmPwd'点击确认密码 } touch_type 针对clickConfirmPwd做逻辑判断
     * @return {*}
     */
    async submitPayment (touch_type = '') {
      this.showLoading()
      const closeLoading = () => this.closeLoading()

      try {
        // 非活动支付才执行待支付订单流程
        if (!this.isActPayment) {
          // 查找待支付订单
          await this.$store.dispatch('payment/searchLastOrderState')
          /** 存在待支付订单 */
          if (!this.lastOrderInfo.is_done) {
            this.handleUnpaidOrder()
            return closeLoading()
          }
        }

        // 非点击确认密码，才启用此校验
        if (touch_type !== 'clickConfirmPwd') {
          // 校验是否需显示密码弹窗
          if (await this.ifShowPwdPopup()) return closeLoading()
        }
        await this.doCreateOrder()
        closeLoading()
      } catch (err) {
        closeLoading()
        return Promise.reject(err)
      }
    },
    /**
     * @description: 是否显示密码弹窗
     * @return {boolean} true显示
     */
    async ifShowPwdPopup () {
      const { usePsdLogin, existPwd } = this.userAuth
      switch (true) {
        // 用券 && 存在密码 && 密码未应用登入
        case selectedCouponItem.value && existPwd && !usePsdLogin:
          this.showPwdPopup()
          return true
        // 用券 && 不存在密码
        case selectedCouponItem.value && !existPwd:
          await this.sendEmailAndShowPwdPopup()
          return true
        // 未用券
        default:
          return false
      }
    },
    async doCreateOrder () {
      // 创建订单接口策略，扩展接口参数
      const submitOrderMap = {
        [payIdMap.wx]: () => this.jsapiInstance.wxPayPolitic(),
        [payIdMap.mayi]: this.handleSubmitByMayi,
        [payIdMap.checkout]: this.initCheckoutSDK,
        [payIdMap.mycard]: async () => {
          // 重定向地址设置为空，网页后端来处理重定向
          await this.handleSubmit({ redirect_url: '' })
        },
        [payIdMap.airwallex]: async () => this.handleSubmitByAirwallex(),
        [payIdMap.steam]: async () => {
          const params = this.genCreateOrderParams({
            redirect_url: this.getSteamRedirectURL(location.href)
          })
          return await this.handleSubmitBySteam(params, this.currCountryItem)
        },
        [payIdMap.pingpong]: async () => {
          const language =
            LanguageOperator.findLangItem(LanguageOperator.getLangOnLocal())
              ?.pingpongKey || 'en'
          return await this.handleSubmit({
            language
          })
        },
        [payIdMap.dukpay]: async () => this.handleSubmit(this.getDukPayChannelParams()),
        default: this.handleSubmit
      }
      const fn =
        submitOrderMap[this.currPayMethodItem.pay_center_id] ||
        submitOrderMap.default
      await fn()
    },
    async handleSubmitByAirwallex () {
      let customer_id
      let create_customer = false
      // qrcode为二维码，mobile_web为跳转页面
      const flow = this.isMobile ? 'mobile_web' : 'qrcode'
      const { pay_center_id: parent_pay_type_id, id: sub_pay_id } =
        this.currPayMethodItem
      const { id: country } = this.currCountryItem
      const { isCardPay } = this.validatePayMethod(sub_pay_id)
      if (this.enableRememberCard && isCardPay) {
        // 获取 customer_id 和 已存的卡
        const { source_json, channel_items_json } = await this.getConsentInfo({
          parent_pay_type_id,
          pay_type_id: sub_pay_id,
          country
        })

        if (source_json) {
          const { customer_id: id } = JSON.parse(source_json)
          id && (customer_id = id)
        }
        create_customer = !customer_id
        this.airwallexSaveCard = channel_items_json
      }

      // 不存在customer_id则创建，存在则不创建
      return await this.handleSubmit({
        /**
         * 开启创建用户
         * 是卡类 && 启用存卡 && 不存在cstId => 开启
         */
        create_customer,
        /**
         * customer_id作为存卡功能开关，固定开启
         * airwallex应用到游戏【英雄之地】，若后续开启全部网页支付功能，需考虑集成密码
         * 否则存卡存在卡暴露安全问题
         */
        customer_id,
        /**
         * 根据设备类型，告诉后端使用二维码还是跳转链接
         * 微信支付需要，后端会自行根据子支付ID透传给支付中心
         */
        flow
      })
    },
    async disposeLimitProduct ({ payableList }) {
      if (payableList?.length) {
        const product_cart = filterPropByProducts(payableList)
        await this.$store.dispatch('cart/submitCart', { product_cart })
        window.location.reload()
      } else {
        !this.isOnlyPaymentPageMode() &&
          this.$store.dispatch('tool/goHome', { isReplace: true })
      }
    },
    // 提交时，存在待支付订单
    handleUnpaidOrder () {
      // 不同页面类型支付策略
      const DISPOSE_FN_MAP = {
        // 待支付订单页 => 直接支付
        [PAYMENT_PAGE_MAP.uppaid]: this.confirmToPayLastOrder,
        // 创建订单页 => 展示待支付弹窗
        [PAYMENT_PAGE_MAP.create]: () => {
          this.$refs.unpaidPopupRef.showPopup(true)
          this.submitTrack('create_order_err_exist_unpaid')
        }
      }
      const fn = DISPOSE_FN_MAP[this.paymentPageType]

      typeof fn === 'function' && fn()
    },
    onclickPayItem (item) {
      const { id } = item
      if (this.loginForm.pay_type_id === id) return
      if (isUnusablePayId(id)) return

      this.UPDATE_LOGIN_FORM({ pay_type_id: id })
      item.selected = true

      // 埋点 - 选择支付方式
      handleTrack('selected_pay', {
        cp_product_list: this.productTrackData,
        country_area_code: this.country_name,
        sub_pay_type_id: id,
        ...this.genCouponTrackData()
      })
    },
    // 设置支付方式 国家下拉框 的默认选项
    setDefaultCountryForPicker (country) {
      let defaultItem = {}
      // 匹配默认项索引
      let defaultItemIndex = this.pay_type_infos.findIndex(
        (item) => item.id === country
      )
      defaultItemIndex = defaultItemIndex === -1 ? 0 : defaultItemIndex

      this.countryPickerDefaultIndex = defaultItemIndex
      defaultItem = this.pay_type_infos[defaultItemIndex]
      if (defaultItem) {
        this.country_name = defaultItem.name
      }
      return defaultItem
    },
    loadPayImgCallback (loadStatus, data) {
      const DISPOSE_MAP = {
        err: {
          key: 'imgs_load_err_info'
        },
        suc: {
          key: 'imgs_load_suc_info'
        }
      }
      const currKey = DISPOSE_MAP[loadStatus].key
      const { key: errKey } = DISPOSE_MAP.err
      const { key: sucKey } = DISPOSE_MAP.suc

      this[currKey].push(data)
      const totalLength = this[errKey].length + this[sucKey].length

      if (totalLength > 0 && totalLength === this.pay_types.length) {
        this.sendTrackForLoadPayImg()
      }
    },
    /**
       * imgs_load_suc_info 图片加载成功信息
        {
          image_url,
          id,
          load_time
        }
      single_min_time 单张最小加载时长
      single_max_time 单张最大加载时长
      img_total 图片张数
      suc_count 加载成功张数
      err_count 加载失败张数
      avg_time 平均加载时长（仅计算加载成功的）
    */
    sendTrackForLoadPayImg () {
      const { imgs_load_err_info, imgs_load_suc_info } = this
      const { length: err_count } = imgs_load_err_info
      const { length: suc_count } = imgs_load_suc_info
      const load_time_arr = imgs_load_suc_info.map((item) => item.load_time)
      // 求和
      const count_load_time = load_time_arr.reduce(
        (count, item) => count + item,
        0
      )
      const data = {
        ...this.genExistParams(),
        imgs_load_err_info,
        imgs_load_suc_info,
        single_min_time: Math.min(...load_time_arr),
        single_max_time: Math.max(...load_time_arr),
        img_total: err_count + suc_count,
        suc_count,
        err_count,
        avg_time: Math.floor(count_load_time / load_time_arr.length)
      }

      // 重置收集的数据，便面下次埋点重复发送
      this.imgs_load_err_info = []
      this.imgs_load_suc_info = []

      handleTrack('load_pay_img_time', data)
    },
    genExistParams () {
      const { user_id, name: role_name } = this.userInfo
      const { user_id: show_id, server_id } = this.loginForm
      const cp_product_list = this.productTrackData

      const data = {
        user_id,
        show_id,
        server_id,
        role_name,
        country_area_code: this.country_name,
        cp_product_list // 购买项
      }
      for (const key in data) {
        if (!data[key]) delete data[key]
      }
      return data
    },
    /**
     * @description: 获取支付方式列表。该数据包括：国家下拉框、支付方式列表
     * @param {str} country 国家标识
     * @return {promise}
     * 注意：数据依赖商品用券后金额，需放在总结计算后调用
     */
    async getPayMethods (country) {
      const { amount: total_amount_coupon } = this.priceInfo
      let { amount: total_amount_display, point: total_amount } =
        this.amountTotalInfo
      const { role_id } = this.userInfo

      total_amount = total_amount || 0

      return Apis.getPayMethods({
        total_amount_coupon,
        total_amount_display,
        total_amount,
        country,
        role_id
      })
        .then((res) => {
          const { pay_types } = res
          this.pay_types = filterPayMethods(pay_types) || []
        })
        .catch((err) => {
          const { code: status_code } = err

          // 埋点 - 获取支付方式（失败）
          handleTrack('get_pays_err', { status_code })
        })
    },
    async onCountryConfirm (item, config = { needUpdatePrice: true }) {
      if (!item || !item.id) return
      const { name, id } = item
      const { needUpdatePrice } = config
      this.country_name = name
      this.UPDATE_LOGIN_FORM({ pay_type_id: '' })
      this.showLoading()
      if (needUpdatePrice) {
        await this.updatePrice(id)
      }
      if (this.isStartCouponUsing) {
        await this.initUseCoupon(id)
      }
      // 依赖优惠券抵扣后金额，需在初始化优惠券后
      if (this.productList.length) {
        await this.getPayMethods(id)
      }
      this.closeLoading()
      this.showCountryPicker = false

      // 埋点锁，首次（页面初始化）调用不触发埋点
      if (this.openTrackForSelected_country) {
        this.$store.dispatch('tool/sendTrack', {
          eventName: 'selected_country',
          event_data: {
            cp_product_list: this.productTrackData,
            country_area_code: this.country_name,
            ...this.genCouponTrackData()
          }
        })
      }
      this.openTrackForSelected_country = true
    },
    // 该函数底下异步方法要await，否则会出现调用【获取支付方式接口】参数异常问题
    async updatePrice (country) {
      if (this.isCreatePaymentPage && this.isActPayment) {
        await this.updateActProducts(country)
      } else {
        const product_cart = filterPropByProducts(this.productList)
        await this.updatePayProducts(product_cart, country)
      }
    },
    initState () {
      const { iPad, mobile, isWechat, isiOS } = userAgent()
      this.query = this.$route.query
      // 是否是移动端
      this.isMobile = iPad || mobile
      // 是否移动微信客户端
      this.isWechat = this.isMobile && isWechat
      this.isiOS = isiOS
      // 【根据地区获取货币信息】接口的请求状态，options: before(调用前),suc(调用成功),err(调用失败)
      this.getPriceStatus = ''
      // 支付项以大尺寸图片展示
      this.bigSizePayTypeIds = ['10029', '10056', '10233048']
      // 记录支付logo图片加载信息
      this.imgs_load_err_info = []
      this.imgs_load_suc_info = []
      this.renderEleId = renderEleId
      this.useCoupnData = useCoupnData
      this.showCouponBar = ifOpenCouponVersion()
      this.airwallexSaveCard = [] // airwallex 已保存卡数据

      // wx jsapi pay 支付实例
      this.jsapiInstance = this.mixin_getWechatJsapiPayClass()
      UseCouponTool.clearCoupon()
    },
    async getPaymentProduct (country) {
      switch (true) {
        // 待支付订单结算
        case this.isUnpaidPage:
          await this.loadUnpaidProducts(country)
          break
        // 地址透传商品结算
        case this.isCreatePaymentPage && this.existUrlProducts:
          await this.loadUrlProducts(country)
          break
        // 活动订单结算
        case this.isCreatePaymentPage && this.isActPayment:
          await this.updateActProducts(country)
          break
        // 购物车下单结算
        default:
          await this.loadOrderProducts(country)
          break
      }
    },
    // 更新活动商品
    async updateActProducts (country) {
      const products = await this.getActProducts(country)
      this.UPDATE_PAYMENT_STATE({ key: 'productList', value: products })
    },
    // 获取国家列表
    async getCountrys () {
      const { role_id } = this.userInfo
      const { countries } = await Apis.getCountrys({ role_id })
      this.filteredCountryList = countries || []
      this.pay_type_infos = [...this.filteredCountryList]
    },
    async initPage () {
      const showLoading = (isShow = true) =>
        (this.loadingBtn.loadPayMethods = isShow)

      showLoading()
      if (!this.defaultCountryInfoForPayment.country_en_us) {
        await this.getDefaultCountry()
      }
      const { country_en_us: country } = this.defaultCountryInfoForPayment

      await this.getCountrys()
      const defaultCountryItem = this.setDefaultCountryForPicker(country)

      // 根据国家编码，获取商品
      await this.getPaymentProduct(defaultCountryItem?.id || country).catch(
        (err) => {
          showLoading(false)
          return Promise.reject(err)
        }
      )
      addCountdownForProducts(this.productList)
      if (defaultCountryItem) {
        await this.onCountryConfirm(defaultCountryItem, {
          needUpdatePrice: false
        })
      }

      showLoading(false)
      sendJumpTrack(this.productList)
      // 添加浏览器返回事件回调，关闭loading，避免部分浏览器缓存页面（safari），因loading无法操作
      this.addListener()
    }
  },
  created () {
    this.initState()
    this.initPage()
  },
  setup () {
    const { activity_order_id, isActPayment, getActProducts } = useActPayment()
    const { getRedirectUrlIfPaymentPageMode, isOnlyPaymentPageMode } =
      useOnlyPaymentPageMode()
    const { getConsentInfo } = useApis()
    const { validatePayMethod } = useGetPayMethod()
    const { handleSubmitBySteam, getSteamRedirectURL, showSteamPayPage } =
      useSteamPay()
    const { addListener, removeListener } = useListenerPopState()

    const {
      showAdyenPayPage,
      showAdyenView,
      onClosedAdyenPop,
      errorMessage: adyenErrorMessage,
      status: adyenStatus
    } = useAdyenPayment()

    return {
      activity_order_id,
      isActPayment,
      addListener,
      removeListener,
      handleSubmitBySteam,
      getSteamRedirectURL,
      showSteamPayPage,
      getActProducts,
      getRedirectUrlIfPaymentPageMode,
      isOnlyPaymentPageMode,
      commonPopupProps,
      getConsentInfo,
      validatePayMethod,
      showAdyenPayPage,
      showAdyenView,
      onClosedAdyenPop,
      AdyenPaymentStatus,
      adyenErrorMessage,
      adyenStatus
    }
  },
  destroyed () {
    this.resetData()
    this.removeListener()
  }
}
</script>
<style scoped lang="less">
@import url("~@/styles/pay-type-checkout.less");
.pay-method-container {
  margin-top: -12px;
}
.pay-method-container {
  padding: 0 20px;
  .pay-method-container__ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.card-btn {
  width: 6.5rem;
  margin: 22px auto;
  display: block;
}
.checkout-pay-popup,
.paypal-pay-popup,
.adyen-pay-popup {
  width: 650px;
  max-height: 70%;
  // 调整关闭按钮位置，避免挡住adyen支付页内容
  /deep/ .van-popup__close-icon--top-right {
    top: 0.1rem;
    right: 0.1rem;
  }
}
.paypal-pay-popup {
  padding: 70px 50px;
}
.total {
  font-size: var(--dp-text-2xl);
  font-weight: bold;
  // padding: 28px 0;
  text-align: right;
  span:last-child {
    color: var(--dp-warning-primary);
  }
}
.method-discount{
  text-align: right;
  font-size: 20px;
  span:last-child {
    color: var(--dp-warning-primary);
  }
}
.ul {
  .li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--dp-text-md);
    line-height: 2;
    color: var(--dp-text-tertiary);
    .li-value {
      color: var(--dp-text-primary);
    }
  }
}

.payment-form-card {
  margin-bottom: 132px;
  padding-bottom: 20px;
  /deep/ .main-card__head {
    padding: 26px 20px;
  }
}
.payment-contry-picker {
  background-color: var(--dp-bg-tertiary);
  /deep/ .van-field {
    border-radius: 0;
    border-bottom: 1px solid var(--dp-divider-primary);
  }
}

.adyen-result-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 68px;
  min-height: 600px;
  .adyen-result_icon {
    width: 96px;
    height: 96px;
  }
  .adyen-result_text {
    margin-top: 64px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 1.32;
  }
}
</style>
