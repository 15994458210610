<!--
 * @Date: 2023-08
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description: airwallex 卡管理
-->
<template>
  <van-popup
    v-model="showPopup"
    :closeable="true"
    :closeOnClickOverlay="false"
    :round="true"
    class="manage-popup"
    v-on="$listeners"
    @closed="onClosed"
  >
    <ul class="card-list" v-if="cards?.length">
      <li
        v-for="item in cards"
        :key="item.id"
        class="flex-between flex-vcenter card-item"
      >
        <div class="flex-vcenter">
          <!-- 卡品牌icon -->
          <van-image
            v-if="getPayCardIcon(getCardValue(item, 'brand'))"
            :src="getPayCardIcon(getCardValue(item, 'brand'))"
          ></van-image>
          <!-- 卡号信息 -->
          <span class="card-desc">{{ cardInfoText(item) }}</span>
        </div>

        <!-- 删除按钮 -->
        <van-button size="small" @click="onRemoveCard(item)">{{
          $t("fav.del")
        }}</van-button>
      </li>
    </ul>

    <!-- 列表为空展示 -->
    <van-empty
      v-else
      class="custom-image"
      :image="require('@/assets/empty/cart_empty.png')"
      description=""
    />
  </van-popup>
</template>

<script setup lang="jsx">
import { ref, defineExpose, inject, defineEmits } from 'vue'
import { useStoreTool } from '@/utils/business/store.js'
import { Empty as VanEmpty } from 'vant'
import { is_debug_order } from '@/utils/business.js'
import { useApis } from '../../hooks/useApis'
import { $t } from '@/i18n'

const cards = ref([]) // 卡列表
let isRemoved = false // 是否触发过删除卡
const getData = inject('getData', () => ({}))

const { showPopup, openPopup } = useHandlePopup()
const { showLoading, closeLoading, showDialog } = useStoreTool()
const { getConsentInfo } = useApis()

// 加载列表 && 显示弹窗
const mountPopup = async () => {
  await getCardInfo()
  isRemoved = false
  openPopup()
}

defineExpose({
  mountPopup
})
const emit = defineEmits(['closed'])

// 返回卡信息展示文案
const cardInfoText = (card) => {
  const { brand, last4 } = card.payment_method?.card || {}
  return ` ${brand} (${last4})`
}
const getCardValue = (card, key) => card.payment_method?.card?.[key]

// 点击删除回调
const onRemoveCard = (card) => {
  showDialog({
    title: '',
    desc: $t('checkoutPayType.deleteCardTip'),
    showCancelButton: true,
    tipConfirmCallbak: async () => {
      await removeCardApi(card)
      await getCardInfo()
      if (!cards.value.length) {
        openPopup(false)
      }
    }
  })
}

// 执行删除卡
async function removeCardApi (card) {
  const { id: card_id } = card
  const { currPayMethodItem, currCountryItem } = getData()
  const { id: country } = currCountryItem
  const { id: sub_pay_type_id, pay_center_id: pay_type_id } = currPayMethodItem
  showLoading()
  await Apis.deleteStoreCard({
    card_id,
    pay_type_id,
    sub_pay_type_id,
    is_test: is_debug_order(),
    country
  }).finally(() => closeLoading())
  isRemoved = true
}

// 控制弹窗
function useHandlePopup () {
  const showPopup = ref(false)
  const openPopup = (isOpen = true) => (showPopup.value = isOpen)
  return { showPopup, openPopup }
}

// 获取卡信息
async function getCardInfo () {
  const { currPayMethodItem, currCountryItem } = getData()
  const { pay_center_id: parent_pay_type_id, id: pay_type_id } = currPayMethodItem
  const { id: country } = currCountryItem

  showLoading()
  const data = await getConsentInfo({
    parent_pay_type_id,
    pay_type_id,
    country
  }).finally(() => closeLoading())
  const { channel_items_json } = data
  cards.value = channel_items_json
  return data
}

function onClosed () {
  emit('closed', {
    isRemoved,
    cards: cards.value
  })
}

// 返回支付方式图片路径
function getPayCardIcon (brandName = '') {
  const ICON_MAP = {
    mastercard: 'mastercard',
    visa: 'visa',
    jcb: 'jcb',
    'american express': 'express',
    unionpay: 'unionpay'
  }
  const iconName = ICON_MAP[brandName]
  return iconName ? require(`@/assets/payment/pay-method/${iconName}.svg`) : ''
}
</script>

<style lang="less" scoped>
@BorderColor: #ccc;
/deep/.custom-image .van-empty__image {
  width: 400px;
  height: 310px;
}
.manage-popup {
  width: 650px;
  min-height: 500px;
  max-height: 70%;
}
.card-list {
  margin: 70px 50px;
  .card-item {
    height: 100px;
    border-bottom: 1px solid @BorderColor;
    font-size: 24px;
    &:last-child {
      border-bottom: 0;
    }

    .card-desc {
      margin-left: 15px;
    }
  }
}
</style>
