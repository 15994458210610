<!--
 * @Date: 2022-03-03 15:52:33
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @FilePath: /dc-pay-front/src/views/Home/popup/SearchProductPopup.vue
-->
<template>
  <div>
    <Dialog
      v-model="showPop"
      title=""
      show-cancel-button
      :confirm-button-text="$t('common.confirm')"
      :cancel-button-text="$t('common.cancel')"
      :beforeClose="beforeClose"
      v-bind="$attrs"
      v-on="$listeners"
      @open="onOpen"
    >
      <div class="label">{{ $t("pwd.writePwdLabel") }}</div>

      <van-field
        clearable
        class="field-style"
        v-model="form.pwd"
        type="password"
        autocomplete
      >
      </van-field>
      <div :class="['forget-btn', { disabled: isCDTime }]">
        <span @click="onclickForgetBtn">{{ $t("pwd.forgetPwdBtn") }}</span>
        <span v-show="isCDTime">（{{ emailCDTime }}）</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/ui/Dialog'

const userStateMap = mapState('user', ['loginForm', 'emailCDTime'])

export default {
  data () {
    return {
      showPop: false
    }
  },
  components: {
    Dialog
  },
  props: {
    beforeClose: {
      type: Function,
      default: (_, done) => { done() }
    }
  },
  computed: {
    isCDTime () {
      return this.emailCDTime > 0
    },
    emailCDTime: userStateMap.emailCDTime,
    form: userStateMap.loginForm
  },
  methods: {
    ...mapMutations('user', ['UPDATE_STATE']),
    ...mapActions(['sendResetMailCommon']),
    ...mapActions('tool', ['visibleFullLoading']),

    async onclickForgetBtn () {
      if (this.isCDTime) return
      await this.sendResetMailCommon()
    },
    onOpen () {},
    showPopup (isShow = true) {
      this.showPop = isShow
      if (isShow) {
        // 关闭全屏loading，避免遮挡密码弹窗
        this.visibleFullLoading(false)
      }
    }
  },
  mounted () {},
  watch: {
    // 发送邮件冷却时间倒计时
    emailCDTime: {
      immediate: true,
      handler () {
        let time = this.emailCDTime
        if (!this.interval && time > 0) {
          this.interval = setInterval(() => {
            if (this.interval && time <= 0) {
              clearInterval(this.interval)
              this.interval = null
              return
            }
            time--
            this.UPDATE_STATE({ emailCDTime: time })
          }, 1000)
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .van-dialog__content {
  padding: 66px 50px 34px 50px;
}
.label {
  font-size: var(--dp-text-2xl);
  line-height: 1.32;
  font-weight: bold;
  text-align: start;
}
.field-style {
  margin: var(--dp-sp-xl) 0 var(--dp-sp-md) 0;
}
.forget-btn {
  text-align: end;
  color: var(--dp-text-tertiary);
  font-size: var(--dp-text-md);
  cursor: pointer;
  &.disabled {
    opacity: 0.4;
    cursor: disabled;
  }
}
</style>
