/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2022-10
 * @Description:
 */
export default {
  // 获取收藏夹列表
  getFavorites: '/favorite/GetGroup',
  // 获取详情
  getFavoriteDetail: '/favorite/GetGroupDetail',
  // 添加
  addFavorite: '/favorite/Add',
  // 更新
  updateFavorite: '/favorite/Update',
  // 删除
  delFavorite: '/favorite/Delete'
}
