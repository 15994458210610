/*
 * @Author: wfj
 * @Date: 2021-04-07 14:18:29
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-07 14:44:34
 * @FilePath: /dc-pay-front/src/utils/request.js
 */
import axios from 'axios'
import { Toast } from 'vant'
import { Dialog } from '@/components/ui/Dialog'
import {
  CacheUserForm,
  LanguageOperator,
  useEncryptApiParam
} from '@/utils/business.js'
import { getVueVm, handleTrack } from '@/utils'
import ErrorHandle from '@/utils/apiError.js'
import { getXHRBaseUrl, getVersionCompanyId } from '@/utils/versionHelper'

const { isEncryptionApi, genEncryptionInfo } = useEncryptApiParam()

axios.defaults.baseURL = getXHRBaseUrl()
axios.defaults.timeout = 15000

axios.interceptors.request.use(
  (config) => {
    const _this = getVueVm()
    const { url, data } = config
    const { state } = _this?.$store
    const trace_id = state?.tool?.trace_id
    const token = state?.user?.userInfo?.token
    const { logical_region_id } = CacheUserForm.getLocalUserForm() || {}
    const options = {
      'operate-entity-id': getVersionCompanyId(), // 公司主体
      lang: LanguageOperator.getLangOnLocal()
    }
    let encryptionHeader = {}

    if (logical_region_id) {
      options['logical-region-id'] = logical_region_id
    }
    if (token) {
      options.Authorization = `Basic ${token}`
    }
    // 使用该id串联网页后端、支付中心链路，便于异常排查
    options['trace-id'] = trace_id

    if (data && isEncryptionApi(url)) {
      encryptionHeader = genEncryptionInfo(data)
    }
    Object.assign(config.headers, options, encryptionHeader)

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 不需要气泡提示
const NO_TIP_ERR_CODE = [216, 252, 401, 405, 247]
// 需要弹窗提示
const DIALOG_TIP_ERR_CODE = [247]
axios.interceptors.response.use(
  (res) => {
    const _this = getVueVm()
    const { config } = res
    const { code, info } = res.data
    const { url } = config

    if (code !== 0) {
      // 是否提示
      const needTip = !NO_TIP_ERR_CODE.includes(code)
      const dialogTip = DIALOG_TIP_ERR_CODE.includes(code)

      if (needTip) {
        Toast(info || _this.$t('common.serverErrorTip'))
      }
      if (dialogTip) {
        Dialog({ message: info, confirmButtonText: _this.$t('cart.sure') })
      }
      handleTrack('use_api_err', {
        params: config.data,
        url,
        code
      })
      ErrorHandle.handleError(code)
      return Promise.reject(res.data)
    }
    return Promise.resolve(res.data.data)
  },
  (err) => {
    const _this = getVueVm()
    Toast(_this.$t('common.connectionErrorTip'))
    return Promise.reject(err)
  }
)

export default axios
