/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description:
 */
import { getVuexInstance } from '@/utils'

const JUMP_FROM = {
  obtainCoupon: 'obtainCoupon'
}

function useCouponApi () {
  async function getCoupons () {
    const _store = getVuexInstance()
    if (!_store) return
    const { country_en_us: country } = _store.state.payment.defaultCountryInfo
    return Apis.getCoupons({ country })
  }

  return { getCoupons }
}

export { useCouponApi, JUMP_FROM }
