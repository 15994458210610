<!--
 * @Date: 2021-12-10 16:30:40
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-05-14 15:23:52
 * @FilePath: /dc-pay-front/src/views/Home/PayForm.vue
 * @describe: 支付主逻辑（2023/8/15调整为jsx版本）
-->
<script lang="jsx">
import StepBar from '@/views/Home/components/StepBar'
import SideBar from '@/views/Home/components/SideBar.vue'
import RecommendGroup from '@/views/Home/components/RecommendGroup.vue'
import CartEntrance from '@/components/CartEntrance.vue'
import FormCard from '@/components/FormCard'
import UnpaidPopup from '@/views/Payment/popup/UnpaidPopup.vue'
import SearchProductPopup from '@/views/Home/popup/SearchProductPopup.vue'
import PwdPopup from '@/views/Home/popup/PwdPopup.vue'
import ProductItem from '@/views/Home/ProductItem/ProductItem.vue'
import NumberItem from '@/components/NumberItem.vue'
import NoticeBoard from '@/views/Home/components/NoticeBoard.vue'
import GuidePopup from '@/views/Activity/popup/GuidePopup.vue'
import EntryIcons from '@/views/Home/components/EntryIcons.vue'
import { setItem, ICON_ID_MAP } from '@/views/Home/components/EntryIcons.js'
import {
  LanguageOperator,
  CacheUserForm,
  getVersionDetailInfo,
  getVersionByParseUrl,
  LocalOperator,
  getCurrGameConfigProperty
} from '@/utils/business.js'
import { useOnlyPaymentPageMode } from '@/hooks/useShoppingMode'
import { isLogin } from '@/utils/business/user.js'
import {
  splitDisabledArr,
  addCountdownForProducts,
  validateDisabledProduct,
  findMenuItem,
  PRODUCT_CONFIG,
  isTimeoutProduct,
  useHandleMenu
} from '@/utils/business/product.js'
// import { setDisabledByMutex } from '@/utils/business/cart.js'
import {
  userAgent,
  getQueryString,
  handleTrack,
  clearWindowHrefParams,
  validateHideScroll,
  Base64,
  triggerClick,
  eventBus
} from '@/utils'
import { payIdMap } from '@/utils/globalData.js'
import { ImagePreview, Badge, Toast } from 'vant'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { PAYMENT_PAGE_MAP } from '@/store/modules/payment.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PayFormTool, {
  useHandleUrlBusiness,
  useFreePwdLogin
} from './PayForm.js'
import MixinLogin from '@/views/Home/mixins/MixinLogin.js'
import { useTurntableAct } from '@/views/Activity/TurntableAct.js'
import { useHandleMyCardCallback } from '@/views/Payment'
import { doActRedirect } from '@/views/Activity'
import {
  useGetRoles,
  useSaveLocalThirdInfo
} from '@/views/User/ThirdPartyLogin'
import FooterOperatorBar, {
  FOOTER_BAR
} from '@/components/FooterOperatorBar.vue'
import ButtonVue from '@/components/ui/Button.vue'
import IconCard from '@/components/ui/icon/IconCard.vue'
import IconShopper from '@/components/ui/icon/IconShopper.vue'

const userStateMap = mapState('user', [
  'userInfo',
  'loginForm',
  'thirdLoginInfo'
])
const paymentStateMap = mapState('payment', [
  'server_name',
  'defaultCountryInfo',
  'lastOrderInfo'
])
const { defaultCountryInfo, lastOrderInfo } = paymentStateMap
const { clickTurntableEntry } = useTurntableAct()
const { transformTypeProduct } = useHandleMenu()

export default {
  provide () {
    return {
      dynamicData: () => {
        const { isSelectedWechatPay } = this
        return { isSelectedWechatPay }
      }
    }
  },
  mixins: [MixinLogin],
  components: {
    [Badge.name]: Badge,
    EntryIcons,
    CartEntrance,
    SideBar,
    StepBar,
    FormCard,
    UnpaidPopup,
    SearchProductPopup,
    PwdPopup,
    ProductItem,
    NumberItem,
    NoticeBoard,
    GuidePopup,
    RecommendGroup,
    FooterOperatorBar
  },
  data () {
    return {
      // 标记-登入后需前往活动
      changeLoginBtnText: false,
      // 是否高亮收藏入口按钮
      highlightFav: false,
      // 表单卡片组件配置
      formCardConfig: [
        {
          id: 'user',
          disabled: false,
          isOpen: true,
          icon: IconCard,
          title: this.$t('form.roleFormTitle')
        },
        {
          id: 'product',
          disabled: true,
          isOpen: false,
          icon: IconShopper,
          title: this.$t('form.productFormTitle')
        }
      ],
      filterServerVal: '', // 过滤小区的值
      loadingBtn: {
        confirmUser: false,
        loadProducts: false // 正在加载购买项列表
      },
      value: '',
      countryPickerDefaultIndex: 0, // 国家下拉框 默认选中项索引
      showUserNameInput: false, // 是否显示角色名称 input
      showPicker: false,
      showCountryPicker: false,
      isChangePayContry: false, // 是否切换过支付方式的国家
      recommendGroup: [], // 推荐组合
      product_infos: [], // 购买项
      backupsFromServerList: [], // 所有区服备份
      serverList: [] // 区服
    }
  },
  computed: {
    ...mapGetters('payment', ['unpaidOrderInfoForPopup']),
    ...mapGetters('product', ['mergeList']),
    ...mapGetters('sys', ['withoutShoppingCartMode']),
    ...mapGetters('user', [
      'userAuth',
      'validateCurrentLoginType',
      'isThirdLogin'
    ]),
    ...mapState('product', ['types', 'labels', 'activeKey']),

    visibleIDInput () {
      if (this.showUserNameInput || !this.validateCurrentLoginType.isSteam) {
        return true
      }
      return false
    },
    visibleCartEntry () {
      return (
        !this.withoutShoppingCartMode &&
        this.userInfo?.token &&
        !!this.defaultCountryInfo?.country_en_us
      )
    },
    showRecommendGroup () {
      const { label_id, list } = this.activeItem
      const { recommend } = PRODUCT_CONFIG.staticMenuMap
      return recommend.label_id === label_id && !!list?.length
    },
    // 是否固定购物车入口
    fixedCartEntry () {
      return !this.menuThenOne
    },
    menuThenOne () {
      return this.extendMergeList.length > 1
    },
    // 菜单数据：增加字段list（购买项列表） && 过滤list为空的菜单
    extendMergeList () {
      const { mergeList, product_infos, recommendGroup } = this
      const { all, recommend, hot } = PRODUCT_CONFIG.staticMenuMap

      return mergeList
        .map((item) => {
          const { label_id, isType, isLabel, isStatic } = item
          let list = []
          if (label_id) {
            if (isType) {
              list = product_infos.filter((item) => item.type_id === label_id)
            } else if (isLabel) {
              if (label_id === recommend.label_id) {
                // 推荐礼包
                list = recommendGroup
              } else {
                // 普通商品
                list = product_infos.filter(
                  (item) => item.label_id.indexOf(label_id) !== -1
                )
                // 热门商品排序
                if (label_id === hot.label_id) {
                  list = list.sort(
                    ({ hot_sort }, { hot_sort: hot_sort_next }) =>
                      hot_sort - hot_sort_next
                  )
                }
              }
            } else if (isStatic) {
              // 静态菜单
              if (all.label_id === label_id) {
                list = product_infos
              }
              // if (recommend.label_id === label_id) {
              //   list = recommendGroup
              // }
            }
          }
          return { ...item, list }
        })
        .filter((item) => !!item.list?.length)
    },
    activeItem () {
      return this.extendMergeList?.[this.activeKey] || {}
    },
    // 用于展示购买项列表 { title: '', list, sub_type_id: '' }
    viewProductList () {
      const { isType, sub_type_list, list } = this.activeItem
      // 最终返回结果
      let result = []
      if (isType) {
        result = transformTypeProduct(list, sub_type_list)
      } else {
        // 标签、静态菜单无需分类，直接兼容格式即可
        result.push({ title: '', list, sub_type_id: '' })
      }
      return result
    },
    lastOrderInfo,
    defaultCountryInfo,
    // 是否存在购买项
    existProducts () {
      return this.viewProductList.some((item) => !!item?.list?.length)
    },
    server_name: {
      get: paymentStateMap.server_name,
      set (data) {
        this.UPDATE_PAYMENT_STATE({ key: 'server_name', value: data })
      }
    },
    form: userStateMap.loginForm,
    userInfo: {
      get: userStateMap.userInfo,
      set (data) {
        this.UPDATE_STATE({ userInfo: data })
      }
    },
    thirdLoginInfo: userStateMap.thirdLoginInfo,
    // 第一个展开表单的索引
    openCardIndex () {
      return this.formCardConfig.findIndex((item) => item.isOpen)
    },

    // orderInfoForPopup () {
    //   const { name } = this.productItemInfoToView
    //   return {
    //     serverName: this.server_name,
    //     userId: this.form.user_id,
    //     roleName: this.userInfo.name,
    //     productName: name,
    //     price: this.viewPrice
    //   }
    // },
    // 已选中微信支付
    isSelectedWechatPay () {
      return this.currPayMethodItem?.pay_center_id === payIdMap.wx
    }
  },
  methods: {
    ...mapActions('cart', ['submitCartByProducts', 'getCartCount']),
    ...mapActions('payment', ['getDefaultCountry', 'goPaymentPage']),
    ...mapActions(['sendResetMailCommon']),
    ...mapActions('product', ['getTypes', 'getLabels']),
    ...mapActions('tool', [
      'showDialog',
      'showLoading',
      'closeLoading',
      'showTipPwdDialog',
      'visibleFullLoading'
    ]),
    ...mapActions('user', [
      'checkExistPwd',
      'isCompleteForm',
      'getUserInfo',
      'genUserInstance',
      'cacheCheckExistPwd',
      'tagTipPwdDialog',
      'isCompletePwd'
    ]),
    ...mapMutations('user', [
      'UPDATE_STATE',
      'UPDATE_LOGIN_FORM',
      'UPDATE_OBJECT'
    ]),
    ...mapMutations('payment', ['UPDATE_PAYMENT_STATE']),
    ...mapMutations('product', ['UPDATE_PRODUCT_STATE']),

    async clickBuy () {
      if (!this.withoutShoppingCartMode) return

      const selectedProduct = this.getSelectedProduct()
      if (!selectedProduct) {
        Toast(this.$t('form.selectProductTip'))
        return
      }

      // 购买数量为 1
      selectedProduct.count = 1
      this.showLoading()
      await this.submitCartByProducts({
        products: [selectedProduct]
      }).finally(this.closeLoading)
      this.goPaymentPage()
    },
    clickProductItem (product) {
      // 无购物车模式才支持选中
      if (this.withoutShoppingCartMode) {
        const selectedProduct = this.getSelectedProduct()
        if (selectedProduct) selectedProduct.selected = false
        product.selected = true
      }
    },
    // 返回已勾选购买项
    getSelectedProduct () {
      const { all } = PRODUCT_CONFIG.staticMenuMap
      // 找到菜单【全部】
      const { item } = findMenuItem(all.label_id, this.extendMergeList)
      if (item?.list) {
        return item.list.find((o) => !!o.selected)
      }
    },
    async clickJoinAct () {
      if (!isLogin()) {
        this.changeLoginBtnText = true
        const isComplete = await this.isCompleteForm(['server_id', 'user_id'])
        if (isComplete) {
          this.automaticLogin()
        }
      }
    },
    async clickIcon (iconItem) {
      const { id, activity_url, login_type } = iconItem
      switch (id) {
        case ICON_ID_MAP.activity:
          doActRedirect(activity_url, login_type)
          break
        case ICON_ID_MAP.turntable:
          clickTurntableEntry()
          break
        case ICON_ID_MAP.coupon:
          this.$router.push('/coupon')
          break
        case ICON_ID_MAP.favorite:
          this.$router.push('/favorite')
          break
        case ICON_ID_MAP.search:
          this.showSearchProductPopup()
          break
        case ICON_ID_MAP.resetPwd:
          this.showDialog({
            title: '',
            desc: this.$t('pwd.confirmSendEmailTip'),
            showCancelButton: true,
            tipConfirmCallbak: () => {
              this.sendResetMailCommon({ sucTip: this.$t('pwd.sentTip') })
            }
          })
          break
      }
    },
    async searchChangeAmount (data) {
      this.changeAmount(data)
      this.$nextTick(() => {
        // 解决搜索列表禁用状态不更新问题
        this.$refs.searchProductPopupRef.updateList()
      })
    },
    changeAmount ({ product, targetCount, count }) {
      // 2024-05-13 互斥商品调整为有数量上限，仅禁用加购按钮不禁用整个购买项

      // // product中的count已是新count
      // const { mutex_id, id: product_id } = product
      // // 点击添加按钮时 existMutex 为true，点击减少按钮并且数量至0时为false
      // const existMutex = count < targetCount
      // const mutex_products = [{ mutex_id, product_id, existMutex }]

      // if (existMutex) {
      //   setDisabledByMutex(mutex_products, this.product_infos)
      // } else {
      //   // 当点击减按钮时，需减至0时才移除购买项禁用效果
      //   if (targetCount === 0) {
      //     setDisabledByMutex(mutex_products, this.product_infos)
      //   }
      // }
    },
    onSideBarChange (index) {
      this.UPDATE_PRODUCT_STATE({ activeKey: index })
    },
    showSearchProductPopup () {
      this.$refs.searchProductPopupRef.showPopup()
    },
    async selectProductFromPopup (item) {
      this.selectedProductMethod = 'search'
      await this.setSideToAll()

      // 将选中项移动到可视化区域
      const productItemDom = this.$refs[`productItemRef${item.id}`]?.$el
      if (productItemDom) {
        await this.domToTopViewport(productItemDom)
      }
    },
    // 设置菜单为全部
    setSideToAll () {
      return new Promise((resolve) => {
        if (this.menuThenOne) {
          const { all } = PRODUCT_CONFIG.staticMenuMap
          // 找到菜单项【全部】的索引
          const { index } = findMenuItem(all.label_id, this.extendMergeList)
          if (index !== -1) {
            this.$refs.sideBarRef.setActivitySide(index)
          }
          this.$nextTick(() => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    onscrollFromProducts () {
      // 鼠标滚轮初次滚动触发一次，之后不再触发（确认购买项或回到上一步重置）
      handleTrack('scroll_products')
    },
    oninputUserId () {
      this.UPDATE_LOGIN_FORM({ pwd: '' })
      this.userInfo = {}
      this.needSendActTag = false
    },
    showGuideImg () {
      const { gameCode } = getVersionByParseUrl()
      const imgName = `guide_${gameCode}`

      ImagePreview({
        images: [require(`@/assets/pay-form/${imgName}.jpg`)],
        closeable: true
      })
    },
    // 过滤服务器数据
    filterServer (val) {
      if (val) {
        val = val.toLowerCase()
        this.serverList = this.backupsFromServerList.filter((item) => {
          const lowerName = item.name.toLowerCase()
          return lowerName.includes(val)
        })
      } else {
        this.serverList = this.backupsFromServerList
      }
    },
    // 取消最后一笔订单
    async onclickUnpaidCancel () {
      this.toProductStep()
    },
    // 点击待支付弹窗的确认支付按钮
    confirmToPayLastOrder () {
      this.goPaymentPage({ pageType: PAYMENT_PAGE_MAP.uppaid })
    },
    clickHead (item) {
      if (item.disabled) return
      // !item.isOpen 表示点击前 card 状态为关闭
      if (item.isOpen) {
        /** 关闭前逻辑 */
        // 不允许关闭
        return
      } else {
        /** 打开前逻辑 */
        // 展开前，先关闭其他展开项
        this.closeAllCard()

        // 展开前，清除卡片状态
        if (item.id === 'user') {
          this.resetCardByCardId('product')
        } else if (item.id === 'product') {
        }
        // 将当前step移动到视口顶部
        this.domToTopViewport(`.${this.soleClassKeyForCard}user`)
      }

      item.isOpen = !item.isOpen
    },
    /**
     * @description: 根据是否存在待支付，做不同处理
     * @param {boolean} isDone: true表示不存在待支付
     */
    disposeByOrderState (isDone) {
      if (isDone) {
        this.toProductStep()
      } else {
        this.$refs.unpaidPopupRef.showPopup(true)
      }
    },
    getBoughts () {
      const { server_id, user_id: edt_show_id } = this.form
      const { user_id, role_id } = this.userInfo
      const { country_en_us: country } = this.defaultCountryInfo

      return Apis.getBounghts({ role_id, user_id, server_id, country })
        .then(async (res) => {
          // 添加额外属性
          res.product_infos = res.product_infos
            .filter((item) => !isTimeoutProduct(item))
            .map((item) => {
              // 控制是否选中
              item.selected = false
              // 是否有互斥商品已加入购物车
              // item.existMutex = false
              item.count = 0
              item.disabled = validateDisabledProduct(item)
              return item
            })
          // 更新数量count
          await this.$store
            .dispatch('cart/updateCountByCart', res.product_infos)
            .catch(() => {})

          const { product_infos = [], recommend_products = [] } = res
          // 剔除推荐礼包商品的限时限购相关字段
          recommend_products.forEach((group) => {
            group.products.forEach((product) => {
              delete product.expired_time
              delete product.limit
            })
          })
          PayFormTool.sendRecommendGroupTrack(recommend_products)
          this.product_infos = product_infos
          this.recommendGroup = recommend_products
          this.domToTopViewport(`.${this.soleClassKeyForCard}user`)
          // this.updateProductsState(mutex_product)
          // 更新购物车互斥组商品数量
          this.getCartCount()
          // 记录互斥信息（需在更新购物车数量之后）
          this.$store.dispatch('product/setMutexGroupInfo', res.product_infos)
        })
        .catch((err) => {
          const { code: status_code } = err

          // 埋点 - 获取购买项（失败）
          handleTrack('get_products_err', {
            edt_show_id,
            status_code
          })
        })
    },
    sortBoughts (list) {
      // disabled 在子组件中设置，所以此处需nextTick
      this.$nextTick(() => {
        const { useabledList, disabledList } = splitDisabledArr(list)
        this.product_infos = useabledList.concat(disabledList)
      })
    },
    // 选择区服回调
    onConfirm (item) {
      const { id: server_id, name, logical_region_id, show_id } = item
      if (!item || !server_id) return
      this.showPicker = false
      handleTrack('form_write_server', {
        server_id,
        channel: this.thirdLoginInfo.third_type
      })
      if (this.form.server_id === server_id) return
      this.UPDATE_LOGIN_FORM({ server_id, user_id: '', pwd: '' })
      this.server_name = name
      this.userInfo = {}
      CacheUserForm.setLocalUserForm({
        user_id: '',
        pwd: '',
        server_id,
        logical_region_id
      })
      // 社交登入时存在 show_id
      if (show_id) {
        this.form.user_id = show_id
        CacheUserForm.setLocalUserForm({
          user_id: show_id,
          pwd: '',
          server_id,
          logical_region_id
        })
      }
    },
    // 限时商品，倒计时结束后禁用该购买项
    countdownDispose () {
      addCountdownForProducts(this.product_infos)
    },
    async getServerApi () {
      let { region: logical_region_id } = getVersionDetailInfo()
      logical_region_id = logical_region_id ? logical_region_id.join('_') : ''

      const res = await Apis.getServers({ logical_region_id })
      this.setServerList(res.server_infos)
    },
    // 处理初始化页面所需发送埋点
    sendTrackByInitPage (href) {
      handleTrack('web_pay_open', { href })

      // 埋点白名单 - 仅允许部分埋点可通过 url param 来发送
      const trackWhiteList = ['open_by_game_site']
      const { trackName, activityId, login } = this.query

      if (trackName && trackWhiteList.includes(trackName)) {
        handleTrack(trackName)
      }
      if (!login && activityId) {
        // 无 personal_id
        handleTrack('open_by_h5', {
          activity_id: activityId,
          query: this.query
        })
      }
    },
    loginByLocalCache () {
      const { server_id } = CacheUserForm.getLocalUserForm() || {}
      this.setServerFormById(server_id)

      // 刷新页面时，当用户已登入过，跳过登入步骤
      if (isLogin()) {
        this.disposeByOrderState(true)
      }
    },
    // 自动登入
    automaticLogin () {
      triggerClick(this.$refs.confirmUserRef)
    },
    /**
     * @description: 根据base64加密的用户信息登录
     * @description: 相关文档：https://xmdc.yuque.com/staff-nt39y2/gnw7rh/yllel5
     * @param {string} base64Str
     */
    async loginByBase64 (base64Str) {
      const { business } = this.query
      let [user_id, server_id, logical_region_id] = ['', '', '']

      try {
        const loginParams = Base64.decode(base64Str)

        user_id = String(loginParams.show_id)
        server_id = String(loginParams.server_id)
        logical_region_id = String(loginParams.logical_region_id || '')
      } catch (error) {}
      Object.assign(this.form, { user_id, server_id })
      await this.setServerFormById(server_id)

      // logical_region_id 有传则设置
      if (logical_region_id) {
        CacheUserForm.setLocalUserForm({ logical_region_id })
      }
      // url 登录，需每次调用获取用户信息接口，所以需清数据
      this.userInfo = {}
      // 标记为活动登入
      this.needSendActTag = true
      if (!user_id || !server_id) return
      this.$nextTick(async () => {
        if (this.validateUseFreePwdBusiness(business)) {
          // 免输密码登入及业务处理
          await this.handleFreePwdLogin()
          this.handleUrlBusinessParam(this.query)
        } else {
          this.automaticLogin()
        }
      })
    },
    async setServerFormById (server_id) {
      if (!server_id) this.server_name = ''
      const { thirdLoginInfo, isThirdLogin } = this

      if (isThirdLogin) {
        const third_id = thirdLoginInfo.third_id || this.getLocalThirdId()
        // 初始化 vuex 中 third_id
        this.UPDATE_OBJECT({ thirdLoginInfo: { third_id } })
        if (!third_id) return
        // 三方登入 - 获取角色列表作为区服
        await this.getRoleList(third_id)
      } else {
        // 普通登入 - 获取区服列表
        await this.getServerApi()
      }
      this.setSelectedServerItem(server_id)
    },
    async getRoleList () {
      const roles = await this.getRoleListByLoginType(
        this.thirdLoginInfo.third_type
      )
      this.setServerList(roles)
      return roles
    },
    setSelectedServerItem (server_id) {
      if (!server_id) return
      const targetItem = this.serverList.find(({ id }) => id === server_id)
      if (targetItem) {
        this.server_name = targetItem.name
        CacheUserForm.setLocalUserForm({
          logical_region_id: targetItem.logical_region_id
        })
      } else {
        this.server_name = ''
      }
    },
    setLoginForm () {
      const { login } = this.query
      if (login) {
        // 从 url 取登录数据
        this.loginByBase64(login)
      } else {
        // 从缓存取登录数据
        this.loginByLocalCache()
      }
    },

    /** *************************** 工具方法 Begin *****************************/

    /**
     * @description: 清除掉地址上某个参数
     * @param {str} paramName 参数名
     */
    clearUrlParam (paramName) {
      const paramVal = getQueryString(paramName)
      if (!paramVal) return

      let url = window.location.href
      const regExp = new RegExp(`${paramName}=${paramVal}`)

      url = url.replace(regExp, '')
      window.history.replaceState({}, '', url)
    },
    /**
     * 修改 card 配置（默认为 open 选项卡）
     */
    setCardOptions (
      cardId,
      options = {
        disabled: false,
        isOpen: true
      }
    ) {
      const targetItem = this.formCardConfig.find((item) => item.id === cardId)

      Object.assign(targetItem, options)
    },
    // 折叠所有card
    closeAllCard () {
      this.formCardConfig.forEach((item) => (item.isOpen = false))
    },
    getMenus () {
      this.getTypes()
      this.getLabels()
    },
    /**
     * @description: 切换到【购买项】步骤
     */
    async toProductStep () {
      const stepId = 'product'

      this.setHighlightFav()
      this.getMenus()
      this.closeAllCard()
      this.setCardOptions(stepId)
      this.loadingBtn.loadProducts = true
      this.showLoading()
      try {
        await this.getDefaultCountry()
        await this.getBoughts()
      } catch (err) {
        this.loadingBtn.loadProducts = false
        this.closeLoading()
        return
      }
      this.countdownDispose()
      this.loadingBtn.loadProducts = false
      this.sortBoughts(this.product_infos)
      // 需接口请求完后才关闭loading
      this.closeLoading()
      this.$emit('productMounted')
    },
    setHighlightFav () {
      const { keys, get, set } = LocalOperator
      const showBadge = get(keys.highlightFavEntry) || false
      setItem(ICON_ID_MAP.favorite, { showBadge })
      // 仅高亮一次
      set(keys.highlightFavEntry, false)
    },
    // 更新互斥商品状态
    // async updateProductsState (mutex_product = []) {
    //   mutex_product.forEach((item) => (item.existMutex = true))
    //   setDisabledByMutex(mutex_product, this.product_infos)
    // },
    /**
     * @description: 滚动页面，使el能在页面顶部显示
     * @param selector {str | element} css选择器 或 dom
     * @param config {obj} 滚动配置
     */
    domToTopViewport (selector, config = { block: 'start' }) {
      return new Promise((resolve) => {
        this.$nextTick(async () => {
          const el =
            typeof selector === 'string'
              ? document.querySelector(selector)
              : selector
          if (el) {
            await scrollIntoView(el, config)
          }
          resolve()
        })
      })
    },
    /**
     * 根据 cardid 重置某个card（包括form、折叠、禁用）
     */
    resetCardByCardId (cardId) {
      const cardMap = {
        product: this.resetProductCard
        // payMethod: this.resetPayMethodCard
      }
      cardMap[cardId]()
    },
    resetProductCard () {
      this.resetForm('product')
      this.setCardOptions('product', {
        disabled: true,
        isOpen: false
      })
    },
    /**
     * 重置表单接口
     */
    resetForm (formType) {
      const formMap = {
        product: this.resetProductForm
      }
      formMap[formType]()
    },
    // 重置购买项表单
    resetProductForm () {
      this.UPDATE_LOGIN_FORM({ product_id: '' })
      this.product_infos = []
    },
    setServerList (list) {
      const { versionName } = getVersionByParseUrl()
      // 区服多语言转换
      list.map((v) => {
        const tKey = versionName + '.' + v.logical_region_id
        if (this.$te(tKey)) {
          v.name = this.$t(tKey)
        }
        return v
      })
      this.serverList = list
      this.backupsFromServerList = list
    }

    /** *************************** 工具方法 End *****************************/
  },
  async created () {
    const { href } = window.location
    const { iPad, mobile, isWechat, isiOS } = userAgent()

    // 是否是移动端
    this.isMobile = iPad || mobile
    // 是否移动微信客户端
    this.isWechat = this.isMobile && isWechat
    this.isiOS = isiOS
    this.hideScroll = validateHideScroll()

    // 步骤头的class name前缀
    this.soleClassKeyForCard = 'form-card-'
    // 【根据地区获取货币信息】接口的请求状态，options: before(调用前),suc(调用成功),err(调用失败)
    this.getPriceStatus = ''
    // 用户选中购买项的方式，options: click(直接点击),search(通过搜索页选中)
    this.selectedProductMethod = ''
    this.query = {
      login: getQueryString('login'),
      typeId: getQueryString('typeId'),
      labelId: getQueryString('labelId'),
      trackName: getQueryString('trackName'),
      activityId: getQueryString('activityId'),
      business: getQueryString('business'),
      app_key: getQueryString('app_key'),
      channelType: getQueryString('channelType')
    }
    this.query.app_key && this.UPDATE_STATE({ app_key: this.query.app_key })

    this.handleMyCardCallback()
    // 依据地址透传业务开启全屏loading
    if (this.validateVisibleFullLoading(this.query.business)) {
      this.visibleFullLoading()
    }
    clearWindowHrefParams()
    this.sendTrackByInitPage(href)
    this.validateClearFreePwdToken()

    /** 设置确认用户表单默认值（依据缓存） */
    const {
      user_id,
      server_id,
      pwd = ''
    } = CacheUserForm.getLocalUserForm() || {}
    Object.assign(this.form, { user_id, server_id, pwd })
    if (!server_id) {
      this.UPDATE_PAYMENT_STATE({ key: 'server_name', value: '' })
    }

    this.lang = LanguageOperator.getLangOnLocal()

    this.setLoginForm()
  },
  mounted () {
    // 社交登入后获取角色列表回调
    eventBus.$on('loadedRoleList', (roles) => {
      this.setServerList(roles)
      roles[0] && this.onConfirm(roles[0])
    })
    // 英雄之地特有业务
    if (
      this.isOnlyPaymentPageMode() &&
      (!this.query.login || !this.query.business)
    ) {
      this.$router.push('/404')
    }
  },
  setup () {
    const { handleMyCardCallback } = useHandleMyCardCallback()
    const {
      handleUrlBusinessParam,
      validateBusinessType,
      validateVisibleFullLoading,
      validateUseFreePwdBusiness
    } = useHandleUrlBusiness()
    const { handleFreePwdLogin, validateClearFreePwdToken } = useFreePwdLogin()
    const { isOnlyPaymentPageMode } = useOnlyPaymentPageMode()
    const { getLocalThirdId } = useSaveLocalThirdInfo()

    const { roleList, getRoleListByLoginType } = useGetRoles()

    return {
      roleList,
      getLocalThirdId,
      getRoleListByLoginType,
      handleUrlBusinessParam,
      validateBusinessType,
      validateVisibleFullLoading,
      handleMyCardCallback,
      validateUseFreePwdBusiness,
      handleFreePwdLogin,
      validateClearFreePwdToken,
      isOnlyPaymentPageMode
    }
  },
  beforeDestroy () {
    eventBus.$off('loadedRoleList')
    // 英雄之地特有业务，延迟关闭避免看到首页
    setTimeout(() => this.visibleFullLoading(false), 100)
  },
  watch: {
    /**
     * @description: 设置默认选中菜单
     * 选中判断优先级：
     * 1.地址存在 typeId 参数：根据 typeId 设置
     * 2.地址存在 labelId 参数：根据 labelId 设置
     * 3.菜单个数大于1，默认选中第2个，否则选中第1个
     */
    extendMergeList (menus) {
      // 地址参数指定菜单
      const { typeId, labelId } = this.query
      let currMenuIndex = menus.length > 1 ? 1 : 0

      // 根据分类id定位到对应分类菜单
      if (typeId) {
        const typeIdIndex = menus.findIndex(
          (item) => item.isType && item.label_id === typeId
        )

        if (typeIdIndex !== -1) {
          currMenuIndex = typeIdIndex
        }
      } else if (labelId) {
        const labelIdIndex = menus.findIndex(
          (item) => item.isLabel && item.label_id === labelId
        )

        if (labelIdIndex !== -1) {
          currMenuIndex = labelIdIndex
        }
      }

      this.UPDATE_PRODUCT_STATE({ activeKey: currMenuIndex })
    }
  },
  render () {
    // 渲染登入表单
    const renderLoginForm = (item) => {
      return (
        <div class="card-user">
          <p class="card-form__label">{this.$t('form.serverLabel')}</p>
          <van-field
            right-icon="arrow-down"
            readonly
            clickable
            value={this.server_name}
            placeholder={this.$t('form.selectServerTip')}
            onClick={() => (this.showPicker = true)}
          />
          <van-popup
            class="home-server-popup"
            v-model={this.showPicker}
            round
            position="bottom"
          >
            <van-field
              class="search-server-input"
              clearable
              input-align="center"
              v-model={this.filterServerVal}
              placeholder={'🔍 ' + this.$t('form.searchServerTip')}
              onInput={this.filterServer}
            />
            <van-picker
              show-toolbar
              columns={this.serverList}
              value-key="name"
              confirm-button-text={this.$t('common.confirm')}
              cancel-button-text={this.$t('common.cancel')}
              onCancel={() => (this.showPicker = false)}
              onConfirm={this.onConfirm}
            />
          </van-popup>
          <div class="card-user__id">
            <div class="id-title card-form__label" v-show={this.visibleIDInput}>
              <p style="margin-right: var(--dp-sp-xs);">ID</p>
              <NumberItem
                class="card-user__id-guide"
                num="?"
                diameter={24}
                fontSize={16}
                v-show={!this.isThirdLogin}
                onClickCB={this.showGuideImg}
              ></NumberItem>
            </div>

            {/* ID 输入框 */}
            <van-field
              clearable
              class="mgB40"
              v-show={this.visibleIDInput}
              v-model={this.form.user_id}
              placeholder={this.$t('form.inputIdTip')}
              disabled={this.validateCurrentLoginType.isSteam}
              onInput={this.oninputUserId}
            />

            <div v-show={this.showUserNameInput}>
              <p class="card-form__label">{this.$t('common.roleName')}</p>
              <van-field
                disabled
                clearable
                class="mgB40"
                v-model={this.userInfo.name}
              />
            </div>
          </div>
          <ButtonVue
            ref="confirmUserRef"
            style="margin-top: var(--dp-sp-7xl);"
            loading={this.loadingBtn.confirmUser}
            onClick={this.onclickConfirmUser}
          >
            {this.changeLoginBtnText
              ? this.$t('activity.joinBtnText')
              : this.$t('common.confirm')}
          </ButtonVue>
        </div>
      )
    }

    // 渲染购买项
    const renderMainContent = (item) => {
      // 侧边栏
      const renderSideBar = () => {
        if (this.menuThenOne) {
          // 最少情况为只有一个【全部】菜单，此时不展示菜单
          return (
            <SideBar
              list={this.extendMergeList}
              ref="sideBarRef"
              class="flex-auto product-sidebar"
              onChange={this.onSideBarChange}
            />
          )
        }
      }

      // 购物车入口
      const renderCartEntry = () => {
        if (this.visibleCartEntry) {
          // common-cart-entry-fixed 表示使用固定定位
          return (
            <div class="flex-center" style={{ paddingTop: '0.2rem' }}>
              <CartEntrance
                class={[this.fixedCartEntry ? 'common-cart-entry-fixed' : '']}
              />
            </div>
          )
        }
      }

      // 商品
      const renderProduct = () => {
        // 推荐组合商品
        const renderRecommendGroup = () => {
          return <RecommendGroup groups={this.viewProductList[0].list} />
        }
        // 商品列表
        const renderProductList = () => {
          // 二级商品列表
          const subList = (subTypeItem) => {
            const renderProductItems = () =>
              subTypeItem.list.map((productItem) => {
                // 有购物车配置
                let selectedStyle = 'none'
                let operatorPosition = 'afterPrice'

                // 无购物车配置
                if (this.withoutShoppingCartMode) {
                  selectedStyle = 'singleChoice'
                  operatorPosition = 'none'
                }
                return (
                  <ProductItem
                    class="pay-form-product-item"
                    ref={`productItemRef${productItem.id}`}
                    key={productItem.id}
                    showImputedPrice
                    productItemInfo={productItem}
                    selectedStyle={selectedStyle}
                    operatorPosition={operatorPosition}
                    onChangeAmount={this.changeAmount}
                    onClickProductItem={this.clickProductItem}
                  ></ProductItem>
                )
              })
            return <ul class="card-product">{renderProductItems()}</ul>
          }

          // 一级商品列表
          const list = () => {
            const parentList = this.viewProductList.map(
              (subTypeItem, subTypeIndex) => {
                const { title } = subTypeItem
                const renderSubTitle = () => {
                  if (title) {
                    return <p class="sub-type-title">{title}</p>
                  }
                }
                return (
                  <div key={subTypeIndex}>
                    {renderSubTitle()}
                    {subList(subTypeItem)}
                  </div>
                )
              }
            )

            return (
              <div
                class={[
                  'common-scroll-container',
                  this.hideScroll ? 'common-hide-scroll' : ''
                ]}
                vOn:scroll_once={this.onscrollFromProducts}
              >
                {parentList}
              </div>
            )
          }

          return list()
        }

        // 空商品
        const renderEmptyProduct = () => {
          return (
            <p class="empty-tip" style={{ marginTop: '1rem' }}>
              {this.$t('form.emptyProductTip')}
            </p>
          )
        }

        // 判断渲染组合商品、普通商品、空
        const validateRender = () => {
          if (this.showRecommendGroup) {
            return renderRecommendGroup()
          } else if (this.existProducts) {
            return renderProductList()
          } else if (!this.loadingBtn.loadProducts) {
            return renderEmptyProduct()
          }
        }

        return (
          <div class="flex-auto product-cont__list">{validateRender()}</div>
        )
      }

      return (
        <div>
          <div class="flex-box product-cont">
            <div class="flex-vbox">
              {renderSideBar()}
              {renderCartEntry()}
            </div>
            {renderProduct()}
          </div>

          {this.withoutShoppingCartMode && this.getSelectedProduct() && (
            <FooterOperatorBar
              businessInfo={{ strutDom: document.body }}
              text={this.$t('cart.buyRightnow')}
              footerBarType={FOOTER_BAR.btn}
              onSubmit={this.clickBuy}
            />
          )}
        </div>
      )
    }

    // 渲染客服邮箱描述
    const renderServiceDesc = () => {
      if (getCurrGameConfigProperty('stepBarDisable') !== true) {
        return (
                <div class="form-main__card form-main__head">
                  <StepBar step={this.openCardIndex + 1}></StepBar>
                </div>
              )
      }
        return (<div></div>)
    }

    // card 的头部右侧显示块
    const renderUserName = (item) => {
      if (this.userInfo.name && !item.disabled && item.id === 'user') {
        // 角色名称
        return (
          <span class="form-main__subtitle" v-show={!item.isOpen}>
            {this.server_name}：{this.userInfo.name}
          </span>
        )
      }
      return ''
    }

    // 选项卡列表
    const renderFormCards = () => {
      return this.formCardConfig.map((item) => {
        const scopedSlots = {
          headRight: () => renderUserName(item),
          logo: () => h(item.icon)
        }
        return (
          <FormCard
            scopedSlots={scopedSlots}
            key={item.id}
            title={item.title}
            isOpen={item.isOpen}
            disabled={item.disabled}
            v-show={item.id !== 'product' || item.isOpen}
            class={['form-main__card', `${this.soleClassKeyForCard}${item.id}`]}
            vOn:clickHead={() => this.clickHead(item)}
          >
            <EntryIcons
              v-show={
                item.id === 'product' &&
                item.isOpen &&
                !this.loadingBtn.loadProducts
              }
              onClickIcon={this.clickIcon}
            />
            {item.id === 'user' && renderLoginForm(item)}
            {item.id === 'product' && renderMainContent(item)}
          </FormCard>
        )
      })
    }

    return (
      <div class="form-main">
        {renderServiceDesc()}
        {renderFormCards()}
        <NoticeBoard />

        {/* 待支付订单弹窗 */}
        <UnpaidPopup
          ref="unpaidPopupRef"
          orderInfo={this.unpaidOrderInfoForPopup}
          onCancel={this.onclickUnpaidCancel}
          onConfirm={this.confirmToPayLastOrder}
        ></UnpaidPopup>

        {/* 搜索购买项弹窗 */}
        <SearchProductPopup
          ref="searchProductPopupRef"
          productInfos={this.product_infos}
          onSelectProduct={this.selectProductFromPopup}
          onChangeAmount={this.searchChangeAmount}
        />

        <PwdPopup ref="pwdPopupRef" beforeClose={this.beforeClosePwdPop} />

        {/* 活动开屏弹窗 */}
        <GuidePopup
          isUrlLogin={!!this.query.login}
          onClickJoinAct={this.clickJoinAct}
        />
      </div>
    )
  }
}
</script>

<style scoped lang="less">
@import url("~@/styles/mixin.less");
@import url("~@/styles/common.less");
@borderColor: #ebedf0;

/deep/.van-badge--fixed {
  top: 5px;
  right: 3px;
}
.form-main {
  box-sizing: border-box;
  padding: var(--dp-sp-lg);
  padding-top: 0;
  .form-main__head {
    background: var(--dp-bg-secondary);
    border-radius: var(--dp-b-rd-md);
    padding: 38px 28px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .form-main__card {
    margin-bottom: var(--dp-sp-lg);
  }
  .form-main__subtitle {
    color: var(--dp-text-tertiary);
    font-size: var(--dp-text-sm);
    margin-right: var(--dp-sp-lg);
    margin-top: var(--dp-sp-xs);
    display: inline-block;
    max-width: 250px;
    line-height: 1.32;
  }
}
.card-user {
  padding: 0 var(--dp-sp-4xl);
  :deep(.van-field) {
    margin-bottom: var(--dp-sp-lg);
  }

  .card-user__id {
    .id-title {
      display: flex;
      align-items: center;
    }
  }
}
.card-product {
  padding: 0 12px;
}
.card-btn {
  width: 80%;
  transform: translateX(10%);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-form__label {
  font-size: var(--dp-text-md);
  color: var(--dp-text-secondary);
  margin-bottom: var(--dp-sp-lg);
  font-weight: bold;
}
.search-server-input {
  height: 80px;
  border-radius: 0;
  border-bottom: 1px solid var(--dp-divider-primary);
  /deep/ input::placeholder {
    font-size: 26px;
  }
}
.form-main__search {
  display: inline-block;
  margin-left: 30px;
  .cust-bg(0.45rem, 0.45rem, "../assets/search/search_logo.png");
}
.form-main__favorite {
  display: inline-block;
  .cust-bg(0.35rem, 0.35rem, "../assets/icon/icon_favorite.png");
}
.product-sidebar {
  flex-shrink: 0;
  width: 104px;
  border-right: 1px solid var(--dp-divider-primary);
  height: 100%;
}
.product-cont {
  border-top: 1px solid var(--dp-divider-primary);
  height: 1000px;
  .product-cont__list {
    height: 100%;
    padding-top: 10px;
    position: relative;
    &.strut-bottom {
      padding-bottom: 110px;
    }
  }
}
.sub-type-title {
  font-size: var(--dp-text-md);
  color: var(--dp-text-secondary);
  font-weight: 700;
  line-height: 1.32;
  margin: var(--dp-sp-xl);
}
.pay-form-product-item {
  padding: var(--dp-sp-5xl) 0 var(--dp-sp-2xl) var(--dp-sp-md);
}
.home-server-popup {
  background-color: var(--dp-bg-tertiary);
}
</style>
