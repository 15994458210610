<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:优惠券详情
-->
<template>
  <div>
    <van-popup
      v-bind="$attrs"
      v-on="$listeners"
      round
      closeable
      position="bottom"
      @opened="openedPop"
    >
      <p class="title">{{ $t("coupon.couponDetailTitle") }}</p>

      <div class="main-cont">
        <Item :item="item" :showApplicableText="false"></Item>

        <ul class="list">
          <li
            class="flex-vcenter item"
            v-for="(val, key) in descList"
            :key="key"
          >
            <p class="label">{{ val }}</p>
            <p class="desc-cont">{{ detailInfo?.[key]?.value }}</p>
          </li>
        </ul>
      </div>

      <FooterOperatorBar
        v-if="showUseBtnInDetail"
        :loading="loading"
        :footerBarType="FOOTER_BAR.btn"
        :text="$t('coupon.useRightNow')"
        @submit="submit"
      >
      </FooterOperatorBar>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, toRef, onMounted, getCurrentInstance } from 'vue'
import Item from '@/views/Coupon/components/Item.vue'
import FooterOperatorBar, {
  FOOTER_BAR
} from '@/components/FooterOperatorBar.vue'
import { $t } from '@/i18n'

let _this = {}
const props = defineProps({
  showUseBtnInDetail: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  },
  detailInfo: {
    type: Object,
    default: () => ({})
  }
})
const loading = ref(false)
const detailInfo = toRef(props, 'detailInfo')
const descList = {
  applicableText: $t('coupon.applicableLabel'),
  conditionText: $t('coupon.conditionLabel'),
  validTime: $t('coupon.validTime')
}
onMounted(() => {
  const { proxy } = getCurrentInstance()
  _this = proxy
})
const submit = () => {
  _this.$store.dispatch('tool/goHome')
}

const openedPop = () => {}
</script>

<style lang="less" scoped>
.title {
  line-height: 100px;
  font-size: var(--dp-text-2xl);
  padding: 0 var(--dp-sp-6xl);
  border-bottom: 1px solid var(--dp-divider-primary);
  background-color: var(--dp-bg-secondary);
}
.van-popup {
  height: 80%;
  background-color: var(--dp-bg-primary);
}
.main-cont {
  padding: 14px;
  display: flex;
  flex-direction: column;
  // 减去标题高度、减去底部按钮高度
  max-height: calc(100% - 1rem - 1.12rem);
}

.list {
  color: var(--dp-text-tertiary);
  line-height: 1.32;
  background-color: var(--dp-bg-secondary);
  font-size: var(--dp-text-md);
  margin-top: 14px;
  flex: 1;
  overflow: auto;
  .item {
    padding: 28px 24px;
    border-bottom: 1px solid var(--dp-divider-primary);
    justify-content: space-between;
    &:last-child {
      border-bottom: 0;
    }
    .label {
      flex: 1;
      padding-right: 35px;
    }
    .desc-cont {
      flex: 0 0 468px;
      color: var(--dp-text-primary);
      text-align: right;
    }
  }
}
</style>
