import "core-js/modules/es.array.concat.js";
export default {
  __name: 'DiscountDescPopup',
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var showPopup = ref(false);
    var payItem = ref({});
    var validTime = computed(function () {
      var _payItem$value = payItem.value,
        activity_start_time = _payItem$value.activity_start_time,
        activity_end_time = _payItem$value.activity_end_time,
        time_zone = _payItem$value.time_zone;
      var timezone = '';
      if (typeof time_zone === 'number') {
        var unit = time_zone < 0 ? '-' : '+';
        timezone = "\uFF08UTC ".concat(unit).concat(Math.abs(time_zone), "\uFF09");
      }
      return "".concat(activity_start_time, " ~ ").concat(activity_end_time, " ").concat(timezone);
    });
    var openPopup = function openPopup(item) {
      payItem.value = item;
      showPopup.value = true;
    };
    expose({
      openPopup: openPopup
    });
    return {
      __sfc: true,
      showPopup: showPopup,
      payItem: payItem,
      validTime: validTime,
      openPopup: openPopup
    };
  }
};