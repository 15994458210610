/*
 * @Date: 2023-08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-03-21 12:49:32
 * @Description:
 */
// Detailed guidance here: https://github.com/airwallex/airwallex-payment-demo/blob/master/docs/dropin.md
import {
  createElement,
  destroyElement,
  loadAirwallex,
  getElement
} from 'airwallex-payment-elements'
import { ENV_DATA } from '@/utils/globalData.js'
import { LanguageOperator, is_debug_order } from '@/utils/business.js'
import { ref } from 'vue'

const { getPayMethod } = useGetPayMethod()

export function useSDK () {
  const sdkState = {
    // 组件类型
    COMP_TYPE: 'dropIn',
    // sdk 是否已加载
    loadedSDK: false,
    // 渲染容器 id
    AIRWALLEX_ID: 'airwallexContainer',
    // 3ds校验表单容器
    CONTAINER_FOR_3DS: 'airwallex3DSContainer',
    // 环境标识
    env: ENV_DATA.isPro ? 'prod' : 'demo',
    // 语言标识
    locale:
      LanguageOperator.findLangItem(LanguageOperator.getLangOnLocal())
        ?.airwallexKey || 'en'
  }
  // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
  // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
  async function loadSDK () {
    await loadAirwallex({
      env: sdkState.env, // 'staging | 'demo' | 'prod'
      origin: window.location.origin, // Setup your event target to receive the browser events message
      locale: sdkState.locale
    })
    sdkState.loadedSDK = true
  }

  // STEP #4: Create the drop-in element
  // 参数介绍：https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#method-parameters
  function createEl ({
    intent_id,
    client_secret,
    currency,
    customer_id,
    sub_pay_id
  }) {
    // 公司主体所在地
    const countryCode = 'HK'
    const options = {
      intent_id,
      client_secret,
      currency,
      // 开启用户信息表单（3ds需要）
      withBilling: true,
      // 指定收集的form
      requiredBillingContactFields: ['name'],
      authFormContainer: sdkState.CONTAINER_FOR_3DS,
      // 风控校验节点样式
      style: {
        popupWidth: 400,
        popupHeight: 549,
        base: {
          color: 'black'
        }
      },
      theme: {
        palette: {
          primary: '#4369E6' // brand color, the default value is #612FFF
        }
      },
      // google pay
      googlePayRequestOptions: {
        countryCode
      },
      // apple pay
      applePayRequestOptions: {
        countryCode
      },
      // wechat pay
      wechatpay: {
        desktopFlow: 'webqr',
        mobileFlow: 'mweb'
      },
      // 启用记卡 checkbox
      customer_id,
      methods: [getPayMethod(sub_pay_id)]
    }
    // STEP #5: Mount the drop-in element to the empty container created previously
    createElement(sdkState.COMP_TYPE, options).mount(sdkState.AIRWALLEX_ID)
  }

  // 销毁节点
  function destroyEl () {
    destroyElement(sdkState.COMP_TYPE)
  }

  function registerEvent ({ onReady, onSuccess, onError }) {
    const domElement = getElement(sdkState.COMP_TYPE)?.domElement
    if (domElement) {
      domElement.addEventListener('onReady', onReady)
      domElement.addEventListener('onSuccess', onSuccess)
      domElement.addEventListener('onError', onError)
    }
  }

  function removeEvent ({ onReady, onSuccess, onError }) {
    const domElement = getElement(sdkState.COMP_TYPE)?.domElement
    if (domElement) {
      domElement.removeEventListener('onReady', onReady)
      domElement.removeEventListener('onSuccess', onSuccess)
      domElement.removeEventListener('onError', onError)
    }
  }

  return {
    sdkState,
    loadSDK,
    createEl,
    destroyEl,
    registerEvent,
    removeEvent
  }
}

export function usePopup () {
  const showPopup = ref(false)
  const openPopup = () => (showPopup.value = true)
  const closePopup = () => (showPopup.value = false)
  return { showPopup, openPopup, closePopup }
}

// 根据子支付id获取支付方式
export function useGetPayMethod () {
  const PAY_METHODS = {
    applepay: 'applepay',
    googlepay: 'googlepay',
    wechatpay: 'wechatpay',
    card: 'card'
  }
  // 支付方式与支付id关系
  const PAY_METHOD_MAP = {
    // 苹果
    10247001: PAY_METHODS.applepay,
    // 谷歌
    10247002: PAY_METHODS.googlepay,
    // 微信
    10247129: PAY_METHODS.wechatpay,
    // 卡类
    default: PAY_METHODS.card
  }

  function getPayMethod (sub_pay_id) {
    return PAY_METHOD_MAP[sub_pay_id] || PAY_METHOD_MAP.default
  }
  function validatePayMethod (sub_pay_id) {
    const method = getPayMethod(sub_pay_id)
    return {
      isApplePay: method === PAY_METHODS.applepay,
      isGooglePay: method === PAY_METHODS.googlepay,
      isWechat: method === PAY_METHODS.wechatpay,
      isCardPay: method === PAY_METHODS.card
    }
  }

  return { getPayMethod, validatePayMethod }
}
