/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */

import { ref } from 'vue'
import { useCouponApi } from '@/views/Coupon'
import {
  setItem,
  ICON_ID_MAP,
  useCouponIconPopover
} from '@/views/Home/components/EntryIcons.js'
import dayjs from 'dayjs'

const coupons = ref([])

/** 操作 */
const { getCoupons } = useCouponApi()
const { visibleIconPopover } = useCouponIconPopover()

function useCouponTip () {
  /**
   * @description: 是否存在即将过期的券
   * @param {array} coupons
   * @return {boolean}
   */
  function existWillExpireCoupon (coupons) {
    return coupons.some(({ end_time }) => {
      const willExpireTime = dayjs(end_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - 48 * 3600 * 1000
      return +new Date() > willExpireTime
    })
  }

  // 优惠券红点高亮
  function setHighlightCouponIcon (showBadge) {
    setItem(ICON_ID_MAP.coupon, { showBadge })
  }

  // 优惠券icon 相关展示
  async function handleVisibleCouponTip () {
    const { coupons: newestCoupons } = await getCoupons()
    const existCoupon = !!newestCoupons?.length
    coupons.value = newestCoupons

    setHighlightCouponIcon(existCoupon)

    if (existCoupon) {
      visibleIconPopover(existWillExpireCoupon(newestCoupons))
    }
  }

  return { coupons, handleVisibleCouponTip, setHighlightCouponIcon }
}

export { useCouponTip }
