import { useSteamAuth, useSteamLoginStatus } from './useSteam.js';
import { THIRD_PARTY_LOGIN_TYPE } from '@/utils/globalData.js';
import Button from '@/components/ui/Button.vue';
export default {
  __name: 'LoginBtn',
  emits: ['loginSuccess', 'clickLogin'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useSteamAuth = useSteamAuth(),
      getIsSteamLogin = _useSteamAuth.getIsSteamLogin,
      getSteamID = _useSteamAuth.getSteamID,
      redirectAuth = _useSteamAuth.redirectAuth;
    var _useSteamLoginStatus = useSteamLoginStatus(),
      afterLoginSuccess = _useSteamLoginStatus.afterLoginSuccess;
    onMounted(function () {
      if (getIsSteamLogin()) {
        var steamId = getSteamID();
        afterLoginSuccess(steamId);
        emit('loginSuccess');
      }
    });
    function onClickLogin() {
      emit('clickLogin', {
        third_type: THIRD_PARTY_LOGIN_TYPE.steam
      });
      // 延迟跳转 避免埋点未发送
      setTimeout(function () {
        return redirectAuth();
      }, 300);
    }
    return {
      __sfc: true,
      emit: emit,
      getIsSteamLogin: getIsSteamLogin,
      getSteamID: getSteamID,
      redirectAuth: redirectAuth,
      afterLoginSuccess: afterLoginSuccess,
      onClickLogin: onClickLogin,
      useSteamAuth: useSteamAuth,
      useSteamLoginStatus: useSteamLoginStatus,
      THIRD_PARTY_LOGIN_TYPE: THIRD_PARTY_LOGIN_TYPE,
      Button: Button
    };
  }
};