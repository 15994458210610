/*
 * @Date: 2023-06
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 活动共有业务
 */
import { isSameDay } from '@/utils/date.js'
import currLocalStorage from '@/utils/localStorage'

export const VIEW_POP_LOCAL_KEY = {
  turntableActPopupShowedTime: 'turntableActPopupShowedTime',
  topUpActPopupShowedTime: 'topUpActPopupShowedTime',
  weekActPopupShowedTime: 'weekActPopupShowedTime',
  haggleActPopupShowedTime: 'haggleActPopupShowedTime'
}

export const ACT_ID_MAP = {
  haggleActId: 'haggle_act',
  topUpActId: 'top_up',
  weekTopUpActId: 'turntable',
  turntableActId: 'top_up_week'
}

export class PopupDisplay {
  #lastVisiblePopupLocalKey = '';

  constructor ({ lastVisiblePopupLocalKey }) {
    this.#lastVisiblePopupLocalKey = lastVisiblePopupLocalKey
  }

  // 今日是否显示过开屏弹窗
  isShowedToday () {
    const showedTimestamp = this.getLastViewPopupTime()
    if (!showedTimestamp) return false

    return isSameDay(+new Date(), showedTimestamp)
  }

  // 设置展示开屏弹窗时的时间
  setLastViewPopupTime (data) {
    currLocalStorage.set(this.#lastVisiblePopupLocalKey, data)
  }

  // 获取开屏弹窗显示时间
  getLastViewPopupTime () {
    return currLocalStorage.get(this.#lastVisiblePopupLocalKey)
  }
}

export class Activity extends PopupDisplay {
  actId = '';

  constructor ({ actId, lastVisiblePopupLocalKey }) {
    super({ lastVisiblePopupLocalKey })
    this.actId = actId
  }

  // 需在子类重写
  isStartActIcon () {}

  // 需在子类重写
  isStartActScreenPopup () {}
}
