<!--
 * @Date: 2021-12-10 10:46:56
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @FilePath: /dc-pay-front/src/views/Home/index.vue
-->
<template>
  <div class="home">
    <HeadBar></HeadBar>

    <!-- Banners 组件（动态渲染） -->
    <!-- div作用：提前占据空间，避免动态渲染组件突然撑开 -->
    <div style="min-height: 3.88rem">
      <component :is="'Banners'" />
    </div>
    <!-- 主逻辑在 PayForm -->
    <PayForm
      ref="payFormRef"
      @productMounted="productMounted"
      @login="onLogin"
    ></PayForm>

    <FootBar></FootBar>

    <!-- 领券提示弹窗 -->
    <Presenter @close="onClosePopup" />

    <!-- 优惠券描述条，固定在底部 -->
    <CouponDescFixedBar />

    <LoginPopup />
  </div>
</template>

<script>
import HeadBar from '@/views/Home/components/HeadBar.vue'
import FootBar from '@/views/Home/components/FootBar.vue'
import PayForm from '@/views/Home/components/PayForm.vue'
import Presenter from '@/views/Coupon/popup/Presenter.vue'
import CouponDescFixedBar from '@/views/Home/components/CouponDescFixedBar.vue'
import LoginPopup from '@/views/User/ThirdPartyLogin/LoginPopup.vue'
import { LanguageOperator, LocalOperator } from '@/utils/business.js'
import PresenterClass from '@/views/Coupon/popup/presenter.js'
import { usePopup } from '@/views/Activity/popup/ActPopup.js'
import { useLoginLogic } from '@/utils/business/login.js'
import { mapState } from 'vuex'
import { useCouponTip } from '@/views/Home/index.js'
import { useAct } from '@/views/Activity'

const { keys, set } = LocalOperator
const { handleVisibleCouponTip, setHighlightCouponIcon } = useCouponTip()

const { isLoginedToday } = useLoginLogic()
const { checkVisibleActDialog } = usePopup()
const { initActConfig } = useAct()

export default {
  name: 'Home',
  components: {
    HeadBar,
    FootBar,
    // Banners 组件中的 swiper 包大，若不异步加载，会导致chunk-vendor.js变大
    Banners: () =>
      import(
        /* webpackChunkName: 'bannersComponent' */ '@/views/Home/components/Banners.vue'
      ),
    PayForm,
    Presenter,
    CouponDescFixedBar,
    LoginPopup
  },
  provide () {
    return {
      getData: this.getData
    }
  },
  data () {
    return {
      // 是否是今天首次登入
      haveLoginToday: isLoginedToday(),
      lang: ''
    }
  },
  computed: {
    ...mapState('payment', ['defaultCountryInfo'])
  },
  methods: {
    onClosePopup () {
      setHighlightCouponIcon(true)
      // 领券后刷新购买项，避免预估到手价无法显示
      this.$refs.payFormRef.getBoughts()
    },
    async onLogin () {
      // 需先判断是否首登，再设置登入时间
      this.haveLoginToday = isLoginedToday()
      set(keys.todayLoginedTimestamp, +new Date())
    },
    async productMounted () {
      const presenter = new PresenterClass()
      // 需等【判断显示领券弹窗】【判断显示活动弹窗】完成后，再执行【判断显示大转盘弹窗】
      // 因为大转盘弹窗要在顶层
      Promise.allSettled([presenter.init(this)], initActConfig()).then(
        async (res) => {
          // 将判断是否有即将过期的优惠券放到列表完全渲染完成后处理，避免闪烁
          handleVisibleCouponTip()
          await checkVisibleActDialog({ haveLoginToday: this.haveLoginToday })
        }
      )
      // 不依赖先后顺序的请求
    },
    getData () {
      return {
        lang: this.lang
      }
    }
  },
  created () {
    this.lang = LanguageOperator.getLangOnLocal()
  }
}
</script>
