<!--
 * @Date: 2022-06-02 14:25:07
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-11 14:09:49
 * @FilePath: /dc-pay-front/src/components/Stepper.vue
-->
<template>
  <!-- 为何不使用change？change会存在多次触发问题 -->
  <div @click.stop="" style="height: .44rem;">
    <!-- 不要v-on $listeners ，会冗余触发change -->
    <van-stepper
      class="stepper-root"
      :value="value"
      :class="{
        'only-show-add': onlyShowAddBtn
      }"
      v-bind="finalConfig"
      v-on="stepperListener"
      @plus="change(value + 1)"
      @minus="change(value - 1)"
    />
  </div>
</template>

<script>
import { Stepper } from 'vant'
import IconCart from '@/assets/icon/icon_shop_cart.svg'

export default {
  components: { [Stepper.name]: Stepper },
  data () {
    return {
      IconCart,
      count: this.value
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    productItemInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    finalConfig () {
      return {
        'button-size': '.44rem',
        'input-width': '.52rem',
        'disable-input': true,
        theme: 'round',
        ...this.defaultProps,
        ...this.$attrs
      }
    },
    stepperListener () {
      return {
        focus: (...args) => this.$emit('focus', ...args),
        change: (...args) => this.$emit('originChange', ...args)
      }
    },
    onlyShowAddBtn () {
      return this.value === 0
    }
  },
  methods: {
    change (num) {
      this.$emit('change', num)
    }
  },
  created () {
    // 无需设置max，限购数量通过父级控制 add btn 是否disabled来限制
    this.defaultProps = {
      'async-change': true,
      min: 0
    }
  }
}
</script>
<style scoped lang="less">
@deepBlue: #3aa6f9;

.stepper-root {
  /deep/ .van-stepper__plus {
    background-color: var(--dp-primary);
  }
  /deep/ .van-stepper__minus {
    background-color: var(--dp-icon-minus);
    border-color: transparent;
    color: var(--dp-bg-icon);
  }

  /deep/ .van-stepper__minus--disabled,
  /deep/ .van-stepper__plus--disabled {
    background-color: var(--dp-bg-quaternary);
    color: var(--dp-disabled-primary);
    opacity: 1;
    &:active {
      opacity: 1;
    }
  }

  /deep/ .van-stepper__input {
    font-size: var(--dp-text-sm);
    color: var(--dp-text-primary);
  }
}

.only-show-add {
  /deep/ .van-stepper__input,
  /deep/ .van-stepper__minus {
    visibility: hidden;
  }
}
</style>
