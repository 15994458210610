/*
 * @Date: 2022-01-05 18:57:08
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-06-03 11:29:29
 * @FilePath: /dc-pay-front/src/router/index.js
 */
import Vue, { getCurrentInstance } from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'
import { beforeEach, afterEach } from '@/router/guard.js'
import { $t } from '@/i18n'
import Payment from '../views/Payment/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 加载微应用专用路由
  {
    path: '/micro-app/*',
    name: 'MicroApp',
    // 小模块分包到chore
    component: () =>
      import(/* webpackChunkName: "chore" */ '../views/MicroApp/index.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () =>
      import(/* webpackChunkName: "cart" */ '../views/Cart/index.vue'),
    meta: {
      // 购物车
      title: $t('cart.shoppingCart')
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    /**
     * FIXME: qiankun 加载子应用样式覆盖，导致结算页布局絮乱
     * 重现步骤：从砍价微应用跳转到结算页
     * 临时解决方案：取消结算页懒加载
     */
    component: Payment,
    meta: {
      // 结算
      title: $t('cart.payment')
    }
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: () =>
      import(/* webpackChunkName: "favorite" */ '../views/Favorite/index.vue'),
    meta: {
      title: $t('fav.favoritesText')
    }
  },
  {
    path: '/resetPassword',
    name: 'Password',
    // 小模块分包到chore
    component: () =>
      import(
        /* webpackChunkName: "chore" */ '../views/User/Password/index.vue'
      ),
    meta: {
      title: $t('pwd.pwdPageTitle')
    }
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: () =>
      import(/* webpackChunkName: "coupon" */ '../views/Coupon/index.vue'),
    meta: {
      title: $t('coupon.pageTitle')
    }
  },
  {
    path: '/paySuccess',
    name: 'PaySuccess',
    // 小模块分包到chore
    component: () =>
      import(/* webpackChunkName: "chore" */ '../views/PaySuccess/index.vue'),
    meta: {
      title: $t('form.paySuccessTip')
    }
  },
  {
    path: '/404',
    name: '404',
    // 小模块分包到chore
    component: () =>
      import(/* webpackChunkName: "chore" */ '../views/404/index.vue'),
    meta: {
      title: '404'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: '/wsy-jp/',
  // base: process.env.BASE_URL,
  routes
})
router.beforeEach(beforeEach)
router.afterEach(afterEach)

// setup 模式下访问router
export const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}

export default router
