import { render, staticRenderFns } from "./IconShopper.vue?vue&type=template&id=0a750331&scoped=true&"
var script = {}
import style0 from "./IconShopper.vue?vue&type=style&index=0&id=0a750331&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a750331",
  null
  
)

export default component.exports