/* eslint-disable no-unused-expressions */
/*
 * @Date: 2022-09
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description:
 */

import Apis from '@/api/index.js'
import {
  LanguageOperator,
  matchingProductHostAndGame
} from '@/utils/business.js'
import localStorage from '@/utils/localStorage'
import { useSetLanguage } from '@/utils/business/lang.js'
import i18n from '@/i18n'
import { handleTrack } from '@/utils'
import { listenerQiankunErr } from '@/qiankun'
import { ENV_DATA } from '@/utils/globalData.js'

const { isSit } = ENV_DATA
const { validateUrlLang } = useSetLanguage()

export default class Initializer {
  listenerGlobalError () {
    const errCallback = (e) => {
      const { target, message = '', error = {} } = e
      const { localName, href } = target
      const { stack, message: errMsg } = error

      // 捕获请求微应用异常并发送埋点
      if (localName === 'link' && href?.includes('act-turntable')) {
        handleTrack('micro_app_loaded_failed', {
          mount_status: 'request html/js/css error'
        })
      }

      handleTrack('global_err', {
        message: errMsg || message,
        stack,
        trigger: 'dcPayFront.window.error'
      })
    }

    window.addEventListener('error', errCallback)
    listenerQiankunErr()
  }

  /**
   * @description: 是否允许进入html
   * @return {boolean} true 允许
   */
  allowEntryHtml () {
    const isMatch = matchingProductHostAndGame()
    if (!isMatch) {
      location.href = location.origin + '/404.html'
    }
    return isMatch
  }

  injectGlobal () {
    window.Apis = Apis
    window._localStorage = localStorage
    window._myVue = null
  }

  async setDefaultLanguage () {
    // 要设置的语言
    let targetLang = ''
    const localLang = LanguageOperator.getLocalLangNoDefault()
    const { urlLang, isRightLang } = validateUrlLang()

    // 设置地址透传语言
    isRightLang && (targetLang = urlLang)

    // 根据 ip 获取默认语言（用户首次使用 或 清空缓存后才会触发）
    if (!targetLang) {
      targetLang = await LanguageOperator.getDefaultLanguage().catch(() => '')
    }

    if (targetLang && targetLang !== localLang) {
      LanguageOperator.setLangOnLocal(targetLang)
      i18n._vm.locale = targetLang
    }
  }

  initVue () {
    import(/* webpackChunkName: "init" */ '@/core/initVue.js')
  }

  async initDevPackage () {
    if (isSit) {
      const Vconsole = (
        await import(/* webpackChunkName: "vconsole" */ 'vconsole')
      ).default

      /* eslint-disable-next-line */
      new Vconsole();
    }
  }
}
