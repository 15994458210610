/**
 * @description: 混入到 src/views/Home/components/PayForm.vue
 */
import { handleTrack } from '@/utils/index.js'

export default {
  data () {
    return {}
  },
  methods: {
    // 是否将活动信息合并到入参
    mergeActInfo (data = {}) {
      const { query, needSendActTag, isThirdLogin, thirdLoginInfo } = this
      const { activityId } = query
      const { third_type: login_type } = thirdLoginInfo
      if (needSendActTag && activityId) {
        data.activity_id = activityId
      }
      // 添加三方登入参数
      if (isThirdLogin) {
        const third_id = this.getLocalThirdId()
        Object.assign(data, { third_id, login_type })
      }
      return data
    },
    beforeClosePwdPop (action, done) {
      const fnMap = {
        confirm: () => this.onclickConfirmPwd(done),
        cancel: done
      }
      fnMap[action]()
    },
    /**
     * @description: 确认密码
     * @param: done 可选参数，仅beforeClosePwdPop调用时传
     */
    async onclickConfirmPwd (done = () => {}) {
      try {
        const isComplete = await this.isCompletePwd()
        if (isComplete) {
          await this.startLogin()
          done()
        } else {
          done(false)
        }
      } catch (error) {
        done(false)
      }
    },
    async onclickConfirmUser () {
      const isComplete = await this.isCompleteForm(['server_id', 'user_id'])
      // 校验表单完整
      if (!isComplete) return
      // 此时必须调用接口判断，因为用户随时有应用、取消的可能
      const { usePsdLogin } = await this.checkExistPwd()

      if (usePsdLogin) {
        // 是否存在密码
        if (this.form.pwd) {
          this.onclickConfirmPwd()
        } else {
          this.$refs.pwdPopupRef.showPopup()
        }
      } else {
        this.startLogin()
      }
    },
    // 所有登入都触发该方法
    async startLogin () {
      this.loadingBtn.confirmUser = true
      try {
        await this.setUserInfo()
        const { needShowPsdTipDialog } = this.userAuth
        // 是否显示【提醒用户设置密码弹窗】
        if (!this.isOnlyPaymentPageMode() && needShowPsdTipDialog) {
          this.setCallbackAndShowDialog()
        } else {
          await this.loginLogic()
        }
        this.loadingBtn.confirmUser = false
      } catch (error) {
        this.loadingBtn.confirmUser = false
        return Promise.reject(error)
      }
    },
    async setCallbackAndShowDialog () {
      this.showTipPwdDialog({
        touch_type: 'firstLogin',
        tipConfirmCallbak: async () => {
          // 需同步 tag （存在并发导致状态更新不正确可能）
          await this.tagTipPwdDialog()
          await this.sendResetMailCommon()
          this.$refs.pwdPopupRef.showPopup()
        },
        cancelCallback: async () => {
          this.tagTipPwdDialog()
          this.showLoading()
          await this.loginLogic().finally(this.closeLoading)
        }
      })
    },
    // 登入后逻辑（所有登入都会触发该方法）
    async loginLogic () {
      const { user_id: edt_show_id } = this.form
      const { third_type } = this.thirdLoginInfo

      // 埋点：填写角色编号
      handleTrack('form_write_roleid', { edt_show_id, channel: third_type })
      // 埋点：获取角色信息（成功）
      handleTrack('get_role_suc', { edt_show_id, channel: third_type })

      const { activityId, login } = this.query

      if (login && activityId) {
        // 存在 personal_id
        handleTrack('open_by_h5', {
          activity_id: activityId,
          query: this.query
        })
      }
      this.showUserNameInput = true
      this.changeLoginBtnText = false

      await this.handleDifferentLogin()
    },
    async handleDifferentLogin () {
      const { business } = this.query
      if (this.validateBusinessType(business)) {
        // 处理 business 业务
        this.handleUrlBusinessParam(this.query)
      } else {
        await this.normalLogin()
      }
    },
    async normalLogin () {
      await this.$store.dispatch('payment/searchLastOrderState')
      this.disposeByOrderState(this.lastOrderInfo.is_done)
      this.$emit('login')
    },
    async setUserInfo () {
      const { user_id: edt_show_id } = this.form
      return await this.getUserInfo(this.mergeActInfo()).catch((err) => {
        const { code: status_code } = err

        // 埋点 - 获取角色信息（失败）
        handleTrack('get_role_err', {
          edt_show_id,
          status_code
        })

        // 密码错误处理
        if (status_code === 230) {
          this.$refs.pwdPopupRef.showPopup()
        } else if (status_code === 252) {
          // 必须设置密码才能登入（后端控制哪些游戏开启）
          this.forceSetPwdTip()
        }
        return Promise.reject(err)
      })
    },
    // 提醒设置密码（无[暂不设置]按钮；弹窗关闭后不执行登录逻辑）
    forceSetPwdTip () {
      this.showTipPwdDialog({
        touch_type: 'mustSetPwd',
        showCancelButton: false,
        showCloseIcon: true,
        tipConfirmCallbak: async () => {
          await this.sendResetMailCommon()
          this.$refs.pwdPopupRef.showPopup()
        },
        cancelCallback: async () => {}
      })
    }
  },
  mounted () {}
}
