/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2022-10
 * @Description:
 */

export default {
  /** 购物车 */
  // 获取购物车列表
  getShoppingCart: '/cart/GetProductCart',
  // 更新购物车
  updateShoppingCart: '/cart/UpdateProductCart',
  // 获取购物车数量
  getShoppingCartCount: '/cart/GetCartCount',
  // 购物车提交订单
  submitShoppingCart: '/cart/Settlement'
}
