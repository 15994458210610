import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import Button from '@/components/ui/Button.vue';
export default {
  model: {
    prop: 'value',
    event: 'update:value'
  },
  emits: ['confirm', 'cancel'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    beforeClose: {
      type: Function,
      default: function _default(action, done) {
        done();
      }
    },
    message: {
      type: String,
      default: ''
    }
  },
  components: {
    Button: Button,
    Popup: _Popup
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmLoading = ref(false);
    function handleClose(action) {
      function done(flag) {
        confirmLoading.value = false;
        if (flag === false) {
          return false;
        }
        emit('update:value', false);
      }
      props.beforeClose(action, done);
    }
    function onCancel() {
      handleClose('cancel');
      emit('cancel');
    }
    function onConfirm() {
      confirmLoading.value = true;
      handleClose('confirm');
      emit('confirm');
    }
    function onClickCloseIcon() {
      handleClose('cancel');
      emit('closed');
    }
    return {
      confirmLoading: confirmLoading,
      onCancel: onCancel,
      onConfirm: onConfirm,
      onClickCloseIcon: onClickCloseIcon
    };
  }
};