import { render, staticRenderFns } from "./UseTip.vue?vue&type=template&id=23a922b2&scoped=true&"
import script from "./UseTip.vue?vue&type=script&setup=true&lang=js&"
export * from "./UseTip.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./UseTip.vue?vue&type=style&index=0&id=23a922b2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a922b2",
  null
  
)

export default component.exports