<!--
 * @Date: 2022-01-19 11:20:20
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-01-17 18:29:31
 * @FilePath: /dc-pay-front/src/views/Home/popup/ProtocolPopup.vue
-->
<template>
  <div>
    <van-popup class="order-popup" v-model="show" closeable>
      <div class="container">
        <iframe
          v-if="iframeUrl"
          :src="iframeUrl"
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getVersionDetailInfo } from '@/utils/business.js'
export default {
  data () {
    return {
      show: false
    }
  },
  inject: ['versionInfo'],
  computed: {
    iframeUrl () {
      const currIframeInfo = this.pageMap[this.type] || {}
      const { versionName } = this.versionInfo

      return currIframeInfo[versionName] || currIframeInfo.default || ''
    }
  },
  props: {
    // privacy || agreement
    type: {
      type: String,
      default: 'privacy'
    }
  },
  methods: {
    showPopup (isShow = true) {
      this.show = isShow
    }
  },
  created () {
    let { website } = getVersionDetailInfo()

    website = encodeURI(website)
    this.pageMap = {
      privacy: {
        default: `/pages/privacy-policy.html?website=${website}`,
        'wsy-cn': '/pages/privacy-policy-for-china.html',
        'dzb-tw': '/pages/privacy-policy-dzb.html',
        'hero-audit': '/pages/privacy-policy-hero-audit.html',
        'hero-global': '/pages/privacy-policy-hero-land.html',
        'hero-steam': '/pages/privacy-policy-hero-land.html',
        'herobump-global': '/pages/privacy-policy-herobump-global.html',
        'vk-global': '/pages/privacy-policy-vk.html',
        'vk-audit': '/pages/privacy-policy-vk.html'
      },
      agreement: {
        default: `/pages/agreement.html?website=${website}`,
        'wsy-kr': '/pages/agreement-for-wsy-korea.html',
        'wsy-cn': '/pages/agreement-for-china.html',
        'dzb-tw': '/pages/agreement-dzb.html',
        'hero-audit': '/pages/agreement-hero-audit.html',
        'hero-global': '/pages/agreement-hero-land.html',
        'hero-steam': '/pages/agreement-hero-land.html',
        'herobump-global': '/pages/agreement-herobump-global.html',
        'vk-global': '/pages/agreement-vk.html',
        'vk-audit': '/pages/agreement-vk.html'
      }
    }
  }
}
</script>
<style scoped lang="less">
@keyColor: #f8e19d;
/deep/ .van-popup__close-icon {
  right: 8px;
}
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 750px) {
  .container {
    width: 750px;
    margin: 0 auto;
  }
}

.order-popup {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
  font-size: 22px;
}
.container {
  height: 100%;
  overflow: auto;
  padding: 88px 54px;
}
.title {
  text-align: center;
  font-size: 36px;
  color: @keyColor;
  padding-bottom: 44px;
}
.sub-title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 26px;
}
.normal-cont {
  padding-bottom: 42px;
  line-height: 34px;
}
</style>
