<!--
 * @Date: 2022-05-31 09:52:13
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-02-06 16:16:20
 * @FilePath: /dc-pay-front/src/views/Payment/mixins/MixinWechatPay.vue
-->

<template>
  <div></div>
</template>

<script>
import { CacheOpenId, CacheOrderParams } from '@/utils/business.js'
import { urlConfig, payIdMap } from '@/utils/globalData.js'
import { getQueryString, clearWindowHrefParams } from '@/utils/index.js'
import { getVersionDetailInfo } from '@/utils/versionHelper'

const { serverRegion } = getVersionDetailInfo()

export default {
  data () {
    return {}
  },
  methods: {
    // wx授权成功，继续创建订单（wx客户端支付）
    disposeRedirect () {
      const code = getQueryString('code')
      const state = getQueryString('state')
      // 微信 jsapi 支付，获取 code 后逻辑

      if (!!code && state === 'wxJspaiPay') {
        // 将地址参数清空 - 防止重复触发
        clearWindowHrefParams()
        const jsapiInstance = this.mixin_getWechatJsapiPayClass()
        jsapiInstance.createOrderForJsapiByCode(code)
      }
    },
    /**
     * @description: wx支付 - 判断客户端环境是否与创建订单时不一致
     * @param {obj} order_info
     * @return {boolean}
     */
    checkMixWechatPay (order_info) {
      let { pay_id, attachment } = order_info
      // 不是wx支付
      if (pay_id !== payIdMap.wx) return false
      const { prepay_id, code_url, h5_url } = attachment
      const { name } = this.getWxStrategy()

      pay_id = String(pay_id)

      // 判断是否是混合环境
      if (
        (h5_url && name === 'mobile') ||
        (code_url && name === 'pc') ||
        (prepay_id && name === 'wxClient')
      ) {
        return false
      }
      return true
    },
    // 唤醒微信支付策略
    toWeChatPay (order_info) {
      const resultFunc = this.getWxStrategy().wekeupPay

      typeof resultFunc === 'function' && resultFunc(order_info)
    },
    /**
     * @description: 微信不同环境策略
     * @remark: wechat_type: '1'(native：pc端), '2'（H5：移动端）, '3'（jsapi：微信浏览器）
     * @return {*}
     */
    getWxStrategy () {
      const {
        isMobile,
        isWechat,
        wechatPayByH5,
        wechatPayByJsapi,
        wechatPayByNative
      } = this

      // 移动端通用浏览器
      const mobile = {
        name: 'mobile',
        wechat_type: '2',
        wekeupPay: wechatPayByH5,
        createOrderFnName: 'handleSubmitByH5Pay'
      }

      // 微信浏览器
      const wxClient = {
        name: 'wxClient',
        wechat_type: '3',
        wekeupPay: wechatPayByJsapi,
        createOrderFnName: 'handleSubmitByJsapiPay'
      }

      // pc浏览器
      const pc = {
        name: 'pc',
        wechat_type: '1',
        wekeupPay: wechatPayByNative,
        createOrderFnName: 'handleSubmitByNativePay'
      }
      if (isMobile) {
        if (isWechat) {
          return wxClient
        } else {
          return mobile
        }
      } else {
        return pc
      }
    },
    /**
     * 唤起微信支付 - native（pc端）
     * @param order_info 订单信息
     * @desc
     *  根据订单信息返回地址生成二维码，并以弹窗呈现
     *  由于无支付回调，支付成功后页面无变化（保持显示二维码弹窗状态）
     */
    wechatPayByNative (order_info) {
      const { attachment, pay_id } = order_info
      const { code_url, order_id } = attachment
      const { showPopup, initOrUpdateQRcode } = this.$refs.qrcodePopupRef
      if (code_url) {
        initOrUpdateQRcode(code_url)
        this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)

        this.$nextTick(() => {
          showPopup()
        })
      } else {
        this.submitTrack('use_third_pay_err', pay_id, 305, order_id)
      }
    },
    /**
     * 唤起微信支付(wx客户端)
     * @param order_info 订单信息
     * @desc 能监听支付回调：成功、失败、取消
     */
    wechatPayByJsapi (order_info) {
      const { attachment, pay_id } = order_info
      // prepay_id
      const { order_id, sign_info } = attachment

      if (sign_info) {
        this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)
      }

      // 请求回调
      const callback = (res) => {
        // 回调函数策略
        const CALLBACK_MAP = {
          // 支付成功
          'get_brand_wcpay_request:ok': () => {
            this.submitTrack('pay_suc', pay_id, 0, order_id)
            if (this.isOnlyPaymentPageMode()) {
              this.$router.replace('/paySuccess')
            } else {
              this.$store.dispatch('tool/goHome', { isReplace: true })
            }
          },
          // 取消支付
          'get_brand_wcpay_request:cancel': () => {
            this.submitTrack('use_third_pay_err', pay_id, 304, order_id)
          },
          // 支付失败
          'get_brand_wcpay_request:fail': () => {
            this.submitTrack('use_third_pay_err', pay_id, 303, order_id)
          }
        }
        CALLBACK_MAP[res.err_msg] && CALLBACK_MAP[res.err_msg]()
      }

      // WeixinJSBridge为微信浏览器内置对象
      WeixinJSBridge.invoke('getBrandWCPayRequest', sign_info, callback)
    },
    // 唤起微信支付 - h5方式
    wechatPayByH5 (order_info) {
      const { homeUrl } = urlConfig.redirect
      const { attachment } = order_info
      let { h5_url } = attachment

      // 此处不能使用 public/redirect.html 对应地址（微信支付回跳时，可能会跳到默认浏览器，
      // 存在同时打开两个浏览器情况，存在redirect.html的session获取不到问题）
      h5_url += `&redirect_url=${encodeURI(
        this.getRedirectUrlIfPaymentPageMode() || homeUrl
      )}`

      // 覆盖 pay_url ，以便复用 showPayPageByUrl
      order_info.pay_url = h5_url

      this.showPayPageByUrl(order_info)
    },
    formateObjToParamStr (paramObj) {
      const sdata = []
      for (const attr in paramObj) {
        sdata.push(`${attr}=${paramObj[attr]}`)
      }
      return sdata.join('&')
    },
    // wechat pay 逻辑
    mixin_getWechatJsapiPayClass () {
      const _this = this
      class JsapiPay {
        /**
         * @description: 微信支付策略
         * @return { promise } 返回promise，以便调用者使用 await 监听完成
         */
        wxPayPolitic () {
          const funcName = _this.getWxStrategy().createOrderFnName

          return typeof this[funcName] === 'function' && this[funcName]()
        }

        // 创建订单 - h5
        handleSubmitByH5Pay () {
          const data = this.genParamsForWechat()
          return _this.useCreateOrderApi(data)
        }

        // 创建订单 - pc
        handleSubmitByNativePay () {
          const data = this.genParamsForWechat()
          return _this.useCreateOrderApi(data)
        }

        // 创建订单 - wx客户端
        handleSubmitByJsapiPay () {
          const openId = CacheOpenId.getOpenId()
          const data = this.genParamsForWechat(openId)
          // 获取过openid就创建订单，否则跳转wx授权
          if (openId) {
            return _this.useCreateOrderApi(data)
          } else {
            this.toAuthWechat(data)
          }
        }

        // 生成创建订单参数（pc/h5/客户端 通用）
        genParamsForWechat (openId) {
          const wechat_type = _this.getWxStrategy().wechat_type
          const wechatParams = {
            wechat_type,
            open_id: openId
          }
          if (!openId) delete wechatParams.open_id

          return _this.genCreateOrderParams(wechatParams)
        }

        /**
         * 缓存订单入参，跳转wx授权（wx客户端支付需要）
          授权成功重定向回结算页，触发 disposeRedirect 后续支付流程
         * @param data 订单参数
         */
        toAuthWechat (data) {
          const authUrl = this.genWechatAuthUrl()
          CacheOrderParams.setCreateOrderParams(data)
          window.location.href = authUrl
        }

        // 生成wx授权url
        genWechatAuthUrl () {
          const { homeUrl } = urlConfig.redirect
          const urlParamObj = {
            appid: serverRegion.wechatAppId,
            // 需重定向结算页
            redirect_uri: window.encodeURIComponent(homeUrl + 'payment'),
            response_type: 'code',
            scope: 'snsapi_base',
            state: 'wxJspaiPay' // 表示为 wx 的 jsapi 支付
          }
          const urlParamStr = _this.formateObjToParamStr(urlParamObj)

          return urlConfig.getWXAuthUrl(urlParamStr)
        }

        /**
         * 根据授权code创建订单（wx客户端支付）
         * @param {*} code 微信授权码
         */
        async createOrderForJsapiByCode (code) {
          const openId = await this.getWXOpenId(code)
          const data = CacheOrderParams.getCreateOrderParams()
          let { channel_info } = data

          // 往 data.channel_info 中添加 open_id
          channel_info = JSON.parse(channel_info)
          channel_info.open_id = openId
          data.channel_info = JSON.stringify(channel_info)

          _this.useCreateOrderApi(data)
        }

        /**
         * 获取wx openId
         * @param {*} code 微信授权码
         */
        getWXOpenId (code) {
          return Apis.getWechatInfo({ code }).then((res) => {
            const openId = res.open_id
            CacheOpenId.setOpenId(openId)
            return openId
          })
        }
      }
      return new JsapiPay()
    }
  },
  created () {
    // 处理重定向
    this.disposeRedirect()
  }
}
</script>
