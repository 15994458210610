<!--
 * @Date: 2021-12-19 16:23:11
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-01-19 19:41:56
 * @FilePath: /dc-pay-front/src/components/CountDownTimer.vue
 * @describe: 倒计时器
-->
<template>
  <div>
    <div class="cd-timer">
      <van-icon class="cd-clock" :name="IconClock"></van-icon>
      <span class="cd-timer-item" v-for="(item, key) in formatTimeObj" :key="key">{{ item }}<span class="split-key">:</span></span>
    </div>
  </div>
</template>

<script>
import IconClock from '@/assets/icon/icon_clock.svg'
export default {
  components: {},
  data () {
    return {
      IconClock,
      time: {
        hour: '00',
        minute: '00',
        second: '00'
      }
    }
  },
  computed: {
    formatTimeObj () {
      const ms = this.timestamp
      if (ms < 0) {
        return {
          hour: '00',
          minute: '00',
          second: '00'
        }
      }

      const time = {
        hour: Math.floor(ms / 3600000),
        minute: Math.floor(ms / 60000) % 60,
        second: Math.floor(ms / 1000) % 60
      }
      // 单个数字时，在前面添加'0'
      for (const key in time) {
        if (Object.hasOwnProperty.call(time, key)) {
          const item = time[key] + ''
          if (item.length === 1) {
            time[key] = '0' + item
          }
        }
      }
      return time
    }
  },
  props: {
    timestamp: {
      type: Number,
      default: 0
    }
  },
  methods: {},
  created () {}
}
</script>
<style scoped lang="less">
.cd-timer {
  font-size: var(--dp-text-md);
  font-weight: 700;
  color: var(--dp-warning-primary);
  .cd-clock {
    font-size: var(--dp-text-md);
    margin-bottom: -2px;
    margin-right: var(--dp-sp-xs);
  }
  .cd-timer-item {
    &:last-child {
      .split-key {
        display: none;
      }
    }
  }
}
</style>
