/*
 * @Date: 2023-01
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 大转盘
 */
import Vue from 'vue'
import { LanguageOperator, getVersionDetailInfo } from '@/utils/business.js'
import { ActTurntableActions } from '@/qiankun/actions/act-turntable.js'
import { STATIC_STATE } from '@/qiankun/actBusiness/common.js'

const { getConfig } = useConfig()

function useConfig () {
  function getEnvActConfig () {
    const { serverRegion } = getVersionDetailInfo()
    const sharedProps = Vue.observable({
      lang: LanguageOperator.getLangOnLocal()
    })

    const envCommonConfig = {
      name: 'act-turntable',
      // 手动挂载共用id
      container: `#${STATIC_STATE.manualMountElId}`,
      props: {
        sharedProps,
        mainAppActions: new ActTurntableActions()
      }
    }

    return {
      dev: {
        entry: '/act-turntable/',
        // entry: '//10.6.192.27:3000/',
        ...envCommonConfig
      },
      sit: {
        // entry: '//192.168.5.125:31212/',
        entry: '/act-turntable/',
        ...envCommonConfig
      },
      uat: {
        entry: '/act-turntable/',
        ...envCommonConfig
      },
      pre: {
        entry: 'https://pre-activity-eu.clktec.com/turntable/',
        ...envCommonConfig
      },
      pro: {
        // 正式部署三个服务，按地区区分
        entry: serverRegion.turntableActProductionUrl,
        ...envCommonConfig
      }
    }
  }

  function getConfig () {
    const env = process.env.VUE_APP_ENV || 'dev'
    return getEnvActConfig()[env] || null
  }

  return { getConfig }
}

export default getConfig()
