<!--
 * @Date: 2022-11
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-12-13 09:29:06
 * @Description:
-->

<template>
  <div class="flex-vbox common-panel-main">
    <!-- flex: 1 -->
    <Convert v-on="{ convertSuccess: $listeners.convertSuccess }" :borderPosition="convertBorderPosition" />

    <!-- 加载中 -->
    <van-loading
      style="
        text-align: center;
        padding-top: 1rem;
        background-color: transparent;
      "
      type="spinner"
      v-if="couponInfo.loadingList"
    />

    <div class="scroll-cont" v-else-if="couponInfo.coupons?.length">
      <div class="flex-between unuse-coupon" v-if="$attrs.openSelect">
        <p>{{ $t("coupon.nonUse") }}</p>
        <van-radio name="" v-model="useCouponData.coupon_id" />
      </div>
      <ul class="panel-list">
        <Item
          class="item-com"
          v-for="item in couponInfo.coupons"
          :key="item.role_coupon_id"
          :item="item"
          :openSelect="$attrs.openSelect"
          @handleClick="clickItem"
        ></Item>
      </ul>
    </div>

    <!-- 列表为空展示 -->
    <van-empty
      v-else
      class="custom-image"
      :image="require('@/assets/empty/coupon_empty.png')"
      :description="$t('coupon.emptyCoupon')"
    />

    <slot name="footer"></slot>

    <Detail
      v-bind="{ showUseBtnInDetail: $attrs.showUseBtnInDetail }"
      :item="data.currItem"
      :detailInfo="data.couponDescInfo"
      v-model="data.showDetailPop"
    />
  </div>
</template>

<script setup>
import Convert from '@/views/Coupon/components/Convert.vue'

import Item from '@/views/Coupon/components/Item.vue'
import Detail from '@/views/Coupon/popup/Detail.vue'
import { reactive, toRefs } from 'vue'
import { Empty as VanEmpty, Radio as VanRadio } from 'vant'
import { data as useCouponData } from '@/views/Coupon/popup/use.js'

const data = reactive({
  currItem: {},
  couponDescInfo: {},
  showDetailPop: false
})

defineProps({
  couponInfo: {
    type: Object,
    default: () => ({
      // 券列表
      coupons: [],
      // 列表是否loading
      loadingList: false
    })
  },
  convertBorderPosition: {
    type: String,
    default: 'bottom'
  }
})

const clickItem = ({ item, couponDescInfo }) => {
    data.currItem = item
    data.couponDescInfo = couponDescInfo
    data.showDetailPop = true
  }

// const { loadingList } = toRefs(data)
</script>

<style lang="less" scoped>
/deep/.custom-image {
  margin-top: 212px;
  .van-empty__image {
    width: 400px;
    height: 310px;
  }
}
.common-panel-main {
  height: 100%;
}
.panel-list{
  padding: 0 14px;
}
.item-com {
  margin: 14px 0;
}
.scroll-cont {
  overflow-y: auto;
  flex: auto;
}
.unuse-coupon {
  padding: 28px 16px;
  background-color: var(--dp-bg-secondary);
  margin: 14px;
  p {
    font-size: var(--dp-text-md);
  }

  /deep/ .van-radio__icon {
    font-size: var(--dp-text-xl);
  }
}
</style>
