/*
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */

import { CommonActions } from './common'
import { usePopup } from '@/views/Activity/popup/ActPopup.js'

export class ActTurntableActions extends CommonActions {
  #openPopup (isOpen) {
    const { openPopup } = usePopup()
    openPopup(isOpen)
  }

  // 点击去使用（优惠券）按钮
  actTurntableToUse = (val) => {
    this.#openPopup(false)
  };

  // 点击关闭按钮
  actTurntableClose = () => {
    this.#openPopup(false)
  };

  // 点击显示活动说明
  actTurntableShowDetail = () => {};

  // 获取奖品信息
  actTurntableLotteryInfo = (val) => {
    return Apis.lotteryInfo(val)
  };

  // 抽奖
  actTurntableDoLottery = async (val) => {
    return Apis.doLottery(val)
  };

  actTurntableLotteryRecord = (val) => {
    return Apis.lotteryRecord(val)
  }
}
