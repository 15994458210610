/*
 * @Date: 2023-06
 * @LastEditors: wfj
 * @LastEditTime: 2023-09
 * @Description:
 */
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('tool', ['showLoading', 'closeLoading']),

    showMyCardPayPage (order_info) {
      this.showPayPageByUrl(order_info)
    }
  }
}
