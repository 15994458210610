/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-11
 * @Description:
 */
import { reactive, watch, ref } from 'vue'
import { ifOpenCouponVersion, LocalOperator, getCurrGameConfigProperty } from '@/utils/business.js'
import { turntableAct } from '@/views/Activity/TurntableAct.js'
import { isSameDay } from '@/utils/date.js'
import { $t } from '@/i18n'
import { getManualAppInstance } from '@/qiankun/actBusiness/manualLoadMicroApp.js'
import { useAct } from '@/views/Activity'
import { isWithoutShoppingCartMode } from '@/utils/business/sys.js'

export const ICON_ID_MAP = {
  activity: 'activity',
  turntable: 'actTurntable',
  coupon: 'coupon',
  favorite: 'favorite',
  search: 'search',
  resetPwd: 'resetPwd'
}
const { getTurntableApp } = getManualAppInstance()
const { icon } = useAct()

/**
 * @description: 首页icon配置
 * item: {
 *    id: string 唯一标识,
      iconPath: string 图片地址,
      showBadge: boolean 是否右上角高亮（默认为红点，显示为图片需配置badgeImg）
      badgeImg?: string 右上角图片
      ifShow?: function 在函数中返回false控制icon不显示，默认都显示
      showPopover?: boolean 是否显示气泡提示
      popoverText?: string 气泡提示文案
      activity_url?: 活动地址（活动特有）
      login_type?: 登入类型（活动特有）
 * }
 */
export const EntryData = reactive({
  icons: [
    {
      id: ICON_ID_MAP.turntable,
      iconPath: require('@/assets/icon/icon_home_activity_turntable.svg'),
      showBadge: true,
      badgeImg: require('@/assets/icon/icon_hot_badge.png'),
      ifShow: () => {
        const turntableApp = getTurntableApp()
        const visible = turntableAct.isStartActIcon()
        if (visible) {
          turntableApp.prefetchMicroApp()
        }
        return visible
      }
    },
    {
      id: ICON_ID_MAP.coupon,
      iconPath: require('@/assets/icon/icon_home_coupon.svg'),
      showBadge: false,
      showPopover: false,
      popoverText: '',
      // 开启密码验证且未禁用优惠卷时启用
      ifShow: () => ifOpenCouponVersion() && getCurrGameConfigProperty('couponDisable') !== true
    },
    {
      id: ICON_ID_MAP.search,
      iconPath: require('@/assets/icon/icon_home_search.svg'),
      showBadge: false
    },
    {
      id: ICON_ID_MAP.favorite,
      iconPath: require('@/assets/icon/icon_home_favorite.svg'),
      showBadge: false,
      ifShow: () => !isWithoutShoppingCartMode()
    },
    {
      id: ICON_ID_MAP.resetPwd,
      iconPath: require('@/assets/icon/icon_home_password.svg'),
      showBadge: false,
      ifShow: () => ifOpenCouponVersion()
    }
  ]
})

function getIndex (id) {
  return EntryData.icons.findIndex((item) => item.id === id)
}
export function setItem (id, option) {
  const i = getIndex(id)
  const item = EntryData.icons[i]
  if (item) {
    Object.assign(item, option)
  }
}

export function useCouponIconPopover () {
  function isSeenToday () {
    const { keys, get } = LocalOperator
    const time = get(keys.showCouponTipTimestamp)
    if (!time) return false
    return isSameDay(+new Date(), time)
  }

  // 显示【优惠券未使用】提示气泡
  function visibleIconPopover (existWillExpireCoupon = false) {
    if (isSeenToday()) return
    const popoverText = existWillExpireCoupon
      ? $t('coupon.existToExpireCouponTip')
      : $t('coupon.existUseabledCouponTip')
    const { keys, set } = LocalOperator

    setItem(ICON_ID_MAP.coupon, {
      showPopover: true,
      popoverText
    })
    set(keys.showCouponTipTimestamp, +new Date())

    setTimeout(() => {
      setItem(ICON_ID_MAP.coupon, {
        showPopover: false
      })
    }, 5000)
  }

  return { visibleIconPopover }
}

export function useActIcon () {
  const actIcons = ref([])

  function addListenerVisibleActIcons () {
    watch(icon, (icons) => {
      actIcons.value = icons.map(
        ({
          show_badge: showBadge,
          badge_image: badgeImg,
          icon_image: iconPath,
          login_type,
          activity_url
        }) => {
          return {
            id: ICON_ID_MAP.activity,
            iconPath,
            showBadge,
            badgeImg,
            activity_url,
            login_type
          }
        }
      )
    })
  }

  return { actIcons, addListenerVisibleActIcons }
}
