/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
 */

export default {
  // 获取优惠券列表
  getCoupons: '/rolecoupon/RoleCoupons',
  // 兑换
  convertCoupon: '/rolecoupon/ConvertCoupon',
  // 获取可使用优惠券
  getUseableCoupons: '/rolecoupon/UseableRoleCoupons',
  // 领取优惠券
  obtainCoupon: '/rolecoupon/ObtainCoupon'
}
