/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-04
 * @Description:
 */
import { mapActions } from 'vuex'
import { CacheUserForm } from '@/utils/business.js'
import { handleTrack } from '@/utils/index.js'

export default {
  methods: {
    ...mapActions(['sendResetMailCommon']),

    // 发送邮件 && 显示密码弹窗
    async sendEmailAndShowPwdPopup () {
      await this.nonexistPwdSendEmail()
      handleTrack('send_pwd_email_from_payment_page')
      this.showPwdPopup()
    },
    async nonexistPwdSendEmail (options) {
      const existPwd = await this.cacheCheckExistPwd()
      if (!existPwd) {
        await this.sendResetMailCommon(options)
      }
    },
    beforeClosePwdPop (action, done) {
      const fnMap = {
        confirm: async () => {
          try {
            const isComplete = await this.isCompletePwd()
            if (isComplete) {
              await this.submitPayment('clickConfirmPwd')
              // 设置缓存密码原因：在创建订单页设置的密码的场景，回到首页后密码为空（首页初始化时会读缓存）
              const { pwd } = this.loginForm
              pwd && CacheUserForm.setLocalUserForm({ pwd })
              done()
            } else {
              done(false)
            }
          } catch (error) {
            done(false)
          }
        },
        cancel: done
      }
      fnMap[action]()
    },
    showPwdPopup () {
      this.UPDATE_LOGIN_FORM({ pwd: '' })
      this.$refs.pwdPopupRef.showPopup()
    }
  },
  created () {}
}
