<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'normal'
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['click'])

function click (event) {
  if (props.loading || props.disabled) {
    return
  }
  emits('click', event)
}

defineExpose({ click })
</script>

<template>
  <button class="ui-button" :class="[type, { disabled }, 'size-' + size]" @click="click($event)" :disabled="disabled">
    <van-icon v-if="icon && !loading" :name="icon" class="btn-icon"></van-icon>
    <van-loading v-if="loading" :size="20" />
    <slot v-else></slot>
  </button>
</template>

<style lang="less" scoped>
.ui-button {
  width: 100%;
  font-size: var(--dp-text-2xl);
  padding: 0 18px;
  border-radius: var(--dp-b-rd-md);
  background-color: var(--dp-primary);
  min-height: 72px;
  color: var(--dp-text-primary);

  border: 0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.1s ease-in-out;

  &.size-mini {
    min-height: 36px;
    font-size: var(--dp-text-sm);
  }

  &:not(.disabled):active {
    background-color: var(--dp-secondary);
  }

  &.cancel {
    background-color: var(--dp-btn-cancel);
  }

  &.danger {
    background-color: var(--dp-error-error);
    &:not(.disabled):active {
      background-color: var(--dp-error-primary);
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .btn-icon {
    margin-right: 4px;
    margin-bottom: 2px;
  }
}
</style>
