/*
 * @Author: wenfujie
 * @Date: 2021-04-07 15:41:55
 * @LastEditors: wfj
 * @LastEditTime: 2022-10
 * @Describe: 琐碎的接口
 */

export default {
  markOrder: '/order/ConfirmPay',
  getOrderPayMar: '/order/QueryConfirmPay'
}
