/*
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 暴露给子应用的方法
 */

import { CommonActions } from './common'
import { useHaggleAct } from '@/views/Activity/HaggleAct.js'

const { openHaggleActPaymentPage } = useHaggleAct()
export class ActHaggleActions extends CommonActions {
  // 跳转活动结算页
  toActPaymentPage ({ activity_order_id }) {
    openHaggleActPaymentPage(activity_order_id)
  }
}
