/*
 * @Date: 2023-06
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: qiankun 手动加载微应用
 */
import { loadMicroApp, prefetchApps } from 'qiankun'
import { getSortApps } from '@/qiankun/apps.js'
import { useSendMicroAppTrack } from '@/qiankun/actBusiness/common.js'
import { usePopup } from '@/views/Activity/popup/ActPopup.js'
import { existChildrenElement } from '@/qiankun/utils'
import ActTurntableConfig from '@/qiankun/appsConfig/act-turntable.js'

const microInsMap = {}
const { openPopup } = usePopup()

export class ManualMicroApp {
  name = '';
  appConfig = null;

  constructor (microAppName) {
    this.name = microAppName
    this.appConfig = this.#getMicroAppConfig(microAppName)
  }

  #getMicroAppConfig (microAppName) {
    const { usableLoadMicroApps } = getSortApps()
    return usableLoadMicroApps.find(({ name }) => microAppName === name)
  }

  manualStartApp () {
    const { container, name } = this.appConfig
    const elId = container?.split('#')[1]
    // 容器是否存在子节点
    const existChildEl = existChildrenElement(elId)
    const { registerMountTrack, sendStartMicroAppTrack } =
      useSendMicroAppTrack(name)

    if (!existChildEl) {
      sendStartMicroAppTrack()
      microInsMap[this.name] = loadMicroApp(this.appConfig, {
        sandbox: { experimentalStyleIsolation: true }
      })
      registerMountTrack(microInsMap[this.name])
    }
    openPopup()
  }

  // 预加载微应用
  prefetchMicroApp () {
    const { entry, name } = this.appConfig
    prefetchApps([{ name, entry }])
  }
}

// 导出各个手动微应用实例，供外部唤起、预加载微应用
export function getManualAppInstance () {
  function getTurntableApp () {
    const { name } = ActTurntableConfig
    return new ManualMicroApp(name)
  }

  return { getTurntableApp }
}
