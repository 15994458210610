/*
 * @Date: 2023-09
 * @LastEditors: wfj
 * @LastEditTime: 2023-09
 * @Description: 项目版本更新提示
 */
import { useStoreTool } from './store'
import { handleTrack } from '@/utils'
import { $t } from '@/i18n'

const { showDialog, showLoading } = useStoreTool()

// 版本更新检测和通知
export function listenerVersionUpdate () {
  const genVersionTrackParam = (new_web_version) => ({ new_web_version })
  const noticeDialog = (new_version) => {
    showDialog({
      title: '',
      desc: $t('system.updateVersionTip'),
      tipConfirmCallbak: () => {
        showLoading()
        handleTrack(
          'click_confirm_version_update_btn',
          genVersionTrackParam(new_version)
        )
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    })
  }

  document.body.addEventListener('plugin_web_update_notice', (e) => {
    const { version } = e.detail
    noticeDialog(version)
    handleTrack(
      'show_version_update_notice_dialog',
      genVersionTrackParam(version)
    )
  })
}

// 手动检查版本更新
export function checkVersionUpdate () {
  if (window.pluginWebUpdateNotice_) {
    window.pluginWebUpdateNotice_.checkUpdate()
  }
}
