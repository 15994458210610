/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description:
 */

export default {
  /** 订单 */
  // 获取支付列表
  getPayMethods: '/pay/GetPayTypeInfo',
  // 获取订单信息
  getOrderInfo: '/cart/GetSettlementInfo',
  // 获取活动订单信息
  getActOrderInfo: '/activityOrder/GetActivityOrderInfo',
  // 创建订单
  createOrder: '/order/CartCreate',
  // 查询是否存在待支付订单
  searchLastOrder: '/order/QueryLastOrderState',
  // 获取国家列表
  getCountrys: '/pay/GetPayTypeCountry',
  // 取消待支付订单
  cancelLastOrder: '/order/CancelLastOrder',
  // 通知支付中心
  informPayCenter: '/order/Validate',
  // 存储支付卡号
  storeCard: '/user/StoredPayTypeSource',
  // 获取已存储卡号列表
  getStoreCard: '/user/GetStoredPayTypeSource',
  // 删除已存储卡号
  deleteStoreCard: '/user/UnbindCard',
  // 通用 - 获取授权绑卡信息
  getConsentInfo: '/user/GetPaymentConsentInfo'
  // 通用 - 渠道绑卡信息与用户关联
  // bindConsentInfo: '/user/CreatePaymentConsentInfo'
}
