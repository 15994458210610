/*
 * @Date: 2022-09
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-04-12 17:17:48
 * @Description: PayForm.vue 代码过长，逻辑拆分到该js
 */
import { productTransformData } from '@/utils/business/product.js'
import { handleTrack, Base64, getVueVm, getVuexInstance } from '@/utils'
import { PAYMENT_PAGE_MAP } from '@/store/modules/payment.js'
import { isLogin } from '@/utils/business/user.js'
import { useOnlyPaymentPageMode } from '@/hooks/useShoppingMode'

const { isOnlyPaymentPageMode } = useOnlyPaymentPageMode()
export default class PayFormTool {
  // 发送【获取推荐礼包】埋点
  static sendRecommendGroupTrack (recommendGroup) {
    if (!recommendGroup?.length) return

    const data = recommendGroup.map((group) => {
      const { products, id, name } = group
      const productsTransform = productTransformData(products, {
        returnFormat: 'json'
      })
      return { id, name, products: productsTransform }
    })

    const recommend_products = JSON.stringify(data)
    handleTrack('get_recommend_group', { recommend_products })
  }
}

// 地址透传 business 的处理
// 使用说明文档：https://xmdc.yuque.com/staff-nt39y2/ulet29/yllel5#T8kaO
export function useHandleUrlBusiness () {
  // 业务类型
  const BUSINESS_TYPE_MAP = {
    callPaymentPage: 'callPaymentPage',
    routerJump: 'routerJump'
  }
  // 需显示全屏loading的业务类型
  const NEED_LOADING_TYPE = [BUSINESS_TYPE_MAP.routerJump]

  // 返回所有类型 string[]
  function getTypes () {
    return Object.keys(BUSINESS_TYPE_MAP).map((key) => BUSINESS_TYPE_MAP[key])
  }

  // 根据 url 中 business 处理业务
  function handleUrlBusinessParam (query) {
    const businessInfo = parseBusiness(query.business)
    if (!businessInfo) return

    businessStrategy(businessInfo, query)
  }

  // url 的 business 处理策略
  function businessStrategy (businessInfo, query) {
    const _store = getVuexInstance()
    const { business_type } = businessInfo
    // business_type 业务策略
    const HANDLE_BUSINESS_MAP = {
      // 唤起支付页
      [BUSINESS_TYPE_MAP.callPaymentPage]: () =>
        toSettlementGoods(businessInfo, query),
      [BUSINESS_TYPE_MAP.routerJump]: () => handleRouterJump(businessInfo)
    }

    const handleFn = HANDLE_BUSINESS_MAP[business_type]
    handleFn && handleFn()
    // 依据业务关闭，关闭全屏loading
    if (NEED_LOADING_TYPE.includes(business_type) && _store) {
      // 需延迟，避免看到首页闪一下
      setTimeout(() => {
        _store.dispatch('tool/visibleFullLoading', false)
      }, 100)
    }
  }

  // 解析 url 的 business
  function parseBusiness (urlBusiness) {
    if (!urlBusiness) return null
    try {
      return Base64.decode(urlBusiness)
    } catch (error) {
      return null
    }
  }

  // 唤起结算页
  async function toSettlementGoods (businessInfo, query) {
    const vm = getVueVm()
    const { products, presentInfo } = businessInfo

    const { channelType } = query
    await vm.$store.dispatch('payment/goPaymentPage', {
      pageType: PAYMENT_PAGE_MAP.create,
      query: {
        products: Base64.encode(products),
        presentInfo: presentInfo ? Base64.encode(presentInfo) : undefined,
        channelType
      }
    })
  }

  // 路由跳转
  function handleRouterJump (businessInfo) {
    const vm = getVueVm()
    const { router_path: path } = businessInfo
    if (path && vm) {
      vm.$router.push({ path })
    }
  }

  // 判断业务类型是否存在
  function validateBusinessType (urlBusiness) {
    const { business_type } = parseBusiness(urlBusiness) || {}
    return getTypes().includes(business_type)
  }

  // 是否显示全屏loading
  function validateVisibleFullLoading (urlBusiness) {
    const { business_type } = parseBusiness(urlBusiness) || {}
    if (
      isOnlyPaymentPageMode() &&
      business_type === BUSINESS_TYPE_MAP.callPaymentPage
    ) {
      return true
    }

    return NEED_LOADING_TYPE.includes(business_type)
  }

  // 是否使用免输密码登入
  function validateUseFreePwdBusiness (urlBusiness) {
    const { business_type } = parseBusiness(urlBusiness) || {}
    return [BUSINESS_TYPE_MAP.routerJump].includes(business_type)
  }

  return {
    BUSINESS_TYPE_MAP,
    parseBusiness,
    handleUrlBusinessParam,
    validateBusinessType,
    validateVisibleFullLoading,
    validateUseFreePwdBusiness
  }
}

// 免输密码登入（该种token仅支持部分接口调用，如砍价下单流程）
export function useFreePwdLogin () {
  // 免输密码登入
  async function handleFreePwdLogin () {
    const _store = getVuexInstance()
    if (!_store) return

    await _store.dispatch('user/getUserInfo', { is_not_verify_password: true })
  }

  // 清除免输密码token，避免接口报错
  function validateClearFreePwdToken () {
    const _store = getVuexInstance()
    if (!_store) return
    const is_not_verify_password = _store.state.user.is_not_verify_password
    const updateData = {}

    if (is_not_verify_password) {
      updateData.is_not_verify_password = false
      if (isLogin()) {
        updateData.userInfo = {}
      }
      _store.commit('user/UPDATE_STATE', updateData)
    }
  }

  return { handleFreePwdLogin, validateClearFreePwdToken }
}
