/*
 * @Date: 2021-06-23 14:31:04
 * @LastEditors: wfj
 * @LastEditTime: 2023-03
 * @FilePath: /dc-pay-front/src/i18n/index.js
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LanguageOperator } from '@/utils/business.js'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', false, /index\.json/)
  return locales('./index.json')
}

const lang = LanguageOperator.getLangOnLocal()

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: lang,
  messages: loadLocaleMessages()
})

// 封装js文件调用方法
export function $t (...args) {
  // eslint-disable-next-line no-useless-call
  return i18n.t.call(i18n, ...args)
}

export default i18n
