<!--
 * @Date: 2022-05-31 11:54:24
 * @LastEditors: wfj
 * @LastEditTime: 2023-09
 * @FilePath: /dc-pay-front/src/views/Payment/mixins/MixinMayiPay.vue
-->

<script>
import { UrlScheme } from '@/utils/index.js'
import { mapActions } from 'vuex'

export default {
  components: {},
  data () {
    return {}
  },
  methods: {
    ...mapActions('tool', ['showDialog']),

    // 唤醒蚂蚁支付
    showMayiPayPage (order_info) {
      const { attachment } = order_info
      const { redirect_url, method } = attachment

      switch (method) {
        case 'GET':
          if (UrlScheme.isSchemeUrl(redirect_url)) {
            this.openSchemeUrl(redirect_url, order_info)
          } else {
            order_info.pay_url = redirect_url
            this.showPayPageByUrl(order_info)
          }
          break
        case 'POST':
          this.postMayiPay(order_info)
          break
      }
    },
    openSchemeUrl (url, order_info) {
      const { attachment, pay_id } = order_info
      const { order_id } = attachment
      this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)
      this.$toast('The app is being activated..')
      window.location.href = url
      // 使用iframe打开存在兼容问题
      // UrlScheme.openSchemeUrl(url, onBeforeWakeup, onWakeupFail)
    },
    postMayiPay (order_info) {
      const { attachment, pay_id } = order_info
      const { redirect_url, parameters, order_id } = attachment

      if (redirect_url) {
        this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)
        this.showLoading()
        Apis.postPayToMayi(redirect_url, parameters).finally(() =>
          this.closeLoading()
        )
      } else {
        this.submitTrack('use_third_pay_err', pay_id, 305, order_id)
      }
    },
    // 创建订单 - 蚂蚁
    handleSubmitByMayi () {
      const channelInfo = {
        terminal_type: '',
        os_type: ''
      }
      if (this.isMobile) {
        channelInfo.terminal_type = 'WAP'
        channelInfo.os_type = this.isiOS ? 'IOS' : 'ANDROID'
      } else {
        channelInfo.terminal_type = 'WEB'
        delete channelInfo.os_type
      }

      const data = this.genCreateOrderParams(channelInfo)

      return this.useCreateOrderApi(data)
    }
  },
  created () {}
}
</script>
