<!--
 * @Date: 2022-07-06 15:41:17
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-09-18 10:28:58
 * @FilePath: /dc-pay-front/src/views/Payment/mixins/MixinPaypalPay.vue
-->

<script>
import { loadScript } from '@paypal/paypal-js'
import { useRetryFn } from '@/utils/index.js'
import { mapActions } from 'vuex'
import { is_debug_order } from '@/utils/business.js'

export const renderEleId = 'paypal-container'
export default {
  components: {},
  data () {
    return {
      showPaypalView: false // 显示支付视图
    }
  },
  methods: {
    ...mapActions('tool', ['showLoading', 'closeLoading']),

    onclosedPaypalPop () {
      const ele = document.querySelector(`#${renderEleId}`)
      ele.innerHTML = ''
    },
    /**
     * @description: 通知服务端支付成功，若失败需重试
     * @param {*} order_id
     */
    informServer (order_id) {
      const { id: country } = this.currCountryItem
      return Apis.informPayCenter({ order_id, country })
    },
    async showPaypalPayPage (order_info) {
      const { currency, attachment, pay_id } = order_info
      const { id, order_id, client_key: clientId } = attachment

      const debug = is_debug_order()
      let paypal

      this.showLoading()
      try {
        paypal = await loadScript({ 'client-id': clientId, currency, debug })
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }

      if (paypal) {
        try {
          await paypal
            .Buttons({
              style: {
                color: 'blue'
              },
              createOrder: (data, actions) => id,
              onApprove: async (data, actions) => {
                this.showPaypalView = false
                this.submitTrack('pay_suc', pay_id, 0, order_id)
                await useRetryFn(() => this.informServer(order_id), 1, 3000)
                this.$toast(this.$t('form.paySuccessTip'))
                this.timer = setTimeout(this.afterPaySucStep, 3000)
                this.$once(
                  'hook:beforeDestroy',
                  () => this.timer && clearTimeout(this.timer)
                )
              },
              onError: () => {
                this.$toast(this.$t('form.payErrorTip'))
                /**
                 * 三方文档（https://developer.paypal.com/sdk/js/reference/#onerror）
                 *文档注明此时的错误不区分类型，为包罗万象的错误即303
                 */
                this.submitTrack('use_third_pay_err', pay_id, 303)
              }
            })
            .render(`#${renderEleId}`)
          this.closeLoading()
          this.showPaypalView = true
        } catch (error) {
          console.error('failed to render the PayPal Buttons', error)
        }
      } else {
        this.closeLoading()
      }
    }
  },
  created () {}
}
</script>
<style scoped lang="less"></style>
