import { getVersionDetailInfo } from '@/utils/versionHelper'
import { urlConfig } from '@/utils/globalData'
/**
 * 无购物车模式
 * @desc:
 *  无购物车页面，仅支持选中单个商品结算
 * @如何开启 src/utils/globalData.js 中 versions 的 withoutShoppingCartMode
 */

/** 唤起结算页模式
 * @desc: 游戏端直接唤起结算页，其他页面不允许访问
  1.结算页不显示返回按钮；
  2.各渠道支付后不重定向首页；
  3.修改路由时，重定向到404页；
  4.自动唤起结算页业务，需显示loading（不让用户看到首页）
 * @如何开启 src/utils/globalData.js 中 versions 的 onlyPaymentPageMode
 */
export function useOnlyPaymentPageMode () {
  // 是否启用模式
  function isOnlyPaymentPageMode () {
    const { onlyPaymentPageMode } = getVersionDetailInfo()
    return !!onlyPaymentPageMode
  }

  // 唤起结算页模式下，返回支付成功重定向地址（非该模式返回''）
  function getRedirectUrlIfPaymentPageMode () {
    if (isOnlyPaymentPageMode()) return urlConfig.redirect.successPageUrl
    return ''
  }

  // 是否拦截到404页
  function interceptTo404 (path) {
    if (!isOnlyPaymentPageMode()) return false
    const whiteListRouter = ['/', '/payment', '/paySuccess', '/404']
    return !whiteListRouter.includes(path)
  }

  return {
    isOnlyPaymentPageMode,
    interceptTo404,
    getRedirectUrlIfPaymentPageMode
  }
}
