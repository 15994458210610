<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-03
 * @Description: 优惠券领取弹窗
-->

<template>
  <popup getContainer="body" v-model="presenterData.showDialog" v-on="$listeners">
    <div class="content">
      <div class="flex-vbox content-child">
        <p class="title">{{ $t("coupon.surprised") }}</p>
        <ul>
          <li v-for="item in presenterData.coupons" :key="item.role_coupon_id">
            <div class="li-cont">
              <span>{{ $t("coupon.getOneCoupon") }}</span>
              <span>{{ item.coupon_name }}</span>
            </div>
          </li>
        </ul>
        <p class="desc">{{ $t("coupon.getPopupDesc") }}</p>

        <div class="flex-between btn-group">
          <!-- 去看看 -->
          <van-button round type="info" @click="clickToViewBtn">{{
            $t("coupon.toViewBtn")
          }}</van-button>
          <!-- 立即使用 -->
          <van-button round type="info" @click="clickUseBtn">{{
            $t("coupon.useRightNow")
          }}</van-button>
        </div>
      </div>

      <div class="divider"></div>
    </div>
  </popup>
</template>

<script setup>
import Popup from '@/components/Popup.vue'
import Presenter, { presenterData } from './presenter.js'
import { JUMP_FROM } from '@/views/Coupon/index.js'
import { getCurrentInstance, onMounted } from 'vue'

let _this = null
const presenterVm = new Presenter()

onMounted(() => {
  const { proxy } = getCurrentInstance()
  _this = proxy
})
const showPwdPopup = () => {
  setTimeout(() => {
    _this.$store.dispatch('ifShowPwdTip', { touch_type: 'receiveCoupon' })
  }, 500)
}
const clickToViewBtn = () => {
  presenterVm.showDialog(false)
  const paramStr = `?from=${JUMP_FROM.obtainCoupon}`
  _this.$router.push(`/coupon${paramStr}`)
}
const clickUseBtn = () => {
  presenterData.showDialog = false
  showPwdPopup()
}
</script>

<style lang="less" scoped>
@import url("~@/styles/mixin.less");
.content {
  .cust-bg(602px, 755px,"../assets/coupon/presenter_dialog_bg.png");
  margin: 0 auto;
  padding: 100px 10px 10px;
  font-size: var(--dp-text-md);
  .content-child {
    height: 100%;
    padding: 100px 45px 60px;
    .title {
      color: #333972;
      font-size: 60px;
      font-weight: bold;
    }
    ul {
      // height: 176px;
      margin: 28px 0;
      overflow: auto;
      flex: 1;
      li {
        margin-bottom: 10px;
        .li-cont {
          display: inline-block;
          background: #fff;
          border-radius: 24px;
          line-height: 48px;
          padding: 0 15px;
          &:last-child {
            margin-bottom: 0;
          }

          span:nth-child(2) {
            color: #ff4266;
            margin-left: 8px;
          }
        }
      }
    }
    .desc {
      color: #323333;
      font-size: 20px;
      line-height: 1.5;
    }
    .btn-group {
      margin-top: 42px;
      .van-button {
        height: 72px;
      }
      .van-button:first-child {
        background-color: #ffbb2a;
        border: #ffbb2a;
      }
      .van-button:nth-child(2) {
        background-color: #ff3254;
        border: #ff3254;
        width: 288px;
      }
    }
  }
}
.divider {
  height: 1000px;
  width: 1px;
  border-left: 1px solid #fff59a;
  position: absolute;
  left: 50%;
  bottom: 630px;
}
</style>
