<!--
 * @Date: 2022-04-26 14:43:53
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-07-05 16:37:59
 * @FilePath: /dc-pay-front/src/views/Home/popup/PropDetailPopup.vue
-->

<template>
  <!-- @click.stop 避免点击弹窗后，点击事件冒泡到父组件导致选中购买项 -->
  <div @click.stop="">
    <van-popup v-model="show" get-container="body" class="prop-detail-popup">
      <div class="prop-view">
        <!-- <div class="triangle position-left"></div> -->
        <!-- <div class="triangle position-right"></div> -->
        <div class="product-img-bg img-container">
          <van-image :src="propItem.image_url" class="img-container__logo" />
        </div>
        <p class="name">{{ propItem.item_name }}</p>
        <p class="count">{{ `*${propItem.count}` }}</p>
        <p class="source" v-show="propItem.item_usage">
          {{ `【${$t("popup.effect")}】${propItem.item_usage}` }}
        </p>

        <p class="usage" v-show="propItem.item_source">
          {{ `【${$t("popup.source")}】${propItem.item_source}` }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      show: false
    }
  },
  props: {
    propItem: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    showPopup (isShow = true) {
      this.show = isShow
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
@imgWidth: 106px;
.prop-detail-popup {
  border-radius: var(--dp-b-rd-md);
  background-color: var(--dp-bg-secondary);
}
.prop-view {
  padding: 36px;
  width: 440px;
  box-sizing: border-box;
  position: relative;
  font-size: var(--dp-text-md);
  line-height: 1.32;
  .triangle {
    width: 0;
    height: 0;
    border: 8px solid #3aa6f9;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .position-left {
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .position-right {
    position: absolute;
    right: 5px;
    top: 5px;
    transform: rotateY(180deg);
  }
  .img-container {
    width: 88px;
    height: 88px;
    margin: 0 auto;
    margin-top: 16px;
    border-radius: 50%;
    background-color: #20254F;
    border: 2px solid #304178;
    .img-container__logo {
      width: 77px;
      transform: translate(-50%, -50%);
      margin-left: 50%;
      margin-top: 50%;
    }
  }
  .name {
    color: var(--dp-text-primary);
    text-align: center;
    padding-top: 10px;
  }
  .font-style {
    font-size: var(--dp-text-sm);
    color: var(--dp-text-tertiary);
    margin-top: 16px;
  }
  .count {
    color: var(--dp-warning-primary);
    text-align: center;
  }
  .source {
    .font-style();
  }
  .usage {
    .font-style();
  }
}
</style>
