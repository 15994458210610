<!--
 * @Date: 2022-03-05 10:29:13
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-05-31 11:43:56
 * @FilePath: /dc-pay-front/src/views/Payment/popup/WechatQRcodePopup.vue
-->
<template>
  <div>
    <van-popup v-model="show" :lazy-render="false" round closeable>
      <div ref="qrcodeRef" style="padding: .5rem;"></div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  components: {},
  data () {
    return {
      show: false,
      // qrcode 实例
      qrcodeInstance: null
    }
  },
  methods: {
    // 初始化或更新二维码
    initOrUpdateQRcode (code_url) {
      if (this.qrcodeInstance) {
        // 存在实例
        this.qrcodeInstance.clear()
        this.qrcodeInstance.makeCode(code_url)
      } else {
        // 不存在实例
        const element = this.$refs.qrcodeRef
        this.qrcodeInstance = new QRCode(element, code_url)
      }
    },
    showPopup (isShow = true) {
      this.show = isShow
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
/deep/ .van-popup__close-icon--top-right {
  top: 0.1rem;
  right: 0.1rem;
}
</style>
