/*
 * @Date: 2023-08
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description: steam 授权
 */
import { useThirdLoginCommon } from '@/views/User/ThirdPartyLogin'
import { THIRD_PARTY_LOGIN_TYPE } from '@/utils/globalData.js'
import { getVueVm } from '@/utils'

// 授权逻辑
export function useSteamAuth () {
  const getRoute = () => getVueVm().$route
  // 是否为steam授权
  const getIsSteamLogin = () => {
    const currentRoute = getRoute()
    return !!currentRoute.query && !!currentRoute.query['openid.identity']
  }

  // 获取steamid（根据地址信息）
  const getSteamID = () => {
    const currentRoute = getRoute()
    const IdentityResponseRegExp =
      /^https:\/\/steamcommunity\.com\/openid\/id\/(\d+)$/
    const identity = currentRoute.query['openid.identity']
    const result = IdentityResponseRegExp.exec(identity)
    if (result && result.length >= 1) {
      return result[1]
    }
    return ''
  }

  /**
   * 返回steam授权地址（目的：获得用户的 SteamID）
   * @link https://zhuanlan.zhihu.com/p/631902685?utm_id=0
   */
  const createGetSteamIDURL = (redirectURL) => {
    const params = {
      'openid.ns': 'http://specs.openid.net/auth/2.0',
      // cSpell: ignore checkid
      'openid.mode': 'checkid_setup',
      'openid.return_to': redirectURL, // 你的回调地址
      'openid.realm': redirectURL, // 你的回调地址（可以和return_to相同）
      'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
      'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select'
    }
    const searchParams = new URLSearchParams()
    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value)
    }
    return `https://steamcommunity.com/openid/login?${searchParams.toString()}`
  }

  // 返回steam授权后重定向地址
  function getSteamRedirectURL (sourceURL) {
    const url = new URL(sourceURL)
    // hash: #/payment?order_id=xxx&products=xxx
    const { origin, pathname, hash } = url
    const [hashPath] = hash.split('?')
    return `${origin}${pathname}${hashPath}`
  }

  function redirectAuth () {
    const redirectURL = getSteamRedirectURL(location.href)
    location.href = createGetSteamIDURL(redirectURL)
  }

  return { getIsSteamLogin, getSteamID, redirectAuth }
}

// 登入状态及缓存信息
export function useSteamLoginStatus () {
  const { commonAfterLogin } = useThirdLoginCommon()

  // 登入后处理
  function afterLoginSuccess (steamId) {
    commonAfterLogin(THIRD_PARTY_LOGIN_TYPE.steam, steamId)
  }

  // 登出
  function logout () {}

  return { logout, afterLoginSuccess }
}
