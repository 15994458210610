import { userAgent } from '@/utils'
const subPayId = {
  tinkoffPay: '10259003'
}
export default {
  methods: {
    getDukPayChannelParams () {
      const { mobile, isiOS, isAndroid } = userAgent()
      const device = mobile ? 'mobile' : 'pc'
      const OSMap = {
        true: 'Windows',
        [isiOS]: 'iOS',
        [isAndroid]: 'Android'
      }
      const os = OSMap.true
      return { device, os }
    },
    // 二维码支付
    showQRCodePopup (order_info) {
      const { pay_id, attachment } = order_info
      const { order_id, qrBase64 } = attachment
      const { showPopup, updateQRcode } = this.$refs.dukPayRef

      if (!qrBase64) {
        this.submitTrack('use_third_pay_err', pay_id, 305, order_id)
        return
      }

      updateQRcode(qrBase64)
      this.submitTrack('use_third_pay_suc', pay_id, 0, order_id)
      this.$nextTick(() => {
        showPopup()
      })
    },
    showDukPayPage (order_info) {
      const { id } = this.currPayMethodItem
      const { mobile } = userAgent()
      // 和渠道确认，tinkoffPay && pc 不支持url跳转
      if (id === subPayId.tinkoffPay && !mobile) {
        this.showQRCodePopup(order_info)
      } else {
        this.showPayPageByUrl(order_info)
      }
    }
  }
}
