<!--
 * @Date: 2022-06-07 10:01:42
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-08-25 15:55:14
 * @FilePath: /dc-pay-front/src/components/CartEntrance.vue
-->
<template>
  <div class="flex-vbox flex-vcenter">
    <van-badge :content="cartLength">
      <van-image
        class="cart-logo"
        :src="IconCart"
        @click="handleClick"
      />
    </van-badge>
    <p class="gold">{{ viewTotalAmount }}</p>
  </div>
</template>

<script>
import { Badge } from 'vant'
import { mapGetters, mapState } from 'vuex'
import IconCart from '@/assets/icon/icon_cart.svg'

export default {
  components: { [Badge.name]: Badge },
  data () {
    return {
      IconCart
    }
  },
  computed: {
    ...mapState('cart', ['cartLength']),
    ...mapGetters('cart', ['viewTotalAmount'])
  },
  methods: {
    handleClick () {
      this.$router.push('/cart')
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
/deep/ .van-badge {
  background-color: var(--dp-error-secondary);
  font-size: var(--dp-text-sm);
  min-width: var(--dp-sp-2xl);
}
/deep/.van-badge--fixed {
  top: 12px !important;
  right: 12px !important;
}
.cart-logo {
  width: var(--dp-icon-md);
  height: var(--dp-icon-md);
  border-radius: 50%;
}
.gold {
  margin-top: var(--dp-sp-xs);
  color: var(--dp-warning-primary);
  line-height: 1.23;
  font-size: var(--dp-text-sm);
  max-width: 90px;
  text-align: center;
}
</style>
