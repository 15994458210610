/*
 * @Date: 2023-08
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description:
 */
import { ref } from 'vue'
import { getVersionDetailInfo } from '@/utils/business.js'
import { THIRD_PARTY_LOGIN_TYPE } from '@/utils/globalData.js'
import { useSaveLocalThirdInfo } from '.'

const { thirdPartyLogin = [] } = getVersionDetailInfo()
const { getLocalThirdId } = useSaveLocalThirdInfo()

// 是否显示登入弹窗
export function useValidateVisible () {
  /**
   * @description: 版本下是否存在登入类型
   * @param {THIRD_PARTY_LOGIN_TYPE} loginType
   * @return {boolean}
   */
  const validateExistLoginType = (loginType) => {
    return thirdPartyLogin.includes(loginType)
  }

  // 是否显示steam登入
  function validateVisibleSteamLogin () {
    return validateExistLoginType(THIRD_PARTY_LOGIN_TYPE.steam)
  }

  // 是否显示登入弹窗
  function validateVisiblePopup () {
    if (validateVisibleSteamLogin() && !getLocalThirdId()) {
      return true
    }
    return false
  }

  return { validateVisiblePopup, validateVisibleSteamLogin }
}

// 控制弹窗
export function usePopup () {
  const visible = ref(false)
  const showPopup = (isShow = true) => (visible.value = isShow)
  return { visible, showPopup }
}
