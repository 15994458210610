<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
-->
<template>
  <div :class="['main-img', 'flex-center', { pink: discountInfo.isDiscount }]">
    <!-- 折扣券 -->
    <div v-if="discountInfo.isDiscount">
      <!-- 中文版 -->
      <div v-if="isChinese" class="flex-vcenter">
        <p class="cn-discount-num">{{ discountInfo.cnDiscount }}</p>
        <p class="cn-discount-unit">折</p>
      </div>
      <!-- 英文版 -->
      <div v-else class="flex-vcenter">
        <p class="discount-num">{{ discountInfo.enDiscount }}</p>
        <div class="discount-unit">
          <p>%</p>
          <p>OFF</p>
        </div>
      </div>
    </div>

    <!-- 优惠券 -->
    <div v-else>
      <p class="pull-num" :style="pullNumStyle" v-priceFormat>
        {{ item?.full_decrement?.amount }}
      </p>
      <p class="pull-unit">{{ item.currency }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { LanguageOperator } from '@/utils/business.js'
import { parseFloatNum } from '@/utils/business/product.js'

const isChinese = LanguageOperator.isChinese()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
const discountInfo = computed(() => {
  const { coupon_type, discount_decrement } = props?.item || {}
  const { discount } = discount_decrement || {}
  const isDiscount = coupon_type === '1'

  const enDiscount = isDiscount ? parseFloatNum(100 * (1 - discount)) : 0
  const cnDiscount = isDiscount ? parseFloatNum(10 * discount) : 0
  return { enDiscount, isDiscount, cnDiscount }
})

// 适当缩小字体大小，避免大数字无法完整显示
const pullNumStyle = computed(() => {
  const { amount } = props?.item?.full_decrement || {}
  if (String(amount).length >= 5) {
    return { fontSize: '0.42rem' }
  }
  return {}
})
</script>

<style lang="less" scoped>
@import url("~@/styles/mixin.less");

.main-img {
  color: #fff;
  .cust-bg(238px, 186px,"../assets/coupon/coupon_blue.png");
  &.pink {
    background-image: url("../../../assets/coupon/coupon_pink.png");
  }
}
.cn-discount-num,
.discount-num {
  font-size: 78px;
  font-weight: bold;
}
.cn-discount-unit,
.discount-unit {
  font-size: 30px;
  font-weight: bold;
}
.cn-discount-unit {
  margin: 15px 0 0 10px;
}
.pull-num {
  font-size: 72px;
  font-weight: bold;
}
.pull-unit {
  text-align: right;
  font-size: 30px;
  font-weight: bold;
}
</style>
