/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description:
 */
import { mode } from 'crypto-js'
import { encrypt, decrypt } from 'crypto-js/aes'
import UTF8, { parse } from 'crypto-js/enc-utf8'
import pkcs7 from 'crypto-js/pad-pkcs7'
import ECB from 'crypto-js/mode-ecb'
import Base64 from 'crypto-js/enc-base64'
import md5 from 'crypto-js/md5'

const cacheCipher = {
  key: '_11111000001111@',
  iv: '@11111000001111_'
}
const microAppCipher = {
  key: 'AHNNJWGZXSGVKOERNZHAUSUNNBKMQNAQ',
  iv: 'AHNNJWGZXSGVKOER'
}

export class AesEncryption {
  key;
  iv;
  mode;

  constructor (opt = { key: cacheCipher.key, iv: cacheCipher.iv, mode: ECB }) {
    const { key, iv, mode } = opt
    if (key) {
      this.key = parse(key)
    }
    if (iv) {
      this.iv = parse(iv)
    }
    if (mode) {
      this.mode = mode
    }
  }

  getOptions () {
    return {
      mode: this.mode,
      padding: pkcs7,
      iv: this.iv
    }
  }

  encryptByAES (cipherText) {
    return encrypt(cipherText, this.key, this.getOptions()).toString()
  }

  decryptByAES (cipherText) {
    return decrypt(cipherText, this.key, this.getOptions()).toString(UTF8)
  }
}

export const encryption = new AesEncryption()

export const microAppEncryption = new AesEncryption({
  ...microAppCipher,
  mode: mode.CBC
})

// base64加密（与window.btoa结果不同，涉及外部使用需用该方法）
export function encryptByBase64 (cipherText) {
  return UTF8.parse(cipherText).toString(Base64)
}

export function decodeByBase64 (cipherText) {
  return Base64.parse(cipherText).toString(UTF8)
}

export function encryptByMd5 (cipherText) {
  return md5(cipherText).toString()
}
