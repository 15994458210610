/*
 * @Date: 2023-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-10
 * @Description:
 */
import { getVersionDetailInfo } from '@/utils/business.js'

const { withoutShoppingCartMode } = getVersionDetailInfo()

// 是否无购物车模式
const isWithoutShoppingCartMode = () => !!withoutShoppingCartMode

export { isWithoutShoppingCartMode }
