/*
 * @Date: 2023-05
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 各个活动通用业务
 */
import { getVuexInstance } from '@/utils'
import { MicroAppTrack } from '@/qiankun/actBusiness/track.js'

export const STATIC_STATE = {
  manualMountElId: 'manual-load-micro-app-container',
  registerMountElId: 'register-load-micro-app-container'
}

// 手动启动微应用的埋点逻辑
export function useSendMicroAppTrack (microAppName) {
  const trackIns = new MicroAppTrack({ microAppName })

  // 开始加载埋点
  function sendStartMicroAppTrack () {
    trackIns.startTrack()
  }

  /**
   * @description: 子应用挂载生命周期埋点
   * @param {obj} microApp 微应用实例
   */
  function registerMountTrack (microApp) {
    const _store = getVuexInstance()

    _store.dispatch('tool/showLoading')
    microApp.loadPromise.then(() => trackIns.loadTrack())
    microApp.bootstrapPromise.then(() => trackIns.bootstrapTrack())
    microApp.mountPromise.then(() => {
      trackIns.mountTrack()
      _store.dispatch('tool/closeLoading')
    })

    Promise.all([
      microApp.loadPromise,
      microApp.bootstrapPromise,
      microApp.mountPromise
    ]).catch(() => {
      _store.dispatch('tool/closeLoading')
      trackIns.loadFailedTrack({ mount_status: microApp.getStatus() })
    })
  }

  return { registerMountTrack, sendStartMicroAppTrack }
}
