/* eslint-disable no-dupe-class-members */
/*
 * @Date: 2023-03
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */
import {
  CacheUserForm,
  getCurrGameConfig,
  LanguageOperator
} from '@/utils/business.js'
import {
  getVuexInstance,
  encodeValue,
  convertObjToUrlParam,
  getVueVm,
  getURLHash
} from '@/utils'
import { microAppEncryption } from '@/utils/cipher.js'
import { ENV_DATA } from '@/utils/globalData.js'
import { reactive, toRefs } from 'vue'

const { getRsaEncryptLoginData, getAesEncryptLoginData } = useEncryptActData()

// 处理跳转活动、唤起微应用所需登入数据
export function useEncryptActData () {
  const { activityConfig } = getCurrGameConfig() || {}
  const { gameId: game_id, rsaPublicKey } = activityConfig || {}

  function rsaEncryptActToken (data) {
    const encodeResult = encodeValue(JSON.stringify(data), rsaPublicKey)
    return encodeURIComponent(encodeResult)
  }

  function aesEncryptActToken (data) {
    return microAppEncryption.encryptByAES(data)
  }

  function getUserInfo () {
    const _store = getVuexInstance()
    const { logical_region_id } = CacheUserForm.getLocalUserForm() || {}
    const { server_id, user_id: show_id } =
      _store?.state?.user?.loginForm || {}
    const {
      role_id,
      name: role_nick_name,
      user_id
    } = _store?.state?.user.userInfo || {}

    return {
      user_id,
      role_id,
      role_nick_name,
      logical_region_id: parseInt(logical_region_id),
      server_id: parseInt(server_id),
      show_id: parseInt(show_id)
    }
  }

  function getTokenData () {
    const userInfo = getUserInfo()

    return {
      ...userInfo,
      game_id,
      timestamp: +new Date()
    }
  }

  // rsa加密内容有长度限制，不宜过长
  function getRsaTokenData () {
    const tokenData = getTokenData()
    const keys = [
      'game_id',
      'timestamp',
      'logical_region_id',
      'server_id',
      'show_id'
    ]
    const handler = (result, key) => {
      result[key] = tokenData[key]
      return result
    }

    return keys.reduce(handler, {})
  }

  // 返回rsa加密的token及活动数据
  function getRsaEncryptLoginData () {
    const tokenData = getRsaTokenData()
    const token = rsaEncryptActToken(tokenData)
    const lang = LanguageOperator.getLangOnLocal()
    const { logical_region_id: logicId } = getUserInfo()

    return { token, logicId, encrypt: 'rsa256', lang }
  }

  // 返回aes加密的token及活动数据
  function getAesEncryptLoginData () {
    const tokenData = getTokenData()
    const token = aesEncryptActToken(JSON.stringify(tokenData))
    const lang = LanguageOperator.getLangOnLocal()
    const { logical_region_id: logicId } = getUserInfo()

    return { token, logicId, encrypt: 'aes-cbc', lang }
  }

  return { getRsaEncryptLoginData, getAesEncryptLoginData }
}

export class ActRedirect {
  actUrl = '';
  // 具体值见当前类的 redirect 方法
  loginType = '';

  constructor (actUrl, loginType) {
    this.actUrl = actUrl
    this.loginType = loginType
  }

  // 执行活动跳转
  redirect () {
    if (!this.actUrl) return
    const FN_MAP = {
      'aes-cbc': () => this.#openAesParamLink(),
      rsa256: () => this.#openRsaParamLink(), // 累充活动、周全额活动
      'micro-app_aes-cbc': () => this.#openMicroApp() // 砍价活动
    }
    const fn = FN_MAP[this.loginType]
    fn && fn()
  }

  #openRsaParamLink () {
    location.href =
      this.actUrl + convertObjToUrlParam(getRsaEncryptLoginData())
  }

  #openAesParamLink () {
    location.href =
      this.actUrl + convertObjToUrlParam(getAesEncryptLoginData())
  }

  #openMicroApp () {
    const { actUrl } = this
    const { origin, pathname } = location
    const hash = getURLHash(actUrl)
    const vm = getVueVm()
    const isSameOrigin = actUrl.includes(origin + pathname)
    const { isDev } = ENV_DATA

    // 若域名相同，切路由即可
    if ((isSameOrigin || isDev) && vm && hash) {
      vm.$router.push(hash)
    } else {
      location.href = actUrl
    }
  }
}

// 执行活动跳转
export function doActRedirect (activity_url, login_type) {
  const redirectIns = new ActRedirect(activity_url, login_type)
  redirectIns.redirect()
}

const actConfig = reactive({
  // 活动弹窗状态
  popup: [],
  // 活动入口图标状态
  icon: []
})
// 处理活动弹窗、icon状态
export function useAct () {
  async function initActConfig () {
    const { popup, icon } = await Apis.getTopUpActPopupInfo()
    actConfig.popup = Array.isArray(popup) ? popup : [popup]
    actConfig.icon = icon
  }

  return { ...toRefs(actConfig), initActConfig }
}
