/*
 * @Date: 2023-07
 * @LastEditors: wfj
 * @LastEditTime: 2023-09
 * @Description: vuex 封装，以满足在 js 文件下调用 vuex actions
 */
import { getVuexInstance } from '@/utils'

// vuex tool
export function useStoreTool () {
  const showLoading = () => dispatch('tool/showLoading')
  const closeLoading = () => dispatch('tool/closeLoading')
  const closeAllLoading = () => dispatch('tool/closeAllLoading')
  const showDialog = (params) => dispatch('tool/showDialog', params)
  const commitPayment = (...args) =>
    commit('payment/UPDATE_PAYMENT_STATE', ...args)
  const commitUser = (...args) => commit('user/UPDATE_STATE', ...args)
  const commitUserObject = (...args) => commit('user/UPDATE_OBJECT', ...args)

  return {
    showLoading,
    closeLoading,
    closeAllLoading,
    showDialog,
    commitPayment,
    commitUser,
    commitUserObject
  }
}

function dispatch (event, ...args) {
  const _store = getVuexInstance()
  if (!_store) return
  _store.dispatch(event, ...args)
}

function commit (event, ...args) {
  const _store = getVuexInstance()
  if (!_store) return
  _store.commit(event, ...args)
}
