<!--
 * @Date: 2022-03-29 10:30:07
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @FilePath: /dc-pay-front/src/views/Home/NoticeBoard.vue
 * @Describe: 公示栏
-->
<template>
  <div class="notice-main">
    <!-- wsy ko-kr独有 -->
    <div v-if="is_wsy_ko_kr">
      <div class="head">
        <NumberItem
          num="i"
          :diameter="27"
          style="margin-top: 0.05rem"
        ></NumberItem>
        <p class="title">
          웹버전에서 다른 계정으로 충전할 경우 고객센터 처리 안내:
        </p>
      </div>

      <ul class="list">
        <li v-for="(child, childIndex) in wsy_ko_kr_list" :key="childIndex">
          {{ child }}
        </li>
      </ul>
    </div>

    <template v-else>
      <div v-for="(item, index) in notices" :key="index">
        <div class="head">
          <NumberItem
            num="i"
            :fontSize="18"
            :diameter="24"
            style="margin-top: 0.05rem"
          ></NumberItem>
          <p class="title">
            {{ item.title }}
          </p>
        </div>

        <ol class="list">
          <li v-for="(child, childIndex) in item.children" :key="childIndex">
            <div class="li-decimal">{{ childIndex + 1 }}.</div>
            <div>{{ child }}</div>
          </li>
        </ol>
      </div>
    </template>
  </div>
</template>

<script>
import NumberItem from '@/components/NumberItem.vue'
import { gameConfigMap } from '@/utils/globalData.js'
import {
  getVersionByParseUrl,
  isMatchGame,
  LanguageOperator
} from '@/utils/business.js'

export default {
  components: { NumberItem },
  data () {
    return {
      gameConfig: {},
      // 要展示的公告列表
      notices: [],
      wsy_ko_kr_list: [
        '플레이어는 1년간 단 한번만 웹버전 결제오류건에 대하여 변경요청을 진행할 수 있습니다.',
        '잘못된 충전 주문이 충전 캐릭터로 인증된 경우 처리되지 않습니다.',
        '잘못된 충전 주문이 충전 캐릭터로 미인증된 경우, 고객센터에 문의하여 처리해주세요.'
      ]
    }
  },
  computed: {
    is_wsy_ko_kr () {
      return (
        isMatchGame('wsy') && LanguageOperator.getLangOnLocal() === 'ko-kr'
      )
    },
    noticeMessages () {
      const { messages, locale } = this.$i18n
      const noticeBoardTextKey =
        this.gameConfig.noticeBoardTextKey ||
        gameConfigMap.default.noticeBoardTextKey

      return messages[locale]?.static?.[noticeBoardTextKey]
    }
  },
  methods: {
    // 判断游戏版本，返回 KEYS_MAP 索引
    getKeysMapIndex () {
      const wsySpecialVersions = ['wsy-tw', 'wsy-kr', 'wsy-en']
      const dqSpecialVersions = ['dq-tw']
      const { versionName } = this.versionInfo

      switch (true) {
        case wsySpecialVersions.includes(versionName):
          return 'isSpecialWsy'
        case dqSpecialVersions.includes(versionName):
          return 'isSpecialDq'
        case versionName === 'wsy-cn':
          return 'wsy-cn'
        default:
          return 'default'
      }
    },
    // 返回公告翻译文案的keys
    getI18nKeysMap () {
      const KEYS_MAP = {
        default: [
          {
            title: 'title',
            children: ['noticeCont1', 'noticeCont2', 'noticeCont3']
          }
        ],
        isSpecialWsy: [
          {
            title: 'title',
            children: [
              'noticeCont1',
              'noticeCont2',
              'specialNoticeCont3',
              'specialNoticeCont4'
            ]
          }
        ],
        isSpecialDq: [
          {
            title: 'title',
            children: [
              'specialNoticeContDq1',
              'noticeCont2',
              'specialNoticeCont3',
              'specialNoticeCont4',
              'specialNoticeContDq5'
            ]
          }
        ],
        'wsy-cn': [
          {
            title: 'title2'
          },
          {
            title: 'title',
            children: [
              'noticeCont1',
              'noticeCont2',
              'specialNoticeCont3',
              'specialNoticeCont4'
            ]
          }
        ]
      }
      return KEYS_MAP[this.getKeysMapIndex()]
    },
    initNotices () {
      const i18nKeysMap = this.getI18nKeysMap()
      return i18nKeysMap.map((item) => {
        const children = item.children
          ?.map((child) => this.noticeMessages?.[child] || '')
          .filter((child) => !!child)

        return {
          title: this.noticeMessages?.[item.title] || '',
          children
        }
      })
    }
  },
  created () {
    this.versionInfo = getVersionByParseUrl()

    this.gameConfig =
      gameConfigMap[this.versionInfo.gameCode] || gameConfigMap.default

    this.notices = this.initNotices()
  }
}
</script>
<style scoped lang="less">
.notice-main {
  padding: 30px 24px;
  background: var(--dp-bg-secondary);
  border-radius: 8px;
  font-size: 24px;
  line-height: 1.32;
  .head {
    display: flex;
    margin-bottom: 15px;
    .title {
      font-weight: bold;
      color: var(--dp-text-secondary);
      line-height: 36px;
      flex: 1;
      margin-left: var(--dp-sp-md);
    }
  }
}
.list {
  li {
    display: flex;
    align-items: start;
    color: var(--dp-text-tertiary);
    margin-top: 0.5em;
  }
  .li-decimal {
    text-align: end;
    flex: 0 0 var(--dp-text-3xl);
  }
}
</style>
