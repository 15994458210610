/*
 * @Date: 2022-08-31 13:58:03
 * @LastEditors: wenfujie
 */
import { getVersionByParseUrl } from '@/utils/business.js'
var Storage = {
  /**
   * 获取缓存
   * @param key 键名
   * @returns {Object}
   */
  get: function (key) {
    const { versionValue } = getVersionInfo()
    return versionValue[key]
  },
  /**
   * 设置缓存
   * @param key 键名
   * @param object 键值
   */
  set: function (key, object) {
    const { versionName, versionValue } = getVersionInfo()
    versionValue[key] = object
    object = versionValue
    key = versionName

    if (object !== null && object !== undefined) {
      let value = object
      if (typeof value !== 'string') {
        try {
          value = JSON.stringify(object)
        } catch (e) {
          value = null
        }
      }
      if (value !== null) {
        sessionStorage.setItem(key, value)
      }
    }
  }
}

/**
 * @description: 获取版本信息
 * @return {obj} {versionName: 'wsy-tw', versionValue: {}}
 */
function getVersionInfo () {
  const { versionName } = getVersionByParseUrl()
  const versionValue = sessionStorage.getItem(versionName) || '{}'
  return {
    versionName,
    versionValue: JSON.parse(versionValue)
  }
}
export default Storage
