<!--
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2022-10
 * @Description:
-->

<script>
export default {
  methods: {
    showPaymentwallPayPage (order_info) {
      const { attachment } = order_info
      const { url } = attachment

      order_info.pay_url = url
      this.showPayPageByUrl(order_info)
    }
  }
}
</script>
