import Apis from '@/api'
import { is_debug_order } from '@/utils/business.js'

export function useApis () {
  // 获取customer_id和已保存的卡列表（channel_items_json）
  async function getConsentInfo ({ parent_pay_type_id, country, is_test, pay_type_id }) {
    const data = await Apis.getConsentInfo({
      parent_pay_type_id,
      pay_type_id,
      is_test: is_test ?? is_debug_order(),
      country
    })
    // 将 channel_items_json 序列化
    const { channel_items_json } = data
    data.channel_items_json = JSON.parse(channel_items_json || '[]')
    return data
  }

  return { getConsentInfo }
}
