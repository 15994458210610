/**
 *  封装本地缓存(在main.js中注册)
 * Created by wfj on 2021-06-21.
 * @use:
        获取：_localStorage.get('key')
        删除：_localStorage.remove('key')
        普通设值：_localStorage.set('key', { a: 1 })
        带失效时间设值(第三个参数为失效时间{秒})：_localStorage.set('key', { a: 1 }, 100)
 */
import { getVersionByParseUrl } from '@/utils/business.js'
// var STORAGE_EXPIRE_MAP = 'STORAGE_EXPIRE_MAP'
var Storage = {
  /**
   * 获取缓存
   * @param key 键名
   * @returns {Object}
   */
  get: function (key) {
    const { versionValue } = getVersionInfo()
    return versionValue[key]
  },
  /**
   * 设置缓存
   * @param key 键名
   * @param object 键值
   */
  set: function (key, object) {
    const { versionName, versionValue } = getVersionInfo()
    versionValue[key] = object
    object = versionValue
    key = versionName

    if (object !== null && object !== undefined) {
      let value = object
      if (typeof value !== 'string') {
        try {
          value = JSON.stringify(object)
        } catch (e) {
          value = null
        }
      }
      if (value !== null) {
        localStorage.setItem(key, value)
      }
    }
  },
  /**
   * 移除
   * @param key
   */
  remove: function (key) {
    const { versionName, versionValue } = getVersionInfo()
    delete versionValue[key]
    localStorage.setItem(versionName, JSON.stringify(versionValue))
  },
  /**
   * 清理
   */
  clear: function () {
    localStorage.clear()
  }
}

/**
 * @description: 获取版本信息
 * @return {obj} {versionName: 'wsy-tw', versionValue: {}}
 */
function getVersionInfo () {
  const { versionName } = getVersionByParseUrl()
  const versionValue = localStorage.getItem(versionName) || '{}'
  return {
    versionName,
    versionValue: JSON.parse(versionValue)
  }
}

/**
 * 设置过期
 * @param key
 * @param expireAt
 */
// function addExpire (key, expireAt) {
//   var map = Storage.get(STORAGE_EXPIRE_MAP) || {}
//   map[key] = expireAt
//   Storage.set(STORAGE_EXPIRE_MAP, map)
// }

/**
 * 清理过期
 */
// function clearExpire () {
//   var map = Storage.get(STORAGE_EXPIRE_MAP) || {}
//   var now = new Date().getTime()
//   for (var key in map) {
//     if (map[key] * 1 < now) {
//       Storage.remove(key)
//       delete map[key]
//     }
//   }
//   Storage.set(STORAGE_EXPIRE_MAP, map)
// }

/**
 * 过期轮询
 */
// setInterval(function () {
//   clearExpire()
// }, 60000)

export default Storage
