/*
 * @Date: 2023-08
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @Description: 暴露给外部的功能
 */
import { ref } from 'vue'
import User from '@/utils/business/user.js'
import {
  CacheUserForm,
  getVersionDetailInfo,
  clearSessionVuexData
} from '@/utils/business.js'
import { THIRD_PARTY_LOGIN_TYPE } from '@/utils/globalData.js'
import { useSteamLoginStatus } from './components/Steam/useSteam.js'
import { eventBus, handleTrack } from '@/utils'
import { useStoreTool } from '@/utils/business/store.js'

const { logout: steamLogout } = useSteamLoginStatus()
const {
  showLoading,
  closeLoading,
  commitPayment,
  commitUser,
  commitUserObject
} = useStoreTool()
const { commonThirdLogout } = useThirdLoginCommon()
const { region, thirdPartyLogin = [] } = getVersionDetailInfo()
const { saveLocalThirdType, saveLocalThirdId, getLocalThirdId } =
  useSaveLocalThirdInfo()

/**
 * @description: 存储三方登入信息到local
 * 使得重新打开页面仍能缓存
 */
export function useSaveLocalThirdInfo () {
  const typeKey = 'third_type'
  const thirdIdKey = 'third_id'

  const saveLocalThirdId = (id) => {
    CacheUserForm.setLocalUserForm({ [thirdIdKey]: id })
  }

  const getLocalThirdId = () => {
    const { [thirdIdKey]: id } = CacheUserForm.getLocalUserForm() || {}
    return id
  }

  const saveLocalThirdType = (loginType) => {
    CacheUserForm.setLocalUserForm({ [typeKey]: loginType })
  }

  const getLocalLoginType = () => {
    const { [typeKey]: loginType } = CacheUserForm.getLocalUserForm() || {}
    const firstType = thirdPartyLogin[0]
    return loginType || firstType || ''
  }

  return {
    getLocalLoginType,
    saveLocalThirdType,
    getLocalThirdId,
    saveLocalThirdId
  }
}

// 不同登入，相同逻辑封装
export function useThirdLoginCommon () {
  const { getRoleListByLoginType } = useGetRoles()

  // 登入后逻辑
  async function commonAfterLogin (login_type, third_id) {
    handleTrack('third_login_suc', {
      channel: login_type,
      sp_user_id: third_id
    })
    commitUserObject({ thirdLoginInfo: { third_id, third_type: login_type } })
    saveLocalThirdType(login_type)
    saveLocalThirdId(third_id)
    const roles = await getRoleListByLoginType(login_type)
    eventBus.$emit('loadedRoleList', roles)
  }

  // 登出
  function commonThirdLogout () {
    commitPayment({ key: 'server_name', value: '' })
    commitUserObject({
      loginForm: {
        user_id: '',
        server_id: '',
        pwd: ''
      }
    })
    commitUser({
      thirdLoginInfo: {}
    })
    saveLocalThirdId('')
    CacheUserForm.clearUserForm()
    clearSessionVuexData()
    User.logout()
  }

  return { commonAfterLogin, commonThirdLogout }
}

// 角色列表
const roleList = ref([])
export function useGetRoles () {
  // api包装
  async function getRoleList (login_type, third_id) {
    const logical_region_id = region ? region.join('_') : ''
    showLoading()
    const data = await Apis.thirdLogin({
      logical_region_id,
      login_type,
      third_id
    }).finally(() => closeLoading())
    data.map((item) => {
      const { server_id, server_name } = item
      item.id = server_id
      item.name = server_name
      return item
    })
    roleList.value = data
    return data
  }

  // steam 包装
  function getSteamRoleList () {
    return getRoleList(THIRD_PARTY_LOGIN_TYPE.steam, getLocalThirdId())
  }

  // 不同登入 - 获取策略
  function getRoleListByLoginType (login_type) {
    const GET_MAP = {
      [THIRD_PARTY_LOGIN_TYPE.steam]: getSteamRoleList
    }
    const fn = GET_MAP[login_type]
    if (typeof fn === 'function') {
      return fn()
    }
  }

  return { roleList, getRoleListByLoginType }
}

// 不同业务策略
export function useThirdTactics () {
  // 登出
  function logoutThird (login_type) {
    const LOGOUT_MAP = {
      [THIRD_PARTY_LOGIN_TYPE.steam]: () => {
        steamLogout()
        commonThirdLogout()
      }
    }
    const fn = LOGOUT_MAP[login_type]
    if (typeof fn === 'function') {
      return fn()
    }
  }

  function getThirdIdLabel (login_type) {
    const LABEL_MAP = {
      [THIRD_PARTY_LOGIN_TYPE.steam]: 'Steam'
    }
    return LABEL_MAP[login_type]
  }
  return { getThirdIdLabel, logoutThird }
}
