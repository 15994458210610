/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 大转盘活动
 */
import { ref } from 'vue'
import { isLogin } from '@/utils/business/user.js'
import {
  handleGuidePopupState,
  useActGuidePopup
} from '@/views/Activity/popup/GuidePopup.js'
import { handleTrack, getVuexInstance } from '@/utils/index.js'
import { getManualAppInstance } from '@/qiankun/actBusiness/manualLoadMicroApp.js'
import { Toast } from 'vant'
import { $t } from '@/i18n'
import {
  Activity,
  VIEW_POP_LOCAL_KEY,
  ACT_ID_MAP
} from '@/views/Activity/common.js'

// 控制显示活动入口
const showActIcon = ref(false)

class TurntableAct extends Activity {
  constructor ({ actId, lastVisiblePopupLocalKey }) {
    super({ actId, lastVisiblePopupLocalKey })
  }

  isStartActIcon () {
    return !!showActIcon.value
  }
}

export const turntableAct = new TurntableAct({
  actId: ACT_ID_MAP.turntableActId,
  lastVisiblePopupLocalKey: VIEW_POP_LOCAL_KEY.turntableActPopupShowedTime
})

const { setImgUrl } = handleGuidePopupState()
const { showPopup } = useActGuidePopup()
const { getTurntableApp } = getManualAppInstance()

// 大转盘活动 api
export function useTurntableAct () {
  // 获取图片信息
  async function getPopupInfo () {
    const { pop_up_image } = await Apis.getTurntableActPopupInfo()
    const imgUrl = pop_up_image || ''
    setImgUrl(imgUrl)
    return imgUrl
  }

  // 获取抽奖信息
  async function getLotteryInfo () {
    const data = await Apis.lotteryInfo()
    const { start_time, end_time } = data
    const nowTime = +new Date()
    showActIcon.value = nowTime > start_time && nowTime < end_time

    return data
  }

  // 是否有抽奖次数
  async function canLottery () {
    if (isLogin()) {
      const { lottery_count } = await getLotteryInfo()
      return lottery_count && lottery_count > 0
    }
    return false
  }

  // 显示判断
  function ifShowOnInitPage (isUrlLogin = false) {
    return !turntableAct.isShowedToday() && !isUrlLogin
  }

  // 是否初始化显示引导弹窗（未登入）
  async function visibleTurntableActGuidePopup (isUrlLogin) {
    const imgUrl = await getPopupInfo()
    if (ifShowOnInitPage(isUrlLogin) && !!imgUrl) {
      showPopup({ isTurntable: true })
      turntableAct.setLastViewPopupTime(+new Date())
      handleTrack('show_turntable_guide_popup')
    }
  }

  // 点击引导弹窗
  async function wakeUpTurntableAct () {
    const turntableApp = getTurntableApp()
    const _store = getVuexInstance()
    const { country_en_us } = _store.state.payment.defaultCountryInfo
    handleTrack('click_guide_join_turntable')

    // 为何要判断存在默认国家？
    // 大转盘抽奖后，会调用获取优惠券接口，该接口依赖 国家
    if (isLogin() && country_en_us && (await canLottery())) {
      turntableApp.manualStartApp()
    }
  }

  // 点击活动入口
  async function clickTurntableEntry () {
    const turntableApp = getTurntableApp()
    await getLotteryInfo()
    if (showActIcon.value) {
      handleTrack('click_turntable_act_icon')
      turntableApp.manualStartApp()
    } else {
      Toast($t('activity.actEndTip'))
    }
  }

  return {
    showActIcon,
    getPopupInfo,
    getLotteryInfo,
    canLottery,
    visibleTurntableActGuidePopup,
    wakeUpTurntableAct,
    clickTurntableEntry
  }
}
