<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
-->
<template>
  <div class="flex-box main">
    <van-field v-bind="$attrs" v-on="$listeners" ref="fieldEle" />
    <div
      @click="onclick"
      :class="['flex-center', 'field-right-btn', { disabled: disabledBtn }]"
    >
      {{ btnText }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, defineExpose } from 'vue'

const emit = defineEmits(['clickBtn'])

const props = defineProps({
  disabledBtn: {
    type: Boolean,
    default: false
  },
  btnText: {
    type: String,
    default: ''
  }
})

const onclick = (e) => {
  if (props.disabledBtn) return
  emit('clickBtn', e)
}

const fieldEle = ref(null)
defineExpose({ fieldEle })
</script>

<style lang="less" scoped>
/deep/ .van-field {
  border-radius: 60px;
  height: 60px;
  line-height: 60px;
  border: 0;
}
.main {
  padding: 14px;
  padding-right: 0;
}
.field-right-btn {
  font-size: var(--dp-text-2xl);
  font-weight: 400;
  color: var(--dp-text-tertiary);
  text-align: center;
  padding: 0 30px;
  white-space: nowrap;
  &.disabled {
    opacity: 0.4;
  }
}
</style>
