/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */
import router from '@/router'

// 获取appsConfig目录下所有js文件
const appsConfigFiles = require.context('./appsConfig', true, /\.js$/)
// 处理所有app引入
const apps = appsConfigFiles.keys().reduce((modules, modulePath) => {
  // 获取每个子应用配置
  const value = appsConfigFiles(modulePath).default
  // 提取子应用配置中当前环境配置
  if (value) {
    modules.push(value)
  }
  return modules
}, [])

const getActiveRule = (hash) => {
  return location.pathname + hash
}

// 统一处理前缀publicpath
apps.forEach((i) => {
  const { activeRule } = i
  if (activeRule) {
    i.activeRule = getActiveRule(i.activeRule)
  }
  i.props = i.props || {}
  // 传递主应用路由
  i.props.mainAppRouter = router
})

export function getSortApps () {
  const isRegisterApp = (app) => !!app.activeRule
  const isLoadApp = (app) => !app.activeRule
  const usableLoadMicroApps = apps.filter(isLoadApp)
  const usableRegisterMicroApps = apps.filter(isRegisterApp)

  return { usableLoadMicroApps, usableRegisterMicroApps }
}
