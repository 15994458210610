<!--
 * @Date: 2022-05-31 16:37:47
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-05-31 16:37:48
 * @FilePath: /dc-pay-front/src/views/Payment/mixins/MixinXsollaPay.vue
-->

<script>
import { urlConfig } from '@/utils/globalData.js'

export default {
  components: {},
  data () {
    return {}
  },
  methods: {
    // 唤醒 xsolla 支付
    showXsollaPayPage (order_info) {
      const { attachment } = order_info
      const { token } = attachment

      // 设置 pay_url ，使得能复用 showPayPageByUrl
      order_info.pay_url = urlConfig.getXsollaPayUrl(token)

      this.showPayPageByUrl(order_info)
    }
  },
  created () {}
}
</script>
