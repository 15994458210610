/*
 * @Date: 2022-06-02 10:22:50
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-02-05 15:55:35
 * @FilePath: /dc-pay-front/src/router/guard.js
 */
import { getVueVm } from '@/utils/index.js'
import User, { isLogin } from '@/utils/business/user.js'
import { useOnlyPaymentPageMode } from '@/hooks/useShoppingMode'
import { checkVersionUpdate } from '@/utils/business/version.js'

// 无需登录就能访问的路由
const NO_AUTH_ROUTERS = ['/', '/resetPassword', '/paySuccess', '/404']
const { interceptTo404 } = useOnlyPaymentPageMode()

// 更新vuex的isHome状态，非vue文件无法使用mapMutations
const updateIsHome = (isHome) => {
  const vm = getVueVm()
  if (vm) {
    vm.$store.commit('tool/UPDATE_TOOL_STATE', {
      key: 'isHome',
      value: isHome
    })
  } else {
    // 存在vue实例初始化较慢情况，短轮询更新，避免状态错误
    setTimeout(() => {
      updateIsHome(isHome)
    }, 100)
  }
}
const clearAllToast = () => {
  const vm = getVueVm()
  if (vm) {
    const { loadingInstanceList } = vm.$store.state.tool
    loadingInstanceList.forEach((item) => {
      vm.$store.dispatch('tool/closeLoading')
    })
  }
}

/**
 * @description: 路由是否登入才能访问
 * @param {*} path 路由地址
 * @return {boolean}
 */
const isAuthRouter = (path) => NO_AUTH_ROUTERS.indexOf(path) === -1
const scrollToTop = () => window.scrollTo(0, 0)

export const beforeEach = (to, from, next) => {
  const { path } = to
  const homePath = '/'
  const isHome = path === homePath

  if (interceptTo404(path)) {
    return next('/404')
  }

  updateIsHome(isHome)
  clearAllToast()
  if (isAuthRouter(path) && !isLogin()) {
    User.authExpire()
    return next(homePath)
  }

  next()
}

export const afterEach = (to, from) => {
  scrollToTop()
  checkVersionUpdate()
}
