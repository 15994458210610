import { ref, getCurrentInstance } from 'vue';
import { useThirdTactics } from '@/views/User/ThirdPartyLogin';
import Button from '@/components/ui/Button.vue';
export default {
  __name: 'ThirdLogoutBtn',
  setup: function setup(__props) {
    var _useThirdTactics = useThirdTactics(),
      logoutThird = _useThirdTactics.logoutThird,
      getThirdIdLabel = _useThirdTactics.getThirdIdLabel;
    var thirdLoginInfo = computed(function () {
      var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;
      return proxy === null || proxy === void 0 ? void 0 : proxy.$store.state.user.thirdLoginInfo;
    });
    // 三方id
    var thirdId = computed(function () {
      var _thirdLoginInfo$value;
      return (_thirdLoginInfo$value = thirdLoginInfo.value) === null || _thirdLoginInfo$value === void 0 ? void 0 : _thirdLoginInfo$value.third_id;
    });
    // // id label文案
    var labelText = computed(function () {
      var _thirdLoginInfo$value2;
      var third_type = (_thirdLoginInfo$value2 = thirdLoginInfo.value) === null || _thirdLoginInfo$value2 === void 0 ? void 0 : _thirdLoginInfo$value2.third_type;
      if (third_type) {
        return getThirdIdLabel(third_type);
      }
      return '';
    });
    var _usePopup = usePopup(),
      visible = _usePopup.visible,
      showPopup = _usePopup.showPopup;
    var onclickLabel = function onclickLabel() {
      showPopup();
    };
    var onOpenPopup = function onOpenPopup() {};
    function usePopup() {
      var visible = ref(false);
      var showPopup = function showPopup() {
        var isShow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        return visible.value = isShow;
      };
      return {
        visible: visible,
        showPopup: showPopup
      };
    }
    return {
      __sfc: true,
      logoutThird: logoutThird,
      getThirdIdLabel: getThirdIdLabel,
      thirdLoginInfo: thirdLoginInfo,
      thirdId: thirdId,
      labelText: labelText,
      visible: visible,
      showPopup: showPopup,
      onclickLabel: onclickLabel,
      onOpenPopup: onOpenPopup,
      usePopup: usePopup,
      ref: ref,
      getCurrentInstance: getCurrentInstance,
      useThirdTactics: useThirdTactics,
      Button: Button
    };
  }
};