import { render, staticRenderFns } from "./OpenScreenActTabs.vue?vue&type=template&id=3042791c&scoped=true&"
import script from "./OpenScreenActTabs.vue?vue&type=script&setup=true&lang=js&"
export * from "./OpenScreenActTabs.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./OpenScreenActTabs.vue?vue&type=style&index=0&id=3042791c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3042791c",
  null
  
)

export default component.exports