<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description: 优惠券item
-->

<template>
  <div class="item-main" @click="clickItem">
    <CouponTitle :item="item" style="flex-shrink: 0" />
    <div class="main-cont">
      <div class="flex-between">
        <p class="coupon-name">{{ item.coupon_name }}</p>
        <!-- 阻止点击事件冒泡导致详情弹窗展示 -->
        <div @click.stop="">
          <van-radio
            :name="item.role_coupon_id"
            v-model="useCouponData.coupon_id"
            v-if="openSelect"
          />
        </div>
      </div>
      <p class="flex-auto valid-time">
        {{ $t("coupon.validTimeLabel") }}{{ validTime }}
      </p>
      <template v-if="showApplicableText">
        <van-divider class="item-divider" />
        <p v-oneline="'4.5rem'" class="applicable-desc">{{ applicableText }}</p>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Divider as vanDivider, Radio as VanRadio } from 'vant'
import CouponTitle from '@/views/Coupon/components/CouponTitle.vue'
import { data as useCouponData } from '@/views/Coupon/popup/use.js'

const props = defineProps({
  openSelect: {
    type: Boolean,
    default: false
  },
  showApplicableText: {
    type: Boolean,
    default: true
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['handleClick'])

/**
 * @return {string} 适用范围文案
 */
const applicableText = computed(() => props.item.coupon_scope)
// 条件文案
const conditionText = computed(() => props.item.coupon_condition)
/**
 * @return {string} 有效时间文案
 */
const validTime = computed(() => {
  const { start_time, end_time, game_utc } = props.item
  let timezone = ''
  if (typeof game_utc === 'number') {
    const unit = game_utc < 0 ? '-' : '+'
    timezone = `（UTC ${unit}${Math.abs(game_utc)}）`
  }

  return `${start_time} ~ ${end_time} ${timezone}`
})

const couponDescInfo = computed(() => {
  return {
    applicableText,
    conditionText,
    validTime
  }
})

const clickItem = () => {
  emit('handleClick', { item: props.item, couponDescInfo })
}
</script>

<style lang="less" scoped>
.van-divider {
  margin: 0;
  margin-bottom: 15px;
}
.item-main {
  display: flex;
  flex-flow: row;
  background-color: var(--dp-bg-secondary);
}
.coupon-name {
  font-size: var(--dp-text-2xl);
  line-height: 1.32;
}
.main-cont {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 16px 10px 26px;
  width: calc(100% - 238px);

  .valid-time {
    font-size: var(--dp-text-sm);
    color: var(--dp-warning-primary);
    margin-top: 5px;
    line-height: 1.32;
  }
  .applicable-desc {
    font-size: var(--dp-text-sm);
    color: var(--dp-text-tertiary);
  }

  /deep/ .van-radio__icon {
    font-size: var(--dp-text-xl);
  }
}
.item-divider {
  /deep/ &.van-divider {
    border-color: #535FB5 !important;
  }
}
</style>
