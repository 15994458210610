/*
 * @Date: 2023-06
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 砍价活动
 */
import { getRouterInstance } from '@/utils'

export function useHaggleAct () {
  // 打开砍价结算页
  function openHaggleActPaymentPage (activity_order_id) {
    const _router = getRouterInstance()
    _router.push({
      path: '/payment',
      query: {
        activity_order_id
      }
    })
  }

  return { openHaggleActPaymentPage }
}
