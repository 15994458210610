<!--
 * @Date: 2022-11
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-12-13 09:48:55
 * @Description:
-->

<template>
  <div @click="onclickBar" class="flex-between bar-main">
    <div class="flex-vcenter bar-label">
      <div class="icon"></div>
      <span>{{ $t("coupon.deductionLabel") }}</span>
    </div>

    <div class="coupon-status">
      <!-- 暂无可用 -->
      <div class="empty-status" v-if="!existUseableCoupon">
        <span>{{ $t("coupon.emptyUseable") }}</span>
        <van-icon name="arrow" />
      </div>
      <!-- 选择之后 -->
      <div class="used-coupon" v-else-if="selectedCouponItem">
        <span>{{ viewMinusAmount }}</span>
        <van-icon class="icon-arrow" name="arrow" />
      </div>
      <!-- 存在可用券，但不使用 -->
      <p class="exist-coupon" v-else>
        {{ $t("coupon.existUseable", { num: data.couponInfo.coupons.length }) }}
      </p>
    </div>

    <UseCouponPop v-on="pick($listeners, ['convertSuccess','change'])" />

    <!-- 购买更多提示：当前抵扣金额不足最高的50%，添加更多商品... -->
    <div @click.stop="">
      <van-popover
        v-model="tipData.visiblePop"
        placement="top-end"
        :offset="[0, 38]"
      >
        <p class="buy-more-tip">
          {{ tipData.popMsg }}
        </p>
      </van-popover>
    </div>
  </div>
</template>

<script setup>
import { pick } from 'lodash-es'
import UseCouponPop from '@/views/Coupon/popup/Use.vue'
import {
  data,
  existUseableCoupon,
  selectedCouponItem,
  viewAmount,
  viewMinusAmount,
  UseCouponBarTool,
  useBuyMoreTip
} from '@/views/Coupon/popup/use.js'
import { Popover as VanPopover } from 'vant'
import { $t } from '@/i18n'

const emit = defineEmits(['clickBar'])

/** 操作 */
const { tipData } = useBuyMoreTip()

const onclickBar = () => {
  UseCouponBarTool.clickBar()
  emit('clickBar')
}
</script>

<style lang="less" scoped>
@import url("~@/styles/common.less");
@import url("~@/styles/mixin.less");

.bar-label {
  font-size: var(--dp-text-md);
  flex: auto;
}
.bar-main {
  padding: var(--dp-sp-xl) 0;
  position: relative;
}
.icon {
  .cust-bg(32px, 32px, "../assets/coupon/coupon_logo.svg");
  margin-right: var(--dp-sp-xl);
  margin-bottom: 2px;
}
.coupon-status {
  .empty-status {
    color: var(--dp-text-tertiary);
    span:first-child {
      font-size: var(--dp-text-md);
      margin-right: var(--dp-sp-xs);
    }
  }
  .exist-coupon {
    font-size: var(--dp-text-md);
    color: var(--dp-warning-primary);
    border: 1px solid var(--dp-warning-primary);
    // background: #ff4266;
    border-radius: 20px;
    // line-height: 40px;
    // color: #fff;
    padding: 3px 20px 1px 20px;
  }
  .used-coupon {
    font-size: 30px;
    font-weight: bold;
    span:first-child {
      color: var(--dp-warning-primary);
      margin-right: var(--dp-sp-lg);
    }
    .icon-arrow {
      font-size: var(--dp-text-xl);
      font-weight: 700;
    }
  }
}
.buy-more-tip {
  max-width: 614px;
  padding: 12px;
  font-size: var(--dp-text-sm);
  color: var(--dp-warning-secondary);
  line-height: 34px;
}
</style>
