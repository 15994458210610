/*
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */

export default {
  // 获取大转盘活动开屏弹窗信息
  getTurntableActPopupInfo: '/rolelottery/ActivityPopUp',
  // 获取活动配置信息
  getTopUpActPopupInfo: '/user/GetThirdActivity'
}
