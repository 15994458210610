/*
 * @Date: 2023-01
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description:
 */
import { initGlobalState } from 'qiankun'

const initState = {
  openActPopup: false
}

const { onGlobalStateChange, setGlobalState } = initGlobalState(initState)

export { onGlobalStateChange, setGlobalState }
