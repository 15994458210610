<!--
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-02
 * @Description: 优惠券描述条，固定在底部
-->

<template>
  <div
    ref="fixedBarRef"
    class="desc-text"
    v-oneline="'7.5rem'"
    v-if="couponDescText"
  >
    {{ couponDescText }}
  </div>
</template>

<script setup>
import { useCouponTip } from '@/views/Home/index.js'
import { ref, computed, nextTick, watch } from 'vue'

const fixedBarRef = ref(null)

const { coupons } = useCouponTip()
const couponDescText = computed(() => {
  const conditions = coupons.value.map(
    ({ coupon_condition }) => coupon_condition
  )
  const noDuplicatesConditions = [...new Set(conditions)]
  return noDuplicatesConditions.join('；')
})

// 设置占位节点，避免滚动到底部其他元素被遮挡
watch(couponDescText, () => {
  nextTick(() => {
    const id = 'CouponDescPlaceholder'
    let el = document.getElementById(id)
    if (fixedBarRef.value) {
      const height = fixedBarRef.value.clientHeight

      if (el) {
        el.style.height = `${height}px`
      } else {
        el = document.createElement('div')
        el.setAttribute('id', id)
        el.style.height = `${height}px`
        el.style.backgroundColor = 'var(--dp-bg-primary)'
        document.body.appendChild(el)
      }
    } else {
      el && el.parentNode.removeChild(el)
    }
  })
})
</script>

<style lang="less" scoped>
@import url("~@/styles/common.less");

.desc-text {
  .common-tip-text();
  line-height: 48px;
  padding: 0 40px;
  position: fixed;
  bottom: 0;
  // left: 0;
  margin: 0 auto;
}
</style>
