/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2022-10
 * @Description:
 */

export default {
  getDefaultLanguage: '/user/GetLang'
}
