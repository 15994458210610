<!--
 * @Date: 2021-12-15 14:15:42
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-02-23 14:02:13
 * @FilePath: /dc-pay-front/src/views/Home/components/FootBar.vue
-->
<template>
  <div class="main">
    <!-- 具有两个logo -->
    <div class="more-logo" v-if="companyLogo2">
      <img :src="companyLogo" alt="" />
      <img :src="companyLogo2" alt="" />
    </div>
    <!-- 只有一个logo -->
    <img :src="companyLogo" alt="" v-else />

    <div class="main-cont">
      <p class="protocol">
        <span
          class="protocol-item"
          @click="showProtocolPopup('privacy')"
        >【{{ $t("footbar.privacyPolicy") }}】</span>
        <span
          class="protocol-item"
          @click="showProtocolPopup('agreement')"
          style="margin-left: 0.1rem"
        >【{{ $t("footbar.userAgreement") }}】</span>
      </p>

      <span class="safe-text">{{ copyRightText }}</span>
    </div>
    <ProtocolPopup :type="type" ref="protocolRef"></ProtocolPopup>
  </div>
</template>

<script>
import ProtocolPopup from '@/views/Home/popup/ProtocolPopup.vue'
import {
  LanguageOperator,
  getCurrGameConfigProperty
} from '@/utils/business.js'
import { getVersionDetailInfo } from '@/utils/versionHelper'

export default {
  components: { ProtocolPopup },
  inject: ['versionInfo'],
  data () {
    return {
      copyRightText:
        getVersionDetailInfo()?.copyRightText ||
        getCurrGameConfigProperty('copyRightText'),
      // 公司主体 logo
      companyLogo: this.getCompanyLogoUrl('companyLogo'),
      // 第二个公司主体 logo (个别版本会配置)
      companyLogo2: this.getCompanyLogoUrl('companyLogoSecond'),
      type: ''
    }
  },
  methods: {
    showProtocolPopup (type) {
      this.type = type
      this.$refs.protocolRef.showPopup()
    },
    getCompanyLogoUrl (key) {
      const lang = LanguageOperator.getLangOnLocal()

      // 优先取版本配置
      const versionLogoConfig = getVersionDetailInfo()?.[key]
      let imgName = versionLogoConfig?.[lang] || versionLogoConfig?.default

      if (!imgName) {
        // 其次取游戏配置
        const gameLogoConfig = getCurrGameConfigProperty(key)
        imgName = gameLogoConfig?.[lang] || gameLogoConfig?.default
      }

      return imgName ? require(`@/assets/foot/${imgName}.png`) : ''
    }
  },
  created () {}
}
</script>
<style scoped lang="less">
.main {
  padding: 0 var(--dp-sp-lg);
  padding-bottom: var(--dp-sp-8xl);
  padding-top: var(--dp-sp-4xl);
  color: var(--dp-text-tertiary);
  text-align: center;
  font-size: var(--dp-text-sm);
  .more-logo {
    img {
      width: 104px;
      height: 104px;
    }
    img:first-child {
      margin-right: var(--dp-sp-6xl);
    }
  }
  img {
    width: 265px;
    height: 74px;
  }
  .main-cont {
    margin-top: var(--dp-sp-xl);
    .protocol {
      margin-bottom: var(--dp-sp-md);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .safe-text {
    font-weight: 400;
    display: inline-block;
  }
}
</style>
