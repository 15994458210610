<!--
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 入口列表
-->

<template>
  <div class="entry-main">
    <ul class="">
      <li
        class="flex-center"
        v-for="(item, index) in finalIcons"
        :key="index"
        @click="clickLi(item)"
      >
        <Popover
          class="popover-item"
          :value="item.showPopover && item.popoverText"
          getContainer="#app"
          :offset="[0, 12]"
          :placement="index === 0 ? 'top-start' : 'top'"
        >
          <div v-if="item.popoverText" class="popover-content">{{ item.popoverText }}</div>
          <template #reference>
            <!-- 通过color来切换是否显示红点 显示：#FF4266 隐藏：transparent -->
            <van-badge
              :dot="checkVisibleDot(item)"
              :color="checkVisibleDot(item) ? '#FF4266' : 'transparent'"
            >
              <!-- 入口icon -->
              <van-icon :name="item.iconPath" size=".88rem" />
              <!-- 右上角图片 -->
              <template #content v-if="checkVisibleImg(item)">
                <div>
                  <van-icon :name="item.badgeImg" size=".48rem" />
                </div>
              </template>
            </van-badge>
          </template>
        </Popover>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, defineEmits, onMounted } from 'vue'
import { Badge as VanBadge, Popover } from 'vant'
import { EntryData, useActIcon } from './EntryIcons.js'

const emit = defineEmits(['clickIcon'])

/** 操作 */
const { checkVisibleDot, checkVisibleImg } = useBadge()
const { actIcons, addListenerVisibleActIcons } = useActIcon()
const icons = computed(() => {
  return actIcons.value.concat(EntryData.icons)
})

const finalIcons = computed(() => {
  return icons.value.filter(item => {
    return !item.ifShow || item.ifShow() !== false
  })
})
onMounted(() => {
  addListenerVisibleActIcons()
})

function clickLi (item) {
  emit('clickIcon', item)
}

function useBadge () {
  function checkVisibleDot (item) {
    return item.showBadge && !item.badgeImg
  }
  function checkVisibleImg (item) {
    return item.showBadge && !!item.badgeImg
  }
  return { checkVisibleDot, checkVisibleImg }
}
</script>

<style lang="less" scoped>
@import url("~@/styles/common.less");
/deep/.van-badge--fixed {
  top: 14px !important;
  right: 18px !important;
}
/deep/.van-badge {
  border: 0;
}

.entry-main {
  padding: var(--dp-sp-3xl) var(--dp-sp-5xl);
  border-top: 1px solid var(--dp-divider-primary);
  overflow-y: auto;
  .van-badge__wrapper {
    display: flex;
  }
  ul {
    display: flex;
    li {
      width: var(--dp-icon-lg);
      height: var(--dp-icon-lg);
      border-radius: 50%;
      background-color: var(--dp-bg-quaternary);
      position: relative;
      margin-left: var(--dp-sp-2xl);
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}
.popover-content {
  .common-tip-text();
  line-height: 1.32;
  padding: var(--dp-sp-sm) var(--dp-sp-xl);
  padding-bottom: var(--dp-sp-xs);
  width: max-content;
  border-radius: var(--dp-b-rd-md);
  max-width: 3.5rem;
}
</style>
