/*
 * @Date: 2022-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-09
 * @Description:
 */
import {
  userAgent,
  handleTrack,
  getQueryString,
  getVuexInstance
} from '@/utils'
import { Toast } from 'vant'
import { productTransformData } from '@/utils/business/product.js'
import { computed, reactive, toRefs } from 'vue'
import { $t } from '@/i18n'
import { useStoreTool } from '@/utils/business/store.js'

const { closeAllLoading } = useStoreTool()

export function sendJumpTrack (products) {
  handleTrack('jump_payment_page', {
    cp_product_list: productTransformData(products)
  })
}

function unusableOnPC (payId) {
  const onlyMobilePayId = ['10233011', '10233012', '10233013', '10233014']
  const onlyMobileUsable = onlyMobilePayId.includes(payId)
  const { mobile } = userAgent()
  let isUnusable = false

  if (onlyMobileUsable && !mobile) {
    isUnusable = true
    Toast(
      'This channel can only be used on mobile devices, or contact customer service'
    )
  }

  return isUnusable
}
export function isUnusablePayId (payId) {
  if (unusableOnPC(payId)) return true
  return false
}

// myCard 支付后重定向回网页支付后 处理逻辑
export function useHandleMyCardCallback () {
  // 处理重定向后逻辑：ReturnCode=1时为支付成功，否则为失败需做提示
  function handleMyCardCallback () {
    let msg = ''
    if (getQueryString('ReturnCode') === '1') {
      // 支付成功
      msg = $t('form.paySuccessTip')
    } else {
      // 支付失败
      const errMsg = getQueryString('ReturnMsg')
      if (errMsg) {
        // 正则作用：渠道方返回的msg中+应为空格（https://blog.csdn.net/abcdefg367/article/details/84687249）
        msg = decodeURIComponent(errMsg.replace(/\+/g, '%20'))
      }
    }

    const _store = getVuexInstance()
    if (msg && _store) {
      _store.dispatch('tool/showDialog', {
        title: '',
        desc: msg,
        tipConfirmCallbak: () => {}
      })
    }
  }

  return { handleMyCardCallback }
}
// 活动订单逻辑
export function useActPayment () {
  const state = reactive({
    // 活动临时订单id（类似购物id）
    activity_order_id: getQueryString('activity_order_id')
  })
  const isActPayment = computed(() => !!state.activity_order_id)

  async function getActProducts (country) {
    const { activity_order_id } = state
    const { product_allowed } = await Apis.getActOrderInfo({
      activity_order_id,
      country
    })
    return product_allowed
  }

  return { ...toRefs(state), isActPayment, getActProducts }
}

// 返回支付弹窗公共 props
export function commonPopupProps () {
  return {
    closeable: true,
    'lazy-render': false,
    'close-on-click-overlay': false,
    round: true
  }
}

export function useListenerPopState () {
  const targetFn = () => {
    if (document.visibilityState === 'hidden') {
      closeAllLoading()
    }
  }
  function addListener () {
    document.addEventListener('visibilitychange', targetFn)
  }
  function removeListener () {
    document.removeEventListener('visibilitychange', targetFn)
  }

  return { addListener, removeListener }
}
