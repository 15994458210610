/*
 * @Date: 2022-06-17 10:47:53
 * @LastEditors: wfj
 * @LastEditTime: 2022-12
 * @FilePath: /dc-pay-front/src/utils/business/user.js
 */
import {
  getVersionByParseUrl,
  CacheUserForm,
  LanguageOperator,
  getSessionVuexData
} from '@/utils/business.js'
import { encodePwd, getVueVm, Base64 } from '@/utils/index.js'
import { Toast } from 'vant'
import { $t } from '@/i18n'

export const isLogin = () => {
  const { user_id: localstorage_user_id, server_id: localstorage_server_id } =
    CacheUserForm.getLocalUserForm() || {}

  const data = getSessionVuexData()
  const { user_id, token } = data?.user?.userInfo || {}
  const { server_id } = data?.user?.loginForm || {}
  return (
    !!user_id &&
    !!server_id &&
    !!token &&
    !!localstorage_server_id &&
    !!localstorage_user_id
  )
}

class Password {
  static checkPwdFormat () {}
}

class User {
  userInfo = {};

  constructor ({ server_id, role_no, pwd }) {
    this.userInfo = { server_id, role_no, pwd }
  }

  // 授权失效处理
  static authExpire () {
    Toast($t('error.401'))
    // 延迟原因：让用户看完toast，直接跳转太突兀
    setTimeout(() => User.logout(), 1500)
  }

  // 退出登入
  static logout () {
    const vm = getVueVm()
    if (vm) {
      const isHome = vm.$store.state.tool.isHome
      vm.$store.commit('user/UPDATE_STATE', { userInfo: {} })
      if (isHome) {
        // 首页时还需考虑从购买项定位到登录情况（直接刷新更为方便）
        location.reload()
      } else {
        vm.$store.dispatch('tool/goHome', { isReplace: true })
      }
    }
  }

  // api：重置密码
  resetPwd ({ is_pwd_auth, new_pwd, params }) {
    const data = { is_pwd_auth, new_pwd, params }
    data.new_pwd = encodePwd(new_pwd)

    return Apis.resetUserPwd(data)
  }

  // api：记录已显示过【提醒设置密码弹窗】
  tagShowedDialog () {
    return Apis.notPwdSetRemind()
  }

  /**
   * @description: api：发送邮件
   * @param: target 版本标识如：wsy-tw
   * @param: frontend_params 前端透传字段，该参数会被拼接到重置密码页url
   */
  async sendResetMail () {
    const { server_id, role_no } = this.userInfo
    const { versionName: target } = getVersionByParseUrl()

    const moreParams = { lang: LanguageOperator.getLangOnLocal() }
    const frontend_params = Base64.encode(moreParams)

    const { cd_time } = await Apis.sendResetMail({
      role_no,
      server_id,
      target,
      frontend_params
    })
    const emailCDTime = parseInt((cd_time - new Date().getTime()) / 1000)
    return { emailCDTime }
  }

  // api：判断是否存在密码、是否在登入应用密码
  async checkPsdConfig () {
    const { server_id, role_no } = this.userInfo
    const { role_info } = await Apis.checkExistPwd({ role_no, server_id })
    const { is_pwd_auth: usePsdLogin = false, if_has_pwd: existPwd = false } =
      role_info
    return { usePsdLogin, existPwd }
  }

  // api：登入
  async login (loginParam = {}) {
    const { server_id, role_no, pwd } = this.userInfo
    loginParam = Object.assign({ server_id, role_no, pwd }, loginParam)

    if (pwd) {
      loginParam.pwd = encodePwd(pwd)
    } else {
      delete loginParam.pwd
    }

    const userInfo = await Apis.userLogin(loginParam).catch(
      this.handleLoginError
    )
    return userInfo
  }

  // api：刷新token
  refreshToken () {
    return Apis.refreshToken()
  }

  handleLoginError (err) {
    const { code } = err

    // 密码错误处理
    if (code === 230) {
      const updateParam = { pwd: '' }
      const vm = getVueVm()

      CacheUserForm.setLocalUserForm(updateParam)
      vm && vm.$store.commit('user/UPDATE_LOGIN_FORM', updateParam)
    }
    return Promise.reject(err)
  }
}

export default User
