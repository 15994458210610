/*
 * @Date: 2022-05-28 15:13:06
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @FilePath: /dc-pay-front/src/store/modules/payment.js
 */
import { handleTrack, getVueVm } from '@/utils/index.js'
import { $t } from '@/i18n'
import { Toast } from 'vant'
import { countAmountInfo } from '@/utils/business/product.js'

export const PAYMENT_PAGE_MAP = {
  // 创建订单
  create: 'create',
  // 待支付订单
  uppaid: 'uppaid'
}
const state = {
  productList: [], // 要结算的商品列表
  // 支付页类型
  paymentPageType: PAYMENT_PAGE_MAP.create,
  // 选中的区服名称
  server_name: '',
  lastOrderInfo: {}, // 最近一笔订单信息
  defaultCountryInfo: {}, // 默认国家信息
  defaultCountryInfoForPayment: {} // 国家信息(用于结算页)
}
const getters = {
  // 是否存在默认国家
  existDefaultCountryInfo (state) {
    const { country_en_us } = state.defaultCountryInfo
    return !!country_en_us
  },
  // 当前为结算待支付订单页
  isUnpaidPage (state) {
    const { paymentPageType } = state
    return paymentPageType === PAYMENT_PAGE_MAP.uppaid
  },
  // 当前为正常结算商品页
  isCreatePaymentPage (state) {
    const { paymentPageType } = state
    return paymentPageType === PAYMENT_PAGE_MAP.create
  },
  amountTotalInfo (state) {
    const { productList } = state
    // 统计金额
    return countAmountInfo(productList)
  },
  // 未支付弹窗信息
  unpaidOrderInfoForPopup (state, getters, rootState) {
    const { order_info } = state.lastOrderInfo
    let price = ''

    if (order_info) {
      const { amount, currency } = order_info

      price = amount + ' ' + currency
    }

    return {
      price
    }
  }
}

const mutations = {
  UPDATE_PAYMENT_STATE (state, { key, value }) {
    state[key] = value
  }
}

const actions = {
  // 前往结算页
  goPaymentPage (
    { commit },
    data = { pageType: PAYMENT_PAGE_MAP.create, query: null }
  ) {
    const { pageType, query } = data
    const vm = getVueVm()
    commit('UPDATE_PAYMENT_STATE', {
      key: 'paymentPageType',
      value: pageType
    })
    vm.$router.push({ path: '/payment', query })
  },
  async getOrderInfo ({ commit, rootState }, country) {
    const { role_id, user_id } = rootState.user.userInfo
    const { server_id } = rootState.user.loginForm
    const { product_allowed } = await Apis.getOrderInfo({
      role_id,
      user_id,
      server_id,
      country
    })
    commit('UPDATE_PAYMENT_STATE', {
      key: 'productList',
      value: product_allowed
    })
  },
  /**
   * @description: 取消订单
   * @param {object} config 可选 { showTip: true }
   */
  cancelLastOrder ({ state, commit, rootState }, config = { showTip: true }) {
    const { showTip } = config
    const { role_id } = rootState.user.userInfo
    const { pay_id: pay_type_id, attachment } = state.lastOrderInfo.order_info
    const { order_id: cp_order_id } = attachment

    return Apis.cancelLastOrder({ role_id }).then((res) => {
      showTip && Toast($t('form.orderCancelTip'))
      handleTrack('cancel_order', {
        pay_type_id,
        cp_order_id
      })
    })
  },
  searchLastOrderState ({ state, commit, rootState }) {
    // return Promise.resolve({
    //   is_done: true
    // })
    const { role_id } = rootState.user.userInfo

    return Apis.searchLastOrder({ role_id }).then((res) => {
      const { attachment } = res.order_info

      // attachment 为string类型的json
      res.order_info.attachment =
        !!attachment && typeof attachment === 'string'
          ? JSON.parse(attachment)
          : {}

      commit('UPDATE_PAYMENT_STATE', {
        key: 'lastOrderInfo',
        value: res
      })
      return res
    })
  },
  // 获取默认国家（用于1.匹配支付方式国家下拉框默认选中项 2.获取支付列表入参）
  async getDefaultCountry ({ state, commit, getters }) {
    const { existDefaultCountryInfo } = getters
    if (!existDefaultCountryInfo) {
      const { country_info, country_info_for_payment } =
        await Apis.getDefaultCountry()

      commit('UPDATE_PAYMENT_STATE', {
        key: 'defaultCountryInfo',
        value: country_info
      })
      commit('UPDATE_PAYMENT_STATE', {
        key: 'defaultCountryInfoForPayment',
        value: country_info_for_payment
      })
    }

    return state.defaultCountryInfo
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
