<!--
 * @Date: 2022-06-06 17:49:37
 * @LastEditors: wfj
 * @LastEditTime: 2023-03
 * @FilePath: /dc-pay-front/src/components/FooterOperatorBar.vue
-->
<template>
  <div
    :class="['operator bottom-operate', { 'fixed-bottom': fixedBottom }]"
    ref="footerBarCont"
  >
    <!-- 购物车logo底栏 -->
    <div style="padding: 0.4rem 0.3rem" v-if="isCartLogo">
      <CartEntrance></CartEntrance>
    </div>

    <!-- 购物车操作底栏 -->
    <div
      class="flex-between flex-vcenter operator-cart"
      v-else-if="isCartOperator"
    >
      <slot name="customCartOperator"></slot>
      <template v-if="!$slots.customCartOperator">
        <div
          class="flex-between flex-vcenter flex-auto"
          style="margin-right: 0.16rem"
        >
          <!-- 已选 -->
          <p>{{ $t("cart.selected") }} {{ amountTotalInfo.count }}</p>
          <template v-if="!!amountTotalInfo.count">
            <!-- 存在券后价 -->
            <p v-if="businessInfo.discountCountPrice">
              <!-- 合计 -->
              <span style="font-weight: bold">{{ $t("cart.count") }}</span>
              <span style="font-size: 0.24rem; font-weight: bold">{{
                amountTotalInfo.amount + " " + amountTotalInfo.currency
              }}</span>

              <DiscountPrice
                :label="$t('product.futurePrices')"
                :value="businessInfo.discountCountPrice"
                :valueStyle="{ 'font-size': '0.24rem' }"
              />
            </p>
            <!-- 不存在券后价 -->
            <p v-else>
              <!-- 合计 -->
              {{ $t("cart.count") }}
              <span class="price wrap">{{
                amountTotalInfo.amount + " " + amountTotalInfo.currency
              }}</span>
            </p>
          </template>
        </div>
        <Button
          :disabled="!selectedCartList.length"
          :loading="loading"
          type="info"
          style="min-width: 2.2rem;width: auto;"
          @click="handleClick"
        >
          {{ text }}
        </Button>
      </template>
    </div>

    <!-- 购买项组合、收藏夹详情弹窗的操作底栏 -->
    <div
      class="flex-between flex-vcenter operator-cart"
      v-else-if="isGroupOperator"
    >
      <div
        class="flex-between flex-vcenter flex-auto"
        style="margin-right: 0.2rem"
      >
        <!-- 存在券后价布局 -->
        <p v-if="businessInfo.discountCountPrice">
          <!-- 合计 -->
          {{ $t("cart.count") }}
          <span style="font-size: 0.24rem">{{ businessInfo.groupAmount }}</span>

          <!-- 小字体原因：组合详情的底部操作栏有两个按钮，避免按钮被挤压出现三行文字情况 -->
          <DiscountPrice
            :label="$t('product.futurePrices')"
            :value="businessInfo.discountCountPrice"
            :valueStyle="{ 'font-size': '0.2rem' }"
          />
        </p>
        <!-- 不存在券后价布局 -->
        <p v-else>
          {{ $t("cart.count") }}
          <span class="price wrap">{{ businessInfo.groupAmount }}</span>
        </p>
      </div>

      <!-- 父组件传额外按钮 -->
      <slot name="groupBtn"></slot>
      <Button
        :loading="loading"
        type="info"
        style="min-width: 2.2rem; width: auto;"
        @click="handleClick"
      >
        {{ text }}
      </Button>
    </div>

    <!-- 单个按钮 -->
    <Button
      class="btn-style"
      :loading="loading"
      type="info"
      v-else
      @click="handleClick"
    >
      {{ text }}
    </Button>
  </div>
</template>

<script>
import CartEntrance from '@/components/CartEntrance.vue'
import DiscountPrice from '@/components/DiscountPrice.vue'
import { mapGetters } from 'vuex'
import { insertStrutDomToBody } from '@/utils/index.js'
import Button from './ui/Button.vue'

export const FOOTER_BAR = {
  // 购物车logo
  cartLogo: 'cartLogo',
  // 购物车操作栏
  cartOperator: 'cartOperator',
  // 按钮
  btn: 'btn',
  // 推荐组、收藏夹的详情弹窗底栏
  groupOperator: 'groupOperator'
}
export default {
  components: { CartEntrance, DiscountPrice, Button },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('cart', ['selectedCartList', 'amountTotalInfo']),
    isCartLogo () {
      return this.footerBarType === FOOTER_BAR.cartLogo
    },
    isCartOperator () {
      return this.footerBarType === FOOTER_BAR.cartOperator
    },
    isBtn () {
      return this.footerBarType === FOOTER_BAR.btn
    },
    isGroupOperator () {
      return this.footerBarType === FOOTER_BAR.groupOperator
    }
  },
  props: {
    // 按钮是否加载中
    loading: {
      type: Boolean,
      default: false
    },
    // 底栏类型
    footerBarType: {
      type: String,
      default: FOOTER_BAR.btn
    },
    // 是否固定于页面底部
    fixedBottom: {
      type: Boolean,
      default: true
    },
    // 按钮文案
    text: {
      type: String,
      default () {
        return this.$t('cart.payment')
      }
    },
    /**
     * 耦合业务的信息通过 businessInfo 传递
     * 格式 { discountCountPrice: 券后价格, groupAmount: 组的金额, strutDom: 指定要撑开的节点 }
     */
    businessInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClick () {
      this.$emit('submit')
    },
    insertElement () {
      const height = this.$refs.footerBarCont?.clientHeight
      if (height) {
        this.strutElement = insertStrutDomToBody(height, {
          dom: this.businessInfo?.strutDom
        })
      }
    }
  },
  mounted () {
    if (this.businessInfo?.strutDom) {
      setTimeout(() => {
        this.insertElement()
      }, 500)
    }
  },
  destroyed () {
    const { strutElement } = this
    const parent = strutElement?.parentNode

    if (parent) {
      parent.removeChild(strutElement)
    }
  }
}
</script>
<style scoped lang="less">
// 覆盖右上角红色数字位置

.operator {
  .operator-cart {
    /deep/.van-checkbox__label {
      color: var(--dp-text-secondary);
    }
    margin: var(--dp-sp-xl);
    padding-left: var(--dp-sp-sm);
    .price {
      color: var(--dp-warning-primary);
      font-size: 32px;
      font-weight: bold;
      &.wrap {
        margin-top: 5px;
        display: block;
      }
    }
  }
  .btn-style {
    width: 90%;
    height: 72px;
    transform: translateX(5%);
    margin: 20px 0;
  }
}
</style>
