<!--
 * @Date: 2022-08-16 11:13:35
 * @LastEditors: wfj
 * @LastEditTime: 2023-10
 * @FilePath: /dc-pay-front/src/components/ProductGroupDetailPopup.vue
-->
<template>
  <!-- @click.stop 避免点击弹窗后，点击事件冒泡到父组件导致选中购买项 -->
  <div @click.stop="">
    <van-popup v-model="show" round closeable position="bottom">
      <div class="detail" ref="detailRef">
        <p class="title">{{ groupInfo.name }}</p>
        <ul class="products">
          <ProductItem
            class="product-item"
            v-for="item in products"
            :key="item.id"
            :productItemInfo="item"
            showCouponPrice
            showNumText
            selectedStyle="none"
            operatorPosition="none"
            @changeAmount="changeAmount"
          ></ProductItem>
        </ul>
      </div>

      <!-- 操作底栏 -->
      <FooterOperatorBar
        :text="$t('cart.buyRightnow')"
        :footerBarType="FOOTER_BAR.groupOperator"
        :loading="loading.buy"
        :businessInfo="propForFooterBar"
        @submit="clickBuy"
      >
        <template #groupBtn>
          <Button
            plain
            type="cancel"
            :loading="loading.addCart"
            style="margin-right: 0.1rem; width: auto;"
            v-if="!withoutShoppingCartMode"
            @click="clickAddCart"
          >
            {{ $t("fav.addCartBtn") }}
          </Button>
        </template>
      </FooterOperatorBar>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { countAmountInfo, parseFloatNum } from '@/utils/business/product.js'
import ProductItem from '@/views/Home/ProductItem/ProductItem.vue'
import FooterOperatorBar, {
  FOOTER_BAR
} from '@/components/FooterOperatorBar.vue'
import Button from './ui/Button.vue'

export default {
  components: { Button, ProductItem, FooterOperatorBar },
  data () {
    return {
      loading: {
        buy: false,
        addCart: false
      },
      show: false
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'loginForm']),
    ...mapState('payment', ['defaultCountryInfo']),
    ...mapGetters('sys', ['withoutShoppingCartMode']),

    propForFooterBar () {
      const { viewPrice, totalInfo, groupInfo } = this
      const { amount, currency } = totalInfo
      const { total_amount_free_estimate = 0 } = groupInfo
      const price = parseFloatNum(amount - total_amount_free_estimate)
      const result = {
        groupAmount: viewPrice
      }

      if (total_amount_free_estimate) {
        result.discountCountPrice = `${price} ${currency}`
      }

      return result
    },
    totalInfo () {
      return countAmountInfo(this.products) || {}
    },
    viewPrice () {
      const { amount, currency } = this.totalInfo
      return `${amount} ${currency}`
    }
  },
  props: {
    // 组信息 {id,name,total_amount_free_estimate: 总共优惠的价格}
    groupInfo: {
      type: Object,
      default: () => ({})
    },
    // 购买项列表
    products: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('payment', ['goPaymentPage']),
    ...mapActions('cart', ['submitCartByProducts']),

    changeAmount () {},
    async clickAddCart () {
      this.$emit('addCart', this.groupInfo.id)
    },
    async clickBuy () {
      const { products } = this

      this.$emit('clickBuy', { products })
      this.loading.buy = true
      await this.submitCartByProducts({ products }).finally(
        () => (this.loading.buy = false)
      )
      this.goPaymentPage()
    },
    showPopup (isShow = true) {
      this.$nextTick(async () => {
        this.show = isShow
      })
    }
  },
  mounted () {},
  created () {
    this.FOOTER_BAR = FOOTER_BAR
  }
}
</script>
<style scoped lang="less">
.detail {
  // padding: 38px 38px 0 38px;
  .title {
    line-height: 100px;
    font-size: var(--dp-text-2xl);
    padding: 0 var(--dp-sp-6xl);
    border-bottom: 1px solid var(--dp-divider-primary);
    background-color: var(--dp-bg-secondary);
  }
  .products {
    padding: 30px 40px;
    overflow-y: auto;
    max-height: 80vh;
    padding-bottom: 150px;
    .product-item {
      padding: 30px 0;
    }
  }
}
</style>
