<!--
 * @Date: 2022-03-03 15:52:33
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-05-14 15:38:22
 * @FilePath: /dc-pay-front/src/views/Home/popup/SearchProductPopup.vue
-->
<template>
  <div>
    <van-popup v-model="showPop" @closed="onClosedPop" @opened="onOpenedPop">
      <div class="search-bar">
        <FieldBtn
          ref="searchInputRef"
          v-model="keyword"
          clearable
          :placeholder="$t('common.searchHint')"
          :btnText="$t('common.cancel')"
          @input="changeSearchKeyword"
          @blur="blurField"
          @clickBtn="showPopup(false)"
        >
        </FieldBtn>
        <div class="search-bar_logo"></div>
      </div>

      <ul class="list" v-if="transformProducts.length">
        <li
          v-for="item in transformProducts"
          :class="['list-item', { disabled: item.disabled }]"
          :key="item.originItem.id"
          @click="onclickItem(item)"
        >
          <div class="list-item__cont">
            <p v-html="item.name" class="list-item__name"></p>
            <p class="common-price">
              {{ item.price }}
            </p>
          </div>

          <!-- 存在子元素才padding （prop-cont） -->
          <div
            :class="[
              'flex-between',
              'flex-vcenter',
              { 'prop-cont': !!item.propsName || !item.disabled },
            ]"
          >
            <!-- 道具名称列表 -->
            <p v-html="item.propsName" class="props-name"></p>
            <!-- 步进器 -->
            <div>
              <Stepper
                v-if="!withoutShoppingCartMode && !item.disabled"
                :disable-plus="isDisabledPlusBtn(item.originItem)"
                v-model="item.originItem.count"
                @change="changeAmount(arguments[0], item.originItem)"
              ></Stepper>
            </div>
          </div>
        </li>
      </ul>

      <!-- 列表为空展示 -->
      <van-empty
        v-else
        class="custom-image"
        :image="require('@/assets/empty/cart_empty.png')"
        description=""
      />

      <CartEntrance
        v-if="!withoutShoppingCartMode"
        class="common-cart-entry-fixed"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, handleTrack } from '@/utils/index.js'
import CartEntrance from '@/components/CartEntrance.vue'
import { Empty } from 'vant'
import Stepper from '@/components/Stepper.vue'
import { isOverbalance, isDisabled } from '@/utils/business/product.js'
import { validateBeforeUpdateCartCount } from '@/utils/business/cart.js'
import FieldBtn from '@/components/FieldBtn.vue'

export default {
  components: { FieldBtn, CartEntrance, Stepper, [Empty.name]: Empty },
  data () {
    return {
      keywords: [], // 用户输入的关键词（输入冷却2s存储一次）
      showPop: false,
      keyword: ''
    }
  },
  props: {
    // 购买项列表
    productInfos: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('sys', ['withoutShoppingCartMode']),
    ...mapGetters('product', ['mutexGroup']),

    /**
     * 过滤后的购买项列表
     * [{
     *    price: string,
     *    name: element,
     *    disabled: boolean
     *    originItem: 原始 item
     * }]
     */
    transformProducts () {
      return this.sortedProducts.map((item) => {
        let { amount_display, currency, name, content } = item
        const disabled = isDisabled(item)
        const { keyword, genHighLightEle, includesString } = this
        const propsName = content
          .filter((prop) => includesString(prop.item_name, keyword))
          .map((prop) => {
            return disabled
              ? prop.item_name
              : genHighLightEle(prop.item_name, keyword)
          })
          .join('、')

        // 仅未禁用时高亮
        if (!disabled) {
          name = genHighLightEle(name, keyword)
        }

        return {
          price: amount_display + ' ' + currency,
          name,
          propsName,
          disabled,
          originItem: item
        }
      })
    },
    // 排好序的购买项列表：禁用项置于底部
    sortedProducts () {
      const activeList = []
      const disabledList = this.filteredProducts.filter((item) => {
        const disabled = isDisabled(item)
        if (!disabled) {
          activeList.push(item)
        }
        return disabled
      })

      return activeList.concat(disabledList)
    },
    // 与用户输入key匹配的 list
    filteredProducts () {
      const { keyword, productInfos } = this

      if (!keyword) return []
      return productInfos.filter((item) => {
        const { name, content } = item
        // 命中购买项名称
        const isHitName = this.includesString(name, keyword)
        // 命中道具名称
        const isHitPropName = content.some((prop) =>
          this.includesString(prop.item_name, keyword)
        )
        return isHitName || isHitPropName
      })
    }
  },
  methods: {
    isDisabledPlusBtn (product) {
      const { mutex_id } = product
      const { isMaximal = false } = this.mutexGroup[mutex_id] || {}
      return isOverbalance(product) || isMaximal
    },
    /**
     * @description: 当点击搜索列表的调整购物车数量按钮时，无法监听到 productInfos 的禁用属性，导致列表禁用项未更新。
     * 调用该方法来使得 filteredProducts 触发，从而更新列表禁用状态
     * @return {*}
     */
    updateList () {
      this.keyword += ' '
      this.keyword = this.keyword.trim()
    },
    includesString (parentStr, childStr) {
      // 不区分大小写
      parentStr = parentStr.toLowerCase()
      childStr = childStr.toLowerCase()
      return parentStr.includes(childStr)
    },
    genHighLightEle (value, keyword) {
      const rex = new RegExp(keyword, 'i')
      // 命中的文字
      const hitText = value.match(rex)?.[0] || ''
      const makeProductNameElement = `<span style="color:#FFAD14">${hitText}</span>`
      return value.replace(rex, makeProductNameElement)
    },
    async changeAmount (targetCount, product) {
      const { count } = product
      await validateBeforeUpdateCartCount({ product, targetCount, vm: this })
      this.$emit('changeAmount', { count, product, targetCount })
    },
    blurField () {
      // 由于输入冷却2s才存储到 keywords ，为避免用户输入后立即触发失焦导致遗漏存储问题，在失焦后存一次
      if (this.keyword) {
        this.keywords.push(this.keyword)
      }
      if (!this.keywords.length) return

      let keywords = [...this.keywords]
      this.keywords = []

      // 去重，去空，取最新100条
      keywords = Array.from(new Set(keywords))
        .filter((item) => !!item)
        .slice(-100)
      handleTrack('filter_products', { keywords })
    },
    changeSearchKeyword (val) {
      this.setKeywordsFn(val)
    },
    onOpenedPop () {
      this.$nextTick(() => {
        const fieldEle = this.$refs.searchInputRef?.fieldEle
        fieldEle && fieldEle.focus()
      })
    },
    onClosedPop () {
      this.keyword = ''
    },
    showPopup (isShow = true) {
      this.showPop = isShow
    },
    onclickItem (item) {
      this.$emit('selectProduct', item.originItem)
      this.showPopup(false)
    }
  },
  created () {
    this.setKeywordsFn = debounce((val) => {
      this.keywords.push(val)
    }, 2000)
  }
}
</script>
<style scoped lang="less">
@import url("~@/styles/mixin.less");
@import url("~@/styles/common.less");

.van-popup {
  width: 750px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dp-bg-primary);
}
.search-bar {
  position: relative;
  // border: 0.5px solid #dcdcdc;
  border-bottom: 0.5px solid var(--dp-divider-secondary);
  background-color: var(--dp-bg-secondary);
  /deep/ .van-field {
    padding-left: 0.64rem;
  }
  .search-bar_logo {
    position: absolute;
    .cust-bg(0.24rem, 0.24rem, "../assets/search/search_logo_gray.svg");
    z-index: 1;
    left: 0.4rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.list {
  padding-bottom: 100px;
  flex: 1;
  overflow: auto;
  color: var(--dp-text-primary);
  padding: 0 14px;
  .list-item {
    border-bottom: 1px solid var(--dp-divider-primary);
    padding: 20px 10px;
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    .list-item__cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .list-item__name {
        font-size: var(--dp-text-md);
      }
    }
  }
}
.prop-cont {
  .props-name {
    font-size: 20px;
    color: var(--dp-text-tertiary);
    line-height: 30px;
    flex: 1;
  }
}
/deep/.custom-image .van-empty__image {
  width: 400px;
  height: 310px;
}
</style>
