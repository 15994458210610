/* eslint-disable no-dupe-class-members */
/*
 * @Date: 2023-06
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description:
 */
import { handleTrack, TimeCount } from '@/utils'

export class MicroAppTrack {
  microAppName = '';
  timeCountMap = {
    loadPromiseTimeCount: null,
    bootstrapPromiseTimeCount: null,
    mountPromiseTimeCount: null
  };

  constructor ({ microAppName }) {
    this.microAppName = microAppName
    this.timeCountMap = {
      loadPromiseTimeCount: new TimeCount(),
      bootstrapPromiseTimeCount: new TimeCount(),
      mountPromiseTimeCount: new TimeCount()
    }
  }

  startTrack () {
    this.#commonTrack('micro_app_start_loading')
    this.#startAllTimeCount()
  }

  loadTrack () {
    const { loadPromiseTimeCount } = this.timeCountMap

    this.#commonTrack('micro_app_load_promise', {
      spend_time: loadPromiseTimeCount.timeEnd()
    })
  }

  bootstrapTrack () {
    const { bootstrapPromiseTimeCount } = this.timeCountMap

    this.#commonTrack('micro_app_bootstrap_promise', {
      spend_time: bootstrapPromiseTimeCount.timeEnd()
    })
  }

  mountTrack () {
    const { mountPromiseTimeCount } = this.timeCountMap

    this.#commonTrack('micro_app_mount_promise', {
      spend_time: mountPromiseTimeCount.timeEnd()
    })
  }

  loadFailedTrack (event_data) {
    this.#commonTrack('micro_app_loaded_failed', event_data)
  }

  #startAllTimeCount () {
    const { timeCountMap } = this
    for (const key in timeCountMap) {
      const timer = timeCountMap[key]
      timer.time()
    }
  }

  #commonTrack (eventName, event_data = {}) {
    const { microAppName } = this
    event_data = handleTrack(eventName, { microAppName, ...event_data })
  }
}
