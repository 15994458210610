/*
 * @Date: 2023-01
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 砍价
 */
import { ActHaggleActions } from '@/qiankun/actions/act-haggle.js'
import { STATIC_STATE } from '@/qiankun/actBusiness/common.js'

function useConfig () {
  function getEnvActConfig () {
    const envCommonConfig = {
      name: 'act-haggle',
      activeRule: '#/micro-app/haggle',
      container: `#${STATIC_STATE.registerMountElId}`,
      props: {
        sharedProps: {},
        mainAppActions: new ActHaggleActions()
      }
    }

    return {
      dev: {
        entry: '//192.168.5.125:32464/thematic/gok-haggle/',
        ...envCommonConfig
      },
      sit: {
        entry: '//192.168.5.125:32464/thematic/gok-haggle/',
        ...envCommonConfig
      },
      uat: {
        entry: '//192.168.5.125:32464/thematic/gok-haggle/',
        ...envCommonConfig
      },
      pre: {
        entry: 'https://activity-eu.clktec.com/thematic/gok-haggle/',
        ...envCommonConfig
      },
      pro: {
        entry: 'https://activity-eu.clktec.com/thematic/gok-haggle/',
        ...envCommonConfig
      }
    }
  }

  function getConfig () {
    const env = process.env.VUE_APP_ENV || 'dev'
    return getEnvActConfig()[env] || null
  }

  return { getConfig }
}

const { getConfig } = useConfig()

export default getConfig()
