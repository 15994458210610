<template>
  <Dialog
    v-model="visible"
    show-cancel-button
    @confirm="onConfirm"
    @opened="opened"
    @closed="closed"
  >
    <p>{{ $t('cart.editAmountTitle') }}</p>
    <Stepper
      v-model="amount"
      class="stepper-style"
      ref="stepperRef"
      :disable-input="false"
      theme=""
      integer
      button-size=".55rem"
      input-width=".9rem"
      v-bind="{...$attrs, max: props.max}"
      @originChange="originChange"
    ></Stepper>
    <p class="err-msg" v-if="errMsg">{{ errMsg }}</p>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import Stepper from '@/components/Stepper.vue'
import { ref, defineExpose } from 'vue'
import { isNumber, isString } from 'lodash-es'
import { $t } from '@/i18n'

const visible = ref(false)
const amount = ref() // 步进器数量
const stepperRef = ref()
const errMsg = ref('')
const props = defineProps({
  max: {
    type: Number,
    default: Infinity
  }
})
const emit = defineEmits(['confirm'])

const openPopup = ({ count }) => {
  amount.value = count
  visible.value = true
}

const opened = () => {
  // 光标聚焦到stepper组件的input
  stepperRef.value.$el.firstChild.children[1].focus()
}

const closed = () => reset()
const onConfirm = () => emit('confirm', amount.value)

const originChange = (val) => {
  amount.value = val

  if (isNumber(val)) {
    if (val < 1) {
      errMsg.value = $t('cart.biggerThanZeroMsg')
      return
    } else if (val >= props.max) {
      errMsg.value = $t('cart.maxAmountErrMsg', { max: props.max })
      return
    }
  } else if (isString(val)) {
    if (['', '-'].includes(val)) {
      errMsg.value = $t('cart.biggerThanZeroMsg')
      return
    }
  }
  errMsg.value = ''
}

function reset () {
  errMsg.value = ''
}

defineExpose({ openPopup })
</script>

<style lang="less" scoped>
/deep/ .stepper-root {
  .van-stepper__input {
    font-size: var(--dp-text-xl);
    color: var(--dp-text-tertiary);
  }
}
/deep/ .dialog-container{
  .dialog-content{
    display: block;
  }
}
.stepper-style{
  padding: 70px 0 0 0;
}
.err-msg{
  margin-top: 60px;
  color: var(--dp-error-primary);
}
</style>
